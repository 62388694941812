import IAPPLogoIcon from "app/assets/IconComponent/IAPPLogoIcon";

const IPPageNumber = ({ number }: { number: string | number }) => {
  const pageNumber = number.toString();
  return (
    <div className="w-full">
      <div className="flex items-center justify-center space-x-6 mb-2">
        <div className="flex">
          <div className="w-1rem">
            <IAPPLogoIcon fill="gray" />
          </div>
          <span
            className="font-semibold whitespace-nowrap ml-2 conthrax-font align-content-center "
            style={{ color: "#7f8181" }}
          >
            Powered by IAPP
          </span>
        </div>
        <div className="flex-1">
          <div className="bg-gradient-to-l from-gray-500 to-transparent h-[2px]" />
        </div>
        <div className="conthrax-font" style={{ color: "#7f8181" }}>
          {pageNumber}
        </div>
      </div>
    </div>
  );
};

export default IPPageNumber;
