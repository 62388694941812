import React from "react";

interface RectangleCurvedProps {
  fill?: string; // Add fill prop
}

const RectangleCurved: React.FC<RectangleCurvedProps> = ({ fill = "#0053D0" }) => (
  <svg width="5" height="53" viewBox="0 0 5 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0.333313L2.31576 10.3683C4.74007 20.8736 4.74006 31.793 2.31576 42.2983L0 52.3333V0.333313Z"
      fill={fill}
    />
  </svg>
);

export default RectangleCurved;
