import SummaryBGImg from "../images/InteractiveProposalSummary.png";
import HexagonImg from "../images/hexagonImg.png";
import IAppLogoWithText from "../../../../app/assets/IAppLogoWithText.png";
import { formatDateWordDateWithoutLocal } from "app/utils/luxon";
import { getProposalType } from "app/utils/utils";
import IAPPLogoIcon from "app/assets/IconComponent/IAPPLogoIcon";

const IPIndex = ({
  proposalType,
  proposalNo,
  entityName,
  startDate,
  indexPage,
}: {
  proposalType: any;
  proposalNo: string;
  entityName: string;
  startDate: any;
  indexPage: any;
}) => {
  const date = formatDateWordDateWithoutLocal(startDate);
  return (
    <div className="conthrax-font h-full">
      <div className="flex flex-col items-center text-white h-full justify-between mx-auto">
        {/* Header Section */}
        <div className="flex items-center pt-4 w-full">
          <div
            className="h-[5px] bg-gradient-to-r from-black to-transparent transform"
            style={{ width: "30%", marginRight: "-2px" }}
          />
          <h2 className="text-[1.7em] pr-4" style={{ color: "#091927" }}>
            {entityName}
          </h2>
        </div>
        <div
          style={{
            backgroundImage: `url(${SummaryBGImg})`,
            backgroundSize: "cover",
            width: "100%",
            height: "49%",
          }}
        >
          <div className="text-center mt-[8em]">
            <h1 className="text-[3em] font-bold tracking-wider uppercase interactive-proposal-text">
              {getProposalType(proposalType)}
            </h1>
            <h3 className="text-[2em] interactive-proposal-text">Proposal {proposalNo}</h3>
            <div className="text-[2em]" style={{ textShadow: "2px 2px #8f8f8f" }}>
              {date}
            </div>
          </div>
        </div>

        {/* Logo Section */}
        <div className="items-center justify-center gap-[1em]">
          <img src={IAppLogoWithText} alt="Logo" style={{ width: "23em" }} />
        </div>

        <div
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
            width: "100%",
            height: "49%",
          }}
        >
          <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em]">
            {indexPage?.map((item: any, index: any) => (
              <div className="relative flex items-center justify-between mb-[1em]" key={index}>
                <div
                  className="text-[1.25em] font-bold tracking-wide z-10 mr-2"
                  style={{ color: "#091927" }}
                >
                  {item.title}
                </div>

                {/* Line from text to hexagon */}
                <div
                  className="h-[2px] bg-gradient-to-r from-black to-transparent transform w-full"
                  style={{
                    marginRight: "-3px",
                  }}
                />

                {/* Hexagon */}
                <div className="relative z-10 flex items-center justify-center h-[3em] ml-auto mr-[0.75em] conthrax-font">
                  <div className="relative">
                    <svg
                      fill="black"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="40px"
                      height="40px"
                      viewBox="0 0 485.688 485.688"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          d="M364.269,453.155H121.416L0,242.844L121.416,32.533h242.853l121.419,210.312L364.269,453.155z"
                          style={{ fill: "white" }}
                        />
                        <g>
                          <path
                            d="M364.269,453.155H121.416L0,242.844L121.416,32.533h242.853l121.419,210.312L364.269,453.155z M131.905,434.997h221.878
l110.939-192.152L353.783,50.691H131.905L20.966,242.844L131.905,434.997z"
                          />
                        </g>
                      </g>
                    </svg>
                    <div
                      className="absolute inset-0 flex items-center justify-center conthrax-font"
                      style={{ fontSize: "1.5em", color: "black" }}
                    >
                      {item.number}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-[3em] px-[4em] w-full max-w-[56em] mx-auto">
          <div className="flex items-center justify-center">
            {/* Left gradient line - fading from text */}
            <div className="flex-1">
              <div className="bg-gradient-to-r from-black to-transparent h-[2px]" />
            </div>

            <div className="flex items-center mx-2">
              <div className="w-1rem">
                <IAPPLogoIcon fill="black" />
              </div>
              <span
                className="font-semibold whitespace-nowrap ml-[0.5em]"
                style={{ color: "#091927" }}
              >
                Powered by IAPP
              </span>
            </div>

            {/* Right gradient line - fading from text */}
            <div className="flex-1">
              <div className="bg-gradient-to-l from-black to-transparent h-[2px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPIndex;
