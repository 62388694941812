import { Course, CourseData } from "@/app/utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Action Types
export const FETCH_ALL_COURSES = "FETCH_ALL_COURSES";
export const FETCH_COURSE_DETAILS = "FETCH_COURSE_DETAILS";
export const SELECT_CURRENT_LESSON = "SELECT_CURRENT_LESSON";
export const SELECT_CURRENT_SUB_LESSON = "SELECT_CURRENT_SUB_LESSON";
export const RESET_COURSE_DETAILS = "RESET_COURSE_DETAILS";
// Action Creators
export const fetchAllCourses = () => ({
  type: FETCH_ALL_COURSES,
});

export const fetchCourseDetails = (courseId: string, setIndexes: boolean = false) => ({
  type: FETCH_COURSE_DETAILS,
  payload: { courseId, setIndexes },
});

export const resetCourseDetails = () => ({
  type: RESET_COURSE_DETAILS,
});

// Define interfaces

interface Comment {
  id: number;
  author: string;
  profilePic: string;
  timeAgo: string;
  comment: string;
  replies: number;
}

interface CourseDetailsState {
  allCourses: Course[];
  currentCourse: CourseData | null;
  loading: boolean;
  error: null | Error;
  selectedLessonIdx: number;
  selectedSubLessonIdx: number;
}

const initialState: CourseDetailsState = {
  allCourses: [],
  currentCourse: null,
  loading: false,
  error: null,
  selectedLessonIdx: 0,
  selectedSubLessonIdx: 0,
};

export const courseDetailsSlice = createSlice({
  name: "courseDetails",
  initialState,
  reducers: {
    // Fetch all courses
    fetchAllCoursesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAllCoursesSuccess: (state, action: PayloadAction<Course[]>) => {
      state.allCourses = action.payload;
      state.loading = false;
    },
    fetchAllCoursesFailure: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.loading = false;
    },

    // Fetch course details
    fetchCourseDetailsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchCourseDetailsSuccess: (
      state,
      action: PayloadAction<{ courseData: CourseData; setIndexes: boolean }>,
    ) => {
      state.currentCourse = action.payload.courseData;
      state.loading = false;

      if (action.payload.setIndexes) {
        const lessonIdx = action.payload?.courseData?.lessons?.findIndex((lesson) =>
          lesson.sublessons.some((subLesson) => !subLesson.completed && subLesson.canAccess),
        );
        const firstIncompleteLessonIdx = lessonIdx > -1 ? lessonIdx : 0;

        const SubLessonIdx = action.payload?.courseData?.lessons?.[
          firstIncompleteLessonIdx
        ]?.sublessons.findIndex((subLesson) => !subLesson.completed && subLesson.canAccess);

        const firstIncompleteSubLessonIdx = SubLessonIdx > -1 ? SubLessonIdx : 0;

        state.selectedLessonIdx = firstIncompleteLessonIdx;
        state.selectedSubLessonIdx = firstIncompleteSubLessonIdx;
      }
    },
    fetchCourseDetailsFailure: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.loading = false;
    },
    addComment: (state, action: PayloadAction<Comment>) => {
      state;
      action;
      // if (state.currentCourse && state.currentCourse.comments) {
      //   state.currentCourse.comments.unshift(action.payload);
      // } else if (state.currentCourse) {
      //   // Initialize comments array if it doesn't exist
      //   state.currentCourse.comments = [action.payload];
      // }
    },

    selectSubLesson: (
      state,
      action: PayloadAction<{
        newSelectedSubLessonIdx: number;
        newSelectedLessonIdx: number;
      }>,
    ) => {
      const { newSelectedSubLessonIdx, newSelectedLessonIdx } = action.payload;

      const updatedLessonIdx =
        (state.currentCourse?.lessons.length ?? 0) > newSelectedLessonIdx
          ? newSelectedLessonIdx
          : 0;

      const updatedSubLessonIdx =
        (state.currentCourse?.lessons?.[newSelectedLessonIdx]?.sublessons?.length ?? 0) >
        newSelectedSubLessonIdx
          ? newSelectedSubLessonIdx
          : 0;

      state.selectedLessonIdx = updatedLessonIdx;
      state.selectedSubLessonIdx = updatedSubLessonIdx;
    },
    resetCurrentCourseDetails: (state) => {
      state.loading = false;
      state.error = null;
      state.currentCourse = null;
      state.selectedLessonIdx = 0;
      state.selectedSubLessonIdx = 0;
    },
  },
});

export const {
  fetchAllCoursesStart,
  fetchAllCoursesSuccess,
  fetchAllCoursesFailure,
  fetchCourseDetailsStart,
  fetchCourseDetailsSuccess,
  fetchCourseDetailsFailure,
  addComment,
  selectSubLesson,
  resetCurrentCourseDetails,
} = courseDetailsSlice.actions;

export default courseDetailsSlice.reducer;
