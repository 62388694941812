import { useState } from "react";

// Utility function to conditionally join classNames
function cn(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

interface ToggleOption {
  value: string;
  label: string | React.ReactNode;
}

interface ToggleOptionProps {
  options: [ToggleOption, ToggleOption];
  defaultValue?: string;
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
  height?: string;
  activeColor?: string;
  bgColor?: string;
}

export function SwitchToggle({
  options,
  defaultValue = options[0].value,
  onChange,
  className,
  disabled = false,
  height = "h-12",
  activeColor = "bg-bluegray-700",
  bgColor = "bg-white",
}: ToggleOptionProps) {
  const [selected, setSelected] = useState(defaultValue);

  const handleSelect = (value: string) => {
    if (!disabled) {
      setSelected(value);
      onChange?.(value);
    }
  };

  return (
    <div
      className={cn(
        `inline-flex items-center ${bgColor} rounded-full p-[2.9px] shadow-sm border-1`,
        height,
        disabled ? "opacity-50 cursor-not-allowed" : "",
        className,
      )}
    >
      {options.map(({ value, label }) => {
        const isActive = selected === value;
        return (
          <div
            key={value}
            onClick={() => handleSelect(value)}
            className={cn(
              "flex items-center justify-center gap-2 rounded-full transition-colors duration-200 px-[7px]",
              "text-sm font-medium",
              isActive ? activeColor : bgColor,
              disabled ? "cursor-not-allowed" : "cursor-pointer",
              "h-full",
            )}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
}
