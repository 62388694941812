import { IPosition } from "app/utils/types";
import { useEffect } from "react";

export const useDialogPosition = (
  ref: React.RefObject<HTMLDivElement>,
  commentPosition: IPosition | undefined,
  dependencies: any[] = [],
) => {
  useEffect(() => {
    const container = document.querySelector(
      `[data-comment-layout-id="${commentPosition?.containerId}"]`,
    );

    if (!container) return;

    const rect = container.getBoundingClientRect();

    if (ref.current && commentPosition) {
      const dialogWidth = ref.current.offsetWidth;
      const dialogHeight = ref.current.offsetHeight;

      const dialogX = (commentPosition.relativeX * rect.width) / 100;
      const dialogY = (commentPosition.relativeY * rect.height) / 100;

      const wouldExceedRight = dialogWidth + dialogX > rect.width;
      const wouldExceedBottom = dialogY + dialogHeight > rect.height;

      const overflowAmountX = dialogWidth + dialogX - rect.width + (wouldExceedBottom ? 45 : 10);
      const xTransform = wouldExceedRight
        ? `${-overflowAmountX}px`
        : wouldExceedBottom
        ? "30px"
        : "0px";

      const overflowAmountY = dialogY + dialogHeight - rect.height + 10;
      const yTransform = wouldExceedBottom ? `-${Math.max(0, overflowAmountY)}px` : "5px";

      ref.current.style.transform = `translate(${xTransform}, ${yTransform})`;
    }
  }, [ref, commentPosition, ...dependencies]);
};
