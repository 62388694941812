import { ProjectionKPFilters } from "app/utils/types";

export const projectionKPColumnJson: any = (selectedProjectionData: any) => [
  { field: "planYear", header: "Plan Year" },
  {
    field: "deathBenefit",
    header: "Death Benefit",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionKPFilters.DEATH_BENEFIT) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "ciBenefit",
    header: "Living Benefit",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionKPFilters.CI_BENEFIT) ? 0.5 : 1,
    },
  },
  {
    field: "disabilityBenefit",
    header: "Disability Benefit",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionKPFilters.DISABILITY_BENEFIT) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "valuation",
    header: "Key Employee Value",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionKPFilters.KEY_EMPLOYEE) ? 0.5 : 1,
    },
  },
];
