import { THEME, TOASTTYPE } from "app/utils/types";
import { Tooltip } from "primereact/tooltip";
import { useIPContext } from "../../InteractiveProposalProvider";
import BeeLight from "../../../../assets/BEE _ICON _WHITE.png";
import BeeDark from "../../../../assets/BEE.png";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import FirmIcon from "app/assets/IconComponent/FirmIcon";
import { getThemeClass } from "../../InteractiveProposalHeader";
import { makeRequest } from "app/utils/makeRequest";
import { Toast } from "primereact/toast";

const BrandingSelector = ({ themeState, proposalId }: { themeState: any; proposalId: string }) => {
  const op = useRef(null);
  const toast: any = useRef(null);
  const navigation = useNavigate();
  const { agentProfile, ipBrandingSelector, setIpBrandingSelector } = useIPContext();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const isDark = themeState === THEME.DARK;
  const isSummary = window.location.href.includes("summary");

  useEffect(() => {
    (op.current as any)?.hide();
  }, [window.location.hash]);

  const handleThemeClick = (theme: any) => {
    setIsDropdownVisible(false);
    makeRequest("ipUpdateBranding")
      .put(`${proposalId}`, { branding: theme.code }, true)
      .then((res) => {
        if (res.status === 200) {
          setIpBrandingSelector(theme.code);
          toast?.current?.show({
            severity: TOASTTYPE.SUCCESS,
            summary: "Success",
            detail: "Branding updated successfully",
          });
        }
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: "Error",
          detail: err?.message || "Error updating branding",
        });
      });
  };

  const brandsOptions = [
    {
      name: "IAPP",
      code: "IAPP",
      img: <img src={isDark ? BeeLight : BeeDark} alt="IAPP" className="w-[16px] h-[16px]" />,
    },
    {
      name: "Firm",
      code: "FIRM",
      img: <FirmIcon fill={isDark ? "#fff" : ""} />,
    },
  ];

  const selectedBrandingTemplate = (option: any) => {
    if (option) {
      return (
        <div className={`flex items-center ${isSummary ? "" : "py-1"}`}>
          {option.img}
          {isSummary && <div className="ml-2 conthrax-font text-sm">{option.name}</div>}
        </div>
      );
    }

    return <span>-</span>;
  };

  const brandOptionTemplate = (option: any) => (
    <div className={`flex items-center ${isSummary ? "" : "py-1"}`}>
      {option.img}
      {isSummary && (
        <div className="ml-2 conthrax-font" style={{ fontSize: "13px" }}>
          {option.name}
        </div>
      )}
    </div>
  );
  const activeBranding = brandsOptions.find((brand) => brand.code === ipBrandingSelector);

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <div
        className="h-7 interactive-proposal-branding-selector"
        onMouseEnter={(e) => {
          if (agentProfile?.subscription !== "Elite") {
            (op.current as any).show(e);
          }
        }}
      >
        <Dropdown
          value={activeBranding}
          onChange={(e) => handleThemeClick(e.value)}
          options={brandsOptions}
          optionLabel="name"
          valueTemplate={selectedBrandingTemplate}
          itemTemplate={brandOptionTemplate}
          style={{
            minWidth: isSummary ? "105.58px" : "",
            borderRadius: isDropdownVisible ? "16px 16px 0px 0px" : "16px",
          }}
          disabled={agentProfile?.subscription !== "Elite"}
          className={`rounded-full h-7 selected-branding ${getThemeClass(isDark)}`}
          panelStyle={{ borderRadius: "0 0 20px 20px" }}
          onShow={() => setIsDropdownVisible(true)}
          onHide={() => setIsDropdownVisible(false)}
        />
      </div>
      <OverlayPanel
        ref={op}
        style={{ width: "350px" }}
        showCloseIcon
        dismissable
        className="branding-selector-overlay-panel"
      >
        <p className="text-sm">
          You can switch between IAPP and your own branding using this button. You can use this
          feature by subscribing to Elite plan in Profile &gt; Billing.
        </p>
        <Button
          label="Upgrade to Elite"
          size="small"
          onClick={() => navigation("/profile/billing")}
          className="mt-2"
        />
      </OverlayPanel>
      {agentProfile?.subscription === "Elite" && (
        <Tooltip
          target=".selected-branding"
          content={`${!isSummary ? activeBranding?.name : ""} Branding`}
          position="bottom"
          className="text-sm"
          style={{ width: "85px" }}
        />
      )}
    </>
  );
};

export default BrandingSelector;
