import { FC, RefObject, useRef, useState } from "react";
import { IComment, IPosition, IUser } from "app/utils/types";
import AddComment from "./AddComment";
import { useComments } from "./CommentContext";
import { useDialogPosition } from "./useDialogPosition";
import ActionMenu from "./ActionMenu";
import { capitalizeWords } from "app/utils/utils";
import UserNameAndTimeStamp from "./UserNameAndTimeStamp";
import DeleteComment from "./DeleteComment";
import { getSessionStore } from "app/utils/sessionStore";

interface CommentProps {
  commentId: number;
  content: string;
  parentRef?: RefObject<HTMLDivElement>;
  commentPosition: IPosition;
  createdBy: IUser;
  replies?: IComment[];
  isResolved?: boolean;
  creatorEmail: string;
  onHide: () => void;
}

const Comment: FC<CommentProps> = ({
  commentId,
  content,
  parentRef,
  commentPosition,
  createdBy,
  replies,
  isResolved,
  creatorEmail,
  onHide,
}) => {
  const { replyComment, editComment, deleteComment, loggedInUserInfo } = useComments();
  const ref = useRef<HTMLDivElement>(null);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { email: loginUserEmail } = getSessionStore("lookUpResponse");

  useDialogPosition(parentRef ?? ref, commentPosition, [editingId]);

  if (!content?.length) return null;

  const getUserName = (user: IUser) =>
    capitalizeWords(`${user.lastName} ${user.firstName?.charAt(0).toUpperCase()}.`);

  let lastUserName = getUserName(createdBy);

  const renderCommentAndActions = (
    comment: string,
    messageId: number,
    showActions = true,
    isDisable = false,
  ) => (
    <div className="flex items-start justify-between gap-2 my-1">
      <p className="text-white text-base whitespace-pre-wrap">{comment}</p>
      <p className="w-[30px]">
        {showActions && (
          <ActionMenu
            isDisabled={isDisable}
            onEdit={() => {
              setEditingId(messageId);
            }}
            onDelete={() => {
              setDeletingId(messageId);
            }}
          />
        )}
      </p>
    </div>
  );

  if (deletingId) {
    return (
      <DeleteComment
        isDeleting={isDeleting}
        message="Delete this reply?"
        onCancel={() => setDeletingId(null)}
        onDelete={async () => {
          setIsDeleting(true);
          await deleteComment(deletingId);
          setDeletingId(null);
          setIsDeleting(false);
        }}
      />
    );
  }

  const lastReplyUser = replies?.[(replies?.length ?? 1) - 1]?.email ?? creatorEmail;

  const isLastReplyByCurrentUser = lastReplyUser === loginUserEmail;

  return (
    <div>
      {renderCommentAndActions(content, commentId, false)}

      {replies?.map((ct) => {
        const currUserName = getUserName(ct.user);
        const showUser = currUserName !== lastUserName;
        const canEditAndDelete = loginUserEmail === ct.email && !isResolved;
        if (currUserName !== lastUserName) {
          lastUserName = currUserName;
        }

        return (
          <div key={ct.createdAt} className={showUser ? "mt-4" : ""}>
            {showUser && (
              <UserNameAndTimeStamp name={lastUserName} date={ct.createdAt} className="my-2" />
            )}
            {editingId === ct.id ? (
              <AddComment
                onClose={() => {}}
                value={ct.comment}
                onAddComment={async (newComment) => {
                  await editComment(editingId, newComment);
                  setEditingId(null);
                  return true;
                }}
                setShowAddComment={() => setEditingId(null)}
              />
            ) : (
              renderCommentAndActions(ct.comment, ct.id, canEditAndDelete)
            )}
          </div>
        );
      })}

      {!editingId && !deletingId && !isResolved && (
        <div className="mt-4">
          {!isLastReplyByCurrentUser && (
            <UserNameAndTimeStamp
              name={capitalizeWords(
                `${loggedInUserInfo?.lastName} ${loggedInUserInfo?.firstName
                  ?.charAt(0)
                  .toUpperCase()}.`,
              )}
              className="my-2"
            />
          )}
          <AddComment
            placeholder="Reply..."
            onClose={onHide}
            onAddComment={async (newComment) => {
              await replyComment(commentId, newComment);
              return true;
            }}
            setShowAddComment={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default Comment;
