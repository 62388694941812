import React from "react";

interface SummaryProps {
  fill?: string; // Optional prop for fill color
}

const SummaryIcon: React.FC<SummaryProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-ea56f437-53be-443c-97f4-d86cda3cbe89"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 331.87 371.75"
  >
    <defs>
      <style>{`.uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c { fill: ${fill}; }`}</style>
    </defs>
    <g id="uuid-0fda2f2d-22b2-497d-8593-139ce2df402e" data-name="Layer 1">
      <g id="uuid-bcc5bd29-7303-40d2-9016-4c2c3944da8c" data-name="12-Summary">
        <path
          className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
          d="M165.94,24c2.29,0,4.54.6,6.52,1.75l128.89,74.41c4.02,2.32,6.52,6.65,6.52,11.3v148.83c0,4.65-2.5,8.97-6.52,11.3l-128.89,74.41c-1.98,1.14-4.24,1.75-6.52,1.75s-4.54-.6-6.52-1.75L30.52,271.59c-4.02-2.32-6.52-6.65-6.52-11.3V111.46c0-4.65,2.5-8.97,6.52-11.3L159.41,25.75c1.98-1.14,4.24-1.75,6.52-1.75M165.94,0c-6.4,0-12.79,1.65-18.52,4.96L18.52,79.38C7.06,86,0,98.23,0,111.46v148.83c0,13.24,7.06,25.46,18.52,32.08l128.89,74.41c5.73,3.31,12.13,4.96,18.52,4.96s12.79-1.65,18.52-4.96l128.89-74.41c11.46-6.62,18.52-18.85,18.52-32.08V111.46c0-13.24-7.06-25.46-18.52-32.08L184.46,4.96c-5.73-3.31-12.13-4.96-18.52-4.96h0Z"
        />
        <g>
          <path
            className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
            d="M261.65,149.88H113.01c-6.63,0-12-5.37-12-12s5.37-12,12-12h148.65c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
          />
          <path
            className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
            d="M261.65,197.88H113.01c-6.63,0-12-5.37-12-12s5.37-12,12-12h148.65c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
          />
          <path
            className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
            d="M261.65,245.88H113.01c-6.63,0-12-5.37-12-12s5.37-12,12-12h148.65c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
          />
          <circle
            className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
            cx="71.95"
            cy="137.88"
            r="11.73"
          />
          <circle
            className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
            cx="71.95"
            cy="185.88"
            r="11.73"
          />
          <circle
            className="uuid-9ffc7ed5-ade2-4831-b822-f89d5feaf44c"
            cx="71.95"
            cy="233.88"
            r="11.73"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SummaryIcon;
