import { useOnClickOutside } from "app/hooks/useOnClickOutside";
import { FC, useRef, useState } from "react";

interface ActionMenuProps {
  onEdit?: () => void;
  onDelete?: () => void;
  showEdit?: boolean;
  showDelete?: boolean;
  isDisabled?: boolean;
}

const ActionMenu: FC<ActionMenuProps> = ({
  onEdit,
  onDelete,
  showEdit = true,
  showDelete = true,
  isDisabled = false,
}) => {
  const [showActions, setShowActions] = useState(false);
  const actionsMenuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(actionsMenuRef, () => setShowActions(false), showActions);

  return (
    <div className="relative">
      <i
        className="pi pi-ellipsis-h text-white z-[9990] mx-2 cursor-pointer opacity-75 hover:opacity-100 transition-opacity duration-300"
        onClick={() => setShowActions(true)}
      />
      {showActions && (
        <div
          ref={actionsMenuRef}
          className="absolute z-[99999] shadow-md bg-white rounded-md px-2 py-2 right-0"
        >
          {showEdit && (
            <button
              type="button"
              disabled={isDisabled}
              className={`w-full text-left border-0 text-blue-500 text-sm ${
                isDisabled
                  ? "opacity-75 cursor-not-allowed"
                  : "cursor-pointer hover:bg-blue-500 hover:text-white transition-colors duration-300"
              } font-normal  px-2 py-1 rounded-md mb-1`}
              onClick={() => {
                onEdit?.();
                setShowActions(false);
              }}
            >
              Edit
            </button>
          )}
          {showDelete && (
            <button
              type="button"
              disabled={isDisabled}
              className={`w-full text-left border-0 text-red-500 text-sm mt-1 font-normal ${
                isDisabled
                  ? "opacity-75 cursor-not-allowed"
                  : "cursor-pointer hover:bg-red-500 hover:text-white transition-colors duration-300"
              } px-2 py-1 rounded-md`}
              onClick={onDelete}
            >
              Delete
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
