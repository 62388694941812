import { useState, useCallback } from "react";
import ChartWithCustomLegend from "app/uicomponents/microcomponents/ChartWithCustomLegend";
import { Card } from "primereact/card";
import { lineChartOptions } from "app/uicomponents/macrocomponents/PlansSummary/config";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import {
  createGradient,
  getThemeColors,
  projectionOptions,
  projectionSDOptions,
} from "app/utils/utils";
import {
  ProjectionFilters,
  ProjectionKPFilters,
  ProjectionSDFilters,
  ProposalTypes,
  THEME,
} from "app/utils/types";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";
import { useSelector } from "react-redux";
import { getAgentBranding } from "app/redux/features/agent-branding";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import {
  headerColumnGroupProjection,
  projectionColumnJson,
} from "../../../Data/projectionColumnJson";
import InsuredFilter from "app/uicomponents/macrocomponents/InteractiveProposal/Funding/InsuredFilter";
import InsuredTable from "app/uicomponents/macrocomponents/InteractiveProposal/Funding/InsuredTable";
import {
  headerColumnGroupSDProjection,
  projectionSDColumnJson,
} from "../../../Data/projectionSDColumnJson";
import { projectionKPColumnJson } from "../../../Data/projectionKPColumnJson";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { useIPContext } from "../InteractiveProposalProvider";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";

let selectedProjectionData: any = null;
const InteractiveProposalProjection = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedVersion, summaryData: proposalSummary } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("interactiveProposalID");
  const globalTheme = getSessionStore("globalTheme");
  const { settingThemeData } = useSelector(getAgentBranding);
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);
  const { setIsCurrPageLoading } = useComments();

  const insuredDetails = proposalSummary?.insuredDetails?.[0] ?? proposalSummary?.primaryContact;
  const calculateChartData = useCallback(
    (chartsData: any[], funding: string[], brandingColor: string) => {
      const labels = chartsData?.map((item: any) => item?.planYear);
      const datasets = [];
      if (proposalSummary?.proposalType === ProposalTypes.SplitDollar) {
        if (funding.includes(ProjectionSDFilters.CASH_VALUE)) {
          datasets.push({
            label: "Cash Value",
            borderColor: projectionSDOptions(brandingColor)[0].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionSDOptions(brandingColor)[0].indicatorColor),
            data: chartsData?.map((item: any) => item?.cashValue),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionSDFilters.LOAN)) {
          datasets.push({
            label: "Loan",
            borderColor: projectionSDOptions(brandingColor)[1].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionSDOptions(brandingColor)[1].indicatorColor),
            data: chartsData?.map((item: any) => item?.loan),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionSDFilters.DEATH_BENEFIT)) {
          datasets.push({
            label: "Death Benefit",
            borderColor: projectionSDOptions(brandingColor)[2].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionSDOptions(brandingColor)[2].indicatorColor),
            data: chartsData?.map((item: any) => item?.deathBenefit),
            fill: true,
            tension: 0.3,
          });
        }
      }
      if (proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance) {
        if (funding.includes(ProjectionKPFilters.DEATH_BENEFIT)) {
          datasets.push({
            label: "Death Benefit",
            borderColor: projectionOptions(brandingColor)[0].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[0].indicatorColor),
            data: chartsData?.map((item: any) => item?.deathBenefit),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionKPFilters.DISABILITY_BENEFIT)) {
          datasets.push({
            label: "Disability Benefit",
            borderColor: projectionOptions(brandingColor)[1].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[1].indicatorColor),
            data: chartsData?.map((item: any) => item?.disabilityBenefit),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionKPFilters.CI_BENEFIT)) {
          datasets.push({
            label: "Living Benefit",
            borderColor: projectionOptions(brandingColor)[2].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[2].indicatorColor),
            data: chartsData?.map((item: any) => item?.ciBenefit),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionKPFilters.KEY_EMPLOYEE)) {
          datasets.push({
            label: "Key Employee Value",
            borderColor: projectionOptions(brandingColor)[2].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[2].indicatorColor),
            data: chartsData?.map((item: any) => item?.valuation),
            fill: true,
            tension: 0.3,
          });
        }
      }
      if (proposalSummary?.proposalType === ProposalTypes.BuySell) {
        if (funding.includes(ProjectionFilters.DEATH_BENEFIT)) {
          datasets.push({
            label: "Death Benefit",
            borderColor: projectionOptions(brandingColor)[0].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[0].indicatorColor),
            data: chartsData?.map((item: any) => item?.deathBenefit),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionFilters.DISABILITY_BENEFIT)) {
          datasets.push({
            label: "Disability Benefit",
            borderColor: projectionOptions(brandingColor)[1].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[1].indicatorColor),
            data: chartsData?.map((item: any) => item?.disabilityBenefit),
            fill: true,
            tension: 0.3,
          });
        }
        if (funding.includes(ProjectionFilters.BUSINESS_VALUE)) {
          datasets.push({
            label: "Business Value",
            borderColor: projectionOptions(brandingColor)[2].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(brandingColor)[2].indicatorColor),
            data: chartsData?.map((item: any) => item?.businessValuation),
            fill: true,
            tension: 0.3,
          });
        }
      }
      return { labels, datasets };
    },
    [],
  );
  // Define the header structure headerColumnGroupProjection
  const headerColumnGroupEle = (
    <Row>
      <Column
        header=""
        rowSpan={1}
        colSpan={proposalSummary?.proposalType === ProposalTypes.SplitDollar ? 2 : 1}
        align="center"
        // headerStyle={{
        //   borderRightWidth: 1,
        //   borderRightColor: "var(--surface-100)",
        //   borderBottomWidth: 1,
        //   borderBottomColor: "var(--surface-100)",
        // }}
      />
      {(
        Object.keys(
          proposalSummary?.proposalType === ProposalTypes.SplitDollar
            ? headerColumnGroupSDProjection
            : headerColumnGroupProjection,
        ) as Array<keyof typeof headerColumnGroupProjection>
      ).map((key: any) => (
        <Column
          header={key}
          key={key}
          rowSpan={1}
          colSpan={
            proposalSummary?.proposalType === ProposalTypes.SplitDollar
              ? headerColumnGroupSDProjection[key as keyof typeof headerColumnGroupSDProjection]
                  .length
              : headerColumnGroupProjection[key as keyof typeof headerColumnGroupProjection].length
          }
          // headerStyle={{
          //   borderRightWidth: 1,
          //   borderRightColor: "var(--surface-100)",
          //   borderBottomWidth: 1,
          //   borderBottomColor: "var(--surface-100)",
          // }}
          align="center"
        />
      ))}
    </Row>
  );
  const onShareholdersChange = useCallback(
    async (
      selectedShareholderIds: string[],
      selectedFundingIds: string[],
      brandingColor: string,
    ) => {
      selectedProjectionData = selectedFundingIds;
      setLoading(true);
      let newShareholderIds = selectedShareholderIds;
      if (proposalSummary?.proposalType === ProposalTypes.SplitDollar) {
        newShareholderIds = [];
      }
      setIsCurrPageLoading(true);
      const response = await makeRequest("interactiveProposalProjection").get(
        `${proposalId}?insuredIds=${JSON.stringify(newShareholderIds)}&filter=${JSON.stringify(
          selectedFundingIds,
        )}`,
        true,
        false,
      );
      if (response.status === 200) {
        const data = calculateChartData(
          response?.data?.data?.graphData,
          selectedFundingIds,
          brandingColor,
        );
        if (
          selectedShareholderIds.length === 0 &&
          proposalSummary?.proposalType === ProposalTypes.SplitDollar
        ) {
          setChartData(null);
        } else {
          setChartData(data);
        }
        setTableData(response?.data?.data?.tableData || []);
      }
      setLoading(false);
      setIsCurrPageLoading(false, true);
    },
    [proposalId],
  );

  return (
    <CommentLayout
      commentLayoutId="interactive-proposal-projection"
      className="border border-1 border-gray-300 rounded-2xl w-full min-h-screen shadow-lg"
    >
      {/* Filter container */}
      <div className="p-3 flex justify-between w-full border-b-2 border-gray-300 mb-3">
        <InsuredFilter
          onShareholdersChange={onShareholdersChange}
          brandingColor={primaryColor}
          label="Values"
          filter={
            proposalSummary?.proposalType === ProposalTypes.SplitDollar
              ? ProjectionSDFilters
              : proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
              ? ProjectionKPFilters
              : ProjectionFilters
          }
        />
      </div>
      {/* Chart container */}
      <div>
        {loading ? (
          <ScreenLoader />
        ) : (
          <CommentLayout commentLayoutId="interactive-proposal-projection-chart">
            <Card
              title={() => <div className="conthrax-font">Projections Chart</div>}
              className="w-full"
            >
              {chartData ? (
                <ChartWithCustomLegend
                  data={chartData}
                  options={lineChartOptions(
                    globalTheme === THEME.DARK,
                    ipTooltipColor,
                    proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
                      ? "Projected Value for Selected Employees"
                      : proposalSummary?.proposalType === ProposalTypes.SplitDollar
                      ? `Projected Value for ${insuredDetails.firstName} ${insuredDetails.lastName}`
                      : "Projected Value for Selected Shareholders",
                  )}
                  type="line"
                  legends={[]}
                />
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-lg text-center">No data available</p>
                </div>
              )}
            </Card>
          </CommentLayout>
        )}
      </div>
      {/* Projection Table */}
      <CommentLayout
        commentLayoutId="interactive-proposal-projection-table"
        className="flex justify-between w-full mt-1"
      >
        {!loading && (
          <Card
            title={() => <div className="conthrax-font">Projections Table</div>}
            className="w-full p-3  border-[1px] border-borderColor"
          >
            <InsuredTable
              columns={
                proposalSummary?.proposalType === ProposalTypes.SplitDollar
                  ? projectionSDColumnJson(selectedProjectionData)
                  : proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
                  ? projectionKPColumnJson(selectedProjectionData)
                  : projectionColumnJson(selectedProjectionData)
              }
              data={tableData}
              hoverColor={primaryColor}
              columnGroup={
                proposalSummary?.proposalType !== ProposalTypes.KeyPersonInsurance
                  ? headerColumnGroupEle
                  : null
              } // Pass the header structure
              scrollable
            />
            <p className="text-lg text-center my-5">
              Projected Value for{" "}
              {proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
                ? "Selected Employees"
                : proposalSummary?.proposalType === ProposalTypes.SplitDollar
                ? `${insuredDetails.firstName} ${insuredDetails.lastName}`
                : "Selected Shareholders"}
            </p>
          </Card>
        )}
      </CommentLayout>
      <div className="hidden">{JSON.stringify(settingThemeData)}</div>
    </CommentLayout>
  );
};
export default InteractiveProposalProjection;
