import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface ShareholderState {
  selectedShareHolder: any;
}

const initialState: ShareholderState = {
  selectedShareHolder: null,
};

// export const resendInvite = createSlice({
//   name: "resendInvite",
//   initialState,
//   reducers: {
//     setResendInviteSuccess: (state, action: PayloadAction<any[]>) => {
//       state.data = action.payload;
//       state.loading = false;
//     },
//     setResendInviteFailure: (state, action: PayloadAction<string>) => {
//       state.error = action.payload;
//       state.loading = false;
//     },
//     setResendInviteOngoing: (state) => {
//       state.data = {};
//       state.loading = true;
//       state.error = {};
//     },
//     resetState: () => initialState,
//   },
// });

// export const {
//   setResendInviteSuccess,
//   setResendInviteFailure,
//   setResendInviteOngoing,
//   resetState,
// } = resendInvite.actions;
// export const getResendInviteStatus = (state: RootState) => state.resendInvite;

// export const resendInviteReducer = resendInvite.reducer;

export const selectShareHolderSlice = createSlice({
  name: "selectShareHolder",
  initialState,
  reducers: {
    setSelectedShareHolder: (state, action: PayloadAction<ShareholderState>) => {
      // console.log("Action", action);
      state.selectedShareHolder = action.payload;
    },
    resetSelectedShareHolder: (state) => {
      state.selectedShareHolder = null;
    },
  },
});

export const { setSelectedShareHolder, resetSelectedShareHolder } = selectShareHolderSlice.actions;

export const getSelectedShareHolder = (state: RootState) => state.selectShareHolder;

export const selectShareHolderReducer = selectShareHolderSlice.reducer;
