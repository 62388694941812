import { getSessionStore } from "app/utils/sessionStore";
import { ProposalTypes } from "app/utils/types";
import {
  createGradient,
  getThemeColors,
  getUSANumberFormat,
  projectionOptions,
} from "app/utils/utils";
import { Chart } from "primereact/chart";
import HexagonImg from "../../images/hexagonImg.png";
import IPPageNumber from "../IPPageNuber";
import { lineChartOptions } from "app/uicomponents/macrocomponents/PlansSummary/config";

const bsTableHeaders = [
  { header: "Plan Year", color: "#3a7cb8" },
  { header: "Business Valuation", color: "#336392" },
  { header: "Annual Growth Rate Projection", color: "#2e547d" },
  { header: "Death Benefit", color: "#244161" },
  { header: "Disability Benefit", color: "#162c46" },
];

const kpiTableHeaders = [
  { header: "Plan Year", color: "#3a7cb8" },
  { header: "Death Benefit", color: "#336392" },
  { header: "Living Benefit", color: "#2e547d" },
  { header: "Disability Benefit", color: "#244161" },
  { header: "Key Employee(s) Value", color: "#162c46" },
];

const IPProjections = ({
  proposalType,
  graphData,
  isNewPage = false,
  pageNumber,
  tableData,
}: {
  proposalType: ProposalTypes;
  graphData?: any;
  isNewPage?: boolean;
  pageNumber: string | number;
  tableData?: any;
}) => {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);

  const planYear = graphData?.map((item: any) => item.planYear);

  const deathBenefit = graphData?.map((item: any) => item.deathBenefit);
  const totalDeathBenefit = deathBenefit?.reduce((acc: any, value: any) => acc + value, 0);

  const disabilityBenefit = graphData?.map((item: any) => item.disabilityBenefit);
  const totalDisabilityBenefit = disabilityBenefit?.reduce(
    (acc: any, value: any) => acc + value,
    0,
  );

  const businessValuation = graphData?.map((item: any) => item.businessValuation);
  const totalBusinessValuation = businessValuation?.reduce(
    (acc: any, value: any) => acc + value,
    0,
  );

  const ciBenefit = graphData?.map((item: any) => item.ciBenefit);
  const totalCiBenefit = ciBenefit?.reduce((acc: any, value: any) => acc + value, 0);

  const thirdChartData = () => {
    if (proposalType === ProposalTypes.KeyPersonInsurance) {
      return {
        label: "Living Benefit",
        total: totalCiBenefit,
        chartData: {
          labels: planYear,
          datasets: [
            {
              label: "Living Benefit",
              fill: true,
              borderColor: projectionOptions(primaryColor)[2].indicatorColor,
              backgroundColor: (context: any) =>
                createGradient(context, projectionOptions(primaryColor)[2].indicatorColor),
              yAxisID: "y",
              tension: 0.4,
              data: ciBenefit,
            },
          ],
        },
      };
    }
    return {
      label: "Business Value",
      total: totalBusinessValuation,
      chartData: {
        labels: planYear,
        datasets: [
          {
            label: "Business Value",
            fill: true,
            borderColor: projectionOptions(primaryColor)[2].indicatorColor,
            backgroundColor: (context: any) =>
              createGradient(context, projectionOptions(primaryColor)[2].indicatorColor),
            yAxisID: "y",
            tension: 0.4,
            data: businessValuation,
          },
        ],
      },
    };
  };

  const deathBenefitChart = {
    labels: planYear,
    datasets: [
      {
        label: "Death Benefit",
        fill: true,
        borderColor: projectionOptions(primaryColor)[0].indicatorColor,
        backgroundColor: (context: any) =>
          createGradient(context, projectionOptions(primaryColor)[0].indicatorColor),
        yAxisID: "y",
        tension: 0.4,
        data: deathBenefit,
      },
    ],
  };

  const disabilityBenefitChart = {
    labels: planYear,
    datasets: [
      {
        label: "Disability Benefit",
        fill: true,
        borderColor: projectionOptions(primaryColor)[1].indicatorColor,
        backgroundColor: (context: any) =>
          createGradient(context, projectionOptions(primaryColor)[1].indicatorColor),
        yAxisID: "y",
        tension: 0.4,
        data: disabilityBenefit,
      },
    ],
  };

  const tableContent = () => {
    if (proposalType === ProposalTypes.KeyPersonInsurance) {
      return (
        <table className="w-full border-collapse bg-white">
          <thead>
            <tr className="conthrax-font">
              <td className="bg-gray-200 border-2 border-white" />
              <td colSpan={4} className="text-center py-3 bg-gray-200 border-2 border-white">
                BENEFITS
              </td>
            </tr>
            <tr>
              {kpiTableHeaders.map((item) => (
                <th
                  key={item.header}
                  style={{ backgroundColor: item.color, width: "20%" }}
                  className="p-1 text-center text-xs font-semibold text-white border-2 border-white"
                >
                  {item.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item: any, index: any) => (
              <tr key={index} style={{ backgroundColor: "#EEEEEE", color: "#000000" }}>
                <td className="p-1 text-sm text-center border-2 border-white">{item.planYear}</td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.deathBenefit, false)}
                </td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.ciBenefit, false)}
                </td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.disabilityBenefit, false)}
                </td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.valuation, false)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    if (proposalType === ProposalTypes.BuySell) {
      return (
        <table className="w-full border-collapse bg-white">
          <thead>
            <tr className="conthrax-font">
              <td className="bg-gray-200 border-2 border-white" />
              <td colSpan={2} className="text-center bg-gray-200 border-2 border-white">
                VALUATIONS
              </td>
              <td colSpan={2} className="text-center py-3 bg-gray-200 border-2 border-white">
                SHAREHOLDER BENEFITS
              </td>
            </tr>
            <tr>
              {bsTableHeaders.map((item) => (
                <th
                  key={item.header}
                  style={{ backgroundColor: item.color, width: "20%" }}
                  className="p-1 text-center text-xs font-semibold text-white border-2 border-white"
                >
                  {item.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item: any, index: any) => (
              <tr key={index} style={{ backgroundColor: "#EEEEEE", color: "#000000" }}>
                <td className="p-1 text-sm text-center border-2 border-white">{item.planYear}</td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.businessValuation, false)}
                </td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  {item.annualGrowthRate || 0}%
                </td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.deathBenefit, false)}
                </td>
                <td className="p-1 text-sm text-center border-2 border-white">
                  ${getUSANumberFormat(item.disabilityBenefit, false)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (isNewPage) {
      return tableContent();
    }
    return (
      <div>
        <h1 className="text-center text-2xl font-bold mb-3 conthrax-font interactive-proposal-text">
          Plan Projections
        </h1>
        <div className="w-full mb-3 bg-white">
          <div className="flex justify-between conthrax-font" style={{ width: "503px" }}>
            <p className="font-bold text-gray-800 text-sm">Death Benefit</p>
            <div className="text-base font-bold text-gray-500">
              ${getUSANumberFormat(totalDeathBenefit, false)}
            </div>
          </div>
          <Chart
            type="line"
            height="200px"
            width="503px"
            data={deathBenefitChart}
            options={lineChartOptions(false, ipTooltipColor, "Plan Year")}
          />
        </div>

        <div className="w-full mb-3 bg-white">
          <div className="flex justify-between conthrax-font" style={{ width: "503px" }}>
            <p className="font-bold text-gray-800 text-sm">Disability Benefit</p>
            <div className="text-base font-bold text-gray-500">
              ${getUSANumberFormat(totalDisabilityBenefit, false)}
            </div>
          </div>

          <Chart
            type="line"
            height="200px"
            width="503px"
            data={disabilityBenefitChart}
            options={lineChartOptions(false, ipTooltipColor, "Plan Year")}
          />
        </div>

        <div className="w-full mb-3 bg-white">
          <div className="flex justify-between conthrax-font" style={{ width: "503px" }}>
            <p className="font-bold text-gray-800 text-sm">{thirdChartData().label}</p>
            <div className="text-base font-bold text-gray-500">
              ${getUSANumberFormat(thirdChartData().total, false)}
            </div>
          </div>
          <Chart
            type="line"
            height="200px"
            width="503px"
            data={thirdChartData().chartData}
            options={lineChartOptions(false, ipTooltipColor, "Plan Year")}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col  h-full relative min-h-screen">
      <div className="relative z-20 px-[4em] w-full max-w-[56em] mx-auto p-[1.75em]">
        {renderContent()}
      </div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default IPProjections;
