import { useCallback, useEffect, useState } from "react";
import { Card } from "primereact/card";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import { Chart } from "primereact/chart";
import { CurrentProposalType, PROPOSAL_STATUSES, ProposalTypes, THEME } from "app/utils/types";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { AnimatedCheckbox } from "app/uicomponents/microcomponents/AnimatedChecbox";
import { fundingSDOptionsSummary, getThemeColors } from "app/utils/utils";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";
import { useIPContext } from "../InteractiveProposalProvider";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";
import ChartWithCustomLegend from "app/uicomponents/microcomponents/ChartWithCustomLegend";
import { barGraphOptionsFunding } from "app/uicomponents/macrocomponents/PlansSummary/config";

interface Shareholder {
  id: string;
  name: string;
  lifePolicyCount?: number;
  deathBenefit?: number;
  disabilityPolicyCount?: number;
  disabilityBenefit?: number;
  ciBenefit?: number;
  firstName?: string;
  lastName?: string;
}

interface ValuationData {
  planYear: number;
  value: number;
}

interface SummaryInsuredData {
  totalLoanedPremium: string;
  deathBenefitYear1: string;
  projectedAnnualIncome: string;
  beginningYear: string;
  firstName: string;
  lastName: string;
}

const InteractiveProposalSummary = () => {
  const [proposalValuation, setProposalValuation] = useState<ValuationData[]>([]);
  const [shareholders, setShareholders] = useState<Shareholder[]>([]);
  const [selectedShareholders, setSelectedShareholders] = useState<Shareholder[]>([]);
  const [chartsData, setChartsData] = useState<any>(null);

  const [summaryInsuredData, setSummaryInsuredData] = useState<SummaryInsuredData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const planInfo = getSessionStore("planInfo");
  const currentProposalType = getSessionStore("currentProposalType");
  const globalTheme = getSessionStore("globalTheme");
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);
  const { selectedVersion, proposalStatus } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(proposalStatus as any);

  const proposalType =
    currentProposalType === CurrentProposalType.PLANS
      ? planInfo?.planType
      : getSessionStore("selectedProposalRow")?.proposalType;

  const { setIsCurrPageLoading } = useComments();

  const [selectedDataTypes, setSelectedDataTypes] = useState<string[]>(["income", "db"]);

  const formatNumber = (value: number) => {
    if (value >= 1000000000) {
      return `$${(value / 1000000000).toFixed(2)}B`;
    }
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(2)}M`;
    }
    if (value >= 1000) {
      return `$${(value / 1000).toFixed(2)}K`;
    }
    return `$${value}`;
  };

  const calculateChartData = useCallback(
    (chartsDataList: any[], funding: string[], brandingColor: string) => {
      const labels = chartsDataList?.map((item: any) => `Age ${item.insuredAge}`);
      const datasets = [];

      if (funding.includes("income")) {
        datasets.push({
          label: "Income",
          backgroundColor: fundingSDOptionsSummary(brandingColor)[0].indicatorColor,
          data: chartsDataList?.map((item: any) => item?.income),
        });
      }
      if (funding.includes("db")) {
        datasets.push({
          label: "Death Benefit",
          backgroundColor: fundingSDOptionsSummary(brandingColor)[1].indicatorColor,
          data: chartsDataList?.map((item: any) => item?.db),
        });
      }

      return { labels, datasets };
    },
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setIsCurrPageLoading(true);
        const response = await makeRequest("interactiveProposalSummary").get(
          proposalId,
          true,
          false,
        );
        setIsCurrPageLoading(false, true);
        const fetchedShareholders =
          proposalType === ProposalTypes.KeyPersonInsurance
            ? response?.data?.data?.employees
            : proposalType === ProposalTypes.SplitDollar
            ? response?.data?.data?.insuredDetails
            : response?.data?.data?.shareholders;
        if (proposalType === ProposalTypes.SplitDollar) {
          setSummaryInsuredData([
            {
              totalLoanedPremium: response?.data?.data?.totalLoanedPremium || "",
              deathBenefitYear1: response?.data?.data?.deathBenefitYear1 || "",
              projectedAnnualIncome: response?.data?.data?.projectedAnnualIncome || "",
              beginningYear: response?.data?.data?.beginningYear || "",
              firstName: response?.data?.data?.primaryContact?.firstName || "",
              lastName: response?.data?.data?.primaryContact?.lastName || "",
            },
          ]);
        }
        setShareholders(fetchedShareholders);
        setSelectedShareholders(fetchedShareholders);
        setProposalValuation(
          proposalType === ProposalTypes.SplitDollar
            ? response?.data?.data?.proposalValuation?.slice(0, 10) || []
            : response?.data?.data?.proposalValuation || [],
        );

        if (proposalType === ProposalTypes.SplitDollar) {
          const data = calculateChartData(
            response?.data?.data?.proposalValuation,
            selectedDataTypes,
            primaryColor,
          );
          setChartsData(data);
        }
      } catch (error) {
        setShareholders([]);
        setSelectedShareholders([]);
        setProposalValuation([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (proposalType === ProposalTypes.SplitDollar) {
      const data = calculateChartData(proposalValuation, selectedDataTypes, primaryColor);
      setChartsData(data);
    }
  }, [selectedDataTypes, proposalValuation, primaryColor, proposalType]);

  const handleCheckboxChange = (shareholder: Shareholder) => {
    setSelectedShareholders((prevSelected) =>
      prevSelected.some((s) => s.id === shareholder.id)
        ? prevSelected.filter((s) => s.id !== shareholder.id)
        : [...prevSelected, shareholder],
    );
  };

  // Calculate total sum for selected shareholders
  const totalLifePolicyCount = selectedShareholders?.reduce(
    (sum, s) => sum + (s.lifePolicyCount || 0),
    0,
  );
  const totalDeathBenefit = selectedShareholders?.reduce(
    (sum, s) => sum + (+(s.deathBenefit || 0) + +(s.ciBenefit || 0) || 0),
    0,
  );
  const totalDisabilityPolicyCount = selectedShareholders?.reduce(
    (sum, s) => sum + (s.disabilityPolicyCount || 0),
    0,
  );
  const totalDisabilityBenefit = selectedShareholders?.reduce(
    (sum, s) => sum + (s.disabilityBenefit || 0),
    0,
  );

  // Add this function to format the chart data
  const getChartData = () => {
    const chartData = {
      labels: proposalValuation.map((item: any) => `Year ${item.planYear}`),
      datasets: [
        {
          label: "Valuation",
          data: proposalValuation.map((item: any) => item.value / 1000000),
          fill: true,
          borderColor: primaryColor,
          tension: 0.4,
          backgroundColor: `${primaryColor}1A`, // 1A for 10% opacity
        },
      ],
    };
    return chartData;
  };

  // Add chart options
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: ipTooltipColor,
        padding: 12,
        titleFont: { size: 14 },
        bodyFont: { size: 14 },
        displayColors: false,
        callbacks: {
          title: (items: any) => `Year ${items[0].dataIndex + 1}`,
          label: (context: any) => `$${context.raw.toFixed(2)}M`,
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 4,
        backgroundColor: primaryColor,
      },
      line: {
        tension: 0.4,
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "index",
    },
  };

  // Function to handle checkbox changes
  const handleDataTypeChange = (dataType: string) => {
    setSelectedDataTypes((prevSelected) =>
      prevSelected.includes(dataType)
        ? prevSelected.filter((type) => type !== dataType)
        : [...prevSelected, dataType],
    );
  };

  return (
    <CommentLayout commentLayoutId="interactive-proposal-summary" className="p-2 mt-8">
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="flex gap-4 ">
          <div className="w-1/2">
            <Card className="h-full border border-1 border-gray-300 rounded-2xl  shadow-lg">
              <div className="flex flex-col h-full">
                <h1 className="text-lg font-bold mb-2 conthrax-font">
                  {proposalType === ProposalTypes.KeyPersonInsurance
                    ? "Key Employee"
                    : proposalType === ProposalTypes.SplitDollar
                    ? "Insured Person"
                    : "Shareholders"}
                </h1>
                <div className="flex flex-wrap gap-2 pb-4 pt-1">
                  {shareholders
                    ? shareholders.map((shareholder, index) => (
                        <div className="flex items-center gap-2" key={index}>
                          <AnimatedCheckbox
                            checked={selectedShareholders.some((s) => s.id === shareholder.id)}
                            onChange={() => handleCheckboxChange(shareholder)}
                            inputId={shareholder.id}
                            label={
                              proposalType !== ProposalTypes.SplitDollar
                                ? shareholder.name?.split(",")?.reverse()?.join(" ")
                                : `${shareholder.firstName} ${shareholder.lastName}`
                            }
                            brandingColor={primaryColor}
                            isDisabled={isPlanOrInactive}
                            ishideIndicator={proposalType === ProposalTypes.SplitDollar}
                          />
                        </div>
                      ))
                    : summaryInsuredData?.[0]?.firstName && (
                        <div className="flex items-center gap-2">
                          <AnimatedCheckbox
                            checked={false} // Default unchecked as there's no ID to match
                            onChange={() => {}}
                            inputId="summaryInsuredData"
                            label={`${summaryInsuredData[0].firstName} ${summaryInsuredData[0].lastName}`}
                            brandingColor={primaryColor}
                            isDisabled={isPlanOrInactive}
                            ishideIndicator={proposalType === ProposalTypes.SplitDollar}
                          />
                        </div>
                      )}
                </div>

                <hr className="my-2 border-2" />

                <div className="border rounded-lg p-3 mt-5">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="text-left">
                      <p className="text-2xl conthrax-font">
                        {proposalType === ProposalTypes.SplitDollar
                          ? `$${summaryInsuredData?.[0]?.totalLoanedPremium}`
                          : totalLifePolicyCount}
                      </p>
                      <p className="text-xl text-[#5c6779]">
                        {proposalType === ProposalTypes.SplitDollar
                          ? "Total Loaned Premium"
                          : "Life Policies"}
                      </p>
                    </div>
                    <div className="text-left">
                      <p className="text-2xl conthrax-font">
                        {proposalType === ProposalTypes.SplitDollar
                          ? `$${summaryInsuredData?.[0]?.deathBenefitYear1}`
                          : formatNumber(totalDeathBenefit)}
                      </p>
                      <p className="text-xl text-[#5c6779]">
                        {" "}
                        {proposalType === ProposalTypes.SplitDollar
                          ? "Death Benefit Year 1"
                          : "Life Policy Benefits"}
                      </p>
                    </div>
                    <div className="text-left">
                      <p className="text-2xl conthrax-font">
                        {proposalType === ProposalTypes.SplitDollar
                          ? `$${summaryInsuredData?.[0]?.projectedAnnualIncome}`
                          : totalDisabilityPolicyCount}
                      </p>
                      <p className="text-xl text-[#5c6779]">
                        {" "}
                        {proposalType === ProposalTypes.SplitDollar
                          ? "Projected Annual Income"
                          : "Disability Policies"}
                      </p>
                    </div>
                    <div className="text-left">
                      <p className="text-2xl conthrax-font">
                        {proposalType === ProposalTypes.SplitDollar
                          ? summaryInsuredData?.[0]?.beginningYear
                          : formatNumber(totalDisabilityBenefit)}
                      </p>
                      <p className="text-xl text-[#5c6779]">
                        {" "}
                        {proposalType === ProposalTypes.SplitDollar
                          ? "Beginning Year"
                          : "Disability Policy Benefits"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="w-1/2">
            <Card className="h-full border border-1 border-gray-300 rounded-2xl shadow-lg">
              {proposalType !== ProposalTypes.SplitDollar ? (
                <div className="flex flex-col h-full">
                  <h1 className="text-lg font-bold mb-2 conthrax-font">
                    {" "}
                    {proposalType === ProposalTypes.KeyPersonInsurance
                      ? "Key Employee Value"
                      : "Valuation"}
                  </h1>
                  <div className="flex-grow">
                    <div className="relative h-[250px]">
                      {proposalValuation.length > 0 && (
                        <Chart
                          type="line"
                          data={getChartData()}
                          options={chartOptions}
                          className="w-full h-full"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div>
                      <p className="text-2xl font-bold ">
                        {proposalValuation[0] ? formatNumber(proposalValuation[0].value) : 0}
                      </p>
                      <p className="text-xl text-[#5c6779]">
                        {proposalType === ProposalTypes.KeyPersonInsurance
                          ? "Estimated Value Year"
                          : "Estimated Valuation Year"}{" "}
                        1
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-2xl font-bold">
                        {proposalValuation.length
                          ? formatNumber(proposalValuation[proposalValuation.length - 1].value)
                          : 0}
                      </p>
                      <p className="text-xl text-[#5c6779]">
                        {proposalType === ProposalTypes.KeyPersonInsurance
                          ? "Estimated Value Year"
                          : "Estimated Valuation Year"}{" "}
                        15
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex flex-wrap gap-2 pb-4 pt-1">
                    <div className="flex flex-col gap-2">
                      <h1 className="text-lg font-bold mb-2 conthrax-font">Benefits Received</h1>
                      <div className="flex gap-2">
                        {["income", "db"].map((dataType, index) => (
                          <div className="flex items-center gap-2" key={index}>
                            <AnimatedCheckbox
                              checked={selectedDataTypes.includes(dataType)}
                              onChange={() => handleDataTypeChange(dataType)}
                              inputId={dataType}
                              label={dataType === "income" ? "Income" : "Death Benefit"}
                              brandingColor={primaryColor}
                              isDisabled={isPlanOrInactive}
                              indicator
                              indicatorColor={
                                dataType === "db" ? `${primaryColor}cc` : primaryColor
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {chartsData ? (
                    <ChartWithCustomLegend
                      data={chartsData}
                      options={barGraphOptionsFunding(
                        globalTheme === THEME.DARK,
                        ipTooltipColor,
                        " ",
                        " ",
                      )}
                      type="bar"
                      legends={[]} // No legends displayed
                    />
                  ) : (
                    <div className="flex justify-center items-center h-full">
                      <p className="text-lg text-center">No data available</p>
                    </div>
                  )}
                </>
              )}
            </Card>
          </div>
        </div>
      )}
    </CommentLayout>
  );
};

export default InteractiveProposalSummary;
