import { ProposalTypes } from "app/utils/types";
import bsSolutionImg from "../images/bsSolutionImg.png";
import kpiSolution from "../images/kpiSolution.png";
import spSolutionImg from "../images/spSolutionImg.png";
import IPPageNumber from "./IPPageNuber";

const bsList = [
  "The business purchases life insurance policies on all owners, The business owns the policies, pays the premiums, and is the beneficiary.",
  "Upon Shareholder 3's death, the life insurance company pays a death benefit directly to the business.",
  "With the life insurance proceeds, the business buys Shareholder 3's equity portion from his estate.",
  "Shareholder 3's estate sells back shares to the business, and Shareholder 1 and 2 now own a larger percentage of the business.",
];

const kpiList = [
  "The business selects a key employee whose loss would significantly impact operations and takes out a life or disability insurance policy on their behalf.",
  "The business pays regular premiums for the policy, maintaining coverage and ensuring the financial protection is in place.",
  "In the event of the key person's death or disability, the business files a claim and receives a payout.",
  "The benefits can used to cover expenses, pay off debts, attract a replacement, or stabilize the company during the transition.",
];

const spList = [
  "Employer extends loans to Executive to pay life insurance premiums. Executive is owner of the life policy.",
  "Executive may make principal and interest payments to employer based on agreement. Employer takes a collateral position in the life insurance policy.",
  "It is important to define an exit strategy at plan termination. Typical options include paying off the remaining loan balance to Employer by taking policy loans (as illustrated here) or Employer “forgives” all or portion of loan owed by Executive.",
  "Executive retains sole access to remaining policy benefits, either cash value if living, or death benefits if deceased.",
];

interface IPSolutionProps {
  pageNumber: string | number;
  proposalType: ProposalTypes;
}

interface SolutionContent {
  image: string;
  list: string[];
}

const getSolutionContent = (proposalType: ProposalTypes): SolutionContent => {
  switch (proposalType) {
    case ProposalTypes.KeyPersonInsurance:
      return { image: kpiSolution, list: kpiList };
    case ProposalTypes.SplitDollar:
      return { image: spSolutionImg, list: spList };
    default:
      return { image: bsSolutionImg, list: bsList };
  }
};
const IPSolution = ({ pageNumber, proposalType }: IPSolutionProps) => {
  const { image, list } = getSolutionContent(proposalType);

  return (
    <div className="flex flex-col px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] h-full">
      <div className="text-xl font-bold mb-3 text-center interactive-proposal-text conthrax-font">
        Solution
      </div>
      <div>
        <div className="flex justify-center">
          <img src={image} alt="Solution Diagram" />
        </div>
        <div className="flex flex-col space-y-4 mt-10">
          {list?.map((text, index) => {
            const backgroundColor = index % 2 === 0 ? "#0f3765" : "#808181";
            return (
              <div key={index} className="flex items-start">
                <div className="flex-shrink-0">
                  <span
                    className="conthrax-font flex items-center justify-center rounded-full text-white w-[30px] h-[30px] text-xl font-bold p-[5px]"
                    style={{ backgroundColor }}
                  >
                    {index + 1}
                  </span>
                </div>
                <div className="ml-3">
                  <p className="text-black text-[13px]">{text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-auto">
        <IPPageNumber number={pageNumber} />
      </div>
    </div>
  );
};

export default IPSolution;
