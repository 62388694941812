import React from "react";

interface ProjectionsProps {
  fill?: string; // Optional prop for fill color
}

const ProjectionsIcon: React.FC<ProjectionsProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-4ef31068-657e-4017-b2cb-38534d7771ba"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 342.28 383.77"
  >
    <defs>
      <style>{`.uuid-53f4e5c0-2957-43bd-b94d-31a496644d92 { fill: ${fill}; }`}</style>
    </defs>
    <g id="uuid-e82af703-55fe-437d-9322-be069c7b7cd7" data-name="Layer 1">
      <g id="uuid-0b843a74-6ab5-482f-83fc-7541cb78aa88" data-name="15-Projections">
        <path
          className="uuid-53f4e5c0-2957-43bd-b94d-31a496644d92"
          d="M171.14,24c2.29,0,4.54.6,6.52,1.75l134.1,77.42c4.02,2.32,6.52,6.65,6.52,11.3v154.84c0,4.65-2.5,8.98-6.52,11.3l-134.1,77.42c-1.98,1.14-4.24,1.75-6.52,1.75s-4.54-.6-6.52-1.75L30.52,280.6c-4.02-2.32-6.52-6.65-6.52-11.3V114.47c0-4.65,2.5-8.98,6.52-11.3L164.62,25.75c1.98-1.14,4.24-1.75,6.52-1.75M171.14,0c-6.4,0-12.79,1.65-18.52,4.96L18.52,82.38C7.06,89,0,101.23,0,114.47v154.84c0,13.24,7.06,25.47,18.52,32.08l134.1,77.42c5.73,3.31,12.13,4.96,18.52,4.96s12.79-1.65,18.52-4.96l134.1-77.42c11.46-6.62,18.52-18.85,18.52-32.08V114.47c0-13.24-7.06-25.46-18.52-32.08L189.66,4.96c-5.73-3.31-12.13-4.96-18.52-4.96h0Z"
        />
        <g>
          <rect
            className="uuid-53f4e5c0-2957-43bd-b94d-31a496644d92"
            x="91.27"
            y="185.02"
            width="31.95"
            height="88.37"
          />
          <rect
            className="uuid-53f4e5c0-2957-43bd-b94d-31a496644d92"
            x="155.17"
            y="134.72"
            width="31.95"
            height="138.68"
          />
          <rect
            className="uuid-53f4e5c0-2957-43bd-b94d-31a496644d92"
            x="219.07"
            y="106.17"
            width="31.95"
            height="167.23"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ProjectionsIcon;
