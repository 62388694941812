import {
  CurrentProposalType,
  PROPOSAL_STATUSES,
  THEME,
  TOASTTYPE,
  USERTYPE,
} from "app/utils/types";
import { getSessionStore, removeSessionStore } from "app/utils/sessionStore";
import { Tooltip } from "primereact/tooltip";
import { Link, useNavigate } from "react-router-dom";
import IAPPLogoDarkIcon from "../../assets/IAPPLogoDarkIcon.svg";
import separatorImg from "../../assets/svg/separator.svg";
import IappLightModeLogo from "../../assets/IAPPLogoColor.svg";
import IappDarkModeLogo from "../../assets/IAPPLogoDarkMode.svg";
import SummaryIcon from "app/assets/IconComponent/summaryIcon";
import SolutionIcon from "app/assets/IconComponent/solutionIcon";
import SignatureIcon from "app/assets/IconComponent/SignatureIcon";
import ProjectionsIcon from "app/assets/IconComponent/ProjectionsIcon";
import FundingIcon from "app/assets/IconComponent/FundingIcon";
import DisclosureIcon from "app/assets/IconComponent/DisclosureIcon";
import AppendixIcon from "app/assets/IconComponent/AppendixIcon";
import ExitIcon from "app/assets/IconComponent/ExitIcon";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { getLightColor, getThemeColors } from "app/utils/utils";
import RectangleCurved from "app/assets/IconComponent/Rectanglecurved";
import BlockPopup from "app/uicomponents/microcomponents/BlockPopup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { useIPContext } from "./InteractiveProposalProvider";
import { Toast } from "primereact/toast";
import { makeRequest } from "app/utils/makeRequest";
import BrandingIconButton from "app/uicomponents/macrocomponents/InteractiveProposal/BrandingIconButton";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";

const InteractiveProposalSidebar = ({ isCollapsed, setIsCollapsed }: any) => {
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const toast: MutableRefObject<any> = useRef(null);
  const userAccess = getObjectFromLocalStore("useraccess");
  const planInfo = getSessionStore("planInfo");
  const globalTheme = getSessionStore("globalTheme");
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const navigation = useNavigate();
  const currentProposalType = getSessionStore("currentProposalType");
  const {
    signatureDetails,
    signatureLoading,
    signatureError,
    fetchApprovalDetails,
    selectedVersion,
    isAgencyManager,
    isPrimaryAdvisor,
    isPrimaryContactUser,
    isSubAgencyManager,
    fetchProposalSummary,
    summaryData,
  } = useIPContext();
  const { t } = useTranslation();
  const iconColor = globalTheme === THEME.DARK ? "#ffffff" : "#66788F";
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const previousPath = getSessionStore("previousPath");
  const [loading, setLoading] = useState(false);
  const { openComments, getComments } = useComments();
  const changesRequested = summaryData?.status === PROPOSAL_STATUSES.FEEDBACK;
  const menuItems = [
    {
      label: "Summary",
      path: "summary",
      icon: <SummaryIcon fill={iconColor} />,
    },
    {
      label: "Solution",
      path: "solution",
      icon: <SolutionIcon fill={iconColor} />,
    },
    {
      label: "Funding",
      path: "funding",
      icon: <FundingIcon fill={iconColor} />,
    },
    {
      label: "Projections",
      path: "projection",
      icon: <ProjectionsIcon fill={iconColor} />,
    },
    {
      label: "Signature",
      path: "signature",
      icon: <SignatureIcon fill={iconColor} />,
    },
    {
      label: "Disclosure",
      path: "disclosure",
      icon: <DisclosureIcon fill={iconColor} />,
    },
    {
      label: "Appendix",
      path: "appendix",
      icon: <AppendixIcon fill={iconColor} />,
    },
    {
      label: "Exit",
      path: "exit",
      icon: <ExitIcon fill={iconColor} />,
    },
  ];
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(signatureDetails?.status as any);

  const isPrimaryAdvisorOrAgencyManager = () =>
    isPrimaryAdvisor || isAgencyManager || isSubAgencyManager;

  const getThemeBasedIAPPBranding = () => {
    if (isCollapsed) {
      return globalTheme === THEME.DARK
        ? IAPPLogoDarkIcon
        : process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/bee_icon.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/BEE+ICON.png`;
    }
    return globalTheme === THEME.DARK ? IappDarkModeLogo : IappLightModeLogo;
  };
  const isPrimaryUser = () =>
    isPrimaryContactUser ||
    isPrimaryAdvisor ||
    isAgencyManager ||
    isSubAgencyManager ||
    userAccess?.userType === USERTYPE.ADMIN;

  const handleExit = () => {
    removeSessionStore("interactiveProposalID");
    navigation(typeof previousPath === "string" ? previousPath : "/proposal");
    removeSessionStore("previousPath");
  };
  const approvalAdress: any = {
    [USERTYPE.AGENT]: "interactiveProposalAdvisorProposalApproval",
    [USERTYPE.CONTACT]: "interactiveProposalContactProposalApproval",
    [USERTYPE.ADMIN]: "interactiveProposalAdminProposalApproval",
  };
  const handleApprove = () => {
    try {
      setLoading(true);
      makeRequest(approvalAdress[userAccess?.userType])
        .patch(
          undefined, // No body needed
          currentProposalType === CurrentProposalType.PLANS
            ? selectedVersion?.id ?? planInfo?.planProposalId
            : proposalId,
          true, // Indicates that `proposalId` is a request parameter
        )
        .then((response: any) => {
          fetchApprovalDetails();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response?.data?.message,
          });
        })
        .catch((error: any) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error?.message,
          });
        });
    } catch (error: any) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
      });
    } finally {
      setLoading(false);
      setShowExitConfirmation(false);
      fetchProposalSummary();
    }
  };
  const handleRequestChanges = () => {
    if (openComments?.length > 0) {
      try {
        setLoading(true);
        makeRequest("proposalRequestCommentChanges")
          .post(
            [],
            currentProposalType === CurrentProposalType.PLANS
              ? selectedVersion?.id ?? planInfo?.planProposalId
              : proposalId,
            true,
          )
          .then(() => {
            fetchApprovalDetails();
            toast?.current?.show({
              severity: TOASTTYPE.SUCCESS,
              summary: t("agentBranding.label.success"),
              detail: "Requested changes has been added to proposal",
              life: 3000,
            });

            getComments();
          })
          .catch(() => {
            toast?.current?.show({
              severity: TOASTTYPE.ERROR,
              summary: t("agentBranding.label.error"),
              detail: "Error in requesting changes",
              life: 3000,
            });
          })
          .finally(() => {
            setLoading(false);
            setShowExitConfirmation(false);
            fetchProposalSummary();
          });
      } catch (error: any) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error?.message,
        });
      }
    }
  };

  useEffect(() => {
    if (!signatureLoading && signatureError) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: signatureError,
      });
    }
  }, [signatureLoading, signatureError]);
  const isFileUploadedAndNotRejected =
    signatureDetails?.signedProposalFileKey && !signatureDetails?.signedProposalRejected;

  const exitPopupFooter = (
    <div className="flex items-center justify-between pt-2 w-full">
      <div>
        {userAccess?.userType === USERTYPE.ADMIN &&
          isFileUploadedAndNotRejected &&
          openComments?.length === 0 &&
          signatureDetails?.status !== PROPOSAL_STATUSES.APPROVED && (
            <BrandingIconButton
              label={t("general.button.approve")}
              icon={() => <FaRegCircleCheck className="h-7" style={{ color: primaryColor }} />}
              onClick={handleApprove}
              loading={loading}
              className="!px-3 !py-2"
            />
          )}
        {isPrimaryUser() &&
          userAccess?.userType === USERTYPE.AGENT &&
          openComments?.length > 0 &&
          !changesRequested &&
          isPrimaryAdvisorOrAgencyManager() && (
            <BrandingIconButton
              label={t("requestedChanges.button.label")}
              className=" rounded-3xl flex items-center px-3 cursor-pointer"
              style={{ border: `1px solid #F3164E` }}
              color="#F3164E"
              onClick={handleRequestChanges}
              icon={() => (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4"
                >
                  <path
                    d="M7.83366 1.1665L10.8337 3.99984M10.8337 3.99984L7.83366 6.83317M10.8337 3.99984H3.83366C2.3609 3.99984 1.16699 5.19374 1.16699 6.6665V10.8332"
                    stroke="#F3164E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            />
          )}
        {isPrimaryUser() &&
          userAccess?.userType === USERTYPE.AGENT &&
          isPrimaryAdvisorOrAgencyManager() &&
          !signatureDetails?.signedProposalFileKey &&
          !signatureDetails?.advisorApprovalStatus &&
          !signatureDetails?.signedProposalRejected &&
          openComments?.length === 0 &&
          !changesRequested && (
            <BrandingIconButton
              label={t("general.button.approve") as string}
              icon={() => <FaRegCircleCheck className="h-7" style={{ color: primaryColor }} />}
              onClick={handleApprove}
              loading={loading}
            />
          )}
        {isPrimaryUser() &&
          userAccess?.userType === USERTYPE.CONTACT &&
          openComments?.length === 0 &&
          !signatureDetails?.signedProposalFileKey &&
          !signatureDetails?.contactApprovalStatus &&
          !signatureDetails?.signedProposalRejected &&
          !changesRequested && (
            <BrandingIconButton
              label={t("general.button.approve") as string}
              icon={() => <FaRegCircleCheck className="h-7" style={{ color: primaryColor }} />}
              onClick={handleApprove}
              loading={loading}
            />
          )}
      </div>
      <div className="flex gap-3 justify-end">
        <Button
          label={t("general.button.cancel") as string}
          severity="secondary"
          text
          className="px-4 py-2  text-gray-700"
          onClick={() => setShowExitConfirmation(false)}
        />
        <Button
          label={t("general.button.exit") as string}
          className="px-4 py-2 text-gray-700 rounded-full"
          outlined
          onClick={handleExit}
        />
      </div>
    </div>
  );

  const exitPopupHeader = (
    <div className="text-xl font-semibold text-gray-800 dark:!text-white">Exit Proposal</div>
  );

  const adminExitPopupContent = (
    <div>
      {!isFileUploadedAndNotRejected
        ? t("exitProposal.exit.confirmationMessage")
        : !(signatureDetails?.advisorApprovalStatus || signatureDetails?.contactApprovalStatus) &&
          signatureDetails?.status !== PROPOSAL_STATUSES.APPROVED
        ? t("exitProposal.admin.message")
        : t("exitProposal.exit.confirmationMessage")}
    </div>
  );

  const showMessageForContact = () => {
    if (!isPrimaryUser()) {
      return t("exitProposal.exit.confirmationMessage");
    }
    if (
      (signatureDetails?.contactApprovalStatus && openComments?.length === 0) ||
      signatureDetails?.signedProposalRejected
    ) {
      return t("exitProposal.exit.confirmationMessage");
    }
    if (
      openComments?.length === 0 &&
      !signatureDetails?.signedProposalFileKey &&
      !signatureDetails?.contactApprovalStatus &&
      !signatureDetails?.signedProposalRejected &&
      !changesRequested
    ) {
      return t("exitProposal.contact.approved.message");
    }
    return t("exitProposal.contact.confirmationMessage");
  };

  const showMessageForAdvisor = () => {
    if (!isPrimaryUser()) {
      return t("exitProposal.exit.confirmationMessage");
    }
    if (openComments?.length > 0 && !changesRequested) {
      return t("exitProposal.agent.contact.commentMessage");
    }
    if (changesRequested || signatureDetails?.signedProposalRejected) {
      return t("exitProposal.exit.confirmationMessage");
    }
    if (signatureDetails?.advisorApprovalStatus) {
      return t("exitProposal.exit.confirmationMessage");
    }
    if (
      openComments?.length === 0 &&
      !signatureDetails?.signedProposalFileKey &&
      !signatureDetails?.advisorApprovalStatus &&
      !signatureDetails?.signedProposalRejected &&
      !changesRequested
    ) {
      return t("exitProposal.agent.contact.message");
    }
    return t("exitProposal.exit.confirmationMessage");
  };

  const agentContactExitPopupContent = (
    <div>
      {isPrimaryAdvisorOrAgencyManager() ? showMessageForAdvisor() : showMessageForContact()}
    </div>
  );

  return (
    <div className={`px-1 mb-4 relative z-[100] ${isCollapsed ? "w-[3.75rem]" : "w-[7.5rem]"}`}>
      <Toast ref={toast} position="top-center" />
      <aside
        className="py-5 fixed z-100 ml-2"
        style={{
          width: isCollapsed ? "3.75rem" : "7.5rem",
          height: window.innerHeight < 783 ? "100vh" : "auto",
          marginTop: "-9.5px",
        }}
      >
        <nav
          className="scrollbar-hide h-full flex flex-col justify-content-between overflow-y-auto border-r border-primary-bg-border border-[1px] shadow-lg rounded-2xl pt-3 pb-4 bg-light-primary dark:bg-blue-1100"
          style={{
            scrollbarWidth: "thin",
          }}
        >
          <div>
            <div className="absolute group right-[-20px] top-90 items-center">
              <button type="button" onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? (
                  <i
                    className="right-angles pi pi-angle-double-right p-2 rounded-lg bg-light-primary border-[1px] border-gray-300 dark:border-[1px] dark:bg-blue-1000 hover:bg-gray-100 dark:hover:!bg-blue-800"
                    data-pr-tooltip="Expand"
                  >
                    <Tooltip target=".right-angles" className="tooltip-wrapper" />
                  </i>
                ) : (
                  <i
                    className="left-angles pi pi-angle-double-left p-2 rounded-lg bg-light-primary border-[1px] border-gray-300 dark:bg-blue-1000 hover:bg-gray-100 dark:hover:!bg-blue-800"
                    data-pr-tooltip="Collapse"
                  >
                    <Tooltip target=".left-angles" className="tooltip-wrapper" />
                  </i>
                )}
              </button>
            </div>
            <img src={getThemeBasedIAPPBranding()} alt="logo" className="mb-10 px-3 flip" />
            <div className="flex justify-center items-center">
              <ul
                className={`leftPanelUl flex-1 text-center conthrax-font ${
                  !isCollapsed ? "w-[48px]" : ""
                }`}
              >
                {menuItems.map((item: any, index: number) => {
                  const isActive = window?.location?.hash?.includes(item.path);
                  return (
                    <li
                      className={`my-3 relative group w-full flex justify-center items-center ${
                        isCollapsed ? "px-2" : "px-3"
                      }`}
                      style={{
                        color: isActive ? primaryColor : "",
                      }}
                      key={index}
                      onClick={() => {
                        if (item.path === "exit") {
                          if (isPlanOrInactive) {
                            handleExit();
                          } else {
                            setShowExitConfirmation(true);
                          }
                          // removeSessionStore("interactiveProposalID");
                          // navigation(
                          //   currentProposalType === CurrentProposalType.PLANS
                          //     ? "/plans"
                          //     : "/proposal",
                          // );
                        }
                      }}
                    >
                      {isActive && (
                        <div className="absolute left-0">
                          <RectangleCurved fill={primaryColor} />
                        </div>
                      )}
                      {isCollapsed && (
                        <Tooltip target={`#tip_${item.path}`} position="right">
                          {item.label}
                        </Tooltip>
                      )}

                      <Link
                        to={item.path === "exit" ? "#" : item.path}
                        className="flex flex-col items-center"
                        onClick={(e) => {
                          if (item.path === "exit") {
                            e.preventDefault();
                          }
                        }}
                      >
                        <div>
                          {item.label === "Exit" && (
                            <img src={separatorImg} alt="exit" className="mb-5 mt-3" />
                          )}
                        </div>

                        <div
                          id={`tip_${item.path}`}
                          style={{
                            filter: isActive
                              ? `drop-shadow(0px 3px 6px ${getLightColor(primaryColor)})`
                              : "",
                          }}
                          className={`w-[24px] transition-transform duration-200 hover:scale-110 flipleft tooltip-${index}`}
                        >
                          {React.cloneElement(item.icon, {
                            fill: isActive ? primaryColor : iconColor,
                          })}
                        </div>

                        {!isCollapsed && (
                          <div className={`text-xs mt-1 ${isActive ? "text-sm" : "font-medium"} `}>
                            {item.label}
                          </div>
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
      </aside>
      <BlockPopup
        visible={showExitConfirmation}
        onHide={() => setShowExitConfirmation(false)}
        header={exitPopupHeader}
        content={
          userAccess?.userType === USERTYPE.ADMIN
            ? adminExitPopupContent
            : agentContactExitPopupContent
        }
        footerContent={exitPopupFooter}
        className="!w-[40vw]"
      />
    </div>
  );
};

export default InteractiveProposalSidebar;
