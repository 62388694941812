import { ProjectionFilters } from "app/utils/types";

export const projectionColumnJson: any = (selectedProjectionData: any) => [
  {
    field: "planYear",
    header: "Plan Year",
    formatter: (_: unknown, options: any) => (options?.rowIndex ?? 0) + 1,
  },
  {
    field: "businessValuation",
    header: "Business Valuation",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionFilters.BUSINESS_VALUE) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "annualGrowthRate",
    header: "Annual Growth Rate Projection",
    formatter: (value: any) => `${value || 0}%`, // Using template literals
  },
  {
    field: "deathBenefit",
    header: "Death Benefit",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionFilters.DEATH_BENEFIT) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "disabilityBenefit",
    header: "Disability Benefit",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionFilters.DISABILITY_BENEFIT) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];
export const headerColumnGroupProjection = {
  VALUATIONS: ["businessValuation", "annualGrowthRate"],
  "SHAREHOLDER BENEFITS": ["disabilityBenefit", "deathBenefit"],
};
