import { motion } from "framer-motion";

interface AnimatedCheckboxProps {
  checked: boolean;
  onChange: () => void;
  size?: number;
  inputId: string;
  label: string;
  indicator?: boolean;
  indicatorColor?: string;
  brandingColor?: string;
  isDisabled?: boolean;
  ishideIndicator?: boolean;
  hideCheckbox?: boolean;
}
export const AnimatedCheckbox = ({
  checked,
  onChange,
  size = 24,
  inputId,
  label,
  indicator,
  indicatorColor,
  brandingColor,
  isDisabled = false,
  ishideIndicator = false,
  hideCheckbox = false,
}: AnimatedCheckboxProps) => (
  <div
    className={`flex items-center ${
      !checked && ` border-1 border-theme-primary`
    } rounded-full p-2 gap-2  ${
      isDisabled
        ? "opacity-50 cursor-not-allowed"
        : hideCheckbox || ishideIndicator
        ? "cursor-default"
        : "cursor-pointer"
    }`}
    onClick={isDisabled || hideCheckbox || ishideIndicator ? undefined : onChange}
    style={
      hideCheckbox || checked || ishideIndicator
        ? {
            border: `1px solid ${brandingColor}`,
          }
        : {}
    }
  >
    {!ishideIndicator && !hideCheckbox && (
      <motion.svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        animate={{ fill: checked ? brandingColor : "#fff" }}
        transition={{ duration: 0.2 }}
      >
        <motion.path
          d="M18.8453 8C19.5598 9.23761 19.5598 10.7624 18.8453 12L16.1547 16.6603C15.4402 17.8979 14.1197 18.6603 12.6906 18.6603L7.3094 18.6603C5.88034 18.6603 4.55983 17.8979 3.8453 16.6603L1.1547 12C0.440168 10.7624 0.440168 9.2376 1.1547 8L3.8453 3.33974C4.55983 2.10214 5.88034 1.33974 7.3094 1.33974L12.6906 1.33975C14.1197 1.33975 15.4402 2.10214 16.1547 3.33975L18.8453 8Z"
          fill={checked ? brandingColor : "#fff"}
          animate={{ fill: checked ? brandingColor : "#fff" }}
          transition={{ duration: 0.2 }}
        />
        <motion.path
          d="M18.1958 8.375C18.7763 9.38055 18.7763 10.6194 18.1958 11.625L15.5052 16.2853C14.9246 17.2908 13.8517 17.9103 12.6906 17.9103L7.3094 17.9103C6.14829 17.9103 5.07537 17.2908 4.49482 16.2853L1.80422 11.625C1.22366 10.6194 1.22366 9.38055 1.80422 8.375L4.49482 3.71474C5.07537 2.70919 6.14829 2.08974 7.3094 2.08974L12.6906 2.08975C13.8517 2.08975 14.9246 2.70919 15.5052 3.71475L18.1958 8.375Z"
          stroke={checked ? brandingColor : "#D1D1DB"}
          strokeWidth="1.5"
          animate={{
            stroke: checked ? brandingColor : "#D1D1DB",
          }}
          transition={{ duration: 0.2 }}
        />
        <motion.path
          d="M7 10L9.16667 12.5L13.5 8"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{
            pathLength: checked ? 1 : 0,
            opacity: checked ? 1 : 0,
          }}
        />
      </motion.svg>
    )}
    <label
      htmlFor={inputId}
      className={`whitespace-nowrap  ${
        isDisabled
          ? "opacity-50 cursor-not-allowed"
          : hideCheckbox || ishideIndicator
          ? "cursor-default"
          : "cursor-pointer"
      }`}
    >
      {label}
    </label>
    {indicator && (
      <span
        className="!h-[10px] !w-[10px] rounded-full shrink-0 overflow-hidden"
        style={{ backgroundColor: indicatorColor || "#0053D0" }}
      />
    )}
  </div>
);
