import { getSessionStore } from "app/utils/sessionStore";
import { getThemeColors } from "app/utils/utils";
import { InputTextarea } from "primereact/inputtextarea";
import React, { FC, useState } from "react";

interface AddCommentProps {
  placeholder?: string;
  value?: string;
  onClose: () => void;
  onAddComment: (content: string) => Promise<boolean>;
  setShowAddComment: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddComment: FC<AddCommentProps> = ({
  value,
  onClose,
  onAddComment,
  setShowAddComment,
  placeholder = "Add a Comment...",
}) => {
  const [commentContent, setCommentContent] = useState(value ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const { branding } = getSessionStore("lookUpResponse");
  const { secondaryColor } = getThemeColors(branding);

  return (
    <div className="w-full flex flex-col gap-3">
      <label>
        <InputTextarea
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
          rows={2}
          className={`w-full text-base bg-[${secondaryColor}] bg-transparent outline-none focus:shadow-none border border-gray-700 rounded-lg text-gray-300`}
          placeholder={placeholder}
          autoFocus
        />
        {commentContent.length > 300 && (
          <small className="text-red-500">Please type comment up to 300 characters</small>
        )}
      </label>

      <div className="flex justify-end gap-3 mb-2">
        <button
          type="button"
          className="text-white hover:text-gray-300 transition-colors"
          onClick={() => {
            setShowAddComment(false);
            onClose();
            setCommentContent("");
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-white px-3 py-1 rounded-2xl text-black !border-0 hover:!text-black font-medium hover:bg-gray-100 transition-colors disabled:opacity-50"
          onClick={() => {
            if (commentContent.trim()) {
              setIsLoading(true);
              onAddComment(commentContent.trim()).then(() => {
                setIsLoading(false);
                setShowAddComment(false);
                setCommentContent("");
              });
            }
          }}
          disabled={!commentContent.trim() || isLoading || commentContent.length > 300}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AddComment;
