import KeyPersonDisclosure from "./KeyPersonDisclosure";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";

const InteractiveProposalDisclosure = () => (
  <CommentLayout
    commentLayoutId="interactive-proposal-disclosure"
    className="p-5 m-2 border border-1 shadow-sm border-[#d1d1db] rounded-md"
  >
    <KeyPersonDisclosure />
  </CommentLayout>
);

export default InteractiveProposalDisclosure;
