import { fundingOptions, getThemeColors, getUSANumberFormat } from "app/utils/utils";
import IPPageNumber from "../IPPageNuber";
import ChartWithCustomLegend from "app/uicomponents/microcomponents/ChartWithCustomLegend";
import { barGraphOptionsFunding } from "app/uicomponents/macrocomponents/PlansSummary/config";
import { getSessionStore } from "app/utils/sessionStore";
import HexagonImg from "../../images/hexagonImg.png";

const IPFunding = ({
  graphData,
  pageNumber,
  isNewPage,
  tableData,
}: {
  graphData?: any;
  tableData?: any;
  pageNumber: string | number;
  isNewPage?: boolean;
}) => {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);

  const tableHeaders = [
    { header: "Plan Year", color: "#356fa6" },
    { header: "Annual Life Premium", color: "#356fa6" },
    { header: "Annual Disability Premium", color: "#33699a" },
    { header: "Total Annual Premium", color: "#33699a" },
    { header: "Annual Admin Fees", color: "#254667" },
    { header: "Plan Origination Fees", color: "#1a3452" },
    { header: "Total Plan Costs", color: "#0a1a32" },
  ];

  const datasets = [
    {
      backgroundColor: fundingOptions(primaryColor)[0].indicatorColor,
      data: graphData?.map((item: any) => item?.lifePolicyPremium),
      label: "Life Premium",
    },
    {
      backgroundColor: fundingOptions(primaryColor)[1].indicatorColor,
      data: graphData?.map((item: any) => item?.disabilityPolicyPremium),
      label: "Disability Premium",
    },
    {
      backgroundColor: fundingOptions(primaryColor)[2].indicatorColor,
      data: graphData?.map((item: any) => item?.adminFees),
      label: "Admin Fees",
    },
    {
      backgroundColor: fundingOptions(primaryColor)[3].indicatorColor,
      data: graphData?.map((item: any) => item?.planOriginationFees),
      label: "Plan Origination Fees",
    },
  ];

  const mainContent = () => {
    if (isNewPage && tableData) {
      return (
        <div>
          {pageNumber === "4" ? (
            <div
              className="text-xl font-bold mb-2 text-center conthrax-font"
              style={{ color: "#495057" }}
            >
              Business Costs
            </div>
          ) : null}
          <table className="w-full border-collapse">
            <thead>
              <tr>
                {tableHeaders?.map(({ header, color }) => (
                  <th
                    key={header}
                    className="p-1 text-center text-xs font-semibold text-white pdf_table_border border-white"
                    style={{ backgroundColor: color }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((policy: any, index: any) => (
                <tr key={index} style={{ backgroundColor: "#EEEEEE", color: "#000000" }}>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    {policy?.planYear}
                  </td>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.annualLifePremium, false)}
                  </td>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.annualDisabilityPremium, false)}
                  </td>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.totalAnnualPremium, false)}
                  </td>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.annualAdminFees, false)}
                  </td>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.planOriginationFees, false)}
                  </td>
                  <td className="p-1 text-sm text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.totalPlanCosts, false)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div>
        <div style={{ width: "503px" }}>
          <ChartWithCustomLegend
            data={{
              labels: graphData?.map((item: any) => item?.planYear),
              datasets,
            }}
            options={barGraphOptionsFunding(false, ipTooltipColor, "Plan Year")}
            type="bar"
            chartWidth="503px"
            chartHeight="450px"
            legends={[]}
          />
          <div className="flex flex-wrap justify-center gap-3">
            {datasets?.map(({ backgroundColor, label }, index) => (
              <div key={index} className="flex items-center">
                <span style={{ color: "#000000", fontSize: "16px" }}>{label}</span>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor,
                    marginLeft: "2px",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full relative min-h-screen">
      <div className="relative z-20 px-[4em] w-full max-w-[56em] mx-auto p-[1.75em]">
        {!isNewPage ? (
          <h1 className="text-center text-2xl font-bold mb-5 conthrax-font interactive-proposal-text">
            Plan Funding
          </h1>
        ) : null}
        {mainContent()}
      </div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default IPFunding;
