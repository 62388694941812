import { FC } from "react";
import { DateTime } from "luxon";

interface IUserNameAndTimeStamp {
  name: string;
  date?: string;
  className?: string;
}

export const formatTimeAgo = (date: string | Date): string => {
  const now = DateTime.local();
  const timeStamp = DateTime.fromJSDate(new Date(date));
  const diff = now.diff(timeStamp, ["minutes", "hours", "days"]).toObject();

  if (diff.hours! > 24) {
    return timeStamp.setZone("America/Chicago").toFormat("MM/dd/yyyy");
  }
  if (diff.hours! === 1) {
    return "1 hour ago";
  }
  if (diff.hours! > 1 && diff.hours! < 24) {
    return `${Math.floor(diff.hours!)} hours ago`;
  }
  if (diff.minutes! < 1) {
    return "Now";
  }
  if (diff.minutes! === 1) {
    return "1 minute ago";
  }
  if (diff.minutes! < 60) {
    return `${Math.floor(diff.minutes!)} minutes ago`;
  }
  return "";
};

const UserNameAndTimeStamp: FC<IUserNameAndTimeStamp> = ({ name, date, className }) => (
  <div className={`flex items-center gap-2 ${className}`}>
    <div className="w-[2rem] h-[2rem] rounded-full flex items-center justify-center text-white border-[1px] border-white">
      {name.charAt(0).toUpperCase()}
    </div>
    <p className="text-white font-medium flex flex-col">
      <span>{name}</span>
      {date && <span className="text-xs text-gray-500">{formatTimeAgo(date)}</span>}
    </p>
  </div>
);

export default UserNameAndTimeStamp;
