import React from "react";

interface CommentIconProps {
  fill?: string;
}

const CommentIcon: React.FC<CommentIconProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-8e068703-c34f-4889-b6f7-20292849c822"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 338.69 382.66"
  >
    <defs>
      <style>{`.uuid-89d928b7-2862-474c-afc0-ccc772cde288 { fill: ${fill}; }`}</style>
    </defs>

    <g id="uuid-b834f88d-3bae-4337-8db1-4421908cdc7a" data-name="Layer 1">
      <g id="uuid-b415cf3b-acfb-4568-8c0d-f070df6e4e26" data-name="19-Comment">
        <path
          className="uuid-89d928b7-2862-474c-afc0-ccc772cde288"
          d="M163.94,221.71c-6.63,0-12-5.37-12-12v-121.02c0-6.63,5.37-12,12-12s12,5.37,12,12v121.02c0,6.63-5.37,12-12,12Z"
        />
        <path
          className="uuid-89d928b7-2862-474c-afc0-ccc772cde288"
          d="M224.45,161.2h-121.02c-6.63,0-12-5.37-12-12s5.37-12,12-12h121.02c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
        />
        <path
          className="uuid-89d928b7-2862-474c-afc0-ccc772cde288"
          d="M233.7,24c5.93,0,11.45,3.19,14.41,8.32l64.35,111.45c2.96,5.13,2.96,11.51,0,16.64l-69.15,119.78h-17.33c-7.24,0-14.14,2.72-19.43,7.66l-65.88,61.46v-40.63c0-15.71-12.78-28.49-28.49-28.49h-16.79L26.23,160.42c-2.96-5.13-2.96-11.51,0-16.64L90.58,32.32c2.96-5.13,8.49-8.32,14.42-8.32h128.71M233.7,0H104.99c-14.52,0-27.94,7.75-35.2,20.32L5.45,131.78c-7.26,12.58-7.26,28.07,0,40.64l74.78,129.53c.8,1.39,2.28,2.24,3.89,2.24h28.05c2.48,0,4.49,2.01,4.49,4.49v57.1c0,10.1,8.27,16.87,16.95,16.87,3.96,0,8.01-1.41,11.38-4.57l77.93-72.69c.83-.78,1.93-1.21,3.06-1.21h28.6c1.6,0,3.09-.86,3.89-2.24l74.78-129.53c7.26-12.58,7.26-28.07,0-40.64l-64.35-111.45c-7.26-12.58-20.68-20.32-35.2-20.32h0Z"
        />
      </g>
    </g>
  </svg>
);

export default CommentIcon;
