import React from "react";
import { Button } from "primereact/button";
// import { Tooltip } from "primereact/tooltip";
import { getFileName } from "app/utils/utils";

interface FileViewComponentProps {
  docPath: string;
  canDelete?: boolean;
  onDelete?: () => void;
  onClick?: (docPath: string) => void;
  disabled?: boolean;
}

const FileViewComponent: React.FC<FileViewComponentProps> = ({
  docPath,
  canDelete = false,
  onDelete,
  onClick,
  disabled = false,
}) => {
  const getIconClass = (path: string) => {
    const extension = path.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "pdf":
        return "pi pi-file-pdf";
      case "doc":
      case "docx":
        return "pi pi-file-word";
      case "xls":
      case "xlsx":
        return "pi pi-file-excel";
      case "csv":
        return "pi pi-file-excel";
      default:
        return "pi pi-file";
    }
  };

  return (
    <div
      className="flex items-center justify-between p-2 bg-gray-100 dark:!bg-blue-1100 rounded-md cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(docPath);
      }}
    >
      <div className="flex items-center">
        <i className={`${getIconClass(docPath)} text-3xl`} />
        <div className="ml-3">
          <div className="font-semibold">{getFileName(docPath)}</div>
        </div>
      </div>
      <div className="flex items-center">
        {canDelete && (
          <Button
            icon="pi pi-times"
            size="small"
            disabled={disabled}
            rounded
            className="ml-2 p-button-rounded !h-[2rem] !w-[2rem] py-1.5 px-3 text-white text-2xl !bg-light-gray p-button-text"
            aria-label="Delete"
            onClick={(e) => {
              e.stopPropagation();
              onDelete && onDelete();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FileViewComponent;
