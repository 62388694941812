import { Button } from "primereact/button";
import React from "react";

interface ErrorPageProps {
  errorMsg?: string;
  showRefresh?: boolean;
  height?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMsg,
  showRefresh,
  height = "h-screen",
}) => {
  const error = errorMsg || "Something went wrong please try again";
  return (
    <div className="block-content">
      <div className={`${height} surface-section px-4 py-8 md:px-6 lg:px-8`}>
        <div
          style={{
            background:
              "radial-gradient(50% 109138% at 50% 50%, rgb(255 43 43 / 27%) 0%, rgb(255 255 255 / 0%) 100%)",
          }}
          className="text-center flipright"
        >
          <span className="bg-white text-red-500 font-bold text-2xl inline-block px-3">Error</span>
        </div>
        <p className="text-700 text-3xl mt-5 text-center">{error}</p>
        {showRefresh && (
          <div className="text-center mt-10">
            <Button
              label="Refresh Page"
              severity="info"
              outlined
              icon="pi pi-refresh"
              onClick={() => window.location.reload()}
            />
          </div>
        )}
      </div>
    </div>
  );
};
