import { getSessionStore } from "app/utils/sessionStore";
import { getThemeColors } from "app/utils/utils";
import { Button } from "primereact/button";

const BrandingIconButton = ({
  disabled,
  onClick,
  label,
  icon,
  className = "",
  loading = false,
  color = null,
}: any) => {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  return (
    <Button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`p-button-outlined p-button-rounded gap-2 ${className}`}
      style={{
        color: color || primaryColor || "#0053D0",
        borderColor: color || primaryColor || "#0053D0",
      }}
      loading={loading}
    >
      {icon && typeof icon === "function" ? icon() : icon}
      {label}
    </Button>
  );
};

export default BrandingIconButton;
