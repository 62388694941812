import { create } from "zustand";

interface SignatureProposalStore {
  signatureDetails: any;
  setSignatureDetails: (details: any) => void;
}

const useSignatureProposalStore = create<SignatureProposalStore>((set: any) => ({
  signatureDetails: null,
  setSignatureDetails: (details: any) => set({ signatureDetails: details }),
}));

export default useSignatureProposalStore;
