import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import loadable, { LoadableComponent } from "@loadable/component";
import { v4 as uuidv4 } from "uuid";
import { Suspense, useEffect, useState } from "react";
import ScreenLoader from "../app/uicomponents/microcomponents/ScreenLoader";
import PrivateRoute from "./PrivateRoute";
import {
  getObjectFromSessionStore,
  getSessionStore,
  removeSessionStore,
  setObjectInSessionStore,
  setSessionStore,
} from "app/utils/sessionStore";
// import { useSelector } from "react-redux";
import { RootState } from "app/store";
import AutoLogoutTimer from "../app/pages/AutoLogoutTimer";

// import { getObjectFromSessionStore, setObjectInSessionStore } from "app/utils/sessionStore";
import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "app/store";
// import AutoLogoutTimer from "../app/pages/AutoLogoutTimer";
import { UPDATE_FCM_TOKEN } from "app/redux/features/resetPassword";
import { getTokenFromFirebase, onMessageListener } from "firebase-setup";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { isEmpty } from "ramda";
import RootChat from "app/pages/RootChat";
import RootLayout from "app/uicomponents/macrocomponents/RootLayout";
import useDarkTheme from "app/hooks/useDarkTheme";
import PrimeReact from "primereact/api";
import { THEME, USERTYPE } from "app/utils/types";
import Footer from "app/uicomponents/microcomponents/Footer";
import { CONTACT_PROFILE_LOOKUP } from "app/redux";
import InteractiveProposalLayout from "app/pages/InteractiveProposal/InteractiveProposalLayout";
import InteractiveProposalSummary from "app/pages/InteractiveProposal/menuPages/InteractiveProposalSummary";
import InteractiveProposalSolution from "app/pages/InteractiveProposal/menuPages/InteractiveProposalSolution";
import InteractiveProposalFunding from "app/pages/InteractiveProposal/menuPages/InteractiveProposalFunding";
import InteractiveProposalProjection from "app/pages/InteractiveProposal/menuPages/InteractiveProposalProjection";
import InteractiveProposalDisclosure from "app/pages/InteractiveProposal/menuPages/ProposalDisclosure/InteractiveProposalDisclosure";
import InteractiveProposalAppendix from "app/pages/InteractiveProposal/menuPages/Appendix/InteractiveProposalAppendix";
import InteractiveProposalProvider from "app/pages/InteractiveProposal/InteractiveProposalProvider";
import InteractiveProposalSignature from "app/pages/InteractiveProposal/menuPages/Signature/InteractiveProposalSignature";

interface RouteElement {
  id: string;
  name: string;
  path: string;
  isPrivate: boolean;
  component: LoadableComponent<any>;
}

// NOTE Define a list of routes
/* eslint-disable */
const routes: RouteElement[] = [
  {
    id: uuidv4(),
    name: "login",
    path: "/",
    isPrivate: false,
    component: loadable(
      () => import(/*  webpackChunkName: "login",  webpackPrefetch: true */ "../app/pages/Login"),
    ),
  },

  {
    id: uuidv4(),
    name: "otp",
    path: "/otp",
    isPrivate: false,
    component: loadable(
      () => import(/*  webpackChunkName: "otp",  webpackPrefetch: true */ "../app/pages/Otp"),
    ),
  },
  {
    id: uuidv4(),
    name: "forgotPassword",
    path: "/forgotPassword",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "forgotPassword",  webpackPrefetch: true */ "../app/pages/ForgotPasswordPage"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "checkInbox",
    path: "/checkInbox",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "checkInbox",  webpackPrefetch: true */ "../app/pages/CheckEmail"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "createNewPassword",
    path: "/createNewPassword",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "createnewpassword",  webpackPrefetch: true */ "../app/pages/CreateNewPassword"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "resetSuccessful",
    path: "/resetSuccessful",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "resetsuccessful",  webpackPrefetch: true */ "../app/pages/ResetSuccessful"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "signup",
    path: "/signup",
    isPrivate: false,
    component: loadable(
      () => import(/* webpackChunkName: "signup",  webpackPrefetch: true */ "../app/pages/Signup"),
    ),
  },
  {
    id: uuidv4(),
    name: "dashboard",
    path: "/dashboard",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "dashboard",  webpackPrefetch: true */ "../app/pages/ContactDashboard"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "client",
    path: "/client",
    isPrivate: true,
    component: loadable(
      () => import(/* webpackChunkName: "client",  webpackPrefetch: true */ "../app/pages/Client"),
    ),
  },
  {
    id: uuidv4(),
    name: "inbox",
    path: "/inbox",
    isPrivate: true,
    component: loadable(
      () => import(/* webpackChunkName: "inbox",  webpackPrefetch: true */ "../app/pages/Inbox"),
    ),
  },
  {
    id: uuidv4(),
    name: "notification",
    path: "/notification",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "notification",  webpackPrefetch: true */ "../app/pages/Notification"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "signup-plans",
    path: "/signup-plans",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "plans",  webPpackPrefetch: true */ "../app/pages/SignupPlans"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "confirm-payment",
    path: "/confirm-payment",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "plans",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/ConfirmPayment/ConfirmPaymentComponent"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "agent/contact",
    path: "/agent/contact/:id",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "bids",  webpackPrefetch: true */ "../app/pages/AgentContacts"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "blockedScreen",
    path: "/blocked",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "blockedScreen",  webpackPrefetch: true */ "../app/pages/BlockedAccount"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "verifiedScreen",
    path: "/verified",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "verifiedScreen",  webpackPrefetch: true */ "../app/pages/VerifiedUserScreen"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "SignInOtp",
    path: "/verifyAccount",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "SignInOtp",  webpackPrefetch: true */ "../app/pages/SignInOtp"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "Branding",
    path: "/branding",
    isPrivate: false,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "Branding",  webpackPrefetch: true */ "../app/pages/Branding"),
    ),
  },
  {
    id: uuidv4(),
    name: "ConfirmDetails",
    path: "/confirmDetails",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "ConfirmDetails",  webpackPrefetch: true */ "../app/pages/ConfirmDetailsPage"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "PolicyInputBase",
    path: "/policyinputbase",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "PolicyInputBase",  webpackPrefetch: true */ "../app/pages/PolicyInputBasePage"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "CreateProposal",
    path: "/createproposal/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/CreateProposal/Header"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "plans",
    path: "/plans",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/pages/PlanListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "plans",
    path: "/plans/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/pages/PlanListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "SendProposal",
    path: "/sendProposal",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/pages/SendProposalPage"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "viewpolicydetails",
    path: "/viewpolicydetails/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/pages/ViewPolicyDetails"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "ViewProposal",
    path: "/viewProposal/:id?",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/pages/ViewApproveProposalPdf"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "Error",
    path: "*",
    isPrivate: false,
    component: loadable(
      () => import(/*  webpackChunkName: "Error",  webpackPrefetch: true */ "../app/pages/Login"),
    ),
  },
  {
    id: uuidv4(),
    name: "Profile",
    path: "/profile/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "Profile",  webpackPrefetch: true */ "../app/pages/Profile"),
    ),
  },
  {
    id: uuidv4(),
    name: "AgencyProfile",
    path: "/agency/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "agencyProfile",  webpackPrefetch: true */ "../app/pages/AgencyProfile"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "AgencyProfile",
    path: "/sub-agency/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "agencyProfile",  webpackPrefetch: true */ "../app/pages/AgencyProfile"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "AgentProfile",
    path: "/agent/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "agencyProfile",  webpackPrefetch: true */ "../app/pages/AgentProfile"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "AssistantProfile",
    path: "/assistant/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "assistantProfile",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/Team/TeamAssistantProfile"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "EditProfile",
    path: "/editProfile/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "EditProfile",  webpackPrefetch: true */ "../app/pages/AgentContactEditForm"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "AgentEdit",
    path: "/editAgentProfile/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "EditProfile",  webpackPrefetch: true */ "../app/pages/SuperAdminAgentProfileEdit"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "ContactProfile",
    path: "/contacts/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "EditProfile",  webpackPrefetch: true */ "../app/pages/ContactProfile"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "RequestProposal",
    path: "/requestProposal",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "RequestProposal",  webpackPrefetch: true */ "../app/pages/RequestProposal"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "reviewContactProposal",
    path: "/proposals/contact/:id",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reviewContactProposal",  webpackPrefetch: true */ "../app/pages/AgentContactReview"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "resetPassword",
    path: "/resetPassword",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "resetPassword",  webpackPrefetch: true */ "../app/pages/ResetPasswordFromEmailLink"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "linkNotFound",
    path: "/linkExpired",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "linkNotFound",  webpackPrefetch: true */ "../app/pages/LinkExpired"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "linkNotFound",
    path: "/linkNotFound",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "linkNotFound",  webpackPrefetch: true */ "../app/pages/LinkNotFound"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "settings",
    path: "/settings",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "linkNotFound",  webpackPrefetch: true */ "../app/pages/Settings"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "SignupFinancialInfo",
    path: "/signupFinancialInfo",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "SignupFinancialInfo",  webpackPrefetch: true */ "../app/pages/SignupFinancialInfo"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "SignupHealthInfo",
    path: "/signupHealthInfo",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "SignupHealthInfo",  webpackPrefetch: true */ "../app/pages/SignupHealthInfo"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "signupSuccessful",
    path: "/signupSuccessful",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "signupSuccessful",  webpackPrefetch: true */ "../app/pages/SignupSuccessful"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "createProposal",
    path: "/createProposal",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "forgotPassword",  webpackPrefetch: true */ "../app/pages/CreateProposal"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "proposal-review",
    path: "/proposal-review/:id",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "verifiedScreen",  webpackPrefetch: true */ "../app/pages/ProposalReview"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "Pending View",
    path: "/pending-view/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "proposalpending",  webpackPrefetch: true */ "../app/pages/ProposalPendingView"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "Plan Summary",
    path: "/plan-summary/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "proposalpending",  webpackPrefetch: true */ "../app/pages/PlanSummary"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "about",
    path: "/about",
    isPrivate: true,
    component: loadable(
      () => import(/*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/About"),
    ),
  },
  {
    id: uuidv4(),
    name: "learnMore",
    path: "/learnMore",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/LearnMore"),
    ),
  },
  {
    id: uuidv4(),
    name: "termsAndConditions",
    path: "/termsAndConditions/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/TermsAndConditions"
        ),
    ),
  },

  {
    id: uuidv4(),
    name: "subagencyagents",
    path: "/subagencyagents",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/AgencyViewRoles/SubAgencyAgentsListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "subagencyassistants",
    path: "/subagencyassistants",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/AgencyViewRoles/SubAgencyAssistantsListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "subagencymanager",
    path: "/subagencymanager",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/AgencyViewRoles/SubAgencyManagerListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "agentsubagencyagents",
    path: "/agentsubagencyagents",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/AgencyViewRoles/SubAgencyAgentsListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "agentsubagencyassistants",
    path: "/agentsubagencyassistants",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/AgencyViewRoles/SubAgencyAssistantsListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "agentsubagencymanager",
    path: "/agentsubagencymanager",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/AgencyViewRoles/SubAgencyManagerListing"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "assistantProfile",
    path: "/assistant/profile/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "team",  webpackPrefetch: true */ "../app/uicomponents/macrocomponents/Team/TeamAssistantProfile"
        ),
    ),
  },
  // {
  //   id: uuidv4(),
  //   name: "proposal",
  //   path: "/proposal/:name",
  //   isPrivate: true,
  //   component: loadable(
  //     () =>
  //       import(
  //         /* webpackChunkName: "Proposal",  webpackPrefetch: true */ "../app/pages/ContactProposal"
  //       ),
  //   ),
  // },
  {
    id: uuidv4(),
    name: "proposal",
    path: "/proposal",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "Proposal",  webpackPrefetch: true */ "../app/pages/ProposalsSection"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "proposal",
    path: "/proposal/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "Proposal",  webpackPrefetch: true */ "../app/pages/ProposalsSection"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "chat",
    path: "/chat",
    isPrivate: true,
    component: loadable(
      () => import(/* webpackChunkName: "Chat",  webpackPrefetch: true */ "../app/pages/ChatPage"),
    ),
  },
  {
    id: uuidv4(),
    name: "Pending",
    path: "/pending",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "proposalpending",  webpackPrefetch: true */ "../app/pages/ProposalPending"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "Pending",
    path: "/pending/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "proposalpending",  webpackPrefetch: true */ "../app/pages/ProposalPending"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "subscribers",
    path: "/subscribers",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "subscribers",  webpackPrefetch: true */ "../app/pages/Subscribers"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "subscribers",
    path: "/subscribers/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "subscribers",  webpackPrefetch: true */ "../app/pages/Subscribers"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "organisation",
    path: "/organisation",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "team",  webpackPrefetch: true */ "../app/pages/Organisation"),
    ),
  },
  {
    id: uuidv4(),
    name: "organisation",
    path: "/organisation/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "team",  webpackPrefetch: true */ "../app/pages/Organisation"),
    ),
  },
  {
    id: uuidv4(),
    name: "reports",
    path: "/reports/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/Report"),
    ),
  },
  {
    id: uuidv4(),
    name: "reports",
    path: "/reports",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/Report"),
    ),
  },
  {
    id: uuidv4(),
    name: "education",
    path: "/academy/course/:id",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/TrainingCenter/CourseDetails/CourseView"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "course",
    path: "/course/new-course",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "reports",  webpackPrefetch: true */ "../app/pages/NewCourse"),
    ),
  },
  {
    id: uuidv4(),
    name: "loans",
    path: "/loans",
    isPrivate: true,
    component: loadable(
      () =>
        import(/* webpackChunkName: "subscribers",  webpackPrefetch: true */ "../app/pages/Loans"),
    ),
  },
  {
    id: uuidv4(),
    name: "loans",
    path: "/loans/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(/* webpackChunkName: "subscribers",  webpackPrefetch: true */ "../app/pages/Loans"),
    ),
  },
  {
    id: uuidv4(),
    name: "academy",
    path: "/academy",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "academy",  webpackPrefetch: true */ "../app/pages/TrainingCenter/TrainingCenter"
        ),
    ),
  },
  {
    id: uuidv4(),
    name: "academy",
    path: "/academy/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "CreateProposal",  webpackPrefetch: true */ "../app/pages/TrainingCenter/TrainingCenter"
        ),
    ),
  },

  /* eslint-disable */
  // NOTE  Add more routes as needed
];
const protectedRoutes: { [key: string]: RouteElement } = {
  "/loans/:name": {
    id: uuidv4(),
    name: "loans",
    path: "/loans/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(/* webpackChunkName: "subscribers",  webpackPrefetch: true */ "../app/pages/Loans"),
    ),
  },

  "/resources/:name": {
    id: uuidv4(),
    name: "resources",
    path: "/resources/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "resources",  webpackPrefetch: true */ "../app/pages/Resources"
        ),
    ),
  },

  "/team": {
    id: uuidv4(),
    name: "team",
    path: "/team",
    isPrivate: true,
    component: loadable(
      () => import(/*  webpackChunkName: "team",  webpackPrefetch: true */ "../app/pages/Team"),
    ),
  },

  "/contacts": {
    id: uuidv4(),
    name: "contacts",
    path: "/contacts",
    isPrivate: true,
    component: loadable(
      () =>
        import(/*  webpackChunkName: "contact",  webpackPrefetch: true */ "../app/pages/Contacts"),
    ),
  },
  "/bids/:name": {
    id: uuidv4(),
    name: "bids",
    path: "/bids/:name",
    isPrivate: true,
    component: loadable(
      () => import(/*  webpackChunkName: "bids",  webpackPrefetch: true */ "../app/pages/Bids"),
    ),
  },

  "/admanagement/:name": {
    id: uuidv4(),
    name: "admanagement",
    path: "/admanagement/:name",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "admanagement",  webpackPrefetch: true */ "../app/pages/Admanagement"
        ),
    ),
  },
  "/masterdata/:name": {
    id: uuidv4(),
    name: "masterdata",
    path: "/masterdata/:name",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /*  webpackChunkName: "masterdata",  webpackPrefetch: true */ "../app/pages/MasterData"
        ),
    ),
  },
  /* eslint-disable */
  // NOTE  Add more routes as needed
};

export const AppRoutes = () => {
  const lookUpResponse = useSelector((state: RootState) => state?.contactProfileLookup);
  const { fcmToken } = useSelector((state: RootState) => state?.token);
  const getlookUpResponse = getObjectFromSessionStore("lookUpResponse");
  const location = useLocation();
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState("");
  const [fetchingFCMToken, setFetchingFCMToken] = useState<
    null | "permissionGiven" | "fetching" | "fetched"
  >(null);
  const dispatch = useDispatch();
  const [fcmPayload, setFcmPayload] = useState<null | { email: string; deviceToken: string }>(null);
  const [domainState, setDomainState] = useState({
    domain: "",
    subDomain: "",
  });

  useEffect(() => {
    if (lookUpResponse?.data?.menu) {
      setObjectInSessionStore("lookUpResponse", lookUpResponse?.data);
      const existingPlanInfo = getSessionStore("planInfo");
      const updatedPlanInfo = {
        ...existingPlanInfo,
        branding: {
          ...existingPlanInfo.branding,
          primaryColor: lookUpResponse?.data?.branding?.primaryColor,
          secondaryColor: lookUpResponse?.data?.branding?.secondaryColor,
        },
      };
      setSessionStore("planInfo", updatedPlanInfo);
      removeSessionStore("refetchLookupAfter");

      const newTheme = lookUpResponse?.data?.branding?.theme || THEME.LIGHT;

      document.documentElement.classList.remove("dark", "light");
      document.documentElement.classList.add(newTheme);
      setSessionStore("globalFontSize", lookUpResponse?.data?.branding?.fontsize || "large");
      document.documentElement.setAttribute(
        "data-font",
        lookUpResponse?.data?.branding?.fontsize || "large",
      );
      setSessionStore("globalTheme", newTheme);
      const themeLink = (document.getElementById("app-theme") as HTMLLinkElement)?.href;
      const splittedTheme = themeLink.split("/");

      const currentThemeName = splittedTheme?.[(splittedTheme?.length ?? 2) - 2];

      const currentTheme = currentThemeName.includes("dark") ? THEME.DARK : THEME.LIGHT;

      if (currentTheme !== newTheme) {
        PrimeReact?.changeTheme?.(
          `lara-${currentTheme}-blue`,
          `lara-${newTheme}-blue`,
          "app-theme",
          () => {
            const allThemeLinks = document.querySelectorAll("link#app-theme");
            if (allThemeLinks.length > 1) {
              for (let i = 1; i < allThemeLinks.length; i++) {
                allThemeLinks[i].remove();
              }
            }
          },
        );
      }
    }
  }, [lookUpResponse]);
  useDarkTheme();

  useEffect(() => {
    const dataFont = document.documentElement.getAttribute("data-font");
    if (!dataFont) {
      const globalFontSize = getSessionStore("globalFontSize");
      document.documentElement.setAttribute("data-font", globalFontSize || "large");
    }
  }, []);

  useEffect(() => {
    let userEmail = lookUpResponse?.data?.email;
    if (!userEmail) {
      userEmail = getlookUpResponse?.email;
    }

    if (userEmail) {
      onMessageListener();

      if ("Notification" in window) {
        if (Notification.permission === "granted") {
          if (!(fcmToken.isUpdating || fcmToken.isTokenUpdated) && !fcmToken.error) {
            getTokenFromFirebase().then((currentToken: string | null) => {
              if (currentToken) {
                setFcmPayload({ deviceToken: currentToken, email: userEmail });
              }
            });
          }
        } else {
          Notification.requestPermission().then((permission) => {
            if (
              permission === "granted" &&
              !(fcmToken.isUpdating || fcmToken.isTokenUpdated) &&
              !fcmToken.error &&
              !fetchingFCMToken
            ) {
              setFetchingFCMToken("permissionGiven");
            }
          });
        }
      }
    }
  }, [lookUpResponse, fcmToken, fetchingFCMToken]);

  const userAccess = getObjectFromLocalStore("useraccess");

  useEffect(() => {
    if (!domainState.domain && !isEmpty(userAccess) && userAccess?.domain) {
      setDomainState({
        domain: userAccess?.domain,
        subDomain: userAccess?.subDomain,
      });
    }
    const hostName = window.location.hostname;
    const isLocalhost = hostName.includes("localhost");

    const appDomain = domainState?.domain?.trim();
    const host = appDomain?.split("//")?.[1];
    if (!isLocalhost && isEmpty(userAccess) && host && hostName !== host) {
      setDomainState({
        domain: "",
        subDomain: "",
      });
      window.location.href = appDomain;
    }
  }, [userAccess, domainState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (fcmPayload && !isEmpty(userAccess)) {
        dispatch({
          type: UPDATE_FCM_TOKEN,
          payload: {
            address: "updateDeviceToken",
            body: {
              email: fcmPayload.email,
              deviceToken: fcmPayload.deviceToken,
            },
          },
        });
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [fcmPayload]);

  const userRoutes: string[] = [];

  (getlookUpResponse?.menu ? getlookUpResponse?.menu : lookUpResponse?.data?.menu)?.forEach(
    (el: any) => {
      el.groupItems?.forEach((item: any) => {
        userRoutes.push(
          `${item.link}${item.groupItems && item.groupItems.length > 0 ? "/:name" : ""}`,
        );
      });
    },
  );

  userRoutes.forEach((r) => {
    if (protectedRoutes[r]) {
      routes.push(protectedRoutes[r]);
    }
  });

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);

    if (location.pathname === prevLoc) {
      setPrevLoc("");
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  useEffect(() => {
    const getlookUpResponse = getObjectFromSessionStore("lookUpResponse");
    function handleBeforeUnload() {
      if (!isEmpty(getlookUpResponse)) {
        setSessionStore("refetchLookupAfter", true);
      }
    }

    const refetchLookupAfter = getSessionStore("refetchLookupAfter");

    if (refetchLookupAfter && !isEmpty(getlookUpResponse)) {
      dispatch({
        type: CONTACT_PROFILE_LOOKUP,
        payload: {
          address: "lookupAfter",
        },
      });
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const pathName = location.pathname.substring(1);

  // pages with sidebar will see Footer with "DashboardWithSidebar" component,
  // pages without sidebar will see Footer when showFooter is true, so add the pathname here
  const showFooter = [
    "plan-summary",
    "viewpolicydetails",
    "pending-view",
    "createproposal",
    "sendProposal",
    "viewProposal",
    "proposal-review",
    "proposals/contact",
  ].reduce((acc, val) => acc || pathName.startsWith(val), false);

  const coverFullHeight = [
    "viewpolicydetails",
    "plan-summary",
    "pending-view/documents",
    "pending-view/pending",
    "pending-view/proposals",
    "pending-view/insurancepending",
  ].reduce((acc, val) => acc || pathName.startsWith(val), false);

  return (
    <Suspense fallback={<ScreenLoader />}>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          {routes.map((route) => {
            const { component: Component, isPrivate, id, path } = route;
            return (
              <Route
                key={id}
                path={path}
                // lazy={() => import(path)}
                element={
                  isPrivate ? (
                    <PrivateRoute>
                      <div
                        className={`bg-[var(--surface-ground)] ${
                          userAccess?.userType !== USERTYPE.CONTACT || coverFullHeight
                            ? "min-h-screen"
                            : ""
                        } flex flex-col`}
                      >
                        <RootChat>
                          <AutoLogoutTimer ComposedClass={Component} location={location} />
                        </RootChat>
                        {showFooter && <Footer />}
                      </div>
                    </PrivateRoute>
                  ) : (
                    <Component />
                  )
                }
              />
            );
          })}
        </Route>
        <Route
          path="/interactive-proposal/*"
          element={
            <InteractiveProposalProvider>
              <InteractiveProposalLayout />
            </InteractiveProposalProvider>
          }
        >
          <Route path="summary" element={<InteractiveProposalSummary />} />
          <Route path="solution" element={<InteractiveProposalSolution />} />
          <Route path="funding" element={<InteractiveProposalFunding />} />
          <Route path="projection" element={<InteractiveProposalProjection />} />
          <Route path="disclosure" element={<InteractiveProposalDisclosure />} />
          <Route path="appendix" element={<InteractiveProposalAppendix />} />
          <Route path="signature" element={<InteractiveProposalSignature />} />
        </Route>
        <Route
          path="/interactive-proposal"
          element={<Navigate to="/interactive-proposal/summary" />}
        />
      </Routes>
    </Suspense>
  );
};
