import Comment from "./Comment";
import { CommentStatus, IComment, ProposalTypes, THEME, USERTYPE } from "app/utils/types";
import CommentDialog from "./CommentDialog";
import { FC, useRef, useState } from "react";
import { capitalizeWords } from "app/utils/utils";
import { useComments } from "./CommentContext";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { useOnClickOutside } from "app/hooks/useOnClickOutside";
import { useIPContext } from "app/pages/InteractiveProposal/InteractiveProposalProvider";
import { getSessionStore } from "app/utils/sessionStore";
import CommentIcon from "app/assets/IconComponent/CommentIcon";

interface IPositionComment {
  comment: IComment;
}

// interface ICommentIcon {
//   className?: string;
// }

const PositionComment: FC<IPositionComment> = ({ comment }) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { seenComment } = useComments();
  const { summaryData, themeState } = useIPContext();
  const userAccess = getObjectFromLocalStore("useraccess");
  const { email: loginUserEmail } = getSessionStore("lookUpResponse");
  const iconFill = themeState === THEME.DARK ? "#fff" : "#000";

  const {
    coordinates: commentPosition,
    comment: content,
    id: commentId,
    user: createdBy,
    seenBy: seenByCurrentUsers,
    email: creatorEmail,
  } = comment;

  const userName = capitalizeWords(
    `${createdBy.lastName} ${createdBy.firstName?.charAt(0).toUpperCase()}.`,
  );

  const shareholdersOrEmployeesId = (
    summaryData?.proposalType === ProposalTypes.SplitDollar
      ? [
          ...(summaryData?.insuredDetails ?? []),
          ...(summaryData?.primaryContact ? [summaryData?.primaryContact] : []),
        ]
      : summaryData?.[
          summaryData?.proposalType === ProposalTypes.BuySell ? "shareholders" : "employees"
        ]
  )?.find((el: any) => el.user?.email === loginUserEmail)?.user?.id;
  const isLoggedInUserContact = userAccess?.userType === USERTYPE.CONTACT;
  const loggedInContactId = shareholdersOrEmployeesId;

  const isLoggedInUserAgent = userAccess?.userType === USERTYPE.AGENT;
  const loggedInAgentId = summaryData?.contactAdvisors?.find(
    (el: any) => el.agent?.email === loginUserEmail,
  )?.agent?.id;

  const isSeen = isLoggedInUserContact
    ? seenByCurrentUsers.contactIds?.[loggedInContactId]
    : isLoggedInUserAgent
    ? seenByCurrentUsers.agentIds?.[loggedInAgentId]
    : seenByCurrentUsers[userAccess?.userType as keyof typeof seenByCurrentUsers];

  const lastReply = comment.replies?.[comment.replies.length - 1] ?? comment;
  const lastReplySeenBy = lastReply?.seenBy ?? null;
  const isLastReplySeen = lastReplySeenBy
    ? isLoggedInUserContact
      ? lastReplySeenBy.contactIds?.[loggedInContactId]
      : isLoggedInUserAgent
      ? lastReplySeenBy.agentIds?.[loggedInAgentId]
      : lastReplySeenBy[userAccess?.userType as keyof typeof lastReplySeenBy]
    : isSeen;

  const handleCommentClick = () => {
    if (!isLastReplySeen) {
      seenComment(lastReply.id);
    }
  };

  useOnClickOutside(dialogRef, () => setShowTooltip(false), showTooltip);

  return (
    <>
      <div
        className="absolute z-[1000] flex w-[30px] h-[30px] items-center justify-center"
        style={{
          left: `${commentPosition.x}rem`,
          top: `${commentPosition.y}rem`,
          transform: "translate(-6px, calc(-100% + 6px))",
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowTooltip(true);
            handleCommentClick();
          }}
          className="relative"
        >
          <div className=" h-[25px] w-[25px]">
            <CommentIcon fill={iconFill} />
          </div>

          {!isLastReplySeen && (
            <div className="absolute w-[10px] h-[10px] bg-red-500 rounded-full top-[10%] right-[20%]" />
          )}
        </div>
      </div>
      {showTooltip && (
        <CommentDialog
          ref={dialogRef}
          userName={userName}
          commentPosition={commentPosition}
          commentId={commentId}
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          // onMouseLeave={() => {
          //   setShowTooltip(false);
          // }}
        >
          <Comment
            commentId={commentId}
            content={content}
            parentRef={dialogRef}
            commentPosition={commentPosition}
            createdBy={createdBy}
            replies={comment.replies}
            creatorEmail={creatorEmail}
            isResolved={comment.status === CommentStatus.RESOLVED}
            onHide={() => {
              setShowTooltip(false);
            }}
          />
        </CommentDialog>
      )}
    </>
  );
};

export default PositionComment;
