import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import { ProgressSpinner } from "primereact/progressspinner";
import IPSummary from "./IPSummary";
import IPDisclosures from "./IPDisclosures";
import IPSignatures from "./IPSignatures";
import IPSolution from "./IPSolution";
import IPIndex from "./IPIndex";
import IPappendix from "./appendix/IPappendix";
import IPFunding from "./funding/IPFunding";
import SDIPFunding from "./funding/SDIPFunding";
import IPProjections from "./Projections/IPProjections";
import PageWrapper from "./PageWrapper";
import { ErrorPage } from "app/uicomponents/macrocomponents/ErrorPage";
import { getThemeColors, isSafari } from "app/utils/utils";
import DownloadIcon from "app/assets/IconComponent/DownloadIcon";
import { Dialog } from "primereact/dialog";
import { useIPContext } from "../InteractiveProposalProvider";
import { ProposalTypes } from "app/utils/types";
import SDIPProjections from "./Projections/SDIPProjections";
import SDIPappendix from "./appendix/SDIPappendix";

// wrap the div.page with a div that has aspect ratio 1/1.414 and font size as PDF_FONT_SIZE
// and inside div.page, every spacing should be in em, like mb-[0.5em], pt-[0.5em], w-[5em] etc.
export const PDF_FONT_SIZE = isSafari() ? "9.7px" : "10.7px";
const a4Width = 595.28;
const a4Height = 841.89;

const addPageToPdf = async (pdf: jsPDF, pageElement: HTMLElement) => {
  const originalStyle = pageElement.style.cssText;

  pageElement.style.cssText = `
    position: fixed;
    top: -9999px;
    left: -9999px;
    width: ${a4Width}px;
    height: ${a4Height}px;
    margin: 0;
    padding: 0;
  `;

  try {
    const canvas = await html2canvas(pageElement, {
      scale: 2,
      useCORS: true,
      imageTimeout: 10000,
      logging: true,
      allowTaint: true,
      width: a4Width,
      height: a4Height,
      windowWidth: a4Width,
      windowHeight: a4Height,
    });
    const imgData = canvas.toDataURL("image/png");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight, undefined, "FAST");
  } finally {
    // Restore original styles
    pageElement.style.cssText = originalStyle;
  }
};

const indexPage = [
  { title: "Summary", number: "1" },
  { title: "Solution", number: "2" },
  { title: "Funding", number: "3" },
  { title: "Projections", number: "6" },
  { title: "Signatures", number: "9" },
  { title: "Disclosures", number: "10" },
  { title: "Appendix", number: "11" },
];

const sdIndexPage = [
  { title: "Summary", number: "1" },
  { title: "Solution", number: "2" },
  { title: "Funding", number: "3" },
  { title: "Projections", number: "5" },
  { title: "Signatures", number: "8" },
  { title: "Disclosures", number: "9" },
  { title: "Appendix", number: "10" },
];

const InteractiveProposalDownload = ({
  setIsDownloadPopupOpen,
}: {
  setIsDownloadPopupOpen: (value: boolean) => void;
}) => {
  const [proposalSummary, setProposalSummary] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { selectedVersion } = useIPContext();
  const proposalID = selectedVersion?.id ?? getSessionStore("interactiveProposalID");
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);

  const handleDownload = async (e: any) => {
    e?.preventDefault();
    setDownloadLoading(true);
    try {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", "a4");
      const pages = document.querySelectorAll(".pdf-page");

      // Add remaining pages
      for (let i = 0; i < pages.length; i++) {
        if (i > 0) {
          pdf.addPage();
        }
        // eslint-disable-next-line no-await-in-loop
        await addPageToPdf(pdf, pages[i] as HTMLElement);
      }

      pdf.save(`${proposalSummary?.summary?.proposalNo}.pdf`);
    } finally {
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    makeRequest("interactiveProposalDownload")
      .get(proposalID, true)
      .then((res) => {
        setProposalSummary(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const content = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-full">
          <ProgressSpinner />
        </div>
      );
    }
    if (error) {
      return <ErrorPage errorMsg={error} height="h-17rem" />;
    }

    if (proposalSummary?.summary?.proposalType === ProposalTypes.SplitDollar) {
      return (
        <div>
          <PageWrapper>
            <div className="pdf-page h-full w-full" id="cover">
              <IPIndex
                proposalType={proposalSummary?.summary?.proposalType}
                proposalNo={proposalSummary?.summary?.proposalNo}
                entityName={proposalSummary?.summary?.entityName}
                startDate={proposalSummary?.summary?.startDate}
                indexPage={sdIndexPage}
              />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="summary">
              <IPSummary summary={proposalSummary?.summary} pageNumber="1" />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="solution">
              <IPSolution pageNumber="2" proposalType={proposalSummary?.summary?.proposalType} />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="funding">
              <SDIPFunding graphData={proposalSummary?.funding?.graphData} pageNumber="3" />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="funding-1">
              <SDIPFunding
                tableData={proposalSummary?.funding?.tableData.slice(0, 15)}
                pageNumber="4"
                isNewPage
              />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="projections">
              <SDIPProjections graphData={proposalSummary?.projection?.graphData} pageNumber={5} />
            </div>
          </PageWrapper>
          <PageWrapper>
            <div className="pdf-page h-full w-full" id="plan-projections">
              <SDIPProjections
                isNewPage
                pageNumber={6}
                tableData={proposalSummary?.projection?.tableData?.plan?.slice(0, 25)}
              />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="loan-projections">
              <SDIPProjections
                isNewPage
                pageNumber={7}
                graphData={proposalSummary?.projection?.graphData}
                tableData={proposalSummary?.projection?.tableData?.loan}
              />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="signatures">
              <IPSignatures pageNumber="8" signatureDetails={proposalSummary.approvalDetails} />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="disclosures">
              <IPDisclosures pageNumber="9" />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="appendix">
              <SDIPappendix
                summary={proposalSummary?.appendix?.summary}
                appendixTableData={proposalSummary?.appendix?.appendix.slice(0, 25)}
                insuranceCompanyName={proposalSummary?.summary?.insuranceCompanyName}
                pageNumber="10"
              />
            </div>
          </PageWrapper>

          <PageWrapper>
            <div className="pdf-page h-full w-full" id="appendix-2">
              <SDIPappendix
                appendixTableData={proposalSummary?.appendix?.appendix.slice(25, 56)}
                pageNumber="11"
                newPage
              />
            </div>
          </PageWrapper>
        </div>
      );
    }
    return (
      <div>
        <PageWrapper>
          <div className="pdf-page h-full w-full" id="cover">
            <IPIndex
              proposalType={proposalSummary?.summary?.proposalType}
              proposalNo={proposalSummary?.summary?.proposalNo}
              entityName={proposalSummary?.summary?.entityName}
              startDate={proposalSummary?.summary?.startDate}
              indexPage={indexPage}
            />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="summary">
            <IPSummary summary={proposalSummary?.summary} pageNumber="1" />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="solution">
            <IPSolution pageNumber="2" proposalType={proposalSummary?.summary?.proposalType} />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="funding">
            <IPFunding graphData={proposalSummary?.funding?.graphData} pageNumber="3" />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="funding-1">
            <IPFunding
              tableData={proposalSummary?.funding?.tableData?.slice(0, 20)}
              pageNumber="4"
              isNewPage
            />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="funding-1">
            <IPFunding
              tableData={proposalSummary?.funding?.tableData?.slice(20, 40)}
              pageNumber="5"
              isNewPage
            />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="projections">
            <IPProjections
              graphData={proposalSummary?.projection?.graphData}
              pageNumber="6"
              proposalType={proposalSummary?.summary?.proposalType}
            />
          </div>
        </PageWrapper>
        <PageWrapper>
          <div className="pdf-page h-full w-full" id="projections-1">
            <IPProjections
              isNewPage
              pageNumber="7"
              tableData={proposalSummary?.projection?.tableData?.slice(0, 20)}
              proposalType={proposalSummary?.summary?.proposalType}
            />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="projections-2">
            <IPProjections
              isNewPage
              pageNumber="8"
              tableData={proposalSummary?.projection?.tableData?.slice(20, 40)}
              proposalType={proposalSummary?.summary?.proposalType}
            />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="signatures">
            <IPSignatures pageNumber="9" signatureDetails={proposalSummary.approvalDetails} />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="disclosures">
            <IPDisclosures pageNumber="10" />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="appendix">
            <IPappendix
              appendix={proposalSummary?.appendix}
              proposalType={proposalSummary?.summary?.proposalType}
              pageNumber="11"
            />
          </div>
        </PageWrapper>

        <PageWrapper>
          <div className="pdf-page h-full w-full" id="appendix-2">
            <IPappendix
              appendix={proposalSummary?.appendix}
              proposalType={proposalSummary?.summary?.proposalType}
              pageNumber="12"
              newPage
            />
          </div>
        </PageWrapper>
      </div>
    );
  };
  const popupFooter = () => (
    <div className="flex justify-end gap-2 mt-2">
      <Button
        label="Cancel"
        severity="secondary"
        text
        raised
        onClick={() => setIsDownloadPopupOpen(false)}
      />
      <Button
        onClick={handleDownload}
        raised
        disabled={loading || downloadLoading}
        style={{
          backgroundColor: primaryColor,
          color: "white",
          border: `1px solid ${primaryColor}`,
        }}
      >
        <span
          className={`w-[20px] mr-1 ${
            downloadLoading
              ? "fadeoutdown animation-duration-1000 animation-iteration-infinite mt-[-15px]"
              : ""
          }`}
        >
          <DownloadIcon />
        </span>
        {downloadLoading ? "Downloading..." : "Download"}
      </Button>
    </div>
  );

  return (
    <div className="ip-download-popup">
      <Dialog
        visible
        modal
        draggable={false}
        closeOnEscape={false}
        closable={false}
        header={<div className="conthrax-font">Preview interactive proposal</div>}
        footer={popupFooter}
        contentStyle={{ backgroundColor: "white", padding: "unset", scrollbarWidth: "none" }}
        style={{ width: "50rem" }}
        onHide={() => setIsDownloadPopupOpen(false)}
      >
        {content()}
      </Dialog>
    </div>
  );
};

export default InteractiveProposalDownload;
