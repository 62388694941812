import { CommentStatus, IPosition, USERTYPE } from "app/utils/types";
import { forwardRef, useMemo, useRef, useState } from "react";
import AddComment from "./AddComment";
import { useComments } from "./CommentContext";
import ActionMenu from "./ActionMenu";
import { useDialogPosition } from "./useDialogPosition";
import UserNameAndTimeStamp from "./UserNameAndTimeStamp";
import DeleteComment from "./DeleteComment";
import { getThemeColors } from "app/utils/utils";
import { getSessionStore } from "app/utils/sessionStore";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { Tooltip } from "primereact/tooltip";
import { useIPContext } from "app/pages/InteractiveProposal/InteractiveProposalProvider";

interface CommentDialogProps {
  commentPosition: IPosition;
  userName: string;
  children: React.ReactNode;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  commentId?: number;
  commentType?: "new" | "reply";
}

const CommentDialog = forwardRef<HTMLDivElement, CommentDialogProps>(
  (
    { commentPosition, userName, children, onMouseLeave, onMouseEnter, commentId, commentType },
    ref,
  ) => {
    const addCommentRef = useRef<HTMLDivElement>(null);
    const combinedRef = useMemo(
      () => (element: HTMLDivElement) => {
        // Update both refs
        if (typeof ref === "function") ref(element);
        else if (ref) ref.current = element;
        if (addCommentRef) (addCommentRef as any).current = element;
      },
      [ref],
    );

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { editComment, deleteComment, resolveComment, loading, comments } = useComments();
    const { branding, email: loginUserEmail } = getSessionStore("lookUpResponse");
    const { primaryColor } = getThemeColors(branding);
    const userAccess = getObjectFromLocalStore("useraccess");
    const { summaryData } = useIPContext();

    const currComment = comments.find((comment) => comment.id === commentId);

    const isResolved = currComment?.status === CommentStatus.RESOLVED;

    useDialogPosition(addCommentRef, commentPosition, [isDeleting, isEditing]);

    const isLoggedInUserAgent = userAccess?.userType === USERTYPE.AGENT;
    const isLoggedInAdmin = userAccess?.userType === USERTYPE.ADMIN;

    const isCreatedByMe = loginUserEmail === currComment?.email;
    const createdByUserType = currComment?.userType;

    let canResolve = false;
    if (createdByUserType === USERTYPE.CONTACT && (isCreatedByMe || isLoggedInUserAgent)) {
      // contact can resolve their own comment or agent can resolve contact's comment
      canResolve = true;
    } else if (createdByUserType === USERTYPE.AGENT && isCreatedByMe) {
      // agent can resolve their own comment
      canResolve = true;
    } else if (isLoggedInAdmin) {
      // admin can resolve any comment
      canResolve = true;
    }

    const onResolve = async () => {
      if (canResolve && commentId && !isResolved) {
        await resolveComment(commentId);
      }
    };

    const onEdit = async (newComment: string): Promise<boolean> => {
      if (commentPosition && newComment.length) {
        commentId && (await editComment(commentId, newComment));
        setIsEditing(false);
      }
      return true;
    };

    const onDelete = async () => {
      setIsDeleting(true);
      commentId && (await deleteComment(commentId));
      setShowDeleteConfirmation(false);
      setIsDeleting(false);
    };

    const changesRequested = summaryData?.status === "feedback";

    const content = (
      <div className={`w-full flex flex-col bg-[${primaryColor}] gap-1 px-4 py-3`}>
        <div className="flex items-center gap-3">
          <UserNameAndTimeStamp
            name={userName}
            date={currComment?.createdAt ?? new Date().toISOString()}
          />
          {commentType !== "new" &&
            (canResolve || isResolved) &&
            (!changesRequested || isLoggedInAdmin) && (
              <div className="ml-auto flex items-center">
                <i
                  className={`pi ${!isResolved ? "pi-check" : "pi-verified"} mx-2 ${
                    canResolve && !isResolved
                      ? "text-white cursor-pointer"
                      : isResolved
                      ? "text-white"
                      : "text-gray-600 cursor-not-allowed"
                  }`}
                  onClick={onResolve}
                  data-pr-tooltip={isResolved ? "Comments resolved" : "Resolve Comment"}
                  data-pr-position="top"
                  id="resolve-tooltip"
                />
                <Tooltip target="#resolve-tooltip" className="custom-tooltip !z-[99999]" />
                {!isResolved && isCreatedByMe && (
                  <ActionMenu
                    onEdit={() => {
                      setIsEditing(true);
                    }}
                    onDelete={() => {
                      setShowDeleteConfirmation(true);
                    }}
                  />
                )}
              </div>
            )}
        </div>
        {isEditing ? (
          <AddComment
            value={currComment?.comment ?? ""}
            onClose={() => {
              setIsEditing(false);
            }}
            setShowAddComment={() => {}}
            onAddComment={onEdit}
          />
        ) : (
          children
        )}
      </div>
    );

    const loadingContent = (
      <div className="w-full flex flex-col gap-3 px-4 py-3">
        <p className="text-white font-medium">Loading...</p>
      </div>
    );

    if (!commentPosition) return null;

    return (
      <div
        ref={combinedRef}
        className="absolute comment-dialog-box z-[9999] flex rounded-lg bg-[#00132E]"
        style={{
          left: `${commentPosition.x}rem`,
          top: `${commentPosition.y}rem`,
          minWidth: "20rem",
          maxWidth: "20rem",
          maxHeight: "20rem",
          overflowY: "auto",
          boxShadow: "0px 0px 3px 2px #00132E",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {loading ? (
          loadingContent
        ) : showDeleteConfirmation ? (
          <DeleteComment
            isDeleting={isDeleting}
            onCancel={() => setShowDeleteConfirmation(false)}
            onDelete={onDelete}
          />
        ) : (
          content
        )}
      </div>
    );
  },
);

export default CommentDialog;
