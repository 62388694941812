import React from "react";

interface NewIconProps {
  fill?: string; // Optional prop for fill color
}

const DisclosureIcon: React.FC<NewIconProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-93c718f8-c4f0-4e2f-86f0-94670c60b9f6"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 386.36 383.01"
  >
    <defs>
      <style>{`.uuid-e9cd754e-703e-4590-bf22-7862f03dfb6c { fill: ${fill}; }`}</style>
    </defs>
    <g id="uuid-7c24d579-833a-4153-bb23-7287f18fa18c" data-name="Layer 1">
      <g id="uuid-bac3c593-366f-463c-86bd-7e90d11dcc3d" data-name="17-Disclosure">
        <path
          className="uuid-e9cd754e-703e-4590-bf22-7862f03dfb6c"
          d="M158.81,24c5.15,0,10,2.01,13.65,5.65l66.7,66.7c4.86,4.86,6.77,12,4.99,18.64l-24.41,91.11c-1.78,6.64-7.01,11.87-13.64,13.64l-91.11,24.41c-1.63.44-3.31.66-4.99.66-5.15,0-10-2.01-13.65-5.65L29.65,172.46c-4.86-4.86-6.77-12-4.99-18.64l24.41-91.11c1.78-6.64,7.01-11.87,13.64-13.64l91.11-24.41c1.63-.44,3.31-.66,4.99-.66M158.81,0c-3.74,0-7.5.48-11.2,1.48L56.5,25.89c-14.94,4-26.61,15.67-30.61,30.61L1.48,147.61c-4,14.94.27,30.88,11.21,41.82l66.7,66.7c8.23,8.23,19.28,12.68,30.62,12.68,3.74,0,7.5-.48,11.2-1.48l91.11-24.41c14.94-4,26.61-15.67,30.61-30.61l24.41-91.11c4-14.94-.27-30.88-11.21-41.82L189.43,12.68c-8.23-8.23-19.28-12.68-30.62-12.68h0Z"
        />
        <path
          className="uuid-e9cd754e-703e-4590-bf22-7862f03dfb6c"
          d="M366.19,383.01c-5.16,0-10.33-1.97-14.27-5.91l-93.42-93.42c-7.88-7.88-7.88-20.65,0-28.53,7.88-7.88,20.65-7.88,28.53,0l93.42,93.42c7.88,7.88,7.88,20.65,0,28.53-3.94,3.94-9.1,5.91-14.27,5.91Z"
        />
        <path
          className="uuid-e9cd754e-703e-4590-bf22-7862f03dfb6c"
          d="M286.89,295.54c-3.07,0-6.14-1.17-8.49-3.51l-60.78-60.78c-4.69-4.69-4.69-12.28,0-16.97,4.69-4.69,12.28-4.69,16.97,0l60.78,60.78c4.69,4.69,4.69,12.28,0,16.97-2.34,2.34-5.42,3.51-8.49,3.51Z"
        />
      </g>
    </g>
  </svg>
);

export default DisclosureIcon;
