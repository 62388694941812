import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import useSignatureProposalStore from "app/zustand/Interactive Proposal/SignatureProposalStore";
import { CurrentProposalType, IProposalSummary, THEME, USERTYPE } from "app/utils/types";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getIsAgencyManagerActiveData } from "app/redux/features/isAgencyActive";
import { getObjectFromLocalStore } from "app/utils/localStore";

interface IVersion {
  id: string;
  proposalNo: string;
}

// eslint-disable-next-line no-spaced-func
const IPContext = createContext<{
  versionList: IVersion[];
  selectedVersion: IVersion | null;
  setSelectedVersion: Dispatch<SetStateAction<IVersion | null>>;
  summaryData: any;
  agentProfile: any;
  allBrandingData: any;
  summaryError: string | null;
  summaryLoading: boolean;
  isThisLatestProposal: boolean;
  fetchProposalSummary: () => void;
  isPrimaryAdvisor: boolean;
  primaryAdvisorDetails: any;
  signatureDetails: any;
  signatureLoading: boolean;
  signatureError: string | null;
  fetchApprovalDetails: () => Promise<boolean>;
  setSignatureDetails: (signatureDetails: any) => void;
  proposalStatus: string;
  isAgencyManager: boolean;
  isSubAgencyManager: boolean;
  isPrimaryContactUser: boolean;
  ipBrandingSelector: string;
  setIpBrandingSelector: (ipBrandingSelector: string) => void;
  themeState: string;
  setThemeState: (theme: string) => void;
}>({
  versionList: [],
  selectedVersion: null,
  setSelectedVersion: () => {},
  summaryData: null,
  agentProfile: null,
  allBrandingData: null,
  summaryError: null,
  summaryLoading: false,
  isThisLatestProposal: false,
  fetchProposalSummary: () => {},
  isPrimaryAdvisor: false,
  primaryAdvisorDetails: null,
  signatureDetails: null,
  signatureLoading: false,
  signatureError: null,
  fetchApprovalDetails: () => Promise.resolve(false),
  setSignatureDetails: () => {},
  proposalStatus: "",
  isAgencyManager: false,
  isSubAgencyManager: false,
  isPrimaryContactUser: false,
  ipBrandingSelector: "IAPP",
  setIpBrandingSelector: () => {},
  themeState: THEME.LIGHT,
  setThemeState: () => {},
});

const InteractiveProposalProvider = ({ children }: { children: React.ReactNode }) => {
  const currentProposalType = getSessionStore("currentProposalType");
  const planInfo = getSessionStore("planInfo");
  const proposalID =
    currentProposalType === CurrentProposalType.PLANS
      ? planInfo?.planProposalId
      : getSessionStore("interactiveProposalID");

  const [versionList, setVersionList] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState<IVersion | null>({
    id: proposalID,
    proposalNo: "",
  });
  const [isThisLatestProposal, setIsThisLatestProposal] = useState(false);
  const { signatureDetails, setSignatureDetails } = useSignatureProposalStore();
  const [summaryData, setSummaryData] = useState<IProposalSummary | null>(null);
  const [agentProfile, setAgentProfile] = useState<any>(null);
  const [allBrandingData, setAllBrandingData] = useState<any>(null);
  const [summaryError, setSummaryError] = useState<string | null>(null);
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false);
  const [signatureLoading, setSignatureLoading] = useState<boolean>(false);
  const [signatureError, setSignatureError] = useState<string | null>(null);
  const [ipBrandingSelector, setIpBrandingSelector] = useState<string>("IAPP");
  const userAccess = getObjectFromLocalStore("useraccess");
  const { isAgencyManagerActive } = useSelector(getIsAgencyManagerActiveData);
  const theme = getSessionStore("globalTheme");
  const [themeState, setThemeState] = useState(theme);
  const { email: loginUserEmail } = getSessionStore("lookUpResponse");

  useEffect(() => {
    makeRequest("interactiveProposalVersionList")
      .get(proposalID, true, false)
      .then((res) => {
        const dt = res.data?.data ?? [];
        setVersionList(dt);
        const idx = dt.findIndex((version: IVersion) => version.id === proposalID);
        setSelectedVersion(dt[idx]);
        setIsThisLatestProposal(idx === (dt?.length ?? 1) - 1);
      });
  }, []);

  const getAllBrandingDetails = () => {
    makeRequest("allBrandingDetails")
      .get("", true, false)
      .then((res) => setAllBrandingData(res?.data?.data));
  };

  useEffect(() => {
    if (userAccess?.userType === USERTYPE.AGENT) {
      makeRequest("agentProfile")
        .get("", true, false)
        .then((res) => {
          if (res?.data?.subscription === "Elite") {
            getAllBrandingDetails();
            setAgentProfile(res?.data);
          }
        });
    }
  }, [userAccess?.userType]);

  const fetchProposalSummary = useCallback(() => {
    setSummaryLoading(true);
    makeRequest("interactiveProposalSummary")
      .get(selectedVersion?.id ?? proposalID, true)
      .then((res) => {
        setIpBrandingSelector(res?.data?.data?.selectedProposalBranding || "IAPP");
        setSummaryData(res?.data?.data);
        setSummaryLoading(false);
      })
      .catch((err) => {
        setSummaryError(err.message);
        setSummaryLoading(false);
      });
  }, [selectedVersion?.id]);

  const fetchApprovalDetails = useCallback(async () => {
    setSignatureLoading(true);
    return makeRequest("interactiveProposalApprovalDetails")
      .get(selectedVersion?.id ?? proposalID, true, false)
      .then((res) => {
        setSignatureDetails(res?.data?.data ?? []);
        setSignatureLoading(false);
        return true;
      })
      .catch((err) => {
        setSignatureError(err.message);
        setSignatureLoading(false);
        return false;
      });
  }, [selectedVersion?.id]);

  useEffect(() => {
    fetchProposalSummary();
    fetchApprovalDetails();
  }, [selectedVersion?.id]);

  useEffect(() => {
    const idx = versionList.findIndex((version: IVersion) => version.id === selectedVersion?.id);
    setIsThisLatestProposal(idx === (versionList?.length ?? 1) - 1);
  }, [selectedVersion?.id, versionList]);

  const primaryAdvisorDetails = summaryData?.contactAdvisors?.find(
    (advisor: any) => advisor?.agentType === "primary",
  );
  const isPrimaryAdvisor = primaryAdvisorDetails?.agent?.email === loginUserEmail;
  const proposalStatus = useMemo(() => summaryData?.status ?? "", [summaryData]);

  // Primary agency manager
  const isAgencyManager =
    isAgencyManagerActive &&
    userAccess?.userType === USERTYPE.AGENT &&
    userAccess?.agencyManager &&
    !userAccess?.parentAgencyId;

  // Primary sub agency manager
  const isSubAgencyManager =
    isAgencyManagerActive &&
    userAccess?.userType === USERTYPE.AGENT &&
    userAccess?.agencyManager &&
    !!userAccess?.parentAgencyId;

  const isPrimaryContactUser = loginUserEmail === summaryData?.primaryContactEmail;

  const value = useMemo(
    () => ({
      selectedVersion,
      setSelectedVersion,
      versionList,
      summaryData,
      summaryError,
      summaryLoading,
      isThisLatestProposal,
      fetchProposalSummary,
      isPrimaryAdvisor,
      primaryAdvisorDetails,
      signatureDetails,
      setSignatureDetails,
      signatureLoading,
      signatureError,
      fetchApprovalDetails,
      proposalStatus,
      isAgencyManager,
      isSubAgencyManager,
      isPrimaryContactUser,
      agentProfile,
      allBrandingData,
      ipBrandingSelector,
      setIpBrandingSelector,
      setThemeState,
      themeState,
    }),
    [
      selectedVersion,
      setSelectedVersion,
      versionList,
      summaryData,
      summaryError,
      summaryLoading,
      isThisLatestProposal,
      fetchProposalSummary,
      isPrimaryAdvisor,
      primaryAdvisorDetails,
      signatureDetails,
      setSignatureDetails,
      signatureLoading,
      signatureError,
      fetchApprovalDetails,
      isAgencyManager,
      isSubAgencyManager,
      isPrimaryContactUser,
      isAgencyManagerActive,
      agentProfile,
      allBrandingData,
      ipBrandingSelector,
      setIpBrandingSelector,
      setThemeState,
      themeState,
    ],
  );

  return <IPContext.Provider value={value}>{children}</IPContext.Provider>;
};

export default InteractiveProposalProvider;

export const useIPContext = () => useContext(IPContext);
