import { getUSANumberFormat } from "app/utils/utils";
import IPPageNumber from "../IPPageNuber";
import { ProposalTypes } from "app/utils/types";
import HexagonImg from "../../images/hexagonImg.png";

const IPappendix = ({
  appendix,
  proposalType,
  pageNumber,
  newPage,
}: {
  appendix: any;
  proposalType: string;
  pageNumber: string | number;
  newPage?: boolean;
}) => {
  const { lifePolicies, disabilityPolicies } = appendix;

  const disabilityPolicyLedger = [
    {
      header: proposalType === ProposalTypes.KeyPersonInsurance ? "Employee" : "Shareholder",
      color: "#376b9d",
    },
    { header: "Premium Expiration", color: "#315d8a" },
    { header: "Benefit Expiration", color: "#32608e" },
    { header: "Insurance Company", color: "#2d5077" },
    { header: "Insurance Type", color: "#244162" },
    { header: "Policy Type", color: "#1c324e" },
    { header: "Lump Sum Benefit", color: "#162b43" },
    { header: "Monthly Benefit ", color: "#1b314c" },
    { header: "Duration (Months)", color: "#1d3650" },
    { header: "Total DI Benefit", color: "#132943" },
    { header: "Annual Premium", color: "#0c1d36" },
  ];

  const defaultLedger = [
    {
      header: proposalType === ProposalTypes.KeyPersonInsurance ? "Employee" : "Shareholder",
      color: "#366da2",
    },
    { header: "Premium Expiration", color: "#366da2" },
    { header: "Benefit Expiration", color: "#32608e" },
    { header: "Insurance Company", color: "#28496d" },
    { header: "Insurance Type", color: "#264466" },
    { header: "Policy Type", color: "#1d3651" },
    { header: "Death Benefit", color: "#162b45" },
    { header: "Annual Premium", color: "#0d2039" },
  ];

  const additionalFields = [
    { header: "Living Benefit Coverage", color: "#0d2039" },
    { header: "Living Benefit", color: "#0d2039" },
  ];

  const lifePolicyLedger =
    proposalType === ProposalTypes.KeyPersonInsurance
      ? [...defaultLedger, ...additionalFields]
      : defaultLedger;

  const renderContent = () => {
    if (newPage) {
      return (
        <div className="px-[2em] w-full max-w-[56em] mx-auto p-[1.75em]">
          <div
            className="text-xl font-bold mb-2 text-center conthrax-font"
            style={{ color: "#495057" }}
          >
            Proposed Disability Policy Ledger
          </div>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                {disabilityPolicyLedger?.map(({ header, color }) => (
                  <th
                    key={header}
                    className="p-1 text-center font-semibold text-white border-2 border-white"
                    style={{ backgroundColor: color, fontSize: "8.53px" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {disabilityPolicies?.map((policy: any, index: any) => (
                <tr
                  key={index}
                  style={{ backgroundColor: "#EEEEEE", color: "#000000", fontSize: "7.07px" }}
                >
                  <td className="p-1 text-center border-2 border-white">
                    {proposalType === ProposalTypes.KeyPersonInsurance
                      ? policy?.kpEmployees?.name
                      : policy?.shareholder?.name || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white" style={{}}>
                    {policy?.expirationYears || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.benefitExpiration || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.insuranceCompany?.name || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.insuranceType || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.insuranceCompany?.productType} - {policy?.insuranceProduct?.name}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(policy?.lumpSumBenefit, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(policy?.monthlyBenefit, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.duration || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(policy?.totalDIBenefit, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(policy?.annualPremium, false)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div className="px-[2em] w-full max-w-[56em] mx-auto p-[1.75em]">
        <div className="text-xl font-bold mb-3 text-center interactive-proposal-text conthrax-font">
          Appendix
        </div>
        <div>
          <div
            className="text-xl font-bold mb-2 text-center conthrax-font"
            style={{ color: "#495057" }}
          >
            Proposed Life Policy Ledger
          </div>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                {lifePolicyLedger?.map(({ header, color }) => (
                  <th
                    key={header}
                    className="p-1 text-center font-semibold text-white border-2 border-white"
                    style={{ backgroundColor: color, fontSize: "8.53px" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lifePolicies?.map((policy: any, index: any) => (
                <tr
                  key={index}
                  style={{ backgroundColor: "#EEEEEE", color: "#000000", fontSize: "7.07px" }}
                >
                  <td className="p-1 text-center border-2 border-white">
                    {proposalType === ProposalTypes.KeyPersonInsurance
                      ? policy?.kpEmployees?.name
                      : policy?.shareholder?.name || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.expirationYears || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.benefitExpiration || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.insuranceCompany?.name || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.insuranceType || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {policy?.insuranceCompany?.productType} - {policy?.insuranceProduct?.name}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(policy?.deathBenefit, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(policy?.annualPremium, false)}
                  </td>
                  {proposalType === ProposalTypes.KeyPersonInsurance && (
                    <>
                      <td className="p-1 text-center border-2 border-white">
                        {policy?.criticalIllness ? "Yes" : "No"}
                      </td>
                      <td className="p-1 text-center border-2 border-white">
                        ${getUSANumberFormat(policy?.ciBenefitAmount, false)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full relative min-h-screen">
      <div className="relative z-20">{renderContent()}</div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[3em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default IPappendix;
