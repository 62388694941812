import { getObjectFromSessionStore, getSessionStore } from "app/utils/sessionStore";
import { intToString } from "../PolicySummary/config";
import { calculateLighterColor } from "app/utils/helper";
import { store } from "@store";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { USERTYPE } from "app/utils/types";
import { getUSANumberFormat } from "app/utils/utils";

const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue("--text-color");
const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
const getlookUpResponse = getObjectFromSessionStore("planInfo");
const activeUserBrandingType = getObjectFromSessionStore("activeBrandingUser");
const isAgencyManagerActive = store.getState().storeIsAgencyManagerActiveData;
const globalTheme = getSessionStore("globalTheme");

const userAccess = getObjectFromLocalStore("useraccess");

export function getBackgroundColor(year: number, index: number, brandingColor?: any) {
  const currentYear = new Date().getFullYear();

  let primaryColor =
    isAgencyManagerActive?.isAgencyManagerActive &&
    activeUserBrandingType?.activeUserType === "iapp"
      ? "rgb(12, 97, 245)"
      : brandingColor?.secondaryColor || "rgb(12, 97, 245)";

  let secondaryColor =
    isAgencyManagerActive?.isAgencyManagerActive &&
    activeUserBrandingType?.activeUserType === "iapp"
      ? "rgb(0, 36, 70)"
      : brandingColor?.primaryColor || "rgb(0, 36, 70)";

  // if user is agent then show default iapp branding
  if (userAccess?.userType === USERTYPE.AGENT) {
    primaryColor = "rgb(12, 97, 245)";
    secondaryColor = "rgb(0, 36, 70)";
  }

  const opacity = 0.75;

  if (year > currentYear) {
    return index === 0
      ? calculateLighterColor(secondaryColor, opacity)
      : calculateLighterColor(primaryColor, opacity);
  }

  return index === 0 ? secondaryColor : primaryColor;
}

export const getBackgroundColorForBarGraph = (isCurrent: any) => {
  if (isCurrent) {
    return documentStyle.getPropertyValue("--blue-100");
  }
  return documentStyle.getPropertyValue("--blue-500");
};

export const barGraphConfigPlan = {
  labels: [],
  datasets: [
    {
      label: "Paid by Employer",
      data: [],
      backgroundColor: ["rgb(0, 36, 70)"],
      barThickness: 30,
      borderWidth: 0.4,
      color: globalTheme === "light" ? textColorSecondary : "#ffff",
      borderColor: getlookUpResponse?.branding?.secondaryColor || "rgb(12, 97, 245)",
    },
    {
      label: "Paid by Employee",
      data: [],
      backgroundColor: ["rgb(12, 97, 245)"],
      barThickness: 30,
      borderWidth: 0.4,
      color: globalTheme === "light" ? textColorSecondary : "#ffff",
      borderColor: getlookUpResponse?.branding?.secondaryColor || "rgb(12, 97, 245)",
    },
  ],
};

export const BSbarGraphConfigPlan = {
  labels: [],
  datasets: [
    {
      label: "Paid by Employer",
      data: [],
      backgroundColor: ["rgb(0, 36, 70)"],
      barThickness: 30,
      borderWidth: 0.4,
      color: globalTheme === "light" ? textColorSecondary : "#ffff",
      borderColor: getlookUpResponse?.branding?.secondaryColor || "rgb(12, 97, 245)",
    },
  ],
};

export const barGraphOptionsPlan = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.7,
  plugins: {
    borderWidth: 0,
    title: {
      display: true,
      text: "",
      align: "start",
      font: {
        size: 22,
      },
    },
    legend: {
      display: false,
      position: "bottom",
      align: "end",
      labels: {
        fontColor: globalTheme === "light" ? textColor : "#ffff",
        color: globalTheme === "light" ? textColor : "#ffff",
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        font: {
          size: 11,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      stacked: true,
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
        color: "",
      },
      ticks: {
        display: true,
        font: {
          size: 11,
        },
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      stacked: true,
      title: {
        display: true,
        text: "Premium in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 7,
      borderDash: [4, 4],
    },
  },
});

export const cashValueLineGraphdata = (isDark: boolean) => ({
  labels: [],
  datasets: [
    {
      label: "Actual Cash Value",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
      tension: 0.4,
      backgroundColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
    },
    {
      label: "Projected Cash Value",
      data: [],
      borderWidth: 1,
      fill: false,
      borderDash: [5, 5],
      tension: 0.4,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--gray-50")
        : documentStyle.getPropertyValue("--gray-500"),
    },
  ],
});

export const cashValueLineGraphoptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: isDark ? "#ffff" : textColorSecondary,
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: isDark ? "#ffff" : textColorSecondary,
      },
      grid: {
        display: true,
        color: isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: isDark ? "#ffff" : textColorSecondary,
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Cash Value in $",
        font: {
          size: 15,
        },
        color: isDark ? "#ffff" : textColorSecondary,
      },
      grid: {
        display: true,
        color: isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const deathBenefitLineGraphdata = (isDark: boolean) => ({
  labels: [],
  datasets: [
    {
      label: "Actual Death Benefit",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
      tension: 0.4,
      backgroundColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
    },
    {
      label: "Projected Death Benefit",
      data: [],
      fill: false,
      borderWidth: 1,
      borderDash: [5, 5],
      tension: 0.4,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--gray-50")
        : documentStyle.getPropertyValue("--gray-500"),
    },
  ],
});

export const ciBenefitLineGraphData = (isDark: boolean) => ({
  labels: [],
  datasets: [
    {
      label: "Actual Living Benefit",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
      tension: 0.4,
      backgroundColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
    },
    {
      label: "Projected Living Benefit",
      data: [],
      fill: false,
      borderWidth: 1,
      borderDash: [5, 5],
      tension: 0.4,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--gray-50")
        : documentStyle.getPropertyValue("--gray-500"),
    },
  ],
});

export const deathBenefitLineGraphoptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: isDark ? "#ffff" : textColorSecondary,
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: isDark ? "#ffff" : textColorSecondary,
      },
      grid: {
        display: true,
        color: isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: isDark ? "#ffff" : textColorSecondary,
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Death Benefit in $",
        font: {
          size: 15,
        },
        color: isDark ? "#ffff" : textColorSecondary,
      },
      grid: {
        display: true,
        color: isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const bsdeathLineGraphdata = {
  labels: [],
  datasets: [
    {
      label: "Actual Death Benefit",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: documentStyle.getPropertyValue("--blue-400"),
      tension: 0.4,
      backgroundColor: documentStyle.getPropertyValue("--blue-100"),
    },
  ],
};

export const bsdeathLineGraphoptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Death Benefit in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const bsdisabilityBenefitLineGraphdata = (isDark: boolean) => ({
  labels: [],
  datasets: [
    {
      label: "Actual Disability Benefit",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
      tension: 0.4,
      backgroundColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
    },
    {
      label: "Projected Disability Benefit",
      data: [],
      fill: false,
      borderWidth: 1,
      borderDash: [5, 5],
      tension: 0.4,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--gray-50")
        : documentStyle.getPropertyValue("--gray-500"),
    },
  ],
});

export const criticalIllnessBenefitGraphOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Death Benefit in $",
        font: {
          size: 15,
        },
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
      },
    },
  },
};

export const bsdisabilityBenefitLineGraphoptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Disability Benefit in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const ciBenefitLineGraphOptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Living Benefit in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const barGraphOptionsFunding = (
  isDark: boolean,
  tooltipColor: string,
  bottomText?: string,
  LeftText?: string,
) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.7,
  mode: "index",
  plugins: {
    tooltip: {
      enabled: true,
      backgroundColor: tooltipColor,
      callbacks: {
        title: (title: any) => {
          const convertValue = title?.[0]?.formattedValue;
          const numberValue = parseInt(convertValue.replace(/,/g, ""), 10);
          return `$${getUSANumberFormat(numberValue)}`;
        },
        label: (tooltipItem: any) => {
          const { dataset, label } = tooltipItem;
          return `${dataset?.label}  (Year ${label})`;
        },
        labelColor: () => ({
          borderColor: "rgba(0,0,0,0)",
          backgroundColor: "rgba(0,0,0,0)",
        }),
      },
      displayColors: false,
      titleColor: () => "white",
      bodyColor: () => "white",
      titleAlign: "center",
      labelAlign: "center",
      bodyAlign: "center",
      padding: 10,
      cornerRadius: 8,
    },
    borderWidth: 0,
    title: {
      display: true,
      text: "",
      align: "center",
      font: {
        size: 22,
      },
    },
    legend: {
      display: false,
      position: "bottom",
      align: "end",
      labels: {
        fontColor: globalTheme === "light" ? textColor : "#ffff",
        color: globalTheme === "light" ? textColor : "#ffff",
      },
      displayColors: false,
      titleColor: () => "white",
      bodyColor: () => "white",
      titleAlign: "center",
      labelAlign: "center",
      bodyAlign: "center",
      padding: 10,
      textAlign: "center",
      cornerRadius: 8,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        font: {
          size: 11,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      stacked: true,
      title: {
        display: true,
        text: bottomText || "Plan Funding for Selected Shareholders",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      title: {
        display: true,
        text: LeftText || "Amount",
        font: {
          size: 15, // Adjust the font size as needed
        },
        color: !isDark ? textColorSecondary : "#ffff", // Adjust the color based on theme
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "",
      },
      ticks: {
        display: true,
        font: {
          size: 11,
        },
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      stacked: true,
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 7,
      borderDash: [4, 4],
    },
  },
});
export const getBrandingColorForFunding = () => {
  const { branding } = getObjectFromSessionStore("lookUpResponse");
  return userAccess?.userType === USERTYPE.CONTACT
    ? branding?.primaryColor || "#004781"
    : "#004781";
};
export const lineChartOptions = (isDark: boolean, tooltipColor: string, bottomText?: string) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.6,
  responsive: true,
  interaction: {
    mode: "nearest",
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      backgroundColor: tooltipColor,
      callbacks: {
        title: (title: any) => {
          const convertValue = title?.[0]?.formattedValue;
          const numberValue = parseInt(convertValue.replace(/,/g, ""), 10);
          return `$${getUSANumberFormat(numberValue)}`;
        },
        label: (tooltipItem: any) => {
          const { dataset, label } = tooltipItem;
          return `${dataset?.label} (Year ${label})`;
        },
        labelBorderColor: () => "transparent",
      },
      displayColors: false,
      titleColor: () => "white",
      bodyColor: () => "white",
      titleAlign: "center",
      labelAlign: "center",
      bodyAlign: "center",
      padding: 10,
      textAlign: "center",
      cornerRadius: 8,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: bottomText || "Projected Value for Selected Shareholders",
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 15,
        },
      },
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      title: {
        display: true,
        text: "Amount",
        font: {
          size: 15, // Adjust the font size as needed
        },
        color: !isDark ? textColorSecondary : "#ffff", // Adjust the color based on theme
      },
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
      },
      beginAtZero: true,
      grid: {
        display: false,
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});
