import { useState, useEffect } from "react";
import "../../../css/tabViewCentered.css";
import InteractiveProposalSolutionInsights from "./ProposalSolutionSubTab.tsx/InsightsTab";
import ExperienceTab from "./ProposalSolutionSubTab.tsx/ExperienceTab";
import { THEME } from "app/utils/types";
import { getSessionStore } from "app/utils/sessionStore";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import VisualTab from "./ProposalSolutionSubTab.tsx/VisualsTab";
import { getThemeColors } from "app/utils/utils";

const VisualIcon = ({ isActive }: { isActive: boolean }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.91659 0.75H9.41659M6.91659 15.25H9.41659M8.16659 1V3.25M8.16659 12.75V15M12.4092 1.98959L14.177 3.75736M2.15619 12.2426L3.92395 14.0104M13.1163 3.05025L11.5254 4.64124M4.80784 11.3588L3.21685 12.9497M15.4166 6.74991V9.24991M0.916626 6.74991V9.24991M15.1666 7.99991H12.9166M3.41663 7.99991H1.16663M14.177 12.2426L12.4092 14.0104M3.92395 1.98959L2.15619 3.75736M13.1163 12.9497L11.5254 11.3588M4.80784 4.64124L3.21685 3.05025M12.4166 8C12.4166 10.3472 10.5138 12.25 8.16659 12.25C5.81938 12.25 3.91659 10.3472 3.91659 8C3.91659 5.65279 5.81938 3.75 8.16659 3.75C10.5138 3.75 12.4166 5.65279 12.4166 8Z"
      stroke={`var(--${isActive ? "theme-primary-color" : "theme-inactive-color"})`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const InsightIcon = ({ isActive }: { isActive: boolean }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.7499 12.75L10.9999 14.25L14.2499 9.75M4.7499 8L11.9999 4.75L19.2499 8C19.2499 8 19.9999 19.25 11.9999 19.25C3.9999 19.25 4.7499 8 4.7499 8Z"
      stroke={`var(--${isActive ? "theme-primary-color" : "theme-inactive-color"})`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ExperienceIcon = ({ isActive }: { isActive: boolean }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
      stroke={`var(--${isActive ? "theme-primary-color" : "theme-inactive-color"})`}
      strokeWidth="1.5"
    />
  </svg>
);

const InteractiveProposalSolution: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [globalTheme, setGlobalTheme] = useState<string>(getSessionStore("globalTheme"));
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const isDarkTheme = globalTheme === THEME.DARK;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTheme = getSessionStore("globalTheme");
      if (updatedTheme !== globalTheme) {
        setGlobalTheme(updatedTheme);
      }
    }, 200); // Check every second

    return () => {
      clearInterval(intervalId);
    };
  }, [globalTheme]);

  const tabs = [
    {
      label: "Visuals",
      icon: (isActive: boolean) => <VisualIcon isActive={isActive} />,
    },
    {
      label: "Insights",
      icon: (isActive: boolean) => <InsightIcon isActive={isActive} />,
    },
    {
      label: "Experience",
      icon: (isActive: boolean) => <ExperienceIcon isActive={isActive} />,
    },
  ];

  return (
    <CommentLayout
      commentLayoutId="interactive-proposal-solution"
      className={`w-full border border-1 shadow-xl rounded-md ${isDarkTheme ? "dark-theme" : ""}`}
      style={
        {
          "--theme-primary-color": primaryColor,
          "--theme-inactive-color": "#8A8AA3",
        } as React.CSSProperties
      }
    >
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4 text-gray-700 conthrax-font">Solutions</h2>

        {/* Tabs */}
        <div className="flex w-full">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab-item ${activeIndex === index ? "active" : ""}
                ${index === 0 ? "rounded-l-md" : ""}
                ${index === tabs.length - 1 ? "rounded-r-md" : ""}`}
              onClick={() => setActiveIndex(index)}
            >
              {typeof tab.icon === "string" ? (
                <i className={`${tab.icon} mr-2`} />
              ) : (
                tab.icon(activeIndex === index)
              )}
              {tab.label}
            </div>
          ))}
        </div>
      </div>

      <hr className="my-2" />

      {/* Tab Content */}
      <CommentLayout
        commentLayoutId={`interactive-proposal-solution-tab-${activeIndex}`}
        className="mt-4 text-gray-700"
      >
        {activeIndex === 0 && <VisualTab />}
        {activeIndex === 1 && <InteractiveProposalSolutionInsights isDarkTheme={isDarkTheme} />}
        {activeIndex === 2 && <ExperienceTab />}
      </CommentLayout>
    </CommentLayout>
  );
};

export default InteractiveProposalSolution;
