import React from "react";
import { AnimatedCheckbox } from "app/uicomponents/microcomponents/AnimatedChecbox";
import { useIPContext } from "app/pages/InteractiveProposal/InteractiveProposalProvider";
import { PROPOSAL_STATUSES } from "app/utils/types";

interface FilterOption {
  name: string;
  id: string;
  indicator?: boolean;
  indicatorColor?: string;
}
interface FilterGroupProps {
  title: string; // Title for the filter group
  options: FilterOption[]; // Array of options for the checkboxes
  selectedOptions: Record<string, boolean>;
  setSelectedOptions: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  className?: string; // Optional className for dynamic styling
  brandingColor?: string;
  hideCheckbox?: boolean;
}
const FilterGroupContainer: React.FC<FilterGroupProps> = ({
  title,
  options,
  selectedOptions,
  setSelectedOptions,
  className,
  brandingColor,
  hideCheckbox = false,
}) => {
  const { proposalStatus } = useIPContext();
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(proposalStatus as any);
  return (
    <div className={`flex flex-col mb-2 ${className}`}>
      <h3 className="font-bold text-lg mb-3 conthrax-font">{title}</h3>
      <div className="flex flex-wrap">
        {options?.map((option) => (
          <div key={option?.id} className="mr-4 mb-2 rounded-full">
            <AnimatedCheckbox
              checked={selectedOptions[option?.id]}
              onChange={() => {
                const newSelectedOptions = {
                  ...selectedOptions,
                  [option?.id]: !selectedOptions[option.id],
                };
                setSelectedOptions(newSelectedOptions);
              }}
              inputId={option?.id}
              label={option?.name}
              indicator={option?.indicator}
              indicatorColor={option?.indicatorColor}
              brandingColor={brandingColor}
              isDisabled={isPlanOrInactive}
              hideCheckbox={hideCheckbox}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default FilterGroupContainer;
