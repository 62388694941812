import React, { MutableRefObject, useRef, useState } from "react";
import { Card } from "primereact/card";
import DocumentView from "app/uicomponents/macrocomponents/ProposalPending/DocumentView";
import { formatDateToWordDate } from "app/utils/luxon";
import {
  GET_DOCUMENT,
  getAllPendingProposals,
  resetDocData,
} from "app/redux/features/pending-proposals";
import FilePreview from "app/uicomponents/macrocomponents/ProposalPending/FilePreview";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFileName, getThemeColors } from "app/utils/utils";
import { Toast } from "primereact/toast";
import BlockPopup from "app/uicomponents/microcomponents/BlockPopup";
import { Button } from "primereact/button";
import { PROPOSAL_STATUSES, SEVERITY } from "app/utils/types";
import BrandingIconButton from "app/uicomponents/macrocomponents/InteractiveProposal/BrandingIconButton";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";
import { useIPContext } from "../../InteractiveProposalProvider";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import FileView from "app/uicomponents/macrocomponents/ProposalPending/FileView";
import InteractiveProposalDownload from "../../InteractiveProposalDownload/InteractiveProposalDownload";

const MAX_REASON_CHARS = 300;
const UploadSection = ({
  fetchApprovalDetails,
  signatureDetails,
  isUploadButtonDisabled,
  isOpenComments,
  isAdmin = false,
  onApprove,
  isProposalInactive,
}: {
  fetchApprovalDetails: () => void;
  signatureDetails: any;
  isUploadButtonDisabled: boolean;
  isOpenComments: boolean;
  isAdmin: boolean;
  onApprove: () => void;
  isProposalInactive: boolean;
}) => {
  const toast: MutableRefObject<any> = useRef(null);
  const { t } = useTranslation();
  const [onClickData, setOnClickData] = useState<any>(null);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectId, setRejectId] = useState<string | null>(null);
  const [reason, setReason] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const { getDocPendingProposals } = useSelector(getAllPendingProposals);
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const {
    selectedVersion,
    isAgencyManager,
    isSubAgencyManager,
    isPrimaryContactUser,
    isPrimaryAdvisor,
    proposalStatus,
  } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useState(false);
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(proposalStatus as any);

  const changesRequested = signatureDetails?.status === PROPOSAL_STATUSES.FEEDBACK;

  const onRemove = () => {
    setConfirmationPopUp(true);
  };
  function removeFileExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, "").replace(/_/g, " ");
  }
  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("proposalId", proposalId);

    try {
      setLoading(true);
      const response = await makeRequest("interactiveProposalSignatureUpload", {
        "Content-Type": "multipart/form-data",
      }).post(formData, "", true, false);
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
      fetchApprovalDetails(); // Refresh the document list after upload
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        toast.current?.show({
          severity: "error",
          summary: "Invalid File Type",
          detail: t("pdf.upload.signature"),
        });
        return;
      }
      if (file.size <= 10 * 1024 * 1024) {
        handleFileUpload(file);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: t("pdf.upload.max.limit"),
        });
      }
    }
    // Reset the file input value to allow re-uploading the same file
    event.target.value = "";
  };
  const deleteDocument = async () => {
    try {
      const response = await makeRequest("interactiveProposalSignatureDelete").delete(
        proposalId,
        true,
        false,
      );
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
      fetchApprovalDetails(); // Refresh the document list after deletion
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.error?.message,
      });
    }
  };
  const onOpenDoc = (el: any) => {
    const documentData = el;
    const formattedDateSubmitted = formatDateToWordDate(documentData?.signedProposalUploadedDate);
    setOnClickData({ ...documentData, formattedDateSubmitted });
    dispatch({
      type: GET_DOCUMENT,
      payload: {
        address: "proposalPendingDocLink",
        key: `${encodeURIComponent(signatureDetails?.signedProposalFileKey ?? "")}`,
        requestParams: true,
      },
    });
  };

  const confirmDelete = () => {
    deleteDocument(); // Assuming fileToDelete has an id property
    setConfirmationPopUp(false);
  };

  const deleteFooter = () => (
    <div>
      <Button
        type="button"
        label={t("general.button.cancel") ?? ""}
        onClick={() => setConfirmationPopUp(false)}
        severity={SEVERITY.SECONDARY}
        outlined
      />
      <Button
        type="submit"
        label={t("general.button.remove") ?? ""}
        onClick={confirmDelete}
        severity={SEVERITY.DANGER}
      />
    </div>
  );
  const requestNewSignature = async () => {
    try {
      const response = await makeRequest("interactiveProposalProposalRequestNewSignature").put(
        proposalId,
        { reason },
        true,
        false,
      );
      fetchApprovalDetails();
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
      });
    } finally {
      setRejectId(null);
      setReason("");
      setConfirmationPopUp(false);
    }
  };

  const onReject = () => {
    requestNewSignature();
  };

  const confirmationPopUpfooterContent = (
    <div>
      <Button
        label="Cancel"
        onClick={() => {
          setRejectId(null);
          setReason("");
        }}
        outlined
        className="border-gray-700 text-gray-700"
      />
      <Button
        label={rejectId ? "Reject" : "Yes"}
        onClick={() => {
          if (rejectId) {
            onReject();
          }
        }}
        className={rejectId ? "bg-red-500 text-white border-0" : "bg-layoutBlue theme-button"}
        disabled={!reason.trim()}
      />
    </div>
  );
  const popUpContent = (
    <div className="">
      <Divider className="mt-0 custom-divider" />
      {rejectId ? (
        <>
          <div className="font-extralight mb-2">Reason</div>
          <InputText
            className="w-full"
            onChange={(e) => e.target.value.length <= MAX_REASON_CHARS && setReason(e.target.value)}
            value={reason}
          />
          <div className="flex justify-end mt-1">{`${reason.length}/${MAX_REASON_CHARS}`}</div>
        </>
      ) : (
        <div>{t("InitialPlanCheckConfirmation")}</div>
      )}
    </div>
  );

  // const uploadedDocumentBy=signatureDetails?.signedProposalUploadedBy
  const getUploadedUserName = () => {
    if (signatureDetails?.signedProposalUploadedBy) {
      if (signatureDetails?.signedProposalUploadedBy === signatureDetails?.contact?.id) {
        return signatureDetails?.contact;
      }
      return signatureDetails?.contact?.contactAdvisors?.find(
        (advisor: any) => advisor?.agentType === "primary",
      )?.agent;
    }
    return null;
  };

  const handleDownload = () => {
    setIsDownloadPopupOpen(true);
  };

  const isPrimaryAdvisorAgencySubAgencyManager =
    isPrimaryAdvisor || isAgencyManager || isSubAgencyManager;

  return (
    <Card
      title={() => <div className="conthrax-font">Signature</div>}
      className="mb-4 shadow-none p-3 border-b-2 border-gray-300"
    >
      <Toast ref={toast} position="top-center" />
      {loading && <ScreenLoader />}
      <p>
        I have reviewed the proposal including the disclosure page. I understand that the proposal
        is hypothetical and that future policy performance and interest rates for loans, if
        applicable, are not guaranteed and are subject to change.
      </p>

      <>
        <p className="mt-4 p-2 rounded-md dark:bg-gray-800 dark:text-black bg-gray-100 inline-block">
          You can approve the proposal here to proceed, or{" "}
          <span className="cursor-pointer" style={{ color: primaryColor }} onClick={handleDownload}>
            Download
          </span>{" "}
          it to have it signed later.
        </p>
        <div className="mt-4 flex items-center gap-2">
          {!isAdmin && (
            <BrandingIconButton
              label="Upload"
              onClick={() => fileInputRef.current?.click()}
              disabled={isUploadButtonDisabled || isOpenComments}
              icon={() => (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.16699 9.83329V10.8333C3.16699 11.9379 4.06242 12.8333 5.16699 12.8333H10.8337C11.9382 12.8333 12.8337 11.9379 12.8337 10.8333V9.83329M8.00033 9.49996V3.33329M5.83366 5.49996L8.00033 3.16663L10.167 5.49996"
                    stroke={primaryColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            />
          )}
          {signatureDetails?.signedProposalFileKey && (
            <div className="flex-1">
              {signatureDetails?.status === "approved" ? (
                <FileView
                  key={proposalId}
                  id={proposalId}
                  docPath={signatureDetails?.signedProposalFileKey}
                  doctype={signatureDetails?.signedProposalFileKey}
                  userAccessBy={getUploadedUserName()}
                  onClick={() => onOpenDoc(signatureDetails)}
                  approvalStatus={signatureDetails?.status}
                  uploadDate={signatureDetails?.signedProposalUploadedDate}
                  onDelete={() => onRemove()}
                />
              ) : (
                <DocumentView
                  key={proposalId}
                  id={proposalId}
                  tooltipWidth="!max-w-[60rem]"
                  docPath={signatureDetails?.signedProposalFileKey}
                  doctype={signatureDetails?.signedProposalFileKey}
                  onDelete={() => onRemove()}
                  onReject={(id) => setRejectId(id)}
                  onClick={() => onOpenDoc(signatureDetails)}
                  isRejectDisable={
                    signatureDetails?.signedProposalRejected ||
                    !isAdmin ||
                    isOpenComments ||
                    isPlanOrInactive
                  }
                  letAdminRemoveTheDoc={
                    !isAdmin &&
                    !changesRequested &&
                    !isProposalInactive &&
                    (isPrimaryAdvisorAgencySubAgencyManager || isPrimaryContactUser)
                  }
                  status={
                    signatureDetails?.signedProposalRejected
                      ? "Rejected"
                      : signatureDetails?.signedProposalRejected
                      ? "Approved"
                      : "Pending"
                  }
                  onApprove={isAdmin ? onApprove : undefined}
                  isApproveBtnDisable={
                    signatureDetails?.signedProposalRejected || isOpenComments || isPlanOrInactive
                  }
                  createdAt={signatureDetails?.signedProposalUploadedDate ?? ""}
                  showTooltip={!!signatureDetails?.signedProposalRejected}
                  tooltipContent={
                    signatureDetails?.ProposalReqChange &&
                    signatureDetails.ProposalReqChange.length > 0
                      ? signatureDetails.ProposalReqChange[
                          signatureDetails.ProposalReqChange.length - 1
                        ]?.data
                      : "Rejected"
                  }
                  approveButtonProps={{
                    icon: "pi pi-check",
                    text: true,
                    outlined: true,
                    className:
                      "!bg-white border-layoutBlue theme-button hover:!bg-white rounded-full",
                  }}
                  userAccessBy={getUploadedUserName()}
                />
              )}
            </div>
          )}
          <input type="file" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
          <FilePreview
            onCancel={() => dispatch(resetDocData())}
            url={getDocPendingProposals.data?.data}
            loading={getDocPendingProposals.loading as boolean}
            {...onClickData}
            downloadFileName={removeFileExtension(getFileName(onClickData?.signedProposalFileKey))}
          />
        </div>
      </>
      <BlockPopup
        visible={!!rejectId}
        footerContent={confirmationPopUpfooterContent}
        header={rejectId ? "Reject Document" : "Confirm"}
        content={popUpContent}
      />
      {confirmationPopUp && (
        <BlockPopup
          visible={confirmationPopUp}
          footerContent={deleteFooter()}
          className="!w-[50vw]"
          header={t("general.button.confirmation")}
          content={t("renderFileDelete.text.subtitle")}
        />
      )}
      {isDownloadPopupOpen && (
        <InteractiveProposalDownload setIsDownloadPopupOpen={setIsDownloadPopupOpen} />
      )}
    </Card>
  );
};

export default UploadSection;
