import { getFullName } from "app/utils/helper";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { customFormatDate } from "app/utils/luxon";
import { getSessionStore } from "app/utils/sessionStore";
import { ApprovalStatus, FileViewInterface, THEME, USERTYPE } from "app/utils/types";
import { getFileName } from "app/utils/utils";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { isEmpty } from "ramda";
import { ImFileZip } from "react-icons/im";
import { FC } from "react";

const FileView: FC<FileViewInterface> = ({
  id,
  docPath,
  doctype,
  onDelete,
  handleDownload,
  approvalStatus,
  rejectRemark,
  onClick,
  uploadDate,
  className,
  docName,
  disableBtn,
  userAccessBy,
  canDelete,
  isIllustrationNotApplicable = false,
  disableRemoveButton = false,
  bodyClassName = "",
}) => {
  const userAccess = getObjectFromLocalStore("useraccess");
  const inactiveProposal = getSessionStore("inactiveProposal");
  const globalTheme = getSessionStore("globalTheme");

  const cardBody = (
    <>
      <Tooltip
        key={id}
        target={`.custom-tooltip-btn-${id}`}
        content={rejectRemark}
        position="top"
      />
      <div
        className={`flex items-center justify-between  custom-tooltip-btn-${id} py-1 px-1 w-full cursor-pointer ${bodyClassName}`}
        data-pr-tooltip={rejectRemark}
        data-pr-position="top"
        // data-pr-at="top bottom"
        data-pr-my="center center-20"
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(docPath);
        }}
      >
        <div className="flex items-center">
          {(approvalStatus &&
            approvalStatus === ApprovalStatus.REJECTED &&
            userAccess?.userType === USERTYPE.AGENT) ||
          (approvalStatus &&
            approvalStatus === ApprovalStatus.REJECTED &&
            userAccess?.userType === USERTYPE.CONTACT) ? (
            <i className="pi pi-info bg-red-400 rounded-full p-1 text-lg text-white" />
          ) : approvalStatus &&
            approvalStatus === ApprovalStatus.REJECTED &&
            userAccess?.userType !== USERTYPE.AGENT ? (
            <i className="pi pi-file-pdf text-3xl" />
          ) : doctype === "pdf" ? (
            <i className="pi pi-file-pdf text-3xl" />
          ) : doctype === "png" || doctype === "jpg" || doctype === "jpeg" ? (
            <i className="pi pi-image text-3xl" />
          ) : doctype === "zip" ? (
            <ImFileZip style={{ height: "28px", width: "28px" }} />
          ) : (
            <i className="pi pi-file-pdf text-3xl" />
          )}

          <div className="flex flex-col ml-3 w-full">
            <div className="overflow-hidden">
              <div className="break-all font-semibold">
                {docName && !isEmpty(docName) ? docName : docPath && getFileName(docPath)}
              </div>
            </div>
            <small className="flex justify-content-start flex-wrap card-container w-15">
              {uploadDate && `${customFormatDate(uploadDate, undefined, undefined, "CST")}`}
              {userAccessBy && `${getFullName(userAccessBy, true)}`}
            </small>
            <small
              className={
                approvalStatus && approvalStatus === ApprovalStatus.REJECTED
                  ? "text-red-400 normal"
                  : "text-layoutBlue normal"
              }
            >
              {approvalStatus?.length
                ? approvalStatus[0].toLocaleUpperCase() + approvalStatus.substring(1)
                : ""}
            </small>
          </div>
        </div>

        {
          userAccess?.userType === USERTYPE.ADMIN && onDelete ? (
            <Button
              className={`rounded-full ${
                (approvalStatus && approvalStatus === ApprovalStatus.APPROVED) ||
                approvalStatus === ApprovalStatus.PAID
                  ? "!bg-layoutBlue hover:!bg-layoutBlue cursor-default"
                  : approvalStatus && approvalStatus === ApprovalStatus.REJECTED
                  ? "!bg-red-500 hover:!bg-red-500"
                  : approvalStatus === ApprovalStatus.SENT
                  ? "!bg-transparent"
                  : "!bg-light-gray hover:!bg-gray-700"
              } border-0  !h-8 !w-8 shrink-0`}
              icon={
                (approvalStatus && approvalStatus === ApprovalStatus.APPROVED) ||
                approvalStatus === ApprovalStatus.PAID
                  ? "pi pi-check"
                  : approvalStatus && approvalStatus === ApprovalStatus.REJECTED
                  ? "pi pi-info"
                  : approvalStatus && approvalStatus === ApprovalStatus.SENT
                  ? ""
                  : "pi pi-times"
              }
              onClick={(e) => {
                e.stopPropagation();
                if (
                  approvalStatus &&
                  approvalStatus !== ApprovalStatus.APPROVED &&
                  approvalStatus !== ApprovalStatus.SENT &&
                  approvalStatus !== ApprovalStatus.PAID &&
                  approvalStatus !== ApprovalStatus.REJECTED
                ) {
                  id && onDelete(id);
                }
              }}
              disabled={disableBtn || inactiveProposal}
            />
          ) : onDelete &&
            (canDelete ||
              userAccess?.userType === USERTYPE.AGENT ||
              (userAccess?.userType === USERTYPE.CONTACT && !handleDownload)) &&
            !isIllustrationNotApplicable ? (
            <Button
              className={`rounded-full ${
                (approvalStatus && approvalStatus === ApprovalStatus.APPROVED) ||
                approvalStatus === ApprovalStatus.PAID
                  ? "!bg-layoutBlue hover:!bg-layoutBlue cursor-default"
                  : approvalStatus && approvalStatus === ApprovalStatus.SENT
                  ? "!bg-transparent"
                  : "!bg-light-gray hover:!bg-gray-700"
              } border-0  !h-8 !w-8 shrink-0`}
              icon={
                (approvalStatus && approvalStatus === ApprovalStatus.APPROVED) ||
                approvalStatus === ApprovalStatus.PAID
                  ? "pi pi-check"
                  : approvalStatus && approvalStatus === ApprovalStatus.SENT
                  ? ""
                  : "pi pi-times"
              }
              onClick={(e) => {
                e.stopPropagation();
                if (
                  approvalStatus &&
                  approvalStatus !== ApprovalStatus.APPROVED &&
                  approvalStatus !== ApprovalStatus.SENT &&
                  approvalStatus !== ApprovalStatus.PAID
                ) {
                  isIllustrationNotApplicable ? "" : id && onDelete(id);
                }
              }}
              disabled={
                disableBtn || inactiveProposal || isIllustrationNotApplicable || disableRemoveButton
              }
            />
          ) : null
          // : userAccess?.userType === USERTYPE.CONTACT && handleDownload ? (
          //   <Button
          //     className="rounded-full
          // !bg-light-gray hover:!bg-gray-700 border-0 !h-8 !w-8 shrink-0"
          //     icon="pi pi-download"
          //     onClick={(e: any) => {
          //       e.stopPropagation();
          //       handleDownload();
          //     }}
          //   />
          // ) :
        }
      </div>
    </>
  );

  return (
    <div
      className={`p-2 mb-4 rounded-md break-all flex w-full items-center bg-gray-100 dark:!bg-blue-1100 ${
        globalTheme === THEME.DARK ? " " : " "
      }  custom-fileview ${className}`}
    >
      {cardBody}
    </div>
  );
};

export default FileView;
