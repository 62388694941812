import React from "react";

interface DownloadIconProps {
  fill?: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-2672208a-bdae-4578-afa4-8a96a3a47e45"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 359.04 324.71"
  >
    <defs>
      <style>{`.uuid-59a3bcc4-7fb9-49f8-b486-34c54dd30ae6 { fill: ${fill}; }`}</style>
    </defs>
    <g id="uuid-e6a1cfb7-a1d3-4fa3-9453-4feb3f9c92d6" data-name="Layer 1">
      <g id="uuid-4d14e49a-3970-4e27-bc4f-5b4fba5f7881" data-name="20-Download">
        <path
          className="uuid-59a3bcc4-7fb9-49f8-b486-34c54dd30ae6"
          d="M179.52,324.71c-8.35,0-16.69-2.15-24.13-6.45L24.13,242.48C9.25,233.88,0,217.87,0,200.68v-75.78c0-6.63,5.37-12,12-12s12,5.37,12,12v75.78c0,8.64,4.65,16.69,12.13,21.01l131.26,75.78c7.48,4.32,16.78,4.32,24.26,0l131.26-75.78c7.48-4.32,12.13-12.37,12.13-21.01v-75.78c0-6.63,5.37-12,12-12s12,5.37,12,12v75.78c0,17.19-9.25,33.2-24.13,41.8l-131.26,75.78c-7.44,4.3-15.79,6.44-24.13,6.45Z"
        />
        <path
          className="uuid-59a3bcc4-7fb9-49f8-b486-34c54dd30ae6"
          d="M179.52,229.69c-6.63,0-12-5.37-12-12V12c0-6.63,5.37-12,12-12s12,5.37,12,12v205.69c0,6.63-5.37,12-12,12Z"
        />
        <path
          className="uuid-59a3bcc4-7fb9-49f8-b486-34c54dd30ae6"
          d="M179.02,230.31c-3.07,0-6.14-1.17-8.49-3.51l-59.36-59.36c-4.69-4.69-4.69-12.28,0-16.97,4.69-4.69,12.28-4.69,16.97,0l59.36,59.36c4.69,4.69,4.69,12.28,0,16.97-2.34,2.34-5.41,3.51-8.49,3.51Z"
        />
        <path
          className="uuid-59a3bcc4-7fb9-49f8-b486-34c54dd30ae6"
          d="M179.4,230.31c-3.07,0-6.14-1.17-8.49-3.51-4.69-4.69-4.69-12.28,0-16.97l59.36-59.36c4.69-4.69,12.28-4.69,16.97,0s4.69,12.28,0,16.97l-59.36,59.36c-2.34,2.34-5.42,3.51-8.49,3.51Z"
        />
      </g>
    </g>
  </svg>
);

export default DownloadIcon;
