import React, { useState, useRef } from "react";
// buy sell icons
import businessIcon from "../../../../assets/VisualsTabsIcons/BuySell/business-icon.png";
import insuranceIcon from "../../../../assets/VisualsTabsIcons/BuySell/insuranceIcon.png";
import shareholderIcon from "../../../../assets/VisualsTabsIcons/BuySell/shareholderIcon.png";
import premiumArrow from "../../../../assets/VisualsTabsIcons/BuySell/premiumArrow.png";
import deathBenefitArrow from "../../../../assets/VisualsTabsIcons/BuySell/deathBenefitArrow.png";
import cashArrow from "../../../../assets/VisualsTabsIcons/BuySell/cashArrow.png";
import stockRedemptionArrow from "../../../../assets/VisualsTabsIcons/BuySell/stockRedemptionArrow.png";
// KPi icons
import kpiHuman from "../../../../assets/VisualsTabsIcons/Kpi/kpi-human.png";
import ar01 from "../../../../assets/VisualsTabsIcons/Kpi/ar01.png";
import ar02 from "../../../../assets/VisualsTabsIcons/Kpi/ar02.png";
import ar03 from "../../../../assets/VisualsTabsIcons/Kpi/ar03.png";
import ar04 from "../../../../assets/VisualsTabsIcons/Kpi/ar04.png";
import kpiDocCheck from "../../../../assets/VisualsTabsIcons/Kpi/kpi-doc-check.png";
import kpiBusiness from "../../../../assets/VisualsTabsIcons/Kpi/kpi-business.png";
import kpiInsurance from "../../../../assets/VisualsTabsIcons/Kpi/kpi-insurance.png";
import kpiPayOFF from "../../../../assets/VisualsTabsIcons/Kpi/kpi-payoff.png";
import SDHuman from "../../../../assets/VisualsTabsIcons/SplitDollar/person.png";
import SDArrow2 from "../../../../assets/VisualsTabsIcons/SplitDollar/SDArrow2.png";
import SDArrow1 from "../../../../assets/VisualsTabsIcons/SplitDollar/SDArrow1.png";
import backGroundColour from "../../../../assets/VisualsTabsIcons/backGroundColour.png";
import count02 from "../../../../assets/VisualsTabsIcons/BuySell/count02.png";
import count01 from "../../../../assets/VisualsTabsIcons/BuySell/count01.png";
import count03 from "../../../../assets/VisualsTabsIcons/BuySell/count03.png";
import count04 from "../../../../assets/VisualsTabsIcons/BuySell/count04.png";
import { getSessionStore } from "app/utils/sessionStore";
import { getThemeColors } from "app/utils/utils";
import { ProposalTypes } from "app/utils/types";

interface PopupInfo {
  content: string;
  stepNumber: number;
}

const VisualsTabs: React.FC = () => {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [currentPopup, setCurrentPopup] = useState<string>("");
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const diagramRef = useRef<HTMLDivElement>(null);
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const proposalType = getSessionStore("selectedProposalRow")?.proposalType;

  const popupData: Record<string, PopupInfo> = {
    premium: {
      content:
        "The business purchases life insurance policies on all owners. The business owns the policies, pays the premiums, and is the beneficiary.",
      stepNumber: 1,
    },
    deathBenefit: {
      content:
        "Upon Shareholder 3's death, the life insurance company pays a death benefit directly to the business.",
      stepNumber: 2,
    },
    cash: {
      content:
        "With the life insurance proceeds, the business buys Shareholder 3's equity portion from his estate.",
      stepNumber: 3,
    },
    stockRedemption: {
      content:
        "Shareholder 3's estate sells back shares to the business, and Shareholder 1 and 2 now own a larger percentage of the business.",
      stepNumber: 4,
    },
  };

  const popupDataKpi: Record<string, PopupInfo> = {
    premium: {
      content:
        "The business selects a key employee whose loss would significantly impact operations and takes out a life or disability insurance policy on their behalf.",
      stepNumber: 1,
    },
    deathBenefit: {
      content:
        "The business pays regular premiums for the policy, maintaining coverage and ensuring the financial protection is in place.",
      stepNumber: 2,
    },
    cash: {
      content:
        "In the event of the key person's death or disability, the business files a claim and receives a payout.",
      stepNumber: 3,
    },
    stockRedemption: {
      content:
        "The benefits can used to cover expenses, pay off debts, attract a replacement, or stabilize the company during the transition.",
      stepNumber: 4,
    },
  };

  const popupDataSplitDollar: Record<string, PopupInfo> = {
    premium: {
      content:
        "Employer extends loans to Executive to pay life insurance premiums. Executive is owner of the life policy.",
      stepNumber: 1,
    },
    deathBenefit: {
      content:
        "Executive may make principal and interest payments to employer based on agreement. Employer takes a collateral position in the life insurance policy.",
      stepNumber: 2,
    },
    cash: {
      content:
        "It is important to define an exit strategy at plan termination. Typical options include paying off the remaining loan balance to Employer by taking policy loans (as illustrated here) or Employer “forgives” all or portion of loan owed by Executive.",
      stepNumber: 3,
    },
    stockRedemption: {
      content:
        "Executive retains sole access to remaining policy benefits, either cash value if living, or death benefits if deceased.",
      stepNumber: 4,
    },
  };

  const handlePopupOpen = (event: React.MouseEvent<SVGElement>, popupId: string) => {
    if (diagramRef.current) {
      const rect = diagramRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top + 20; // Offset to position below the cursor

      setPopupPosition({ x, y });
      setCurrentPopup(popupId);
      setPopupVisible(true);
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  const popupInfo = currentPopup
    ? proposalType === ProposalTypes.BuySell
      ? popupData[currentPopup]
      : proposalType === ProposalTypes.SplitDollar
      ? popupDataSplitDollar[currentPopup]
      : popupDataKpi[currentPopup]
    : { title: "", content: "", stepNumber: 0 };

  const buySellContainer = (
    <>
      {/* Business Hexagon */}
      <g>
        <image href={businessIcon} x="130" y="95" width="150" height="150" />
        <text
          x="205"
          y="250"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          BUSINESS
        </text>
      </g>

      {/* Insurance Company Hexagon */}
      <g>
        <image href={insuranceIcon} x="550" y="95" width="150" height="150" />
        <text
          x="630"
          y="250"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          INSURANCE CO.
        </text>
      </g>

      {/* Shareholder Hexagons */}
      <g>
        <image href={shareholderIcon} x="305" y="270" width="260" height="250" />
      </g>

      {/* Arrow 2: Insurance to Business (Death Benefit) */}
      <g>
        <image
          transform="rotate(180, 420, 150)"
          href={premiumArrow}
          x="290"
          y="120"
          width="260"
          height="50"
        />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "premium")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count01}
          x="400"
          y="130"
          width="50"
          height="50"
        />
        <text
          x="430"
          y="120"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          DEATH BENEFIT
        </text>
      </g>

      {/* Arrow 1: Business to Insurance (Premium) */}
      <g>
        <image
          transform="rotate(180, 420, 150)"
          href={deathBenefitArrow}
          x="290"
          y="80"
          width="260"
          height="40"
        />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "deathBenefit")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count02}
          x="380"
          y="180"
          width="95"
          height="50"
        />
        <text
          x="430"
          y="235"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          PREMIUM (X3)
        </text>
      </g>

      {/* Arrow 4: Shareholders to Business (Stock Redemption) */}
      <g>
        <image href={stockRedemptionArrow} x="180" y="220" width="440" height="280" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "stockRedemption")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count04}
          x="400"
          y="440"
          width="50"
          height="50"
        />
        <text
          x="440"
          y="505"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          STOCK REDEMPTION
        </text>
      </g>

      {/* Arrow 3: Business to Shareholders (Cash) */}
      <g>
        <image href={cashArrow} x="220" y="210" width="320" height="180" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "cash")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count03}
          x="400"
          y="280"
          width="50"
          height="50"
        />
        <text
          x="420"
          y="280"
          textAnchor="middle"
          fill="#10294e"
          className="conthrax-font"
          fontSize="16"
          fontWeight="bold"
        >
          CASH
        </text>
      </g>
    </>
  );

  const kpiContainer = (
    <>
      {/* human Hexagon */}
      <g>
        <image href={kpiHuman} x="350" y="50" width="150" height="150" />
        <text
          x="430"
          y="210"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="16"
          fontWeight="bold"
        >
          KEY PERSON
        </text>
      </g>

      {/* Arrow 2: Business to Human */}
      <g>
        <image href={ar01} x="150" y="110" width="300" height="140" />
        <image href={kpiDocCheck} x="214" y="60" width="80" height="150" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "premium")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count01}
          x="230"
          y="190"
          width="50"
          height="50"
        />
      </g>

      {/* Business Hexagon */}
      <g>
        <image href={kpiBusiness} x="180" y="240" width="150" height="150" />
        <text
          x="255"
          y="400"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          BUSINESS
        </text>
      </g>
      {/* Arrow 2: Business to  Insurance */}
      <g>
        <image href={ar02} x="320" y="245" width="200" height="50" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "deathBenefit")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count02}
          x="400"
          y="250"
          width="50"
          height="50"
        />
        <text
          x="430"
          y="245"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          PREMIUMS
        </text>
      </g>
      {/* Arrow 2: Insurance to Business */}
      <g>
        <image href={ar03} x="325" y="320" width="200" height="50" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "cash")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count03}
          x="400"
          y="320"
          width="50"
          height="50"
        />
        <text
          x="430"
          y="380"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          BENEFIT
        </text>
      </g>
      {/* Insurace Hexagon */}
      <g>
        <image href={kpiInsurance} x="530" y="240" width="150" height="150" />
        <text
          x="610"
          y="400"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          INSURANCE CO.
        </text>
      </g>

      {/* Arrow 4: Business to payoff  */}
      <g>
        <image href={ar04} x="200" y="410" width="200" height="100" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "stockRedemption")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count04}
          x="230"
          y="465"
          width="50"
          height="50"
        />
      </g>
      {/* Payoff Hexagon */}
      <g>
        <image href={kpiPayOFF} x="360" y="420" width="150" height="150" />
      </g>
    </>
  );

  const SplitDollarContainer = (
    <>
      {/* Business Hexagon */}
      <g>
        <image href={kpiHuman} x="130" y="95" width="150" height="150" />
        <text
          x="205"
          y="250"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          EXECUTIVE
        </text>
      </g>

      {/* Insurance Company Hexagon */}
      <g>
        <image href={kpiBusiness} x="550" y="95" width="150" height="150" />
        <text
          x="630"
          y="250"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          BUSINESS
        </text>
      </g>

      {/* Shareholder Hexagons */}
      <g>
        <image href={kpiInsurance} x="340" y="270" width="160" height="150" />
      </g>

      {/* Arrow 2: Insurance to Business (Death Benefit) */}
      <g>
        <image
          transform="rotate(180, 420, 150)"
          href={premiumArrow}
          x="290"
          y="120"
          width="260"
          height="50"
        />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "premium")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count01}
          x="400"
          y="130"
          width="50"
          height="50"
        />
        <text
          x="430"
          y="120"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          LOANS TO PAY PREMIUM
        </text>
      </g>

      {/* Arrow 1: Business to Insurance (Premium) */}
      <g>
        <image
          transform="rotate(180, 420, 150)"
          href={deathBenefitArrow}
          x="290"
          y="80"
          width="260"
          height="40"
        />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "deathBenefit")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count02}
          x="380"
          y="180"
          width="95"
          height="50"
        />
        <text
          x="430"
          y="235"
          textAnchor="middle"
          fill="#888"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          PRINCIPAL & INTEREST
        </text>
      </g>

      {/* Arrow 4: Shareholders to Business (Stock Redemption) */}
      <g>
        <image href={ar04} x="420" y="430" width="110" height="140" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "stockRedemption")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count04}
          x="400"
          y="510"
          width="50"
          height="50"
        />
      </g>

      {/* Arrow 4: Executive to life insurance */}
      <g>
        <image href={SDArrow1} x="200" y="240" width="110" height="140" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "premium")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count01}
          x="180"
          y="315"
          width="50"
          height="50"
        />
        <text
          x="210"
          y="380"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          PREMIUM
        </text>
      </g>

      {/* Arrow 3: Business to Shareholders (Cash) */}
      <g>
        <image href={SDArrow2} x="510" y="240" width="130" height="120" />
        <image
          onMouseEnter={(e) => handlePopupOpen(e, "cash")}
          onMouseLeave={handlePopupClose}
          style={{ cursor: "pointer" }}
          href={count03}
          x="595"
          y="315"
          width="50"
          height="50"
        />
        <text
          x="620"
          y="380"
          textAnchor="middle"
          fill="#193251"
          className="conthrax-font"
          fontSize="14"
          fontWeight="bold"
        >
          RECOUP LOANS
        </text>
        <text
          x="420"
          y="435"
          textAnchor="middle"
          fill="#10294e"
          className="conthrax-font"
          fontSize="16"
          fontWeight="bold"
        >
          LIFE INSURANCE POLICY
        </text>
      </g>
      {/* Human Hexagons */}
      <g>
        <image href={SDHuman} x="520" y="510" width="60" height="50" />
      </g>
    </>
  );

  return (
    <div className="insurance-flow-diagram relative mx-auto mt-2 mb-24 max-w-4xl" ref={diagramRef}>
      <svg viewBox="0 0 800 600" width="100%" height="100%" preserveAspectRatio="xMidYMid meet">
        {/* Main Background */}
        <image href={backGroundColour} x="100" y="10" width="650" height="600" />

        {/* Buy-Sell Container */}
        {proposalType === ProposalTypes.BuySell
          ? buySellContainer
          : proposalType === ProposalTypes.SplitDollar
          ? SplitDollarContainer
          : kpiContainer}
      </svg>

      {popupVisible && (
        <div
          className="popup-container absolute bg-white border border-gray-300 rounded-md shadow-lg p-4"
          style={{
            left: `${popupPosition.x}px`,
            top: `${popupPosition.y}px`,
            maxWidth: "300px",
            transform: "translateX(-50%)",
          }}
        >
          <div className="flex items-start">
            <div
              style={{
                backgroundColor: primaryColor,
                color: "white",
                borderRadius: "0.375rem",
                padding: "0.25rem 0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "0.75rem",
              }}
            >
              <span className="font-bold">{popupInfo.stepNumber}</span>
            </div>
            <div>
              <p className="text-sm text-black">{popupInfo.content}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualsTabs;
