import { FC, useEffect, useState } from "react";
import { useComments } from "./CommentContext";
import PositionComment from "./PositionComment";

interface CommentListProps {
  commentLayoutId: string;
}

const CommentList: FC<CommentListProps> = ({ commentLayoutId }) => {
  const { comments } = useComments();

  // Add a state to force re-render
  const [, setForceUpdate] = useState({});

  useEffect(() => {
    const container = document.querySelector(`[data-comment-layout-id="${commentLayoutId}"]`);

    if (!container) return;

    // Create ResizeObserver for the container
    const resizeObserver = new ResizeObserver(() => {
      // Force re-render by updating state
      setForceUpdate({});
    });

    // Observe the container
    resizeObserver.observe(container);

    // Observe all children
    Array.from(container.children).forEach((child) => {
      resizeObserver.observe(child);
    });

    // Create MutationObserver for dynamic content
    const mutationObserver = new MutationObserver((mutations) => {
      // When new elements are added, observe them too
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            resizeObserver.observe(node);
          }
        });
      });

      // Force re-render
      setForceUpdate({});
    });

    // Start observing
    mutationObserver.observe(container, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    // Add window resize listener
    const handleResize = () => setForceUpdate({});
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    // Handle zoom events
    window.addEventListener("wheel", (e) => {
      if (e.ctrlKey) {
        setForceUpdate({});
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, [commentLayoutId]);

  return (
    <>
      {comments
        .filter((comment) => comment.coordinates?.containerId === commentLayoutId)
        .map((comment) => {
          const container = document.querySelector(
            `[data-comment-layout-id="${comment.coordinates.containerId}"]`,
          );

          if (!container) return null;

          const rect = container.getBoundingClientRect();
          const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

          // Only render comments if their container exists
          // eslint-disable-next-line consistent-return
          return container ? (
            <PositionComment
              key={comment.id}
              comment={{
                ...comment,
                coordinates: {
                  ...comment.coordinates,
                  x: (comment.coordinates.relativeX * rect.width) / (rootFontSize * 100),
                  y: (comment.coordinates.relativeY * rect.height) / (rootFontSize * 100),
                },
              }}
            />
          ) : null;
        })}
    </>
  );
};

export default CommentList;
