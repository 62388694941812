import { FundingSDFilters } from "app/utils/types";

export const fundingSDColumnJson: any = (selectedFundingData: any) => [
  {
    field: "policyYear",
    header: "Year",
    formatter: (value: any) => value, // Assuming no formatting needed
  },
  { field: "planYear", header: "Plan Year" },
  {
    field: "insuredAge",
    header: "Insured Age",
    formatter: (value: any) => value, // Assuming no formatting needed
  },
  {
    field: "employeePremium",
    header: "Employee Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
    style: { opacity: !selectedFundingData?.includes(FundingSDFilters.EMPLOYEE_PREMIUM) ? 0.5 : 1 },
  },
  {
    field: "employeeInterestPayment",
    header: "Employee Interest Payment",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
    style: {
      opacity: !selectedFundingData?.includes(FundingSDFilters.EMPLOYEE_INTEREST_PAYMENT) ? 0.5 : 1,
    },
  },
  {
    field: "employeePrincipalPayment",
    header: "Employee Principal Payment",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "employerPremium",
    header: "Employer Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
    style: { opacity: !selectedFundingData?.includes(FundingSDFilters.EMPLOYER_PREMIUM) ? 0.5 : 1 },
  },
  {
    field: "annualAdminFees",
    header: "Employer Admin Fees",
    style: { opacity: !selectedFundingData?.includes(FundingSDFilters.ADMIN_FEES) ? 0.5 : 1 },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },

  {
    field: "planOriginationFees",
    header: "Plan Origination Fees",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
    style: {
      opacity: !selectedFundingData?.includes(FundingSDFilters.PLAN_ORIGINATION_FEES) ? 0.5 : 1,
    },
  },
  {
    field: "totalPolicyPremium",
    header: "Total Policy Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];
export const headerColumnGroupFundingSD = {
  "Employee Out of Pocket Payments": [
    "employeePremium",
    "employeeInterestPayment",
    "employeePrincipalPayment",
  ],
  "Employer Out of Pocket Payments": ["employerPremium", "annualAdminFees", "planOriginationFees"],
  "Total Premium Paid to Insurance Company": ["totalPolicyPremium"],
};
