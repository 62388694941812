import { formatUnits } from "app/utils/utils";
import * as d3 from "d3";

export const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const capitalizeName = (name: string) =>
  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

export const formatNames = (name: string) =>
  name
    ?.split(" ")
    .map((ch: string) => capitalizeName(ch))
    .join(" ") || "-";

export const reverseFormatNames = (name: string = "") => {
  if (typeof name !== "string") {
    return "-";
  }

  return (
    name
      .replace(/  +/g, " ")
      .split(" ")
      .reverse()
      .map((ch: string) => capitalizeName(ch))
      .join(", ") || "-"
  );
};

export const formatNamesWithComma = (name: string = "") => {
  if (typeof name !== "string") {
    return "-";
  }

  if (name?.includes(",")) {
    return name;
  }

  return (
    name
      .replace(/  +/g, " ")
      .split(" ")
      .map((ch: string) => capitalizeName(ch))
      .join(", ") || "-"
  );
};

export const convertToDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  return `${month} ${day}, ${year}`;
};
export const convertToDateCompleteDetails = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const day = date.getDate();
  return `${month}/${day}/${year}`;
};

export const toTitleCase = (str: string): string => {
  const spacedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
};
export const arrayJsonData = (apiData: any) =>
  Object.entries(apiData)?.map(([key, value]) => ({
    label:
      key.toLowerCase() === "dob"
        ? "Date of Birth"
        : key.toLowerCase() === "nicotine"
        ? toTitleCase("nicotineOrTobaccoUsage")
        : key === "typeOfPlan"
        ? "Type of Plan"
        : key === "fullyFundedOnDeath"
        ? "Fully Funded on Death"
        : key === "fullyFundedOnDisability"
        ? "Fully Funded on Disability"
        : toTitleCase(key),
    value:
      key.toLowerCase() === "dob"
        ? convertToDateCompleteDetails(value as string)
        : key.toLowerCase() === "yearlyincome" ||
          key.toLowerCase() === "networth" ||
          key.toLowerCase() === "employeepremium" ||
          key.toLowerCase() === "employerpremium"
        ? formatUnits(Number(value), "$")
        : value?.toString() || "",
  }));

// check data containe base64 format or not
export const isBase64 = (data: string | any): boolean => {
  try {
    const base64Regex = /^data:[\w/]+;base64,[\s\S]+$/;
    return typeof data === "string" && base64Regex.test(data);
  } catch (error) {
    return false;
  }
};

// makes the color blur
export const calculateLighterColor = (color: string, factor: number): string => {
  const hslColor = d3.hsl(color);
  hslColor.l += (1 - hslColor.l) * factor;

  return hslColor.toString();
};

export const formatNamesWithoutUnderScore = (name: string): string => {
  if (!name) {
    return "-";
  }
  return name
    .replace(/_/g, " ")
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function getFullName(userCreatedBy: any, addBracket?: boolean) {
  if (userCreatedBy && userCreatedBy.profile) {
    const { firstName, lastName } = userCreatedBy.profile;
    if (addBracket) {
      return `( ${lastName}, ${firstName} )`;
    }
    return `${lastName}, ${firstName}`;
  }
  return "";
}
