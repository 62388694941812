import {
  fetchAllCoursesStart,
  fetchAllCoursesSuccess,
  fetchAllCoursesFailure,
  fetchCourseDetailsStart,
  fetchCourseDetailsSuccess,
  fetchCourseDetailsFailure,
  FETCH_ALL_COURSES,
  FETCH_COURSE_DETAILS,
  selectSubLesson,
  SELECT_CURRENT_SUB_LESSON,
  RESET_COURSE_DETAILS,
  resetCurrentCourseDetails,
} from "app/redux/features/course-details";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* fetchAllCourses(): Generator<any, void, any> {
  try {
    yield put(fetchAllCoursesStart());

    const response = yield call(() => makeRequest("getAllCourses").get(""));

    if (!response.data) {
      throw new Error("Invalid response format: missing data");
    }

    const coursesData = response.data.data || response.data;

    yield put(fetchAllCoursesSuccess(coursesData));
  } catch (error: any) {
    yield put(fetchAllCoursesFailure(error));
  }
}

function* fetchCourseDetails(action: any): Generator<any, void, any> {
  try {
    const { courseId, setIndexes } = action.payload;
    yield put(fetchCourseDetailsStart());

    const response = yield call(() => makeRequest("getAllCourses").get(courseId, true));

    if (!response.data || !response.data.data) {
      throw new Error("Invalid response format: missing data");
    }

    const courseData = response.data.data;

    if (courseData?.published) {
      // update course view status
      yield call(() => makeRequest("updateCourseViewStatus").put(courseData.id, {}, true));
    }

    if (!courseData) {
      throw new Error(`Course with ID ${courseId} not found`);
    }

    yield put(fetchCourseDetailsSuccess({ courseData, setIndexes }));
  } catch (error: any) {
    yield put(fetchCourseDetailsFailure(error));
  }
}

function* selectCurrentSubLesson(action: any): Generator<any, void, any> {
  const { subLessonIdx, lessonIdx } = action.payload;
  yield put(
    selectSubLesson({ newSelectedSubLessonIdx: subLessonIdx, newSelectedLessonIdx: lessonIdx }),
  );
}

function* resetCourseDetails(): Generator<any, void, any> {
  yield put(resetCurrentCourseDetails());
}

export default function* courseDetailsSaga() {
  yield all([
    takeLatest(FETCH_ALL_COURSES, fetchAllCourses),
    takeLatest(FETCH_COURSE_DETAILS, fetchCourseDetails),
    takeLatest(RESET_COURSE_DETAILS, resetCourseDetails),
    takeLatest(SELECT_CURRENT_SUB_LESSON, selectCurrentSubLesson),
  ]);
}
