export const SplitDollarAppendixInitialPolicyJson = [
  {
    field: "initialFaceAmount",
    header: "Initial Face Amount",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "lapseYear",
    header: "Lapse Year",
  },
  {
    field: "mecYear",
    header: "MEC Year",
  },
  {
    field: "modalPremium",
    header: "Modal Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "minimumPremiumMmp",
    header: "Minimum Premium (MMP)",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "deathBenefitProtectionPremiumMgp",
    header: "Death Benefit Protection Premium (MGP)",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "targetPremium",
    header: "Target Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "mecPremium",
    header: "MEC Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "guidelineLevelPremium",
    header: "Guideline Level Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "guidelineSinglePremium",
    header: "Guideline Single Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];
