import { PDF_FONT_SIZE } from "./InteractiveProposalDownload";

const PageWrapper = ({ children }: { children: React.ReactNode }) => (
  <div
    className="border-1 border-gray-500 overflow-hidden"
    style={{ aspectRatio: "1 / 1.314", fontSize: PDF_FONT_SIZE }}
  >
    {children}
  </div>
);

export default PageWrapper;
