import { getSessionStore } from "app/utils/sessionStore";
import {
  createGradient,
  getThemeColors,
  getUSANumberFormat,
  projectionOptions,
} from "app/utils/utils";
import { Chart } from "primereact/chart";
import IPPageNumber from "../IPPageNuber";
import HexagonImg from "../../images/hexagonImg.png";
import { lineChartOptions } from "app/uicomponents/macrocomponents/PlansSummary/config";

const projectionsTableHeaders = [
  { header: "Policy Year", color: "#3a7cb8" },
  { header: "Insured Age", color: "#336392" },
  { header: "Estimated Loan Payoff/Income", color: "#2e547d" },
  { header: "Illustrated Plan Accumulated Value", color: "#244161" },
  { header: "Illustrated Plan Surrender Value", color: "#162c46" },
  { header: "Illustrated Plan DB", color: "#162c46" },
];

const loanTableHeaders = [
  { header: "Policy Year", color: "#3a7cb8" },
  { header: "Insured Age", color: "#336392" },
  { header: "Plan Loan Rate", color: "#2e547d" },
  { header: "Minimum AFR", color: "#244161" },
  { header: "Plan Interest", color: "#244161" },
  { header: "Minimum AFR Interest", color: "#244161" },
  { header: "Imputed Compensation To EMPLOYEE", color: "#162c46" },
  { header: "EOY Outstanding Loan Balance", color: "#162c46" },
];

const SDIPProjections = ({
  graphData,
  isNewPage = false,
  pageNumber,
  tableData,
}: {
  graphData?: any;
  isNewPage?: boolean;
  pageNumber: string | number;
  tableData?: any;
}) => {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);

  const planYear = graphData?.map((item: any) => item.planYear);

  const deathBenefit = graphData?.map((item: any) => item.deathBenefit);
  const totalDeathBenefit = deathBenefit?.reduce((acc: any, value: any) => acc + value, 0);

  const cashBenefit = graphData?.map((item: any) => item.cashValue);
  const totalCash = cashBenefit?.reduce((acc: any, value: any) => acc + value, 0);

  const loanData = graphData?.map((item: any) => item.loan);
  const totalLoan = loanData?.reduce((acc: any, value: any) => acc + value, 0);

  const deathBenefitChart = {
    labels: planYear,
    datasets: [
      {
        label: "Death Benefit",
        fill: true,
        borderColor: projectionOptions(primaryColor)[0].indicatorColor,
        backgroundColor: (context: any) =>
          createGradient(context, projectionOptions(primaryColor)[0].indicatorColor),
        yAxisID: "y",
        tension: 0.4,
        data: deathBenefit,
      },
    ],
  };

  const cashValueChart = {
    labels: planYear,
    datasets: [
      {
        label: "Cash Value",
        fill: true,
        borderColor: projectionOptions(primaryColor)[1].indicatorColor,
        backgroundColor: (context: any) =>
          createGradient(context, projectionOptions(primaryColor)[1].indicatorColor),
        yAxisID: "y",
        tension: 0.4,
        data: cashBenefit,
      },
    ],
  };

  const loanChart = {
    labels: planYear,
    datasets: [
      {
        label: "Loan",
        fill: true,
        borderColor: projectionOptions(primaryColor)[1].indicatorColor,
        backgroundColor: (context: any) =>
          createGradient(context, projectionOptions(primaryColor)[1].indicatorColor),
        yAxisID: "y",
        tension: 0.4,
        data: loanData,
      },
    ],
  };

  const renderContent = () => {
    if (isNewPage && pageNumber === 7) {
      return (
        <div>
          <div className="w-full mb-2 bg-white">
            <div className="flex justify-between" style={{ width: "503px" }}>
              <p className="font-bold text-gray-800 text-sm conthrax-font">Loan</p>
              <div className="text-base font-bold text-gray-500 conthrax-font">
                ${getUSANumberFormat(Math.floor(totalLoan))}
              </div>
            </div>
            <Chart
              type="line"
              height="200px"
              width="503px"
              data={loanChart}
              options={lineChartOptions(false, ipTooltipColor, "Plan Year")}
            />
          </div>
          <table className="w-full border-collapse bg-white">
            <thead>
              <tr>
                {loanTableHeaders.map((item) => (
                  <th
                    key={item.header}
                    style={{ backgroundColor: item.color }}
                    className="p-1 text-center font-semibold text-white pdf_table_border border-white"
                  >
                    {item.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item: any, index: any) => (
                <tr
                  key={index}
                  style={{ backgroundColor: "#EEEEEE", color: "#000000", fontSize: "10px" }}
                >
                  <td className="p-1 text-center pdf_table_border border-white">
                    {item.policyYear}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    {item.insuredAge}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    {item.planLoanRate || 0}%
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    {item.minimumAFR || 0}%
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(item.planInterest, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(item.minimumAFRInterest, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(item.imputedCompensationToEmployee, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(item.eoyOutstandingLoanBalance, false)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    if (isNewPage && pageNumber === 6) {
      return (
        <table className="w-full border-collapse bg-white">
          <thead>
            <tr>
              {projectionsTableHeaders.map((item) => (
                <th
                  key={item.header}
                  style={{ backgroundColor: item.color }}
                  className="p-1 text-center font-semibold text-white pdf_table_border border-white"
                >
                  {item.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item: any, index: any) => (
              <tr key={index} style={{ backgroundColor: "#EEEEEE", color: "#000000" }}>
                <td className="p-1 text-center pdf_table_border border-white">{item.policyYear}</td>
                <td className="p-1 text-center pdf_table_border border-white">{item.insuredAge}</td>
                <td className="p-1 text-center pdf_table_border border-white">
                  ${getUSANumberFormat(item.estimatedLoanPayoffOrIncome, false)}
                </td>
                <td className="p-1 text-center pdf_table_border border-white">
                  ${getUSANumberFormat(item.illustratedPlanAccumulatedValue, false)}
                </td>
                <td className="p-1 text-center pdf_table_border border-white">
                  ${getUSANumberFormat(item.illustratedPlanSurrenderValue, false)}
                </td>
                <td className="p-1 text-center pdf_table_border border-white">
                  ${getUSANumberFormat(item.illustratedPlanDB, false)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return (
      <div>
        <h1 className="text-center text-2xl font-bold mb-5 conthrax-font interactive-proposal-text">
          Plan Projections
        </h1>
        <div className="w-full mb-5 bg-white">
          <div className="flex justify-between conthrax-font" style={{ width: "503px" }}>
            <p className="font-bold text-gray-800 text-sm">Cash Value</p>
            <div className="text-base font-bold text-gray-500">
              ${getUSANumberFormat(totalCash, false)}
            </div>
          </div>
          <Chart
            type="line"
            height="200px"
            width="503px"
            data={cashValueChart}
            options={lineChartOptions(false, ipTooltipColor, "Plan Year")}
          />
        </div>
        <div className="w-full bg-white">
          <div className="flex justify-between conthrax-font" style={{ width: "503px" }}>
            <p className="font-bold text-gray-800 text-sm">Death Benefit</p>
            <div className="text-base font-bold text-gray-500">
              ${getUSANumberFormat(totalDeathBenefit, false)}
            </div>
          </div>
          <Chart
            type="line"
            height="200px"
            width="503px"
            data={deathBenefitChart}
            options={lineChartOptions(false, ipTooltipColor, "Plan Year")}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col  h-full relative min-h-screen">
      <div className="relative z-20 px-[2em] w-full max-w-[56em] mx-auto p-[1.75em]">
        {renderContent()}
      </div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default SDIPProjections;
