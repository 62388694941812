import { getUSANumberFormat } from "app/utils/utils";
import IPPageNumber from "../IPPageNuber";
import HexagonImg from "../../images/hexagonImg.png";

const AppendixTable = ({ tableData, tableHeaders }: { tableData: any; tableHeaders: any }) => (
  <table className="w-full border-collapse">
    <thead>
      <tr>
        {tableHeaders?.map(({ header, color }: any) => (
          <th
            key={header}
            className="p-1 text-center font-semibold text-white border-2 border-white"
            style={{ backgroundColor: color, fontSize: "8.53px" }}
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {tableData?.map((policy: any, index: any) => (
        <tr
          key={index}
          style={{ backgroundColor: "#EEEEEE", color: "#000000", fontSize: "7.07px" }}
        >
          <td className="p-1 text-center border-2 border-white">{policy?.policyYear}</td>
          <td className="p-1 text-center border-2 border-white">{policy?.insuredAge || "-"}</td>
          <td className="p-1 text-center border-2 border-white">
            ${getUSANumberFormat(policy?.totalPolicyPremium, false)}
          </td>
          <td className="p-1 text-center border-2 border-white">{policy?.planLoanRate || "0"}%</td>
          <td className="p-1 text-center border-2 border-white">{policy?.minimumAFR || "0"}%</td>
          <td className="p-1 text-center border-2 border-white">
            ${getUSANumberFormat(policy?.endOfYearOutstandingLoanBalance, false)}
          </td>
          <td className="p-1 text-center border-2 border-white">
            ${getUSANumberFormat(policy?.estimatedLoanPayoffOrIncome, false)}
          </td>
          <td className="p-1 text-center border-2 border-white">
            ${getUSANumberFormat(policy?.illustratedPlanAccumulatedValue, false)}
          </td>
          <td className="p-1 text-center border-2 border-white">
            ${getUSANumberFormat(policy?.illustratedPlanSurrenderValue, false)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const SDIPappendix = ({
  summary,
  appendixTableData,
  pageNumber,
  newPage,
  insuranceCompanyName,
}: {
  summary?: any;
  appendixTableData?: any;
  pageNumber: string | number;
  newPage?: boolean;
  insuranceCompanyName?: string;
}) => {
  const firstTable = [
    { header: "Initial Face Amount", color: "#376b9d" },
    { header: "Lapse Year", color: "#315d8a" },
    { header: "MEC Year", color: "#32608e" },
    { header: "Modal Premium", color: "#2d5077" },
    { header: "Minimum Premium (MMP)", color: "#244162" },
    { header: "Death Benefit Protection Premium (MGP)", color: "#1c324e" },
    { header: "Target Premium", color: "#162b43" },
    { header: "MEC Premium", color: "#1b314c" },
    { header: "Guideline Level Premium", color: "#1d3650" },
    { header: "Guideline Single Premium", color: "#132943" },
  ];

  const secondTable = [
    { header: "Year", color: "#376b9d" },
    { header: "Insured Age", color: "#315d8a" },
    { header: "Total Policy Premium", color: "#32608e" },
    { header: "Plan Loan Rate", color: "#2d5077" },
    { header: "Minimum AFR", color: "#244162" },
    { header: "End of Year Outstanding Loan Balance", color: "#1c324e" },
    { header: "Estimated Loan Payoff/Income", color: "#162b43" },
    { header: "Illustrated Plan Accumulated Value", color: "#1b314c" },
    { header: "Illustrated Plan Surrender Value", color: "#1d3650" },
  ];

  const renderContent = () => {
    if (newPage) {
      return (
        <div className="px-[2em] w-full max-w-[56em] mx-auto p-[1.75em]">
          <AppendixTable tableData={appendixTableData} tableHeaders={secondTable} />
        </div>
      );
    }
    return (
      <div className="px-[2em] w-full max-w-[56em] mx-auto p-[1.75em]">
        <div className="text-xl font-bold mb-3 text-center interactive-proposal-text conthrax-font">
          Appendix
        </div>
        <div className="mb-3">
          <p className="mb-2 text-base conthrax-font" style={{ color: "#495057" }}>
            Policy 1 – {insuranceCompanyName} – Please see full insurance company illustration
          </p>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                {firstTable?.map(({ header, color }) => (
                  <th
                    key={header}
                    className="p-1 text-center font-semibold text-white border-2 border-white"
                    style={{ backgroundColor: color, fontSize: "8.53px" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {summary?.map((item: any, index: any) => (
                <tr
                  key={index}
                  style={{ backgroundColor: "#EEEEEE", color: "#000000", fontSize: "7.07px" }}
                >
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.initialFaceAmount, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    {item?.lapseYear || "-"}
                  </td>
                  <td className="p-1 text-center border-2 border-white">{item?.mecYear || "-"}</td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.modalPremium, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.minimumPremiumMmp, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.deathBenefitProtectionPremiumMgp, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.targetPremium, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.mecPremium, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.guidelineLevelPremium, false)}
                  </td>
                  <td className="p-1 text-center border-2 border-white">
                    ${getUSANumberFormat(item?.guidelineSinglePremium, false)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <AppendixTable tableData={appendixTableData} tableHeaders={secondTable} />
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full relative min-h-screen">
      <div className="relative z-20">{renderContent()}</div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default SDIPappendix;
