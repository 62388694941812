import IPPageNumber from "./IPPageNuber";
import HexagonImg from "../images/hexagonImg.png";
import { capitalizeNames } from "app/utils/utils";
import { FaRegCircleCheck } from "react-icons/fa6";
import { PROPOSAL_STATUSES } from "app/utils/types";

const IPSignatures = ({
  pageNumber,
  signatureDetails,
}: {
  pageNumber: string | number;
  signatureDetails: any;
}) => {
  const contactInfo = signatureDetails?.contact?.profile;
  const advisorInfo = signatureDetails?.contact?.contactAdvisors?.find(
    (advisor: any) => advisor?.agentType === "primary",
  )?.agent?.profile;

  const dateFormate = (date: any) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

  const proposalUploadedDate = signatureDetails?.signedProposalUploadedDate || null;
  const displayProposalUploadedDate = () => {
    if (proposalUploadedDate) {
      return dateFormate(proposalUploadedDate);
    }
    return null;
  };
  const displayContactApprovedDate = () => {
    if (signatureDetails?.contactApprovalDate) {
      return dateFormate(signatureDetails?.contactApprovalDate);
    }
    return null;
  };
  const displayAdvisorApprovedDate = () => {
    if (signatureDetails?.advisorApprovalDate) {
      return dateFormate(signatureDetails?.advisorApprovalDate);
    }
    return null;
  };

  const isContactUploadedProposal =
    signatureDetails?.signedProposalUploadedBy === signatureDetails?.contact?.id;

  return (
    <div className="flex flex-col h-full relative min-h-screen">
      <div className="relative z-20 px-[4em] w-full max-w-[56em] mx-auto p-[1.75em]">
        {/* Title */}
        <h1 className="text-center text-2xl font-bold mb-5 conthrax-font interactive-proposal-text">
          Signatures
        </h1>

        {/* Disclosure Text */}
        <p className="mb-8 leading-relaxed" style={{ fontSize: "15px", color: "#000000" }}>
          I have reviewed the proposal including the disclosure page. I understand that the proposal
          is hypothetical and that future policy performance and interest rates for loans are not
          guaranteed and are subject to change.
        </p>

        {/* Client Signature Row */}
        <div className="flex gap-4 mb-12">
          <div className="flex flex-col w-full">
            <div
              className="text-sm text-center font-bold align-content-end"
              style={{ minHeight: "50px", color: "black" }}
            >
              {contactInfo
                ? capitalizeNames(`${contactInfo?.firstName} ${contactInfo?.lastName}`)
                : "-"}
            </div>
            <div className="border-gray-400 h-1 mb-2 border-bottom-2" />
            <label className="text-sm text-center" style={{ color: "#616161" }}>
              Client Name
            </label>
          </div>

          <div className="flex flex-col w-full">
            <div
              className="text-sm text-center font-bold align-content-end flex items-end justify-center"
              style={{ minHeight: "50px", color: "black" }}
            >
              {signatureDetails?.contactApprovalStatus && (
                <FaRegCircleCheck className="mr-2 mb-1" />
              )}
              {signatureDetails?.contactApprovalStatus ? "Approved" : "-"}
            </div>
            <div className="border-gray-400 h-1 mb-2 border-bottom-2" />
            <label className="text-sm  text-center" style={{ color: "#616161" }}>
              Client Signature
            </label>
          </div>

          <div className="flex flex-col w-full">
            <div
              className="text-sm text-center font-bold align-content-end"
              style={{ minHeight: "50px", color: "black" }}
            >
              {signatureDetails?.signedProposalRejected
                ? "-"
                : signatureDetails?.contactApprovalDate
                ? displayContactApprovedDate()
                : signatureDetails?.status === PROPOSAL_STATUSES.UPLOADED &&
                  !isContactUploadedProposal
                ? displayProposalUploadedDate()
                : displayContactApprovedDate() || "-"}
            </div>
            <div className="border-gray-400 h-1 mb-2 border-bottom-2" />
            <label className="text-sm  text-center" style={{ color: "#616161" }}>
              Date
            </label>
          </div>
        </div>

        {/* Advisor Signature Row */}
        <div className="flex gap-4">
          <div className="flex flex-col w-full">
            <div
              className="text-sm text-center font-bold align-content-end"
              style={{ minHeight: "50px", color: "black" }}
            >
              {advisorInfo && capitalizeNames(`${advisorInfo?.firstName} ${advisorInfo?.lastName}`)}
            </div>
            <div className="border-gray-400 h-1 mb-2 border-bottom-2" />
            <label className="text-sm  text-center" style={{ color: "#616161" }}>
              Advisor Name
            </label>
          </div>

          <div className="flex flex-col w-full">
            <div
              className="text-sm text-center font-bold align-content-end flex items-end justify-center"
              style={{ minHeight: "50px", color: "black" }}
            >
              {signatureDetails?.advisorApprovalStatus && (
                <FaRegCircleCheck className="mr-2 mb-1" />
              )}
              {signatureDetails?.advisorApprovalStatus ? "Approved" : "-"}
            </div>
            <div className="border-gray-400 h-1 mb-2 border-bottom-2" />
            <label className="text-sm  text-center" style={{ color: "#616161" }}>
              Advisor Signature
            </label>
          </div>

          <div className="flex flex-col w-full">
            <div
              className="text-sm text-center font-bold align-content-end"
              style={{ minHeight: "50px", color: "black" }}
            >
              {signatureDetails?.signedProposalRejected
                ? "-"
                : displayAdvisorApprovedDate() || displayProposalUploadedDate() || "-"}
            </div>
            <div className="border-gray-400 h-1 mb-2 border-bottom-2" />
            <label className="text-sm  text-center" style={{ color: "#616161" }}>
              Date
            </label>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default IPSignatures;
