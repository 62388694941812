import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import { useEffect, useState, memo } from "react";
import FilterGroupContainer from "../FilterGroupContainer";
import { ProposalTypes } from "app/utils/types";
import {
  fundingOptions,
  fundingSDOptions,
  projectionKPOptions,
  projectionOptions,
  projectionSDOptions,
} from "app/utils/utils";
import { Skeleton } from "primereact/skeleton";
import { isEmpty } from "ramda";
import { useIPContext } from "app/pages/InteractiveProposal/InteractiveProposalProvider";
import { useComments } from "../../Comments/CommentContext";

const InsuredFilter = ({
  onShareholdersChange,
  brandingColor,
  label,
  filter,
}: {
  onShareholdersChange: (
    selectedShareholderIds: string[],
    selectedFundingIds: string[],
    brandingColor: string,
  ) => void;
  brandingColor: string;
  label: string;
  filter: any;
}) => {
  const [shareholdersData, setShareholdersData] = useState<any>([]);
  const [selectedShareholders, setSelectedShareholders] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFunding, setSelectedFunding] = useState<any>(
    Object.keys(filter ?? {})?.reduce((acc: any, key) => {
      acc[key] = true;
      return acc;
    }, {}),
  );
  const { setIsCurrPageLoading } = useComments();

  const { selectedVersion, summaryData: proposalSummary } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("interactiveProposalID");
  const proposalPlatform = {
    [ProposalTypes.BuySell]: "shareHolderLedgerList",
    [ProposalTypes.KeyPersonInsurance]: "kpishareHolderLedgerList",
    [ProposalTypes.SplitDollar]: "shareHolderLedgerList",
  };
  const lableNamesForProposal = {
    [ProposalTypes.BuySell]: "Shareholders",
    [ProposalTypes.KeyPersonInsurance]:
      proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
        ? "Key Employees"
        : "Shareholders",
    [ProposalTypes.SplitDollar]: "Insured Person",
  };
  const secondFilterOptions = {
    [ProposalTypes.BuySell]:
      label === "Funding" ? fundingOptions(brandingColor) : projectionOptions(brandingColor),
    [ProposalTypes.KeyPersonInsurance]:
      label === "Funding" ? fundingOptions(brandingColor) : projectionKPOptions(brandingColor),
    [ProposalTypes.SplitDollar]:
      label === "Funding" ? fundingSDOptions(brandingColor) : projectionSDOptions(brandingColor),
  };
  useEffect(() => {
    setLoading(true);
    if (proposalSummary?.proposalType === ProposalTypes.SplitDollar) {
      const insuredDetails =
        proposalSummary?.insuredDetails?.[0] || proposalSummary?.primaryContact;
      setShareholdersData([
        {
          id: "123",
          name: `${insuredDetails?.firstName} ${insuredDetails?.lastName}`,
        },
      ]);
      setSelectedShareholders({
        "123": true,
      });
      setLoading(false);
    } else {
      setIsCurrPageLoading(true);
      makeRequest(proposalPlatform[proposalSummary?.proposalType as keyof typeof proposalPlatform])
        .get(`${proposalId}?ownershipPercentage=ALL`, true, false)
        .then((res) => {
          setShareholdersData(
            res?.data?.data?.map((item: any) => ({
              ...item,
              name: `${item?.user?.profile?.firstName} ${item?.user?.profile?.lastName}`,
            })),
          );
          setSelectedShareholders(
            res?.data?.data?.reduce((acc: any, item: any) => {
              acc[item?.id] = true;
              return acc;
            }, {}),
          );
          setLoading(false);
          setIsCurrPageLoading(false, true);
        });
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(selectedShareholders)) {
      onShareholdersChange(
        Object.keys(selectedShareholders)?.filter((key) => selectedShareholders[key]),
        Object.keys(selectedFunding)?.filter((key) => selectedFunding[key]),
        brandingColor,
      );
    }
  }, [selectedShareholders, selectedFunding, brandingColor]);
  return (
    <>
      {loading ? (
        <div className="flex items-center h-full gap-1 w-[55%] p-2 flex-col  ">
          <Skeleton className="w-[30%] h-[60px] mt-4" />
          <Skeleton className="w-[30%] h-[60px] mt-4" />
        </div>
      ) : (
        <FilterGroupContainer
          title={
            lableNamesForProposal[
              proposalSummary?.proposalType as keyof typeof lableNamesForProposal
            ]
          }
          options={shareholdersData}
          selectedOptions={selectedShareholders}
          setSelectedOptions={setSelectedShareholders}
          className="w-[55%]"
          brandingColor={brandingColor}
          hideCheckbox={proposalSummary?.proposalType === ProposalTypes.SplitDollar}
        />
      )}
      <FilterGroupContainer
        title={label}
        options={
          secondFilterOptions[proposalSummary?.proposalType as keyof typeof secondFilterOptions]
        }
        selectedOptions={selectedFunding}
        setSelectedOptions={setSelectedFunding}
        className="flex-1 border-l border-gray-300 pl-4"
        brandingColor={brandingColor}
      />
    </>
  );
};
export default memo(InsuredFilter);
