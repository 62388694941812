import { useState, useCallback } from "react";
import ChartWithCustomLegend from "app/uicomponents/microcomponents/ChartWithCustomLegend";
import { Card } from "primereact/card";
import { barGraphOptionsFunding } from "app/uicomponents/macrocomponents/PlansSummary/config";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import { fundingOptions, fundingSDOptions, getThemeColors } from "app/utils/utils";
import { FundingSDFilters, ProposalFilter, ProposalTypes, THEME } from "app/utils/types";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";
import { useSelector } from "react-redux";
import { getAgentBranding } from "app/redux/features/agent-branding";
import { fundingColumnJson } from "../../../Data/fundingColumnJson";
import { fundingSDColumnJson, headerColumnGroupFundingSD } from "../../../Data/fundingSDColumnJson";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import InsuredTable from "app/uicomponents/macrocomponents/InteractiveProposal/Funding/InsuredTable";
import InsuredFilter from "app/uicomponents/macrocomponents/InteractiveProposal/Funding/InsuredFilter";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { useIPContext } from "../InteractiveProposalProvider";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";

let selectedFundingData: any = null;
const InteractiveProposalFunding = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedVersion, summaryData: proposalSummary } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("interactiveProposalID");
  const globalTheme = getSessionStore("globalTheme");
  const { settingThemeData } = useSelector(getAgentBranding);
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);
  const { setIsCurrPageLoading } = useComments();

  const filterForProposals = {
    [ProposalTypes.KeyPersonInsurance]: ProposalFilter,
    [ProposalTypes.BuySell]: ProposalFilter,
    [ProposalTypes.SplitDollar]: FundingSDFilters,
  };

  const insuredDetails = proposalSummary?.insuredDetails?.[0] ?? proposalSummary?.primaryContact;

  const calculateChartData = useCallback(
    (chartsData: any[], funding: string[], brandingColor: string) => {
      let labels = chartsData?.map((item: any) => item?.planYear);
      if (proposalSummary?.proposalType === ProposalTypes.SplitDollar) {
        labels = chartsData?.map((item: any) => `Age ${item?.insuredAge}`);
      }
      const datasets = [];
      if (proposalSummary?.proposalType === ProposalTypes.SplitDollar) {
        if (funding.includes(FundingSDFilters.EMPLOYEE_PREMIUM)) {
          datasets.push({
            label: "Employee Premium",
            backgroundColor: fundingSDOptions(brandingColor)[0].indicatorColor,
            data: chartsData?.map((item: any) => item?.employeePremium),
          });
        }
        if (funding.includes(FundingSDFilters.EMPLOYER_PREMIUM)) {
          datasets.push({
            label: "Employer Premium",
            backgroundColor: fundingSDOptions(brandingColor)[1].indicatorColor,
            data: chartsData?.map((item: any) => item?.employerPremium),
          });
        }
        if (funding.includes(FundingSDFilters.EMPLOYEE_INTEREST_PAYMENT)) {
          datasets.push({
            label: "Employee Interest Payment",
            backgroundColor: fundingSDOptions(brandingColor)[2].indicatorColor,
            data: chartsData?.map((item: any) => item?.employeeInterestPayment),
          });
        }
        if (funding.includes(FundingSDFilters.ADMIN_FEES)) {
          datasets.push({
            label: "Admin Fees",
            backgroundColor: fundingSDOptions(brandingColor)[3].indicatorColor,
            data: chartsData?.map((item: any) => item?.adminFees),
          });
        }
        if (funding.includes(FundingSDFilters.PLAN_ORIGINATION_FEES)) {
          datasets.push({
            label: "Plan Origination Fees",
            backgroundColor: fundingSDOptions(brandingColor)[4].indicatorColor,
            data: chartsData?.map((item: any) => item?.planOriginationFees),
          });
        }
      } else {
        if (funding.includes(ProposalFilter.LIFE)) {
          datasets.push({
            label: "Life Premium",
            backgroundColor: fundingOptions(brandingColor)[0].indicatorColor,
            data: chartsData?.map((item: any) => item?.lifePolicyPremium),
          });
        }
        if (funding.includes(ProposalFilter.DISABILITY)) {
          datasets.push({
            label: "Disability Premium",
            backgroundColor: fundingOptions(brandingColor)[1].indicatorColor,
            data: chartsData?.map((item: any) => item?.disabilityPolicyPremium),
          });
        }
        if (funding.includes(ProposalFilter.ADMIN_FEES)) {
          datasets.push({
            label: "Admin Fees",
            backgroundColor: fundingOptions(brandingColor)[2].indicatorColor,
            data: chartsData?.map((item: any) => item?.adminFees),
          });
        }
        if (funding.includes(ProposalFilter.PLAN_ORIGINATION_FEES)) {
          datasets.push({
            label: "Plan Origination Fees",
            backgroundColor: fundingOptions(brandingColor)[3].indicatorColor,
            data: chartsData?.map((item: any) => item?.planOriginationFees),
          });
        }
      }
      return { labels, datasets };
    },
    [],
  );

  // Define the header structure headerColumnGroupProjection
  const headerColumnGroupEle = (
    <Row>
      <Column
        header=""
        rowSpan={1}
        colSpan={3}
        // style={{
        //   borderRightWidth: 1,
        //   borderRightColor: "var(--surface-100)",
        //   borderBottomWidth: 1,
        //   borderBottomColor: "var(--surface-100)",
        // }}
        align="center"
      />
      {(
        Object.keys(headerColumnGroupFundingSD) as Array<keyof typeof headerColumnGroupFundingSD>
      ).map((key: any) => (
        <Column
          header={key}
          key={key}
          rowSpan={1}
          colSpan={
            headerColumnGroupFundingSD[key as keyof typeof headerColumnGroupFundingSD].length
          }
          // style={{
          //   borderRightWidth: 1,
          //   borderRightColor: "var(--surface-100)",
          //   borderBottomWidth: 1,
          //   borderBottomColor: "var(--surface-100)",
          // }}
          align="center"
        />
      ))}
    </Row>
  );
  const onShareholdersChange = useCallback(
    async (
      selectedShareholderIds: string[],
      selectedFundingIds: string[],
      brandingColor: string,
    ) => {
      selectedFundingData = selectedFundingIds;
      let newShareholderIds = selectedShareholderIds;
      if (proposalSummary?.proposalType === ProposalTypes.SplitDollar) {
        newShareholderIds = [];
      }
      setLoading(true);
      setIsCurrPageLoading(true);
      const response = await makeRequest("interactiveProposalFunding").get(
        `${proposalId}?insuredIds=${JSON.stringify(newShareholderIds)}&filter=${JSON.stringify(
          selectedFundingIds,
        )}`,
        true,
        false,
      );
      setIsCurrPageLoading(false, true);
      if (response.status === 200) {
        const data = calculateChartData(
          response?.data?.data?.graphData,
          selectedFundingIds,
          brandingColor,
        );
        if (
          selectedShareholderIds.length === 0 &&
          proposalSummary?.proposalType === ProposalTypes.SplitDollar
        ) {
          setChartData(null);
        } else {
          setChartData(data);
        }
        setTableData(response?.data?.data?.tableData || []);
      }
      setLoading(false);
    },
    [proposalId],
  );

  return (
    <CommentLayout
      commentLayoutId="interactive-proposal-funding"
      className=" border border-1 border-gray-300 rounded-2xl w-full min-h-screen  shadow-lg"
    >
      {/* Filter container */}
      <div className="p-3 flex justify-between w-full border-b-2 border-gray-300 mb-3">
        <InsuredFilter
          onShareholdersChange={onShareholdersChange}
          brandingColor={primaryColor}
          label="Funding"
          filter={
            filterForProposals[proposalSummary?.proposalType as keyof typeof filterForProposals]
          }
        />
      </div>
      {/* Chart container */}
      <div>
        {loading ? (
          <ScreenLoader />
        ) : (
          <>
            <CommentLayout commentLayoutId="interactive-proposal-funding-chart">
              <Card title={() => <div className="conthrax-font">Funding Chart</div>}>
                {chartData ? (
                  <ChartWithCustomLegend
                    data={chartData}
                    options={barGraphOptionsFunding(
                      globalTheme === THEME.DARK,
                      ipTooltipColor,
                      proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
                        ? "Plan Funding for Selected Employees"
                        : proposalSummary?.proposalType === ProposalTypes.SplitDollar
                        ? `Plan Funding for ${insuredDetails.firstName} ${insuredDetails.lastName}`
                        : "Plan Funding for Selected Shareholders",
                    )}
                    type="bar"
                    legends={[]} // No legends displayed
                  />
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <p className="text-lg text-center">No data available</p>
                  </div>
                )}
              </Card>
            </CommentLayout>
            {/* Funding Table */}
            <CommentLayout
              commentLayoutId="interactive-proposal-funding-table"
              className=" flex justify-between w-full mt-1"
            >
              {/* Table code here */}
              {!loading && (
                <Card
                  title={() => <div className="conthrax-font">Funding Table</div>}
                  className="w-full p-3 border-[1px] border-borderColor"
                >
                  <InsuredTable
                    columns={
                      proposalSummary?.proposalType === ProposalTypes.SplitDollar
                        ? fundingSDColumnJson(selectedFundingData)
                        : fundingColumnJson(selectedFundingData)
                    }
                    data={tableData}
                    hoverColor={primaryColor}
                    columnGroup={
                      proposalSummary?.proposalType === ProposalTypes.SplitDollar
                        ? headerColumnGroupEle
                        : null
                    }
                    scrollable
                  />
                  <p className="text-lg  text-center my-5">
                    {proposalSummary?.proposalType === ProposalTypes.SplitDollar
                      ? `Plan Funding for ${insuredDetails.firstName} ${insuredDetails.lastName}`
                      : "Plan Funding for Selected Shareholders"}
                  </p>
                </Card>
              )}
            </CommentLayout>
          </>
        )}
      </div>
      <div className="hidden">{JSON.stringify(settingThemeData)}</div>
    </CommentLayout>
  );
};
export default InteractiveProposalFunding;
