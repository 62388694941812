import { getSessionStore, removeSessionStore, setSessionStore } from "app/utils/sessionStore";
import React, {
  type SetStateAction,
  type Dispatch,
  useState,
  useContext,
  useEffect,
  MutableRefObject,
  useRef,
} from "react";
import {
  getProposalType,
  getThemeColors,
  InteractiveProposalHeaderBgSvg,
  proposalStatuses,
} from "app/utils/utils";
import Lottie from "lottie-react";
import { FaRegCircleCheck } from "react-icons/fa6";
import InteractiveProposalHeaderBg from "../../assets/svg/interactive-proposal-header.svg";
import SummaryDetail from "../../assets/summary-details-light.svg";
import SummaryDetailDark from "../../assets/summary-details-dark.svg";
import {
  CurrentProposalType,
  PROPOSAL_STATUSES,
  ProposalTypes,
  THEME,
  TOASTTYPE,
  USERTYPE,
} from "app/utils/types";
import { getObjectFromLocalStore } from "app/utils/localStore";
import useDarkTheme from "../../hooks/useDarkTheme";
import { ThemeContext } from "app/uicomponents/macrocomponents/RootLayout";
import { useDispatch } from "react-redux";
import { isEmpty } from "ramda";
import PrimeReact from "primereact/api";
import { TRIGGER_SAVE_THEME_SETTINGS } from "app/redux/features/agent-branding";
import CommentIcon from "app/assets/IconComponent/CommentIcon";
import DownloadIcon from "app/assets/IconComponent/DownloadIcon";
import { Tooltip } from "primereact/tooltip";
import InteractiveProposalDownload from "./InteractiveProposalDownload/InteractiveProposalDownload";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";
import { makeRequest } from "app/utils/makeRequest";
import { Toast } from "primereact/toast";
import BlockPopup from "app/uicomponents/microcomponents/BlockPopup";
import { Button } from "primereact/button";
import { useIPContext } from "./InteractiveProposalProvider";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BrandingSelector from "./menuPages/Header/BrandingSelector";
import ThemeSelector from "./menuPages/Header/ThemeSelector";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  proposalNo?: string;
  proposalType?: string;
  isCollapsed?: boolean;
  error?: string | null;
  loadingPage?: boolean;
  animationData?: any;
  setIsHovered?: (isHovered: boolean) => void;
  lottieRef?: any;
  isCommentMode: boolean;
  setIsCommentMode: Dispatch<SetStateAction<boolean>>;
}

const encodeSvg = (svg: string) => `data:image/svg+xml;base64,${btoa(svg)}`;

export const getThemeClass = (isDark: boolean) =>
  isDark ? "bg-blue-1100 text-white" : "bg-white text-black-alpha-90";

const InteractiveProposalHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  proposalNo,
  proposalType,
  isCollapsed,
  error,
  loadingPage,
  animationData,
  setIsHovered,
  lottieRef,
  setIsCommentMode,
  isCommentMode,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { branding } = getSessionStore("lookUpResponse");
  const userAccess = getObjectFromLocalStore("useraccess");
  const dispatch = useDispatch();
  const theme = getSessionStore("globalTheme");
  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useState(false);
  const [openRevokePopup, setOpenRevokePopup] = useState(false);
  const { setIsDarkMode } = useContext(ThemeContext);
  const [, setTheme]: [string, Dispatch<SetStateAction<string>>] = useDarkTheme();
  const { primaryColor, secondaryColor } = getThemeColors(branding);
  const {
    selectedVersion,
    setSelectedVersion,
    versionList,
    isThisLatestProposal,
    fetchProposalSummary,
    isPrimaryAdvisor,
    fetchApprovalDetails,
    signatureDetails,
    summaryData,
    summaryLoading,
    proposalStatus,
    isAgencyManager,
    isSubAgencyManager,
    isPrimaryContactUser,
    themeState,
    setThemeState,
  } = useIPContext();
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(proposalStatus as any);
  const [isBtndisable, setIsBtnDisable] = useState(false);
  const iconFill = themeState === THEME.DARK ? "#fff" : "#000";
  const toast: MutableRefObject<any> = useRef(null);
  const { comments, getComments, openComments, resolveAllComments, setIsCurrPageLoading } =
    useComments();
  const location = useLocation();

  useEffect(() => {
    getComments();
  }, [location.pathname]);

  const changesRequested = summaryData?.status === "feedback";

  const currentProposalType = getSessionStore("currentProposalType");
  const planInfo = getSessionStore("planInfo");

  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");

  const svgString = secondaryColor
    ? InteractiveProposalHeaderBgSvg(secondaryColor)
    : InteractiveProposalHeaderBgSvg();

  React.useEffect(() => {
    document.documentElement.style.setProperty("--interactive-proposal-text", primaryColor);
  }, [primaryColor]);

  const isSummary = window.location.href.includes("summary");
  const backgroundStyle = isSummary
    ? {
        backgroundColor: secondaryColor,
        backgroundImage: `url(${encodeSvg(svgString)})`,
        filter: `hue-rotate(${secondaryColor})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }
    : {
        ...(themeState === THEME.DARK
          ? { backgroundColor: "#2F2F44" }
          : { backgroundImage: `url(${InteractiveProposalHeaderBg})`, backgroundColor: "#fff" }),
        filter: `hue-rotate(${secondaryColor})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "70px",
        boxShadow: "0 2px 10px rgba(18, 18, 23, 0.1)",
      };

  useEffect(() => {
    if (!isEmpty(themeState)) {
      dispatch({
        type: TRIGGER_SAVE_THEME_SETTINGS,
        payload: {
          address: "saveThemeSettings",
          body: { theme: themeState },
        },
      });
    }
  }, [themeState]);
  const isRevokeDisabled = isPrimaryAdvisor || isAgencyManager || isSubAgencyManager;

  const handleThemeClick = (selectedTheme: string) => {
    setTheme(selectedTheme);
    setSessionStore("globalTheme", selectedTheme);
    setThemeState(selectedTheme);
    setIsDarkMode(selectedTheme === THEME.DARK);
    PrimeReact?.changeTheme?.(
      `lara-${theme}-blue`,
      `lara-${selectedTheme}-blue`,
      "app-theme",
      () => {
        const allThemeLinks = document.querySelectorAll("link#app-theme");
        if (allThemeLinks.length > 1) {
          for (let i = 1; i < allThemeLinks.length; i++) {
            allThemeLinks[i].remove();
          }
        }
      },
    );
  };

  const handleSeenComment = async () => {
    if (comments?.length > 0 && !isBtndisable) {
      setIsBtnDisable(true);
      setIsCommentMode(false);
      makeRequest("proposalRequestCommentChanges")
        .post([], proposalId, true)
        .then(() => {
          toast?.current?.show({
            severity: TOASTTYPE.SUCCESS,
            summary: t("agentBranding.label.success"),
            detail: "Requested changes has been added to proposal",
            life: 3000,
          });
          fetchProposalSummary();
          getComments();
        })
        .catch(() => {});
    }
  };
  const approvalAdress: any = {
    [USERTYPE.AGENT]: "interactiveProposalAdvisorProposalApproval",
    [USERTYPE.CONTACT]: "interactiveProposalContactProposalApproval",
    [USERTYPE.ADMIN]: "interactiveProposalAdminProposalApproval",
  };

  async function approveProposal() {
    if (!isThisLatestProposal) {
      return;
    }
    try {
      const response = await makeRequest(approvalAdress[userAccess?.userType]).patch(
        undefined, // No body needed
        currentProposalType === CurrentProposalType.PLANS
          ? selectedVersion?.id ?? planInfo?.planProposalId
          : proposalId,
        true, // Indicates that `proposalId` is a request parameter
      );
      fetchApprovalDetails();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
    } catch (err: any) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.message,
      });
    }
  }
  function handleRevokeCallback() {
    makeRequest("interactiveProposalProposalRevoke")
      .put(
        proposalId,
        undefined, // No body needed
        true, // Indicates that `proposalId` is a request parameter
      )
      .then((res) => {
        fetchApprovalDetails();
        fetchProposalSummary();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res?.data?.message,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err?.error,
        });
      })
      .finally(() => {
        setOpenRevokePopup(false);
      });
  }
  const revokePopupFooterContent = (
    <div>
      <Button
        label="Cancel"
        onClick={() => setOpenRevokePopup(false)}
        className="p-button-text border-gray-500 text-gray-500"
      />
      <Button
        label="Revoke"
        className="bg-red-500 border-red-500"
        autoFocus
        onClick={() => handleRevokeCallback()}
      />
    </div>
  );

  const handleEditProposal = () => {
    makeRequest("interactiveProposalCreateNewVersion")
      .patch({}, proposalId, true, false)
      .then((res) => {
        toast?.current?.show({
          severity: TOASTTYPE.SUCCESS,
          summary: t("agentBranding.label.success"),
          detail: "New version has been created",
          life: 3000,
        });

        setSessionStore("proposalID", res?.data?.data?.proposalId);
        setSessionStore("bsProposalId", res?.data?.data?.proposalId);
        setSessionStore("proposalNo", res?.data?.data?.proposalNo);
        setSessionStore("interactiveProposalID", res?.data?.data?.proposalId);
        setSessionStore("proposalStatus", proposalStatuses().draft);

        // clear existing product and company name from storage
        removeSessionStore("prouctNameforUpload");
        removeSessionStore("CompanyNameforUpload");

        navigate("/createproposal/contactdetails", { replace: true });
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error creating new proposal",
          life: 3000,
        });
      });
  };

  const isLoggedInUserAdmin = userAccess?.userType === USERTYPE.ADMIN;
  const isSignatureDocumentUploaded = !!signatureDetails?.signedProposalFileKey;
  const isPrimaryAdvisorOrAgencyManager = () =>
    isPrimaryAdvisor || isAgencyManager || isSubAgencyManager;

  const revokeButton = () => {
    const isEnabled =
      [isPrimaryAdvisor, isAgencyManager, isSubAgencyManager, isLoggedInUserAdmin].includes(true) &&
      (isThisLatestProposal || isRevokeDisabled) &&
      ![
        PROPOSAL_STATUSES.IN_ACTIVE,
        PROPOSAL_STATUSES.DRAFT,
        PROPOSAL_STATUSES.CREATED,
        PROPOSAL_STATUSES.REVOKED,
        PROPOSAL_STATUSES.PLAN_CREATED,
      ].includes(proposalStatus as any);

    return (
      <div
        className={`h-7 revoke-icon rounded-3xl flex items-center px-[12px] border-1 ${getThemeClass(
          themeState === THEME.DARK,
        )} ${
          isEnabled
            ? "cursor-pointer transition-transform duration-200 hover:scale-105"
            : "opacity-75 cursor-not-allowed"
        }`}
        onClick={() => {
          if (isEnabled) {
            setOpenRevokePopup(true);
            setIsCommentMode(false);
          }
        }}
      >
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.51016 4.53125L7.40609 6.42719C7.58916 6.61025 7.58916 6.90703 7.40609 7.09011C7.22303 7.27316 6.92625 7.27316 6.74317 7.09011L4.00314 4.35006C3.80787 4.1548 3.80787 3.83822 4.00314 3.64295L6.74317 0.902922C6.92623 0.719859 7.22303 0.719859 7.40609 0.902922C7.58916 1.08598 7.58916 1.38277 7.40609 1.56583L5.37817 3.59375H10.7344C12.909 3.59375 14.6719 5.35663 14.6719 7.53125C14.6719 9.70588 12.909 11.4688 10.7344 11.4688H1.79688C1.53798 11.4688 1.32812 11.2589 1.32812 11C1.32812 10.7411 1.53798 10.5312 1.79688 10.5312H10.7344C12.3912 10.5312 13.7344 9.18811 13.7344 7.53125C13.7344 5.87439 12.3912 4.53125 10.7344 4.53125H5.51016Z"
            fill={iconFill}
            stroke={iconFill}
            strokeWidth="0.5"
          />
        </svg>

        {isSummary ? (
          <span className="text-sm font-medium pl-1" style={{ color: iconFill }}>
            Revoke
          </span>
        ) : (
          <Tooltip target=".revoke-icon" content="Revoke" position="bottom" className="text-sm" />
        )}
      </div>
    );
  };

  const downloadButton = (
    <div
      className={`h-7 download-icon rounded-3xl flex items-center px-[12px] cursor-pointer transition-transform duration-200 border-1 hover:scale-105 ${getThemeClass(
        themeState === THEME.DARK,
      )}`}
      onClick={() => setIsDownloadPopupOpen(true)}
    >
      <span className="w-1rem">
        <DownloadIcon fill={iconFill} />
      </span>
      {isSummary ? (
        <span className="text-sm font-medium ml-1">Download</span>
      ) : (
        <Tooltip target=".download-icon" content="Download" position="bottom" className="text-sm" />
      )}
    </div>
  );

  const addCommentButton = userAccess?.userType !== USERTYPE.ADMIN &&
    !isPlanOrInactive &&
    isThisLatestProposal && (
      <div
        className={`h-7 add-comment-btn rounded-3xl flex items-center px-[12px] border-1 ${getThemeClass(
          themeState === THEME.DARK,
        )} ${isCommentMode ? "!text-blue-500 !font-bold scale-105" : ""} ${
          changesRequested ||
          [
            PROPOSAL_STATUSES.IN_ACTIVE,
            PROPOSAL_STATUSES.DRAFT,
            PROPOSAL_STATUSES.CREATED,
          ].includes(proposalStatus as any)
            ? "cursor-not-allowed opacity-85"
            : "cursor-pointer transition-transform duration-200 hover:scale-105"
        }`}
        onClick={() => {
          if (!changesRequested) {
            setIsCommentMode((p) => !p);
          }
        }}
      >
        <span className="w-1rem">
          <CommentIcon fill={isCommentMode ? "#4299e1" : iconFill} />
        </span>
        {isSummary ? (
          <span className="text-sm font-medium ml-1 whitespace-nowrap">Add Comment</span>
        ) : (
          <Tooltip
            target=".add-comment-btn"
            content="Add Comment"
            position="bottom"
            className="text-sm"
          />
        )}
      </div>
    );

  const approveButton = () => {
    const isEnabled =
      [
        isPrimaryAdvisor,
        isAgencyManager,
        isSubAgencyManager,
        isPrimaryContactUser,
        isLoggedInUserAdmin,
      ].includes(true) &&
      isThisLatestProposal &&
      ![
        PROPOSAL_STATUSES.IN_ACTIVE,
        PROPOSAL_STATUSES.DRAFT,
        PROPOSAL_STATUSES.CREATED,
        PROPOSAL_STATUSES.REVOKED,
        PROPOSAL_STATUSES.PLAN_CREATED,
      ].includes(proposalStatus as any);

    const isEnableForAdmin = () =>
      isEnabled &&
      isLoggedInUserAdmin &&
      isSignatureDocumentUploaded &&
      !signatureDetails?.signedProposalRejected &&
      signatureDetails?.status !== PROPOSAL_STATUSES.APPROVED;

    const isEnableForAdvisor = () =>
      isEnabled &&
      isPrimaryAdvisorOrAgencyManager() &&
      !isSignatureDocumentUploaded &&
      !signatureDetails?.signedProposalRejected &&
      !signatureDetails?.advisorApprovalStatus;

    const isEnableForContact = () =>
      isEnabled &&
      isPrimaryContactUser &&
      (isSignatureDocumentUploaded || !signatureDetails?.contactApprovalStatus) &&
      !signatureDetails?.signedProposalRejected;

    return (
      <div
        className={`approve-btn h-7 rounded-3xl flex align-items-center px-[12px] border-1 ${getThemeClass(
          themeState === THEME.DARK,
        )} ${
          isEnableForAdmin() || isEnableForAdvisor() || isEnableForContact()
            ? "cursor-pointer transition-transform duration-200 hover:scale-105"
            : "opacity-85 cursor-not-allowed"
        }`}
        onClick={() => {
          signatureDetails?.status === PROPOSAL_STATUSES.UPLOADED
            ? undefined
            : (isEnableForAdmin() || isEnableForAdvisor() || isEnableForContact()) &&
              approveProposal();
        }}
      >
        <FaRegCircleCheck className="h-4" style={{ color: iconFill }} />
        {isSummary ? (
          <span className="text-sm font-medium ml-1" style={{ color: iconFill }}>
            Approve
          </span>
        ) : (
          <Tooltip target=".approve-btn" content="Approve" position="bottom" className="text-sm" />
        )}
      </div>
    );
  };
  const requestChangeButton = (
    <div
      className={`h-7 request-change rounded-3xl flex items-center border-1 px-[13px] ${
        changesRequested || isPlanOrInactive || !isPrimaryAdvisor ? "" : "cursor-pointer"
      } ${getThemeClass(themeState === THEME.DARK)}`}
      onClick={
        changesRequested || isPlanOrInactive || !isPrimaryAdvisor ? undefined : handleSeenComment
      }
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-4"
      >
        <path
          d="M7.83366 1.1665L10.8337 3.99984M10.8337 3.99984L7.83366 6.83317M10.8337 3.99984H3.83366C2.3609 3.99984 1.16699 5.19374 1.16699 6.6665V10.8332"
          stroke={iconFill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {isSummary ? (
        <span className="text-sm font-medium pl-1 whitespace-nowrap" style={{ color: iconFill }}>
          {changesRequested ? "Changes Requested" : "Request Changes"}
        </span>
      ) : (
        <Tooltip
          target=".request-change"
          content={changesRequested ? "Changes Requested" : "Request Changes"}
          position="bottom"
          className="text-sm"
        />
      )}
    </div>
  );

  const headerRightSideContent = () => {
    if (error || loadingPage) return null;

    return (
      <div
        className={`flex gap-2 flex-wrap justify-end ${isSummary ? "h-5" : "align-items-center"}`}
      >
        {userAccess?.userType !== USERTYPE.CONTACT &&
          (changesRequested && isLoggedInUserAdmin ? (
            <>
              <div
                className={`h-7 edit-proposal rounded-3xl flex items-center px-[12px] border-1 ${getThemeClass(
                  themeState === THEME.DARK,
                )} ${
                  openComments.length === 0
                    ? "cursor-pointer transition-transform duration-200 hover:scale-105"
                    : "opacity-75 cursor-not-allowed"
                }`}
                onClick={() => {
                  openComments.length === 0 && handleEditProposal();
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8334 12.8333H9.16669M6.00002 12.1667L3.16669 12.8333L3.83335 10L10.0286 3.80474C10.289 3.54439 10.7111 3.54439 10.9714 3.80474L12.1953 5.02859C12.4556 5.28894 12.4556 5.71105 12.1953 5.9714L6.00002 12.1667Z"
                    stroke={iconFill}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {isSummary ? (
                  <span className="text-sm font-medium pl-1" style={{ color: iconFill }}>
                    Edit
                  </span>
                ) : (
                  <Tooltip
                    target=".edit-proposal"
                    content="Edit"
                    position="bottom"
                    className="text-sm"
                  />
                )}
              </div>
              {openComments.length > 0 && (
                <div
                  className={`h-7 resolve-comment rounded-3xl flex items-center px-[12px] cursor-pointer ${getThemeClass(
                    themeState === THEME.DARK,
                  )} cursor-pointer transition-transform duration-200 hover:scale-105 border-1`}
                  onClick={() => resolveAllComments()}
                >
                  <span className="w-1rem">
                    <CommentIcon fill={isCommentMode ? "#4299e1" : iconFill} />
                  </span>
                  {isSummary ? (
                    <span className="text-sm font-medium pl-1" style={{ color: iconFill }}>
                      Resolve Comment
                    </span>
                  ) : (
                    <Tooltip
                      target=".resolve-comment"
                      content="Resolve Comment"
                      position="bottom"
                      className="text-sm"
                    />
                  )}
                </div>
              )}
            </>
          ) : openComments.length > 0 || changesRequested ? (
            requestChangeButton
          ) : (
            approveButton()
          ))}
        {openComments.length === 0 &&
          userAccess?.userType === USERTYPE.CONTACT &&
          signatureDetails?.advisorApprovalStatus &&
          signatureDetails?.status === PROPOSAL_STATUSES.SENT &&
          approveButton()}
        {addCommentButton}
        {downloadButton}
        {revokeButton()}
        <ThemeSelector themeState={themeState} handleThemeClick={handleThemeClick} />
        {userAccess?.userType === USERTYPE.AGENT && (
          <BrandingSelector themeState={themeState} proposalId={proposalId} />
        )}
      </div>
    );
  };

  const iconSize = () => {
    if (proposalType === ProposalTypes.KeyPersonInsurance) {
      return 30;
    }
    return 48;
  };

  const animationLoop = () => {
    if (
      proposalType === ProposalTypes.KeyPersonInsurance ||
      proposalType === ProposalTypes.SplitDollar
    ) {
      return 1;
    }
    return false;
  };

  const proposalTypeIcon = () => (
    <div
      onMouseEnter={() => {
        setIsHovered?.(true);
        if (proposalType === ProposalTypes.BuySell) {
          if (lottieRef.current) {
            lottieRef.current.playSegments([0, 150], true); // Play first bounce
            setTimeout(() => {
              lottieRef.current.playSegments([80, 150], true);
              setTimeout(() => {
                lottieRef.current.playSegments([0, 150], true); // Play second bounce
              }, 1000); // Play second bounce
            }, 1000);
          }
        }
      }}
      onMouseLeave={() => setIsHovered?.(false)}
      style={{ width: iconSize(), height: iconSize() }}
    >
      <Lottie
        lottieRef={lottieRef}
        animationData={animationData}
        loop={animationLoop()} // Ensure animation does not loop indefinitely
        autoplay={false} // Prevent auto-play
      />
    </div>
  );

  useEffect(() => {
    if (!summaryLoading) {
      setIsCurrPageLoading(false, true);
    }
  }, [summaryLoading]);

  const versionDropdown =
    versionList.length > 1 ? (
      <Dropdown
        value={
          selectedVersion
            ? {
                name: `Proposal - ${selectedVersion.proposalNo}`,
                code: selectedVersion.id,
              }
            : null
        }
        onChange={(e) => {
          setIsCurrPageLoading(true);
          setSelectedVersion(versionList.find((el) => el.id === e.value?.code) || null);
        }}
        options={versionList.map((el: any) => ({
          name: `Proposal - ${el.proposalNo}`,
          code: el.id,
        }))}
        optionLabel="name"
        className="w-auto shadow-none bg-transparent border-none proposal-version-dropdown"
      />
    ) : (
      `Proposal - ${proposalNo}`
    );

  const leftSideContent = () => {
    if (error || loadingPage) return null;
    if (isSummary) {
      return (
        <div
          style={{
            backgroundImage: `url(${
              themeState === THEME.DARK ? SummaryDetailDark : SummaryDetail
            })`,
            backgroundSize: "cover",
            height: 250,
            width: 500,
            marginLeft: "-30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "20px",
            paddingTop: "5px",
          }}
          className="flipup"
        >
          <div className="ml-10 pr-3">
            <div className="inline-flex items-center px-3 py-2 bg-white/10 backdrop-blur-sm rounded-2xl border-1 shadow-sm h-[44px]">
              {proposalTypeIcon()}
              <span className="whitespace-nowrap ml-2">{getProposalType(proposalType)}</span>
            </div>
            <h2 className="text-2xl interactive-proposal-text">{title}</h2>
            <p>{subtitle}</p>
            <p>{versionDropdown}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="flex items-center flipup mt-3 ml-1">
        <div className="w-12 h-12 bg-white rounded-2xl flex items-center justify-center shadow-sm border-1">
          {proposalTypeIcon()}
        </div>
        {/* Vertical Divider */}
        <div className="h-12 w-px bg-gray-300 mx-2 w-1rem" />{" "}
        {/* Adjust height and color as needed */}
        <div>
          <h1 className="whitespace-nowrap interactive-proposal-text text-base">{title}</h1>
          <p className="whitespace-nowrap text-xs">{subtitle}</p>
        </div>
        <div
          className={`${
            themeState === THEME.DARK ? "bg-[#1E1E29]" : "bg-white"
          } px-3 py-2 rounded-full shadow-sm ml-3`}
        >
          <span className="whitespace-nowrap text-sm">{versionDropdown}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full fixed z-50 conthrax-font">
      <Toast ref={toast} position="top-center" />
      <div className="h-full">
        <header
          className={`p-3 flex justify-between ${isSummary ? "h-154" : "h-70"}`}
          style={backgroundStyle}
        >
          <div className="flex" style={{ marginTop: "-12.5px" }}>
            <div className={`${isCollapsed ? "w-[5rem]" : "w-[9rem]"}`} />
            {leftSideContent()}
          </div>
          {headerRightSideContent()}
        </header>
      </div>
      {isDownloadPopupOpen && (
        <InteractiveProposalDownload setIsDownloadPopupOpen={setIsDownloadPopupOpen} />
      )}
      {openRevokePopup && (
        <BlockPopup
          visible={openRevokePopup}
          footerContent={revokePopupFooterContent}
          header="Confirm Revoke"
          className="!w-[50vw]"
          content="Are you sure you want to revoke the proposal?"
        />
      )}
    </div>
  );
};

export default InteractiveProposalHeader;
