import {
  createContext,
  useState,
  useContext,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { CommentStatus, IComment, IPosition, TOASTTYPE } from "app/utils/types";
import { makeRequest } from "app/utils/makeRequest";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { getSessionStore } from "app/utils/sessionStore";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { useIPContext } from "app/pages/InteractiveProposal/InteractiveProposalProvider";

interface ILoggedInUserInfo {
  firstName: string;
  lastName: string;
}

// eslint-disable-next-line no-spaced-func
const CommentContext = createContext<{
  loggedInUserInfo: ILoggedInUserInfo | null;
  commentModeActive: boolean;
  loading: boolean;
  comments: IComment[];
  openComments: IComment[];
  addComment: (position: IPosition, content: string) => void;
  editComment: (commentId: number, newContent: string) => void;
  deleteComment: (commentId: number) => void;
  seenComment: (commentId: number) => void;
  resolveAllComments: () => void;
  resolveComment: (commentId: number) => void;
  replyComment: (commentId: number, newContent: string) => void;
  getComments: () => void;
  isCurrPageLoading: boolean;
  setIsCurrPageLoading: (val: boolean, delay?: boolean) => void;
}>({
  loggedInUserInfo: null,
  commentModeActive: false,
  loading: false,
  comments: [],
  openComments: [],
  addComment: () => {},
  editComment: () => {},
  deleteComment: () => {},
  resolveAllComments: () => {},
  resolveComment: () => {},
  replyComment: () => {},
  seenComment: () => {},
  getComments: () => {},
  isCurrPageLoading: false,
  setIsCurrPageLoading: () => {},
});

export const CommentProvider = ({
  children,
  commentModeActive,
}: {
  children: React.ReactNode;
  commentModeActive: boolean;
}) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState<IComment[]>([]);
  const [loading, setLoading] = useState(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState<ILoggedInUserInfo | null>(null);

  // To show comments only after the page is fully loaded
  const [isCurrPageLoading, setIsCurrPageLoading] = useState(false);

  const openComments = useMemo(
    () => comments.filter((comment) => comment.status === CommentStatus.OPEN),
    [comments],
  );
  const toast = useRef<Toast>(null);
  const { selectedVersion, fetchProposalSummary } = useIPContext();
  const proposalId = selectedVersion?.id || getSessionStore("interactiveProposalID");
  const { profile } = getSessionStore("lookUpResponse");
  const userAccess = getObjectFromLocalStore("useraccess");

  const getComments = useCallback(async () => {
    setLoading(true);
    makeRequest("proposalComment")
      .get(proposalId, true)
      .then((res) => {
        setComments(
          res.data?.data?.map((el: IComment) => ({
            ...el,
            coordinates: JSON.parse(el.coordinates as unknown as string),
          })),
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [proposalId, setLoading, setComments]);

  const getLoggedInUserInfo = () => {
    setLoggedInUserInfo({
      firstName: profile?.firstName || "John",
      lastName: profile?.lastName || "Doe",
    });
  };

  useEffect(() => {
    getComments();
    getLoggedInUserInfo();
  }, [proposalId]);

  const addComment = async (
    position: IPosition | Record<string, never>,
    content: string,
    parentId: number = 0,
  ) => {
    const body = {
      comment: content,
      parentId,
      coordinates: JSON.stringify(parentId ? {} : position),
      section: "summary",
    };

    return makeRequest("proposalComment")
      .post(body, proposalId, true)
      .then(() => {
        toast?.current?.show({
          severity: TOASTTYPE.SUCCESS,
          summary: t("agentBranding.label.success"),
          detail: "Comment added successfully",
          life: 3000,
        });

        getComments();
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error adding comment",
          life: 3000,
        });
        fetchProposalSummary();
        getComments();
      });
  };

  const editComment = async (commentId: number, newContent: string) => {
    const body = {
      comment: newContent,
    };

    return makeRequest("proposalComment")
      .put(`${proposalId}/${commentId}`, body, true)
      .then(() => {
        getComments();
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error editing comment",
          life: 3000,
        });
      });
  };

  const replyComment = async (commentId: number, newContent: string) =>
    addComment({}, newContent, commentId);

  const seenComment = async (commentId: number) => {
    const body = {
      seenBy: {
        [userAccess?.userType]: true,
      },
    };

    return makeRequest("proposalComment")
      .put(`${proposalId}/${commentId}`, body, true)
      .then(() => {
        getComments();
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error seen comment",
          life: 3000,
        });
      });
  };

  const deleteComment = async (commentId: number) => {
    const body = {
      status: CommentStatus.DELETED,
    };

    return makeRequest("proposalComment")
      .put(`${proposalId}/${commentId}`, body, true)
      .then(() => {
        getComments();
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error deleting comment",
          life: 3000,
        });
      });
  };

  const resolveComment = async (commentId: number) => {
    const body = {
      status: CommentStatus.RESOLVED,
    };

    return makeRequest("proposalComment")
      .put(`${proposalId}/${commentId}`, body, true)
      .then(() => {
        getComments();
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error resolving comment",
          life: 3000,
        });
      });
  };

  const resolveAllComments = async () =>
    makeRequest("proposalResolveAllComments")
      .put(`${proposalId}`, {}, true)
      .then(() => {
        toast?.current?.show({
          severity: TOASTTYPE.SUCCESS,
          summary: t("agentBranding.label.success"),
          detail: "All comments resolved successfully",
          life: 3000,
        });
        getComments();
      })
      .catch((err) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: err?.message || "Error resolving all comments",
          life: 3000,
        });
      });

  const value = useMemo(
    () => ({
      loading,
      commentModeActive,
      comments,
      addComment,
      editComment,
      deleteComment,
      resolveAllComments,
      seenComment,
      resolveComment,
      replyComment,
      loggedInUserInfo,
      openComments,
      getComments,
      isCurrPageLoading,
      setIsCurrPageLoading: (val: boolean, delay?: boolean) => {
        if (delay) {
          setTimeout(() => {
            setIsCurrPageLoading(val);
          }, 50);
        } else {
          setIsCurrPageLoading(val);
        }
      },
    }),
    [
      loading,
      commentModeActive,
      comments,
      addComment,
      editComment,
      deleteComment,
      seenComment,
      resolveAllComments,
      resolveComment,
      replyComment,
      loggedInUserInfo,
      openComments,
      getComments,
      isCurrPageLoading,
      setIsCurrPageLoading,
    ],
  );

  return (
    <CommentContext.Provider value={value}>
      <Toast ref={toast} />
      {children}
    </CommentContext.Provider>
  );
};

export const useComments = () => useContext(CommentContext);
