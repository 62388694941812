import { ProposalFilter } from "app/utils/types";

export const fundingColumnJson: any = (selectedFundingData: any) => [
  {
    field: "planYear",
    header: "Plan Year",
    formatter: (_: unknown, options: any) => (options?.rowIndex ?? 0) + 1,
  },
  {
    field: "annualLifePremium",
    header: "Annual Life Premium",
    style: { opacity: !selectedFundingData?.includes(ProposalFilter.LIFE) ? 0.5 : 1 },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "annualDisabilityPremium",
    header: "Annual Disability Premium",
    style: { opacity: !selectedFundingData?.includes(ProposalFilter.DISABILITY) ? 0.5 : 1 },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "totalAnnualPremium",
    header: "Total Annual Premium",
    // style: { opacity: isOpacity ? 0.5 : 1, textAlign: "center" },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "annualAdminFees",
    header: "Annual Admin Fees",
    style: { opacity: !selectedFundingData?.includes(ProposalFilter.ADMIN_FEES) ? 0.5 : 1 },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "planOriginationFees",
    header: "Plan Origination Fees",
    style: {
      opacity: !selectedFundingData?.includes(ProposalFilter.PLAN_ORIGINATION_FEES) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "totalPlanCosts",
    header: "Total Plan Costs",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];
