import { EffectCallback, useEffect, useRef, MutableRefObject } from "react";

const isProduction = process.env.REACT_APP_ENVIRONMENT === "PROD";

const CommonHookForAPI = (effect: EffectCallback) => {
  const destroyFunc = useRef<MutableRefObject<undefined> | any>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // on production call useEffect with default behavior
    if (isProduction) {
      effect();
    }
    // if not a production render useEffect call only once
    if (isProduction === false) {
      // only execute the effect first time around
      if (!effectCalled.current) {
        destroyFunc.current = effect();
        effectCalled.current = true;
      }
    }

    return () => {
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

export default CommonHookForAPI;
