import { DateTime } from "luxon";

export function formatDate(dateString: any, format = "yyyy-MM-dd") {
  return dateString && DateTime.fromISO(dateString, { zone: "utc" }).toFormat(format);
}

export function formatDateFromJSDate(dateString: any, format = "yyyy-MM-dd") {
  return dateString && DateTime.fromJSDate(dateString).toFormat(format);
}

export function getEndDate(date: any) {
  const originalTime = DateTime.fromISO(date, { zone: "Asia/Kolkata" });
  return originalTime.endOf("day");
}

export function getDiffs(dt: string, diffIn: string[] = ["day"]) {
  const date = DateTime.fromJSDate(new Date(dt));
  return date.diffNow(diffIn).toObject();
}

export function formatDateToLocalTime(date: string, format: Intl.DateTimeFormatOptions) {
  const selectedDateLocal = new Date(date);
  const selectedDateInLocalTimezone = selectedDateLocal.toLocaleString(undefined, format);
  return selectedDateInLocalTimezone;
}

export function formatDateToSubmit(date: string, format: string) {
  const selectedDateObj = new Date(date);

  const formattedDate = DateTime.fromJSDate(selectedDateObj).toFormat(format);
  return formattedDate;
}

// Convert like August 3, 2023
export function formatDateToWordDate(date: string, month = "long" as any) {
  const selectedDateObj = new Date(date);
  return selectedDateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month,
    day: "numeric",
  });
}

export function formatDateToWordDateWithoutLocal(dateObj: any) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = dateObj.getUTCDate();
  const month = monthNames[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();

  return `${month} ${day}, ${year}`;
}

export function formatDateWordDateWithoutLocal(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function customFormatDate(
  dateTimeString: string,
  format: string = "MM/dd/yyyy hh:mm a",
  timezone: string = "America/Chicago",
  dateType?: string,
): string {
  if (dateTimeString) {
    const dateTime = DateTime.fromISO(dateTimeString, { zone: timezone });
    if (dateType) {
      return `${dateTime.toFormat(format)} ${dateType} `;
    }
    return dateTime.toFormat(format);
  }
  return "";
}

export function formatAsUSDWithTwoDecimals(number: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}
