import React from "react";

interface ExitIconProps {
  fill?: string; // Add fill prop
}

const ExitIcon: React.FC<ExitIconProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-667a473b-99c7-4ca8-9ca3-b97ceffa23eb"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 356.87 383.42"
  >
    <defs>
      <style>{`.uuid-d64abc14-0c24-4394-90c4-55de2ffe86e1 { fill: ${fill}; }`}</style>
    </defs>
    <g id="uuid-21bcec0d-db99-42e9-8274-df6a56a4276e" data-name="Layer 1">
      <g id="uuid-07022ef2-dfb2-4035-b096-5636b8d3df18" data-name="23-Exit">
        <path
          className="uuid-d64abc14-0c24-4394-90c4-55de2ffe86e1"
          d="M344.26,203.4H127.76c-6.63,0-12-5.37-12-12s5.37-12,12-12h216.5c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
        />
        <path
          className="uuid-d64abc14-0c24-4394-90c4-55de2ffe86e1"
          d="M286.43,262.34c-3.07,0-6.14-1.17-8.49-3.51-4.69-4.69-4.69-12.28,0-16.97l58.44-58.44c4.69-4.69,12.28-4.69,16.97,0s4.69,12.28,0,16.97l-58.44,58.44c-2.34,2.34-5.42,3.51-8.49,3.51Z"
        />
        <path
          className="uuid-d64abc14-0c24-4394-90c4-55de2ffe86e1"
          d="M344.87,203.52c-3.07,0-6.14-1.17-8.49-3.51l-58.44-58.44c-4.69-4.69-4.69-12.28,0-16.97s12.29-4.69,16.97,0l58.44,58.44c4.69,4.69,4.69,12.28,0,16.97-2.34,2.34-5.41,3.51-8.49,3.51Z"
        />
        <path
          className="uuid-d64abc14-0c24-4394-90c4-55de2ffe86e1"
          d="M173.01,383.42c-8.99,0-17.98-2.31-26-6.94l-121.01-69.87c-16.04-9.26-26-26.52-26-45.03V121.84c0-18.52,9.96-35.77,26-45.03L147.01,6.94c16.04-9.26,35.96-9.26,52,0l71.92,41.52c5.74,3.31,7.71,10.65,4.39,16.39-3.31,5.74-10.65,7.7-16.39,4.39l-71.92-41.52c-8.63-4.99-19.36-4.99-28,0L38,97.59c-8.64,4.99-14,14.28-14,24.25v139.73c0,9.97,5.36,19.26,14,24.25l121.01,69.87c8.64,4.99,19.37,4.99,28,0l71.92-41.52c5.74-3.31,13.08-1.35,16.39,4.39,3.31,5.74,1.35,13.08-4.39,16.39l-71.92,41.52c-8.02,4.63-17.01,6.94-26,6.94Z"
        />
      </g>
    </g>
  </svg>
);

export default ExitIcon;
