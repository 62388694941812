import { useState } from "react";
import { Card } from "primereact/card";
import FileUploadComponent from "./FileUploadComponent";
import InsuredTable from "app/uicomponents/macrocomponents/InteractiveProposal/Funding/InsuredTable";
import { getSessionStore } from "app/utils/sessionStore";
import { getThemeColors } from "app/utils/utils";
import CommonHookForAPI from "app/HOC/CommonHookForAPI";
import { makeRequest } from "app/utils/makeRequest";
import { CurrentProposalType, ProposalTypes } from "app/utils/types";
import { AppendixLifePolicyJson } from "app/Data/AppendixLifePolicyJson";
import { AppendixDisabilityPolicyJson } from "app/Data/AppendixDisabilityPolicyJson";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { useIPContext } from "../../InteractiveProposalProvider";
import { KPAppendixLifePolicyJson } from "app/Data/KPAppendixLifePolicyJson";
import { KPAppendixDisabilityPolicyJson } from "app/Data/KPAppendixDisabilityPolicyJson";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";
import { SplitDollarAppendixInitialPolicyJson } from "app/Data/SplitDollarAppendixInitialPolicyJson";
import { SDAppendixPolicyJson } from "app/Data/SDAppendixPolicyJson";

const InteractiveProposalAppendix = () => {
  const { selectedVersion, summaryData: proposalSummary } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const planInfo = getSessionStore("planInfo");
  const currentProposalType = getSessionStore("currentProposalType");
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const { setIsCurrPageLoading } = useComments();

  const [isLoading, setIsLoading] = useState(false);
  const [appendixData, setAppendixData] = useState<any>([]);
  const fetchAppendixData = async () => {
    setIsLoading(true);
    setIsCurrPageLoading(true);
    makeRequest("interactiveProposalAppendix")
      .get(
        currentProposalType === CurrentProposalType.PLANS
          ? selectedVersion?.id ?? planInfo?.planProposalId
          : proposalId,
        true,
        false,
      )
      .then((response) => {
        setAppendixData(response.data?.data ?? null);
      })
      .finally(() => {
        setIsLoading(false);
        setIsCurrPageLoading(false, true);
      });
  };
  CommonHookForAPI(() => {
    fetchAppendixData();
  });

  return (
    <CommentLayout commentLayoutId="interactive-proposal-appendix">
      <Card className="border border-1 border-gray-300 rounded-2xl w-full min-h-screen  shadow-lg">
        <FileUploadComponent />
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <>
            <div className="p-card-title my-3 conthrax-font">
              {proposalSummary?.proposalType !== ProposalTypes.SplitDollar
                ? "Proposed Life Policy Ledger"
                : `Policy 1 – ${
                    proposalSummary?.insuranceCompanyName ?? "National Life Group"
                  } – Please see full insurance company illustration`}
            </div>

            <InsuredTable
              columns={
                proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
                  ? KPAppendixLifePolicyJson
                  : proposalSummary?.proposalType === ProposalTypes.SplitDollar
                  ? SplitDollarAppendixInitialPolicyJson
                  : AppendixLifePolicyJson
              }
              data={
                proposalSummary?.proposalType === ProposalTypes.SplitDollar
                  ? appendixData?.summary ?? []
                  : appendixData?.lifePolicies ?? []
              }
              hoverColor={primaryColor}
            />
            {proposalSummary?.proposalType !== ProposalTypes.SplitDollar && (
              <div className="p-card-title my-3 conthrax-font mt-3">
                Proposed Disability Policy Ledger
              </div>
            )}
            <div
              className={proposalSummary?.proposalType !== ProposalTypes.SplitDollar ? "" : "mt-4"}
            >
              <InsuredTable
                columns={
                  proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance
                    ? KPAppendixDisabilityPolicyJson
                    : proposalSummary?.proposalType === ProposalTypes.SplitDollar
                    ? SDAppendixPolicyJson
                    : AppendixDisabilityPolicyJson
                }
                data={
                  proposalSummary?.proposalType === ProposalTypes.SplitDollar
                    ? appendixData?.appendix ?? []
                    : appendixData?.disabilityPolicies ?? []
                }
                hoverColor={primaryColor}
                scrollable
              />
            </div>
          </>
        )}
      </Card>
    </CommentLayout>
  );
};

export default InteractiveProposalAppendix;
