import React from "react";

interface SolutionProps {
  fill?: string; // Optional prop for fill color
}

const SolutionIcon: React.FC<SolutionProps> = ({ fill = "#fff" }) => (
  <svg
    id="uuid-ea56f437-53be-443c-97f4-d86cda3cbe89"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 274.18 446.56"
  >
    <defs>
      <style>{`.uuid-f525e0e2-4d7a-4500-acf5-1d26a8709a2a { fill: ${fill}; }`}</style>
    </defs>
    <g id="uuid-73010466-4c4b-411d-8dba-d83d343afb7b" data-name="Layer 1">
      <g id="uuid-d5c6474a-5fbd-46b9-ad90-9333e03b23ec" data-name="13-Solution">
        <path
          className="uuid-f525e0e2-4d7a-4500-acf5-1d26a8709a2a"
          d="M116.41,181.49c21.09-21.26,41.36-41.68,61.63-62.1,2.43-2.45,4.83-4.92,7.33-7.29,7.09-6.72,15.23-7.35,20.7-1.66,5.54,5.77,4.79,13.48-2.38,20.68-25.2,25.31-50.45,50.57-75.76,75.76-8.33,8.29-14.86,8.2-23.39-.2-11.38-11.21-22.67-22.52-33.89-33.89-7.38-7.47-8.13-14.61-2.46-20.61,5.75-6.08,13.74-5.36,21.3,2.14,8.66,8.6,17.19,17.34,26.93,27.17Z"
        />
        <path
          className="uuid-f525e0e2-4d7a-4500-acf5-1d26a8709a2a"
          d="M173.96,350.93h-73.72c-16.22,0-29.42-13.2-29.42-29.42v-44.61c0-1.93-1.04-3.73-2.71-4.7l-53.39-30.81c-9.08-5.24-14.71-15-14.71-25.48v-124.33c0-10.48,5.64-20.24,14.71-25.48L122.38,3.93c9.08-5.24,20.35-5.24,29.42,0l107.66,62.17c9.07,5.24,14.71,15,14.71,25.48v124.33c0,10.48-5.64,20.24-14.71,25.48l-53.37,30.81c-1.67.97-2.71,2.76-2.71,4.7v44.61c0,16.22-13.2,29.42-29.42,29.42ZM137.1,23.99c-.94,0-1.88.24-2.71.72L26.71,86.88c-1.67.97-2.71,2.76-2.71,4.69v124.33c0,1.93,1.04,3.73,2.71,4.7l53.39,30.81c9.08,5.24,14.71,15,14.71,25.48v44.61c0,2.99,2.43,5.42,5.42,5.42h73.72c2.99,0,5.42-2.43,5.42-5.42v-44.61c0-10.48,5.64-20.24,14.71-25.48l53.37-30.81c1.67-.97,2.71-2.76,2.71-4.69v-124.33c0-1.93-1.04-3.73-2.71-4.7l-107.66-62.17c-.84-.48-1.77-.72-2.71-.72Z"
        />
        <path
          className="uuid-f525e0e2-4d7a-4500-acf5-1d26a8709a2a"
          d="M182.02,401.06h-89.86c-6.63,0-12-5.37-12-12s5.37-12,12-12h89.86c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
        />
        <path
          className="uuid-f525e0e2-4d7a-4500-acf5-1d26a8709a2a"
          d="M164.48,446.56h-54.78c-6.63,0-12-5.37-12-12s5.37-12,12-12h54.78c6.63,0,12,5.37,12,12s-5.37,12-12,12Z"
        />
      </g>
    </g>
  </svg>
);

export default SolutionIcon;
