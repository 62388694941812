import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore } from "app/utils/sessionStore";
import React, { useEffect, useState } from "react";

const ExperienceTab: React.FC = () => {
  const [videoUrl, setVideoUrl] = useState<string>("");
  const proposalType =
    Object.keys(getSessionStore("proposalType") || {}).length > 0
      ? getSessionStore("proposalType")
      : getSessionStore("selectedProposalRow")?.proposalType;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeRequest("interactiveProposalSolution").get(
          `proposalType=${proposalType}`,
          false,
          true,
        );

        setVideoUrl(response?.data?.data?.experience || "");
      } catch (error) {
        // console.error(error);
        // Handle error (e.g., show a toast message)
      }
    };

    fetchData();
  }, []);

  return (
    <CommentLayout
      commentLayoutId="interactive-proposal-solution-experience"
      className="min-h-screen flex flex-col items-center p-4"
    >
      <div className="w-full max-w-6xl">
        {videoUrl && (
          <div className="relative w-full aspect-video rounded-2xl overflow-hidden bg-gray-600">
            <video
              className="absolute inset-0 w-full h-full object-cover"
              controls
              playsInline
              controlsList="nodownload"
              aria-label="Experience video content"
            >
              <source src={videoUrl} type="video/mp4" />
              <track kind="captions" src="" label="English captions" srcLang="en" default />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </CommentLayout>
  );
};

export default ExperienceTab;
