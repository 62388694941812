import { Outlet, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { useEffect, useRef, useState } from "react";
import {
  getObjectFromSessionStore,
  getSessionStore,
  setObjectInSessionStore,
} from "app/utils/sessionStore";
import PageHeader from "./InteractiveProposalHeader";
import InteractiveProposalSidebar from "./InteractiveProposalSidebar";
import { ErrorPage } from "app/uicomponents/macrocomponents/ErrorPage";
import { CommentProvider } from "app/uicomponents/macrocomponents/Comments/CommentContext";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { CONTACT_PROFILE_LOOKUP } from "app/redux";
import { CurrentProposalType, ProposalTypes } from "app/utils/types";
import useSignatureProposalStore from "app/zustand/Interactive Proposal/SignatureProposalStore";
import CommonHookForAPI from "app/HOC/CommonHookForAPI";
import { useIPContext } from "./InteractiveProposalProvider";
import { makeRequest } from "app/utils/makeRequest";

// Define a custom type for the Lottie reference
type LottieRef = {
  play: () => void;
  stop: () => void;
};

const InteractiveProposalLayout = () => {
  const lookUpResponse = useSelector((state: RootState) => state?.contactProfileLookup);
  const { isLoading } = lookUpResponse;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [animationData, setAnimationData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const lottieRef = useRef<LottieRef | null>(null);
  const [isCommentMode, setIsCommentMode] = useState(false);
  const {
    summaryData: proposalSummary,
    summaryError,
    summaryLoading,
    selectedVersion,
  } = useIPContext();

  const getlookUpResponse = getObjectFromSessionStore("lookUpResponse");
  const dispatch = useDispatch();
  const isSummary = window.location.href.includes("summary");

  const { setSignatureDetails } = useSignatureProposalStore();
  const currentProposalType = getSessionStore("currentProposalType");
  const planInfo = getSessionStore("planInfo");
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const location = useLocation();
  const pathName = location.pathname.split("/")[2];

  useEffect(() => {
    if (!getlookUpResponse?.menu) {
      dispatch({
        type: CONTACT_PROFILE_LOOKUP,
        payload: {
          address: "lookupAfter",
        },
      });
    }
  }, []);

  const fetchApprovalDetails = async () => {
    const response = await makeRequest("interactiveProposalApprovalDetails").get(
      currentProposalType === CurrentProposalType.PLANS
        ? selectedVersion?.id ?? planInfo?.planProposalId
        : proposalId,
      true,
      false,
    );
    setSignatureDetails(response?.data?.data ?? []);
  };

  CommonHookForAPI(() => {
    fetchApprovalDetails();
  });

  const animationURL = () => {
    if (proposalSummary?.proposalType === ProposalTypes.KeyPersonInsurance) {
      return "https://iapp.alexmelik.com/wp-content/uploads/2024/08/Key-Person.json";
    }
    if (proposalSummary?.proposalType === ProposalTypes.BuySell) {
      return "https://iapp.alexmelik.com/wp-content/uploads/2023/09/Buy-Sell.json";
    }
    return "https://iapp.alexmelik.com/wp-content/uploads/2023/09/Split-Dollar.json";
  };

  useEffect(() => {
    fetch(animationURL())
      .then((response) => response.json())
      .then((data) => {
        setAnimationData(data);
      });
  }, [proposalSummary?.proposalType]);

  useEffect(() => {
    if (lookUpResponse?.data?.menu) {
      setObjectInSessionStore("lookUpResponse", lookUpResponse?.data);
    }
  }, [lookUpResponse]);

  useEffect(() => {
    if (lottieRef.current) {
      if (isHovered) {
        lottieRef.current.play();
      } else {
        lottieRef.current.stop();
      }
    }
  }, [isHovered]);

  const loadingPage = isLoading || summaryLoading;

  const mainContent = () => {
    if (loadingPage) {
      return (
        <div className="flex justify-center items-center h-full">
          <ProgressSpinner />
        </div>
      );
    }
    if (summaryError) {
      return <ErrorPage errorMsg={summaryError} showRefresh />;
    }
    return <Outlet />;
  };

  return (
    <CommentProvider commentModeActive={isCommentMode}>
      <div className={`flex h-screen ${isCommentMode ? "comment-mode" : ""}`}>
        <PageHeader
          title={proposalSummary?.employerName}
          subtitle={`${proposalSummary?.primaryContact?.firstName} ${proposalSummary?.primaryContact?.lastName}`}
          proposalNo={proposalSummary?.proposalNo}
          proposalType={proposalSummary?.proposalType}
          error={summaryError}
          loadingPage={loadingPage}
          isCollapsed={isCollapsed}
          animationData={animationData}
          setIsHovered={setIsHovered}
          lottieRef={lottieRef}
          setIsCommentMode={setIsCommentMode}
          isCommentMode={isCommentMode}
        />
        <CommentLayout
          commentLayoutId={`interactive-proposal-layout-${pathName}`}
          className="flex flex-1 overflow-x-hidden"
        >
          <InteractiveProposalSidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          <div
            className={`flex-1 overflow-auto ${
              isSummary ? "h-[calc(100vh-154px)]" : "h-[calc(100vh-70px)]"
            } mt-auto ${isSummary ? "p-4" : "py-4 pl-6 pr-4"} scrollbar-hide`}
          >
            {mainContent()}
          </div>
        </CommentLayout>
      </div>
    </CommentProvider>
  );
};

export default InteractiveProposalLayout;
