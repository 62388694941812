import React from "react";

interface NewIconProps {
  fill?: string; // Optional prop for fill color
}

const FirmIcon: React.FC<NewIconProps> = ({ fill = "#001934" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1.00167C8.11693 1.00167 8.23254 1.0313 8.3337 1.08681L14.1148 4.26795C14.3207 4.38105 14.4485 4.59224 14.4485 4.81887V11.1807C14.4485 11.4073 14.3207 11.6185 14.1148 11.7316L8.3337 14.9128C8.23254 14.9687 8.11693 14.9979 8 14.9979C7.88307 14.9979 7.76746 14.9683 7.6663 14.9128L1.88474 11.7321C1.67891 11.6189 1.55103 11.4078 1.55103 11.1811V4.81887C1.55103 4.59224 1.67891 4.38105 1.88474 4.26795L7.66586 1.08681C7.76702 1.03088 7.88263 1.00167 7.99956 1.00167M8 0C7.70308 0 7.4066 0.0730384 7.14078 0.219533L1.35922 3.40067C0.827572 3.69324 0.5 4.23372 0.5 4.81887V11.1807C0.5 11.7659 0.827572 12.3068 1.35922 12.5993L7.14034 15.7805C7.40617 15.927 7.70308 16 7.99956 16C8.29604 16 8.59296 15.927 8.85878 15.7805L14.6399 12.5993C15.1716 12.3068 15.4991 11.7659 15.4991 11.1807V4.81887C15.4991 4.23372 15.1716 3.69282 14.6399 3.40025L8.85922 0.219533C8.5934 0.0730384 8.29648 0 8 0Z"
      fill="url(#paint0_linear_2907_12145)"
    />
    <path
      d="M10.187 11.7321C10.0797 11.7321 9.97154 11.7074 9.87213 11.6573L7.99034 10.7145L6.10899 11.6573C5.87951 11.7721 5.60668 11.7533 5.39735 11.6081C5.18758 11.4629 5.08467 11.2216 5.12846 10.9783L5.488 8.98164L3.96576 7.56762C3.78007 7.39525 3.71482 7.14232 3.79496 6.90735C3.87511 6.67237 4.084 6.50459 4.34063 6.46912L6.44444 6.1778L7.38512 4.36102C7.49986 4.1394 7.73196 4.00209 7.99122 4.00209C8.25047 4.00209 8.48257 4.1394 8.59731 4.36102L9.53799 6.1778L11.6418 6.46912C11.8984 6.50459 12.1073 6.67279 12.1875 6.90735C12.2676 7.14232 12.2019 7.39525 12.0162 7.56762L10.494 8.98164L10.8535 10.9783C10.8973 11.2216 10.7944 11.4633 10.5846 11.6081C10.4664 11.6903 10.3276 11.7316 10.1879 11.7316L10.187 11.7321ZM7.99034 9.66653C8.09851 9.66653 8.20668 9.69116 8.30521 9.74082L9.6882 10.4336L9.42412 8.96578C9.38646 8.75668 9.45916 8.54383 9.61857 8.39566L10.7375 7.35601L9.19115 7.14191C8.97087 7.11144 8.7808 6.97997 8.68227 6.78965L7.99078 5.45409L7.29928 6.78965C7.20075 6.97955 7.01069 7.11144 6.79085 7.14191L5.24408 7.35601L6.36299 8.39566C6.5224 8.54383 6.59509 8.7571 6.55743 8.96578L6.29336 10.4336L7.67634 9.74082C7.77488 9.69157 7.88261 9.66695 7.99078 9.66695L7.99034 9.66653Z"
      fill="url(#paint1_linear_2907_12145)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2907_12145"
        x1="17.8533"
        y1="5.14286"
        x2="-0.0313582"
        y2="5.14286"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fill} />
        <stop offset="1" stopColor={fill || "#0184E5"} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2907_12145"
        x1="13.5488"
        y1="6.48672"
        x2="3.4616"
        y2="6.48672"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fill} />
        <stop offset="1" stopColor={fill || "#0184E5"} />
      </linearGradient>
    </defs>
  </svg>
);

export default FirmIcon;
