const BuySellDisclosure = () => (
  <div>
    <h2 className="text-xl font-extrabold opacity-60 conthrax-font">Disclosures</h2>
    <h2 className="text-xl font-bold mt-3 conthrax-font">GENERAL RISKS & CONSIDERATIONS</h2>
    <p className="mt-1 text-lg">
      This proposal is for informational and illustrative purposes only and does not constitute an
      offer or commitment for financing, insurance coverage, or investment. All projections,
      including policy performance, interest rates, and collateral requirements, are hypothetical
      and subject to change based on market conditions, underwriting decisions, and lender terms.
    </p>
    <p className="mt-1 text-lg">
      The Insurance Advanced Planning Platform LLC (IAPP) offers a variety of advanced life
      insurance solutions, some of which may involve premium financing while others do not.
      Regardless of the strategy employed, policy performance is dependent on the insurance
      company’s future crediting rates, dividend scales, and other factors that are not guaranteed.
      Clients are strongly encouraged to obtain and review the insurance carrier’s official
      illustration for a detailed understanding of policy mechanics, potential risks, and projected
      outcomes.
    </p>
    <p className="mt-1 text-lg">
      For strategies involving financing, collateral requirements are estimates and will ultimately
      be determined by the lending institution. Lenders will maintain a secured collateral position,
      and additional collateral obligations may arise in the future. Borrowers should consider the
      risks of potential interest rate fluctuations, policy underperformance, and collateral
      shortfalls, which may require additional out-of-pocket contributions.
    </p>

    <h2 className="text-xl font-bold mt-4 conthrax-font">NON-RELIANCE</h2>
    <p className="mt-1 text-lg">
      No representation or warranty is made with regard to any computations, graphs, tables,
      diagrams, or commentary in this material, which are provided for illustration and reference
      purposes only. The views, opinions, estimates, and strategies expressed in this material
      constitute our judgment based on current market conditions and are subject to change without
      notice. The Insurance Advanced Planning Platform LLC (IAPP) assumes no duty to update any
      information in this material in the event such information changes.
    </p>
    <p className="mt-1 text-lg">
      The views, opinions, estimates, and strategies expressed herein may differ from those
      expressed by other areas of IAPP, views expressed for other purposes, or in other contexts,
      and this material should not be regarded as a research report.
    </p>

    <p className="mt-1 text-lg">
      Nothing in this document shall be construed as giving rise to any duty of care owed to, or
      advisory relationship with, you or any third party. Clients and advisors should conduct their
      own due diligence and consult with qualified professionals before implementing any life
      insurance or financing strategy.
    </p>
    <p className="mt-1 text-lg">
      Insurance Advanced Planning Platform, also known as IAPP, is a Wyoming LLC. IAPP logos,
      including but not limited to Hexagon Bee, are registered trademarks and may not be duplicated.
    </p>

    <p className="mt-4 text-light-gray text-lg conthrax-font">
      INVESTMENT AND INSURANCE PRODUCTS ARE:
      <span className="mx-1 text-xl">•</span>
      <span> NOT FDIC INSURED</span>
      <span className="mx-1 text-xl">•</span>
      <span> NOT INSURED BY ANY FEDERAL GOVERNMENT AGENCY</span>
      <span className="mx-1 text-xl">•</span>
      <span>
        NOT A DEPOSIT OR OTHER OBLIGATION OF, OR GUARANTEED BY, INSURANCE ADVANCED PLANNING
        PLATFORM, LLC OR ANY OF ITS AFFILIATES
      </span>
      <span className="mx-1 text-xl">•</span>
      <span>
        SUBJECT TO INVESTMENT RISKS, INCLUDING POSSIBLE LOSS OF THE PRINCIPAL AMOUNT INVESTED
      </span>
    </p>
  </div>
);

export default BuySellDisclosure;
