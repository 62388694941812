import { getObjectFromSessionStore, getSessionStore } from "app/utils/sessionStore";

const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue("--text-color");
const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
const getlookUpResponse = getObjectFromSessionStore("planInfo");
const globalTheme = getSessionStore("globalTheme");

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
  }[];
}

export const chartConfig: ChartData = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: [],
    },
  ],
};

export const barGraphConfig = {
  labels: [],
  datasets: [
    {
      label: "Paid by Employer",
      data: [],
      backgroundColor: ["rgb(0, 36, 70)"],
      barThickness: 30,
      borderWidth: 0.4,
      borderColor: getlookUpResponse?.branding?.secondaryColor || "rgb(0, 36, 70)",
    },
    {
      label: "Paid by Employee",
      data: [],
      backgroundColor: ["rgb(12, 97, 245)"],
      barThickness: 30,
      borderWidth: 0.4,
      borderColor: getlookUpResponse?.branding?.secondaryColor || "rgb(0, 36, 70)",
    },
  ],
};

export const intToString = (num: any) => {
  if (num === undefined || num === null || num === "" || num === "NaN") {
    return "0";
  }
  num = num.toString().replace(/[^0-9.]/g, "");
  if (num < 1000) {
    return num;
  }
  const si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
};

export const lineGraphOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.7,
  plugins: {
    borderWidth: 0,
    title: {
      display: true,
      text: "",
      align: "start",
      font: {
        size: 22,
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      stacked: false,
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: true,
        color: "#DBEAFE",
      },
      ticks: {
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = `${(value / 1000).toFixed()}k`;
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      stacked: true,
      title: {
        display: true,
        text: "Cash Value in $",
        font: {
          size: 15,
        },
      },
    },
  },
};

export const barGraphOptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.7,
  plugins: {
    borderWidth: 0,
    title: {
      display: true,
      text: "",
      align: "start",
      font: {
        size: 22,
      },
    },
    legend: {
      display: false,
      position: "bottom",
      align: "end",
      labels: {
        fontColor: textColor,
        color: !isDark ? textColorSecondary : "#ffff",
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      stacked: true,
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
        color: "",
      },
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      stacked: true,
      title: {
        display: true,
        text: "Premium in $",
        font: {
          size: 15,
        },
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 7,
      borderDash: [4, 4],
    },
  },
});

export const deathBenefitLineGraphdata = (isDark: boolean) => ({
  labels: [],
  datasets: [
    {
      label: "Actual Death Benefit",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
      tension: 0.4,
      backgroundColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
    },
    {
      label: "Projected Death Benefit",
      data: [],
      fill: false,
      borderWidth: 1,
      borderDash: [5, 5],
      tension: 0.4,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--gray-50")
        : documentStyle.getPropertyValue("--gray-500"),
    },
  ],
});

export const deathBenefitLineGraphoptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Death Benefit in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
        color: isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const cashValueLineGraphdata = (isDark: boolean) => ({
  labels: [],
  datasets: [
    {
      label: "Actual Cash Value",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
      tension: 0.4,
      backgroundColor: isDark
        ? documentStyle.getPropertyValue("--blue-500")
        : documentStyle.getPropertyValue("--blue-300"),
    },
    {
      label: "Projected Cash Value",
      data: [],
      borderWidth: 1,
      fill: false,
      borderDash: [5, 5],
      tension: 0.4,
      borderColor: isDark
        ? documentStyle.getPropertyValue("--gray-50")
        : documentStyle.getPropertyValue("--gray-500"),
    },
  ],
});

export const cashValueLineGraphoptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: globalTheme === "light" ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Cash Value in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
        color: isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const barGraphBSConfigPolicy = {
  labels: [],
  datasets: [
    {
      label: "Paid by Employer",
      data: [],
      backgroundColor: ["rgb(12, 97, 245)"],
      barThickness: 30,
      borderWidth: 0.4,
      borderColor: ["rgb(12, 97, 245)"],
    },
  ],
};

export const policyBenefitLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Yearly Policy Benefit",
      data: [],
      fill: true,
      borderWidth: 1,
      borderColor: documentStyle.getPropertyValue("--blue-400"),
      tension: 0.4,
      backgroundColor: documentStyle.getPropertyValue("--blue-100"),
    },
  ],
};

export const policyBenefitLineGraphOptions = (isDark: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.9,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: false,
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      ticks: {
        color: !isDark ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        display: true,
        text: "Policy Benefit in $",
        font: {
          size: 15,
        },
        color: !isDark ? textColorSecondary : "#ffff",
      },
      grid: {
        display: true,
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
      border: {
        color: !isDark ? "#82868c" : "#BDBDBD",
      },
    },
  },
});

export const barGraphOptionsPlan = (isDarkMode: boolean) => ({
  maintainAspectRatio: false,
  aspectRatio: 0.7,
  plugins: {
    borderWidth: 0,
    title: {
      display: true,
      text: "",
      align: "start",
      font: {
        size: 22,
      },
    },
    legend: {
      display: false,
      position: "bottom",
      align: "end",
      labels: {
        color: !isDarkMode ? textColor : "#ffff",
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        font: {
          size: 11,
        },
        color: !isDarkMode ? textColorSecondary : "#ffff",
      },
      stacked: true,
      title: {
        display: true,
        text: "Years",
        font: {
          size: 15,
        },
        color: !isDarkMode ? textColorSecondary : "#ffff",
      },
      border: {
        color: !isDarkMode ? "#82868c" : "#BDBDBD",
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
        color: "",
      },
      ticks: {
        display: true,
        font: {
          size: 11,
        },
        color: !isDarkMode ? textColorSecondary : "#ffff",
        callback: (value: any) => {
          const formattedValue = intToString(value);
          return `$${formattedValue}`;
        },
      },
      stacked: true,
      title: {
        display: true,
        text: "Premium in $",
        color: !isDarkMode ? textColorSecondary : "#ffff",
        font: {
          size: 15,
        },
      },
      border: {
        color: !isDarkMode ? "#82868c" : "#BDBDBD",
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 7,
      borderDash: [4, 4],
    },
  },
});

export const barGraphBSConfigPlan = {
  labels: [],
  datasets: [
    {
      label: "Paid by Employer",
      data: [],
      backgroundColor: ["rgb(12, 97, 245)"],
      barThickness: 30,
      borderWidth: 0.4,
      borderColor: ["rgb(12, 97, 245)"],
    },
  ],
};
