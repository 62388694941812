import React, { useState, useRef } from "react";
import { useComments } from "./CommentContext";
import { IPosition } from "app/utils/types";
import AddComment from "./AddComment";
import CommentList from "./CommentList";
import CommentDialog from "./CommentDialog";
import { capitalizeWords } from "app/utils/utils";

interface CommentLayoutProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  commentLayoutId: string;
}

const CommentLayout = ({
  children,
  className = "",
  style = {},
  commentLayoutId,
}: CommentLayoutProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAddComment, setShowAddComment] = useState(false);
  const [commentPosition, setCommentPosition] = useState<IPosition | null>(null);

  const { addComment, commentModeActive, loggedInUserInfo, isCurrPageLoading } = useComments();

  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (!container) return;

  //   // Create ResizeObserver for the container
  //   const resizeObserver = new ResizeObserver(() => {
  //     // Force update of comment positions by triggering a window resize event
  //     window.dispatchEvent(new Event("resize"));
  //   });

  //   // Observe the container
  //   resizeObserver.observe(container);

  //   // Observe all children
  //   Array.from(container.children).forEach((child) => {
  //     resizeObserver.observe(child);
  //   });

  //   // Create MutationObserver for dynamic content
  //   const mutationObserver = new MutationObserver((mutations) => {
  //     // When new elements are added, observe them too
  //     mutations.forEach((mutation) => {
  //       mutation.addedNodes.forEach((node) => {
  //         if (node instanceof HTMLElement) {
  //           resizeObserver.observe(node);
  //         }
  //       });
  //     });

  //     // Force update of comment positions
  //     window.dispatchEvent(new Event("resize"));
  //   });

  //   // Start observing
  //   mutationObserver.observe(container, {
  //     childList: true,
  //     subtree: true,
  //     attributes: true,
  //   });

  //   // eslint-disable-next-line consistent-return
  //   return () => {
  //     resizeObserver.disconnect();
  //     mutationObserver.disconnect();
  //   };
  // }, []);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // Stop event from bubbling to parent CommentLayouts
    e.stopPropagation();

    // Find the closest commentable container to the click target
    const target = e.target as HTMLElement;
    const closestContainer = target.closest("[data-comment-layout-id]") as HTMLElement;

    // Only proceed if this is the closest container
    if (closestContainer?.getAttribute("data-comment-layout-id") !== commentLayoutId) {
      return;
    }

    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const scrollLeft = container.scrollLeft || 0;
    const scrollTop = container.scrollTop || 0;

    // Calculate position relative to the container including scroll
    const x = e.clientX - rect.left + scrollLeft;
    const y = e.clientY - rect.top + scrollTop;
    const xInRootFontSize = x / rootFontSize;
    const yInRootFontSize = y / rootFontSize;

    const position: IPosition = {
      x: xInRootFontSize,
      y: yInRootFontSize,
      relativeX: (x / rect.width) * 100,
      relativeY: (y / rect.height) * 100,
      containerWidth: rect.width,
      containerHeight: rect.height,
      containerId: commentLayoutId,
    };

    if (commentModeActive) {
      setCommentPosition(position);
      setShowAddComment(true);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`relative w-full ${className}`}
      style={{
        ...style,
        position: "relative",
        overflow: "auto",
      }}
      onClick={handleClick}
      data-comment-layout-id={commentLayoutId}
    >
      {children}

      {!isCurrPageLoading && <CommentList commentLayoutId={commentLayoutId} />}

      {showAddComment && commentPosition && (
        <CommentDialog
          commentPosition={commentPosition}
          userName={`${capitalizeWords(loggedInUserInfo?.lastName ?? "")} ${
            loggedInUserInfo?.firstName?.charAt(0).toUpperCase() ?? ""
          }.`}
          commentType="new"
        >
          <AddComment
            onClose={() => setShowAddComment(false)}
            setShowAddComment={setShowAddComment}
            onAddComment={async (content: string) => {
              if (commentPosition && content.length) {
                await addComment(commentPosition, content);
              }
              return true;
            }}
          />
        </CommentDialog>
      )}
    </div>
  );
};

export default CommentLayout;
