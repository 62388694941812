import React from "react";
import { MdSunny } from "react-icons/md";
import { FaRegMoon } from "react-icons/fa6";
import { Tooltip } from "primereact/tooltip";
import { THEME } from "app/utils/types";
import { SwitchToggle } from "app/uicomponents/microcomponents/SwitchToggle";

interface ThemeSelectorProps {
  themeState: string;
  handleThemeClick: (selectedTheme: string) => void;
}

const ThemeSelector = ({ themeState, handleThemeClick }: ThemeSelectorProps) => {
  const isSummary = window.location.href.includes("summary");
  const isDark = themeState === THEME.DARK;

  return (
    <>
      <SwitchToggle
        options={[
          {
            value: THEME.LIGHT,
            label: (
              <>
                <MdSunny className="w-[16px] h-[16px]" />
                {isSummary && "Light"}
              </>
            ),
          },
          {
            value: THEME.DARK,
            label: (
              <>
                <FaRegMoon className="w-[16px] h-[16px]" /> {isSummary && "Dark"}
              </>
            ),
          },
        ]}
        defaultValue={themeState}
        onChange={(value: string) => handleThemeClick(value as "IAPP" | "Advisor")}
        height="h-7"
        activeColor={isDark ? "bg-bluegray-700" : "bg-black-alpha-10"}
        bgColor={isDark ? "bg-blue-1100" : "bg-white"}
        className="ip-mode-tooltip"
      />

      <Tooltip
        target=".ip-mode-tooltip"
        content="Mode"
        position="bottom"
        className="tooltip-wrapper text-sm"
      />
    </>
  );
};

export default ThemeSelector;
