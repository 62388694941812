import { Chart } from "primereact/chart";
import { useContext } from "react";
import { ThemeContext } from "../macrocomponents/RootLayout";

interface ChartWithCustomLegendProps {
  data: any;
  options: any;
  type: "bar" | "line" | "pie" | "doughnut";
  legends?: {
    label: string;
    color: string;
  }[];
  chartWidth?: string;
  chartHeight?: string;
}

const ChartWithCustomLegend = ({
  data,
  options,
  type,
  legends,
  chartWidth,
  chartHeight,
}: ChartWithCustomLegendProps) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <div>
      <Chart
        type={type}
        data={data}
        options={options}
        key={`${isDarkMode}`}
        width={chartWidth}
        height={chartHeight}
      />
      <div className="flex flex-row gap-4 justify-end mt-3">
        {legends?.map((legend) => (
          <div key={legend.label} className="flex flex-row items-center gap-2">
            <div
              className="w-[2.5rem] h-[0.8rem] border"
              style={{ backgroundColor: legend.color }}
            />
            <small className="text-[0.8125rem]">{legend.label}</small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartWithCustomLegend;
