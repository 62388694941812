import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { FC, MutableRefObject, useRef } from "react";
import "../../../css/Popup.css";
import { ApprovalStatus, DocumentViewInterface } from "app/utils/types";
import { getFileName } from "app/utils/utils";
import { customFormatDate } from "app/utils/luxon";
import { getFullName } from "app/utils/helper";
import { Tooltip } from "primereact/tooltip";

const DocumentView: FC<DocumentViewInterface> = ({
  onApprove,
  onReject,
  id,
  docPath,
  doctype,
  onClick,
  createdAt,
  name,
  isRejectDisable,
  disableButton,
  content,
  userAccessBy,
  isApproveBtnDisable = false,
  approveRejectNotApplicable = false,
  letAdminRemoveTheDoc = false,
  status = null,
  onDelete,
  showTooltip,
  tooltipContent,
  approveButtonProps = {},
  tooltipWidth = "",
}) => {
  const menu: MutableRefObject<any> = useRef(null);
  const menuItems = [
    {
      template: () => (
        <Button
          {...(onReject && {
            onClick: (e) => {
              e.stopPropagation();
              onReject(id);
              menu.current?.hide(e);
            },
          })}
          className="w-full p-link align-items-center px-3 py-2 m-0 hover:!bg-white"
        >
          <i className="pi pi-info-circle text-red-600 mr-2" style={{ fontSize: ".8rem" }} />
          <span className="text-red-600">Reject Document</span>
        </Button>
      ),
    },
  ];

  return (
    <div
      className={`flex justify-between items-center p-3 rounded-md mt-4 mb-2 cursor-pointer bg-gray-100 dark:!bg-blue-1100 ${
        showTooltip ? `custom-tooltip-btn-${id}` : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(docPath);
      }}
      onMouseLeave={(e) => {
        if (menu?.current) {
          menu.current?.hide(e);
        }
      }}
    >
      {showTooltip && (
        <Tooltip
          target={`.custom-tooltip-btn-${id}`}
          content={tooltipContent}
          position="top"
          className={tooltipWidth}
        />
      )}
      <div className="flex items-center">
        {doctype === "pdf" ? (
          <i className="pi pi-file-pdf text-3xl" />
        ) : doctype === "png" || doctype === "jpg" || doctype === "jpeg" ? (
          <i className="pi pi-image text-3xl" />
        ) : (
          <i className="pi pi-file-pdf text-3xl" />
        )}
        <div className="ml-3">
          <p className="font-semibold">{name || getFileName(docPath && docPath)}</p>
          <div className="flex justify-content-start">
            <small>{customFormatDate(createdAt, undefined, undefined, "CST")}</small>
            <small className="ml-1">{userAccessBy && `${getFullName(userAccessBy, true)}`}</small>
          </div>
          {status && (
            <small
              className={
                status?.toLowerCase() === ApprovalStatus.REJECTED
                  ? "text-red-400 normal"
                  : "text-layoutBlue normal"
              }
            >
              {status}
            </small>
          )}
        </div>
      </div>
      {!approveRejectNotApplicable ? (
        <div className="flex items-center">
          {onApprove && (
            <Button
              {...approveButtonProps}
              label={doctype === "kpfile" ? "Approve Original Proposal" : "Approve Document"}
              className={`bg-layoutBlue border-layoutBlue theme-button ${approveButtonProps?.className}`}
              size="small"
              disabled={
                ((content === "medicalRecord" || content === "remitPayment") && disableButton) ||
                isApproveBtnDisable
              }
              onClick={(e) => {
                e.stopPropagation();
                onApprove(id);
              }}
            />
          )}
          {letAdminRemoveTheDoc && (
            <Button
              className={`rounded-full
                 !bg-light-gray hover:!bg-gray-700
               border-0  !h-8 !w-8 shrink-0 ml-2`}
              icon="pi pi-times"
              onClick={(e) => {
                e.stopPropagation();
                onDelete && onDelete(id);
              }}
            />
          )}
          {!isRejectDisable && (
            <Button
              icon="pi pi-ellipsis-v"
              type="button"
              size="small"
              disabled={
                (content === "medicalRecord" || content === "remitPayment") && disableButton
              }
              className="text-gray-600 border-0 hover:!bg-transparent"
              onClick={(e) => {
                e.stopPropagation();
                menu.current.toggle(e);
              }}
            />
          )}

          {onReject && <Menu className="text-red-500" model={menuItems} popup ref={menu} />}
        </div>
      ) : (
        // if Admin clicked not applicable checkbox  for signed insurance illustration
        // do not show any check or approve/reject buttons
        <></>
      )}
    </div>
  );
};

export default DocumentView;
