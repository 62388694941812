import { fundingSDOptions, getThemeColors, getUSANumberFormat } from "app/utils/utils";
import IPPageNumber from "../IPPageNuber";
import ChartWithCustomLegend from "app/uicomponents/microcomponents/ChartWithCustomLegend";
import { barGraphOptionsFunding } from "app/uicomponents/macrocomponents/PlansSummary/config";
import { getSessionStore } from "app/utils/sessionStore";
import HexagonImg from "../../images/hexagonImg.png";

const SDIPFunding = ({
  graphData,
  pageNumber,
  isNewPage,
  tableData,
}: {
  graphData?: any;
  tableData?: any;
  pageNumber: string | number;
  isNewPage?: boolean;
}) => {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, ipTooltipColor } = getThemeColors(branding);

  const datasets = [
    {
      backgroundColor: fundingSDOptions(primaryColor)[0].indicatorColor,
      data: graphData?.map((item: any) => item?.employeePremium),
      label: "Employee Premium",
    },
    {
      backgroundColor: fundingSDOptions(primaryColor)[1].indicatorColor,
      data: graphData?.map((item: any) => item?.employerPremium),
      label: "Employer Premium",
    },
    {
      backgroundColor: fundingSDOptions(primaryColor)[2].indicatorColor,
      data: graphData?.map((item: any) => item?.employeeInterestPayment),
      label: "Employee Interest Payment",
    },
    {
      backgroundColor: fundingSDOptions(primaryColor)[3].indicatorColor,
      data: graphData?.map((item: any) => item?.adminFees),
      label: "Admin Fees",
    },
    {
      backgroundColor: fundingSDOptions(primaryColor)[4].indicatorColor,
      data: graphData?.map((item: any) => item?.planOriginationFees),
      label: "Plan Origination Fees",
    },
  ];

  const mainContent = () => {
    if (isNewPage && tableData) {
      return (
        <div>
          <table className="w-full border-collapse">
            <thead className="text-white font-semibold">
              <tr>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  rowSpan={2}
                  style={{ backgroundColor: "#3775b5", fontSize: "9px" }}
                >
                  Year
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  rowSpan={2}
                  style={{ backgroundColor: "#3676b6", fontSize: "9px" }}
                >
                  Plan Year
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  rowSpan={2}
                  style={{ backgroundColor: "#3779b8", fontSize: "9px" }}
                >
                  Insured Age
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  colSpan={3}
                  style={{ backgroundColor: "#3067a0", fontSize: "9px" }}
                >
                  Employee Out of Pocket Payments
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  colSpan={3}
                  style={{ backgroundColor: "#1f4671", fontSize: "9px" }}
                >
                  Employer Out of Pocket Payments
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#132c4c", fontSize: "8px" }}
                >
                  Total Premium Paid to Insurance Company
                </th>
              </tr>
              <tr style={{ fontSize: "9px" }}>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#387cbc" }}
                >
                  EMPLOYEE Premium
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#306aa3" }}
                >
                  EMPLOYEE Interest Payment
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#28598b" }}
                >
                  EMPLOYEE Principal Payment
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#1c426b" }}
                >
                  EMPLOYER Premium
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#183a5f" }}
                >
                  EMPLOYER Admin Fees
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#183a5f" }}
                >
                  Plan Origination Fees
                </th>
                <th
                  className="p-1 text-center pdf_table_border border-white"
                  style={{ backgroundColor: "#0a1a33" }}
                >
                  Total Policy Premium
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((policy: any, index: any) => (
                <tr
                  key={index}
                  style={{ backgroundColor: "#EEEEEE", color: "#000000", fontSize: "8px" }}
                >
                  <td className="p-1 text-center pdf_table_border border-white">
                    {policy?.policyYear}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    {policy?.planYear}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    {policy?.insuredAge}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.employeePremium, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.employeeInterestPayment, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.employeePrincipalPayment, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.employerPremium, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.annualAdminFees, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.planOriginationFees, false)}
                  </td>
                  <td className="p-1 text-center pdf_table_border border-white">
                    ${getUSANumberFormat(policy?.totalPolicyPremium, false)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div>
        <div style={{ width: "503px" }}>
          <ChartWithCustomLegend
            data={{
              labels: graphData?.map((item: any) => `Age ${item?.insuredAge}`),
              datasets,
            }}
            options={barGraphOptionsFunding(false, ipTooltipColor, "Plan Year")}
            type="bar"
            chartWidth="503px"
            chartHeight="450px"
            legends={[]}
          />
          <div className="flex flex-wrap justify-center gap-3">
            {datasets?.map(({ backgroundColor, label }, index) => (
              <div key={index} className="flex items-center">
                <span style={{ color: "#000000", fontSize: "16px" }}>{label}</span>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor,
                    marginLeft: "2px",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full relative min-h-screen">
      <div className="relative z-20 px-[2em] w-full max-w-[56em] mx-auto p-[1.75em]">
        {!isNewPage && (
          <h1 className="text-center text-2xl font-bold mb-5 conthrax-font interactive-proposal-text">
            Plan Funding
          </h1>
        )}
        {mainContent()}
      </div>

      <div className="absolute bottom-0 left-0 w-full">
        <div
          className="h-[350px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${HexagonImg})`,
            backgroundSize: "cover",
          }}
        />
        <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default SDIPFunding;
