import IPPageNumber from "./IPPageNuber";
import HexagonImg from "../images/hexagonImg.png";

const IPDisclosures = ({ pageNumber }: { pageNumber: string | number }) => (
  <div className="flex flex-col h-full relative min-h-screen">
    <div className="relative z-20 px-[4em] w-full max-w-[56em] mx-auto p-[1.75em]">
      <div className="text-center text-2xl font-bold mb-5 conthrax-font interactive-proposal-text">
        Disclosures
      </div>
      <div style={{ color: "#000000", fontSize: "11px" }}>
        <div className="conthrax-font">GENERAL RISKS & CONSIDERATIONS</div>
        <div className="mt-2">
          This proposal is for informational and illustrative purposes only and does not constitute
          an offer or commitment for financing, insurance coverage, or investment. All projections,
          including policy performance, interest rates, and collateral requirements, are
          hypothetical and subject to change based on market conditions, underwriting decisions, and
          lender terms.
        </div>

        <div className="mt-2">
          The Insurance Advanced Planning Platform LLC (IAPP) offers a variety of advanced life
          insurance solutions, some of which may involve premium financing while others do not.
          Regardless of the strategy employed, policy performance is dependent on the insurance
          company’s future crediting rates, dividend scales, and other factors that are not
          guaranteed. Clients are strongly encouraged to obtain and review the insurance carrier’s
          official illustration for a detailed understanding of policy mechanics, potential risks,
          and projected outcomes.
        </div>
        <div className="mt-2">
          For strategies involving financing, collateral requirements are estimates and will
          ultimately be determined by the lending institution. Lenders will maintain a secured
          collateral position, and additional collateral obligations may arise in the future.
          Borrowers should consider the risks of potential interest rate fluctuations, policy
          underperformance, and collateral shortfalls, which may require additional out-of-pocket
          contributions.
        </div>

        <div className="mt-2 conthrax-font">NON-RELIANCE</div>
        <div className="mt-2">
          No representation or warranty is made with regard to any computations, graphs, tables,
          diagrams, or commentary in this material, which are provided for illustration and
          reference purposes only. The views, opinions, estimates, and strategies expressed in this
          material constitute our judgment based on current market conditions and are subject to
          change without notice. The Insurance Advanced Planning Platform LLC (IAPP) assumes no duty
          to update any information in this material in the event such information changes.
        </div>
        <div className="mt-2">
          The views, opinions, estimates, and strategies expressed herein may differ from those
          expressed by other areas of IAPP, views expressed for other purposes, or in other
          contexts, and this material should not be regarded as a research report.
        </div>
        <div className="mt-2">
          Nothing in this document shall be construed as giving rise to any duty of care owed to, or
          advisory relationship with, you or any third party. Clients and advisors should conduct
          their own due diligence and consult with qualified professionals before implementing any
          life insurance or financing strategy.
        </div>
        <div className="mt-2">
          Insurance Advanced Planning Platform, also known as IAPP, is a Wyoming LLC. IAPP logos,
          including but not limited to Hexagon Bee, are registered trademarks and may not be
          duplicated.
        </div>
        <div className="mt-2 conthrax-font">
          INVESTMENT AND INSURANCE PRODUCTS ARE: • NOT FDIC INSURED • NOT INSURED BY ANY FEDERAL
          GOVERNMENT AGENCY • NOT A DEPOSIT OR OTHER OBLIGATION OF, OR GUARANTEED BY, INSURANCE
          ADVANCED PLANNING PLATFORM, LLC OR ANY OF ITS AFFILIATES • SUBJECT TO INVESTMENT RISKS,
          INCLUDING POSSIBLE LOSS OF THE PRINCIPAL AMOUNT INVESTED
        </div>
      </div>
    </div>

    <div className="absolute bottom-0 left-0 w-full">
      <div
        className="h-[350px] bg-cover bg-center"
        style={{
          backgroundImage: `url(${HexagonImg})`,
          backgroundSize: "cover",
        }}
      />
      <div className="px-[4em] w-full max-w-[56em] mx-auto p-[1.75em] pt-1">
        <IPPageNumber number={pageNumber} />
      </div>
    </div>
  </div>
);

export default IPDisclosures;
