import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_PLAN_FINALIZATION = "GET_PLAN_FINALIZATION";
export const SEND_DOC_TO_AGENT = "SEND_DOC_TO_AGENT";
export const VERIFY_PROPOSAL = "VERIFY_PROPOSAL";
export const REJECT_PROPOSAL = "REJECT_PROPOSAL";
export const VERIFY_DELIVERY = "VERIFY_DELIVERY";
export const APPROVE_AGREEMENT = "APPROVE_AGREEMENT";
export const APPROVE_ORIGINAL_AGREEMENT = "APPROVE_ORIGINAL_AGREEMENT";
export const REJECT_AGREEMENT = "REJECT_AGREEMENT";
export const UPDATE_PROPOSAL = "UPDATE_PROPOSAL";
export const CLIENT_INVOICE_GENERATE_PROPOSAL = "CLIENT_INVOICE_GENERATE_PROPOSAL";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface pendingPlanFinalizationResponseType {
  fetchPlanFinalization?: InitialStateInterface;
  sendDataToAgent?: InitialStateInterface;
  verifyProposal?: InitialStateInterface;
  rejectProposal?: InitialStateInterface;
  verifyDelivery?: InitialStateInterface;
  approveAgreement?: InitialStateInterface;
  approveOriginalAgreement?: InitialStateInterface;
  rejectAgreement?: InitialStateInterface;
  updateProposal?: InitialStateInterface;
  clientInvoiceGenerateProposal?: InitialStateInterface;
}

const initialState: pendingPlanFinalizationResponseType = {
  fetchPlanFinalization: { data: {}, error: {}, loading: false },
  sendDataToAgent: { data: {}, error: {}, loading: false },
  verifyProposal: { data: {}, error: {}, loading: false },
  rejectProposal: { data: {}, error: {}, loading: false },
  verifyDelivery: { data: {}, error: {}, loading: false },
  approveAgreement: { data: {}, error: {}, loading: false },
  approveOriginalAgreement: { data: {}, error: {}, loading: false },
  rejectAgreement: { data: {}, error: {}, loading: false },
  updateProposal: { data: {}, error: {}, loading: false },
  clientInvoiceGenerateProposal: { data: {}, error: {}, loading: false },
};

export const planFinalizationSlice = createSlice({
  name: "planFinalization",
  initialState,
  reducers: {
    fetchPlanFinalizationStart: (state) => {
      state.fetchPlanFinalization = { data: {}, error: {}, loading: true };
    },
    fetchPlanFinalizationSuccess: (state, action: PayloadAction<any>) => {
      state.fetchPlanFinalization = { data: action.payload, error: {}, loading: false };
    },
    fetchPlanFinalizationError: (state, action: PayloadAction<any>) => {
      state.fetchPlanFinalization = { data: {}, error: action.payload, loading: false };
    },
    sendDataToAgentStart: (state) => {
      state.sendDataToAgent = { data: {}, error: {}, loading: true };
    },
    sendDataToAgentSuccess: (state, action: PayloadAction<any>) => {
      state.sendDataToAgent = { data: action.payload, error: {}, loading: false };
    },
    sendDataToAgentError: (state, action: PayloadAction<any>) => {
      state.sendDataToAgent = { data: {}, error: action.payload, loading: false };
    },
    verifyProposalStart: (state) => {
      state.verifyProposal = { data: {}, error: {}, loading: true };
    },
    verifyProposalSuccess: (state, action: PayloadAction<any>) => {
      state.verifyProposal = { data: action.payload, error: {}, loading: false };
    },
    verifyProposalError: (state, action: PayloadAction<any>) => {
      state.verifyProposal = { data: {}, error: action.payload, loading: false };
    },
    rejectProposalStart: (state) => {
      state.rejectProposal = { data: {}, error: {}, loading: true };
    },
    rejectProposalSuccess: (state, action: PayloadAction<any>) => {
      state.rejectProposal = { data: action.payload, error: {}, loading: false };
    },
    rejectProposalError: (state, action: PayloadAction<any>) => {
      state.rejectProposal = { data: {}, error: action.payload, loading: false };
    },
    verifyDeliveryStart: (state) => {
      state.verifyDelivery = { data: {}, error: {}, loading: true };
    },
    verifyDeliverySuccess: (state, action: PayloadAction<any>) => {
      state.verifyDelivery = { data: action.payload, error: {}, loading: false };
    },
    verifyDeliveryError: (state, action: PayloadAction<any>) => {
      state.verifyDelivery = { data: {}, error: action.payload, loading: false };
    },
    approveAgreementStart: (state) => {
      state.approveAgreement = { data: {}, error: {}, loading: true };
    },
    approveAgreementSuccess: (state, action: PayloadAction<any>) => {
      state.approveAgreement = { data: action.payload, error: {}, loading: false };
    },
    approveAgreementError: (state, action: PayloadAction<any>) => {
      state.approveAgreement = { data: {}, error: action.payload, loading: false };
    },
    resetApproveAgreement: (state) => {
      state.approveAgreement = { data: {}, error: {}, loading: false };
    },
    approveOriginalAgreementStart: (state) => {
      state.approveOriginalAgreement = { data: {}, error: {}, loading: true };
    },
    approveOriginalAgreementSuccess: (state, action: PayloadAction<any>) => {
      state.approveOriginalAgreement = { data: action.payload, error: {}, loading: false };
    },
    approveOriginalAgreementError: (state, action: PayloadAction<any>) => {
      state.approveOriginalAgreement = { data: {}, error: action.payload, loading: false };
    },
    resetApproveOriginalAgreement: (state) => {
      state.approveOriginalAgreement = { data: {}, error: {}, loading: false };
    },
    rejectAgreementStart: (state) => {
      state.rejectAgreement = { data: {}, error: {}, loading: true };
    },
    rejectAgreementSuccess: (state, action: PayloadAction<any>) => {
      state.rejectAgreement = { data: action.payload, error: {}, loading: false };
    },
    rejectAgreementError: (state, action: PayloadAction<any>) => {
      state.rejectAgreement = { data: {}, error: action.payload, loading: false };
    },
    updateProposalStart: (state) => {
      state.updateProposal = { data: {}, error: {}, loading: true };
    },
    updateProposalSuccess: (state, action: PayloadAction<any>) => {
      state.updateProposal = { data: action.payload, error: {}, loading: false };
    },
    updateProposalError: (state, action: PayloadAction<any>) => {
      state.updateProposal = { data: {}, error: action.payload, loading: false };
    },
    resetUpdateProposal: (state) => {
      state.updateProposal = { data: {}, error: {}, loading: false };
    },
    clientInvoiceGenerateProposalStart: (state) => {
      state.clientInvoiceGenerateProposal = { data: {}, error: {}, loading: true };
    },
    clientInvoiceGenerateProposalSuccess: (state, action: PayloadAction<any>) => {
      state.clientInvoiceGenerateProposal = { data: action.payload, error: {}, loading: false };
    },
    clientInvoiceGenerateProposalError: (state, action: PayloadAction<any>) => {
      state.clientInvoiceGenerateProposal = { data: {}, error: action.payload, loading: false };
    },
    resetClientInvoiceGenerateProposal: (state) => {
      state.clientInvoiceGenerateProposal = { data: {}, error: {}, loading: false };
    },
    resetVerifyProposal: (state) => {
      state.verifyProposal = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchPlanFinalizationStart,
  fetchPlanFinalizationSuccess,
  fetchPlanFinalizationError,
  sendDataToAgentStart,
  sendDataToAgentSuccess,
  sendDataToAgentError,
  verifyProposalStart,
  verifyProposalSuccess,
  verifyProposalError,
  rejectProposalStart,
  rejectProposalSuccess,
  rejectProposalError,
  verifyDeliveryStart,
  verifyDeliverySuccess,
  verifyDeliveryError,
  approveAgreementStart,
  approveAgreementSuccess,
  approveAgreementError,
  rejectAgreementStart,
  rejectAgreementSuccess,
  rejectAgreementError,
  updateProposalStart,
  updateProposalSuccess,
  updateProposalError,
  resetUpdateProposal,
  clientInvoiceGenerateProposalError,
  clientInvoiceGenerateProposalStart,
  clientInvoiceGenerateProposalSuccess,
  resetClientInvoiceGenerateProposal,
  resetVerifyProposal,
  approveOriginalAgreementError,
  approveOriginalAgreementStart,
  approveOriginalAgreementSuccess,
  resetApproveOriginalAgreement,
  resetApproveAgreement,
} = planFinalizationSlice.actions;
export const getPlanFinalizationInfo = (state: RootState) => state.getPlanFinalization;

export default planFinalizationSlice.reducer;
