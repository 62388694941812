import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import FileViewComponent from "./FileViewComponent";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { CurrentProposalType, PROPOSAL_STATUSES, SEVERITY, USERTYPE } from "app/utils/types";
import BlockPopup from "app/uicomponents/microcomponents/BlockPopup";
import { useTranslation } from "react-i18next";
import { getSessionStore } from "app/utils/sessionStore";
import { getFileName, getThemeColors } from "app/utils/utils";
import { makeRequest } from "app/utils/makeRequest";
import CommonHookForAPI from "app/HOC/CommonHookForAPI";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import {
  GET_DOCUMENT,
  getAllPendingProposals,
  resetDocData,
} from "app/redux/features/pending-proposals";
import { formatDateToWordDate } from "app/utils/luxon";
import FilePreview from "app/uicomponents/macrocomponents/ProposalPending/FilePreview";
import { useSelector, useDispatch } from "react-redux";
import { useIPContext } from "../../InteractiveProposalProvider";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";

const FileUploadComponent = () => {
  const userAccess = getObjectFromLocalStore("useraccess");
  const { t } = useTranslation();
  const toast: MutableRefObject<any> = useRef(null);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [onClickData, setOnClickData] = useState<any>(null);
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const planInfo = getSessionStore("planInfo");
  const currentProposalType = getSessionStore("currentProposalType");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [documentList, setDocumentList] = useState<any>([]);
  const { getDocPendingProposals } = useSelector(getAllPendingProposals);
  const dispatch = useDispatch();
  const [fileToDelete, setFileToDelete] = useState<any>(null);
  const { selectedVersion, proposalStatus } = useIPContext();
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(proposalStatus as any);

  const fetchDocumentList = async () => {
    setIsLoading(true);
    try {
      const response = await makeRequest("interactiveProposalAppendixDocumentList").get(
        currentProposalType === CurrentProposalType.PLANS
          ? selectedVersion?.id ?? planInfo?.planProposalId
          : proposalId,
        true,
        false,
      );
      setDocumentList(response.data?.data ?? []);
    } finally {
      setIsLoading(false);
    }
  };
  CommonHookForAPI(() => {
    fetchDocumentList();
  });

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("proposalId", proposalId);

    try {
      setIsUploading(true);
      const response = await makeRequest("interactiveProposalAppendixDocumentUpload", {
        "Content-Type": "multipart/form-data",
      }).post(formData, "", true, false);
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
      fetchDocumentList(); // Refresh the document list after upload
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.current?.show({
          severity: "error",
          summary: "Invalid File Type",
          detail: t("Please upload file in PDF, DOCX, CSV or XLSX format (up to 10 MB)."),
        });
        return;
      }
      if (file.size <= 10 * 1024 * 1024) {
        handleFileUpload(file);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: t("pdf.upload.max.limit"),
        });
      }
    }
  };

  const onRemove = (file: any) => {
    setFileToDelete(file);
    setConfirmationPopUp(true);
  };

  const deleteDocument = async (documentId: string) => {
    try {
      setIsLoading(true);
      const response = await makeRequest("interactiveProposalAppendixDocumentDelete").delete(
        documentId,
        true,
        false,
      );
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
      fetchDocumentList(); // Refresh the document list after deletion
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = () => {
    if (fileToDelete) {
      deleteDocument(fileToDelete.id); // Assuming fileToDelete has an id property
    }
    setConfirmationPopUp(false);
  };

  const deleteFooter = () => (
    <div>
      <Button
        type="button"
        label={t("general.button.cancel") ?? ""}
        onClick={() => setConfirmationPopUp(false)}
        severity={SEVERITY.SECONDARY}
        outlined
      />
      <Button
        type="submit"
        label={t("general.button.remove") ?? ""}
        onClick={confirmDelete}
        severity={SEVERITY.DANGER}
      />
    </div>
  );
  function removeFileExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, "").replace(/_/g, " ");
  }
  const onOpenDoc = (el: any) => {
    const documentData = el;
    const formattedDateSubmitted = formatDateToWordDate(documentData?.createdAt);
    setOnClickData({ ...documentData, formattedDateSubmitted });

    const fileExtension = documentData?.docPath?.split(".").pop()?.toLowerCase();
    if (fileExtension === "pdf") {
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          address: "proposalPendingDocLink",
          key: `${encodeURIComponent(typeof el === "string" ? el : el.docPath)}`,
          requestParams: true,
        },
      });
    } else {
      setIsPreview(true);
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          address: "proposalPendingDocLink",
          key: `${encodeURIComponent(typeof el === "string" ? el : el.docPath)}`,
          requestParams: true,
        },
      });
    }
  };
  useEffect(() => {
    if (isPreview && getDocPendingProposals.data?.data) {
      setIsUploading(true);
      const url = getDocPendingProposals.data.data;
      fetch(url, { method: "get" })
        .then((response) => response.blob())
        .then((response) => {
          const href = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = href;
          const fileName = removeFileExtension(getFileName(onClickData?.docPath));
          link.setAttribute("download", fileName); // Ensure the full file name with extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          dispatch(resetDocData());
          setIsPreview(false);
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  }, [getDocPendingProposals.data]);
  return (
    <div className="flex items-center">
      {isUploading && <ScreenLoader />}
      {userAccess?.userType === USERTYPE.ADMIN && (
        <div>
          <Button
            type="button"
            disabled={documentList?.length === 5 || isPlanOrInactive}
            onClick={() => fileInputRef.current?.click()}
            className="p-button-outlined p-button-rounded"
            style={{ color: primaryColor || "#0053D0", borderColor: primaryColor || "#0053D0" }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.16699 9.83329V10.8333C3.16699 11.9379 4.06242 12.8333 5.16699 12.8333H10.8337C11.9382 12.8333 12.8337 11.9379 12.8337 10.8333V9.83329M8.00033 9.49996V3.33329M5.83366 5.49996L8.00033 3.16663L10.167 5.49996"
                stroke={primaryColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t("general.button.upload")}
          </Button>
          <input type="file" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
        </div>
      )}

      {!isPreview && (
        <FilePreview
          onCancel={() => dispatch(resetDocData())}
          url={getDocPendingProposals.data?.data}
          loading={getDocPendingProposals.loading as boolean}
          {...onClickData}
          downloadFileName={removeFileExtension(getFileName(onClickData?.docPath))}
        />
      )}
      {isLoading && <Skeleton className="!w-56 !h-[50px] ml-2" />}
      <div className="flex flex-wrap">
        <Toast ref={toast} position="top-center" />
        {!isLoading &&
          documentList?.map((file: any) => (
            <div key={file?.id} className="p-2">
              <FileViewComponent
                docPath={file?.docPath}
                canDelete={userAccess?.userType === USERTYPE.ADMIN}
                onDelete={() => onRemove(file)}
                onClick={() => onOpenDoc(file)}
                disabled={isPlanOrInactive}
              />
            </div>
          ))}
      </div>
      {confirmationPopUp && (
        <BlockPopup
          visible={confirmationPopUp}
          footerContent={deleteFooter()}
          className="!w-[50vw]"
          header={t("general.button.confirmation")}
          content={t("renderFileDelete.text.subtitle")}
        />
      )}
    </div>
  );
};

export default FileUploadComponent;
