import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define interfaces for our state and export them
export interface Comment {
  id: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  reported?: boolean | null;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture?: string;
  };
  replyCount: number;
}

export interface Reply {
  id: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  pagination: Pagination;
}

interface Pagination {
  total: number;
  pageSize: number;
  pageNumber: number;
  totalPages: number;
}

interface ReplyPagination {
  pageNumber: number;
  hasMore: boolean;
  loading: boolean;
}

interface RepliesState {
  [commentId: string]: {
    data: Reply[];
    pagination: ReplyPagination;
  };
}

interface CoursesState {
  comments: {
    data: Comment[];
    pagination: Pagination;
    loading: boolean;
    error: null | Error;
  };
  replies: RepliesState;
  commentLoading: boolean;
  replyLoading: boolean;
  error: null | Error;
}

// Action Types
export const FETCH_COURSE_COMMENTS = "FETCH_COURSE_COMMENTS";
export const FETCH_COMMENT_REPLIES = "FETCH_COMMENT_REPLIES";
export const ADD_COURSE_COMMENT = "ADD_COURSE_COMMENT";
export const ADD_COMMENT_REPLY = "ADD_COMMENT_REPLY";
export const DELETE_COURSE_COMMENT = "DELETE_COURSE_COMMENT";
export const DELETE_COMMENT_REPLY = "DELETE_COMMENT_REPLY";

// Action Creators
export const fetchComments = (courseId: string) => ({
  type: FETCH_COURSE_COMMENTS,
  payload: { courseId, pageNumber: 1, pageSize: 10 },
});

export const fetchReplies = (commentId: string, pageNumber: number = 1, pageSize: number = 5) => ({
  type: FETCH_COMMENT_REPLIES,
  payload: { commentId, pageNumber, pageSize },
});

export const addComment = (text: string, courseId: string) => ({
  type: ADD_COURSE_COMMENT,
  payload: { text, courseId },
});

export const addReply = (text: string, commentId: string, isReply: boolean) => ({
  type: ADD_COMMENT_REPLY,
  payload: { text, commentId, isReply },
});

export const deleteComment = (commentId: string, courseId?: string) => ({
  type: DELETE_COURSE_COMMENT,
  payload: { commentId, courseId },
});

export const deleteReply = (replyId: string, commentId: string) => ({
  type: DELETE_COMMENT_REPLY,
  payload: { replyId, commentId },
});

// Initial state
const initialState: CoursesState = {
  comments: {
    data: [],
    pagination: {
      total: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPages: 0,
    },
    loading: false,
    error: null,
  },
  replies: {},
  commentLoading: false,
  replyLoading: false,
  error: null,
};

// Slice
const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    // Comments
    fetchCommentsStart: (state) => {
      state.comments.loading = true;
      state.error = null;
    },
    fetchCommentsSuccess: (
      state,
      action: PayloadAction<{ data: Comment[]; pagination: Pagination }>,
    ) => {
      state.comments.data = [...action.payload.data];
      state.comments.pagination = { ...action.payload.pagination };
      state.comments.loading = false;
    },
    fetchCommentsFailure: (state, action: PayloadAction<Error>) => {
      state.comments.loading = false;
      state.error = action.payload;
    },

    // Replies
    fetchRepliesStart: (state, action: PayloadAction<{ commentId: string }>) => {
      const { commentId } = action.payload;
      if (!state.replies[commentId]) {
        state.replies[commentId] = {
          data: [],
          pagination: {
            pageNumber: 1,
            hasMore: false,
            loading: true,
          },
        };
      } else {
        state.replies[commentId].pagination.loading = true;
      }
      state.replyLoading = true;
    },
    fetchRepliesSuccess: (
      state,
      action: PayloadAction<{
        commentId: string;
        data: { data: Reply[]; pagination: Pagination };
      }>,
    ) => {
      const { commentId, data } = action.payload;
      if (state.replies[commentId]) {
        if (state.replies[commentId].pagination.pageNumber === data?.pagination?.pageNumber) {
          state.replies[commentId].data = [...data.data];
        } else {
          const existingIds = new Set(state.replies[commentId].data.map((reply) => reply.id));
          const newReplies = data.data.filter((reply) => !existingIds.has(reply.id));
          state.replies[commentId].data = [...state.replies[commentId].data, ...newReplies];
        }
        state.replies[commentId].pagination = {
          pageNumber: data?.pagination?.pageNumber,
          hasMore: data?.pagination?.totalPages > data?.pagination?.pageNumber,
          loading: false,
        };
      } else {
        state.replies[commentId] = {
          data: data.data,
          pagination: {
            pageNumber: data?.pagination?.pageNumber,
            hasMore: data?.pagination?.totalPages > data?.pagination?.pageNumber,
            loading: false,
          },
        };
      }
      state.replyLoading = false;
    },
    fetchRepliesFailure: (state, action: PayloadAction<{ commentId: string; error: Error }>) => {
      const { commentId, error } = action.payload;
      state.replyLoading = false;
      if (state.replies[commentId]) {
        state.replies[commentId].pagination.loading = false;
      }
      state.error = error;
    },

    // Add Comment
    addCommentStart: (state) => {
      state.commentLoading = true;
    },
    addCommentSuccess: (state, action: PayloadAction<Comment>) => {
      state.comments.data = [action.payload];
      state.comments.pagination.total += 1;
      state.commentLoading = false;
    },
    addCommentFailure: (state, action: PayloadAction<Error>) => {
      state.commentLoading = false;
      state.error = action.payload;
    },

    // Add Reply
    addReplyStart: (state) => {
      state.replyLoading = true;
    },
    addReplySuccess: (state) => {
      state.replyLoading = false;
    },
    addReplyFailure: (state, action: PayloadAction<Error>) => {
      state.replyLoading = false;
      state.error = action.payload;
    },

    // Delete Comment
    deleteCommentStart: (state) => {
      state.commentLoading = true;
    },
    deleteCommentSuccess: (state, action: PayloadAction<{ commentId: string; data: any }>) => {
      const { commentId } = action.payload;
      state.comments.data = state.comments.data.filter(
        (comment) => "id" in comment && comment.id !== commentId,
      );
      state.comments.pagination.total -= 1;
      state.commentLoading = false;
    },
    deleteCommentFailure: (state, action: PayloadAction<Error>) => {
      state.commentLoading = false;
      state.error = action.payload;
    },

    // Delete Reply
    deleteReplyStart: (state) => {
      state.replyLoading = true;
    },
    deleteReplySuccess: (
      state,
      action: PayloadAction<{ replyId: string; commentId: string; data: any }>,
    ) => {
      const { replyId, commentId } = action.payload;
      if (state.replies[commentId]) {
        state.replies[commentId].data = state.replies[commentId].data.filter(
          (reply) => reply.id !== replyId,
        );
      }

      state.comments.data = state.comments.data.map((comment) =>
        "id" in comment && comment.id === commentId
          ? { ...comment, replyCount: "replyCount" in comment ? comment.replyCount - 1 : 0 }
          : comment,
      );

      state.replyLoading = false;
    },
    deleteReplyFailure: (state, action: PayloadAction<Error>) => {
      state.replyLoading = false;
      state.error = action.payload;
    },
    clearReplies: (state, action: PayloadAction<{ commentId: string }>) => {
      const { commentId } = action.payload;
      if (state.replies[commentId]) {
        delete state.replies[commentId];
      }
    },
  },
});

export const {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  fetchRepliesStart,
  fetchRepliesSuccess,
  fetchRepliesFailure,
  addCommentStart,
  addCommentSuccess,
  addCommentFailure,
  addReplyStart,
  addReplySuccess,
  addReplyFailure,
  deleteCommentStart,
  deleteCommentSuccess,
  deleteCommentFailure,
  deleteReplyStart,
  deleteReplySuccess,
  deleteReplyFailure,
  clearReplies,
} = coursesSlice.actions;

export default coursesSlice.reducer;
