export const AppendixDisabilityPolicyJson = [
  {
    field: "shareholder",
    header: "Shareholder",
    formatter: (value: any) => `${value?.name}`,
  },
  { field: "expirationYears", header: "Premium Expiration" },
  { field: "benefitExpiration", header: "Benefit Expiration" },
  {
    field: "insuranceCompany",
    header: "Insurance Company",
    formatter: (value: any) => `${value?.name}`,
  },
  { field: "insuranceType", header: "Insurance Type" },
  {
    field: "policyNumber",
    header: "Policy Type",
    formatter: (_: any, __: any, rowData: any) =>
      `${rowData?.insuranceCompany?.productType} - ${rowData?.insuranceProduct?.name}`,
  },
  {
    field: "lumpSumBenefit",
    header: "Lump Sum Benefit",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "monthlyBenefit",
    header: "Monthly Benefit",
    formatter: (value: any) =>
      value
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value)
        : "-",
  },
  { field: "duration", header: "Duration (Months)", formatter: (value: any) => `${value}` },
  {
    field: "totalDIBenefit",
    header: "Total DI Benefit",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "annualPremium",
    header: "Annual Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];
