import { Card } from "primereact/card";
import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import BrandingIconButton from "app/uicomponents/macrocomponents/InteractiveProposal/BrandingIconButton";
import { getSessionStore } from "app/utils/sessionStore";
import { capitalizeNames, getThemeColors } from "app/utils/utils";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Divider } from "primereact/divider";
import { MutableRefObject, useRef } from "react";
import { makeRequest } from "app/utils/makeRequest";
import { CurrentProposalType, PROPOSAL_STATUSES, TOASTTYPE, USERTYPE } from "app/utils/types";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { Toast } from "primereact/toast";
import ScreenLoader from "app/uicomponents/microcomponents/ScreenLoader";
import UploadSection from "./UploadSection";
import { useComments } from "app/uicomponents/macrocomponents/Comments/CommentContext";
import { useSelector } from "react-redux";
import { getIsAgencyManagerActiveData } from "app/redux/features/isAgencyActive";
import { useTranslation } from "react-i18next";
import CommonHookForAPI from "app/HOC/CommonHookForAPI";
import { useIPContext } from "../../InteractiveProposalProvider";

const InteractiveProposalSignature = () => {
  const {
    selectedVersion,
    isPrimaryAdvisor,
    signatureDetails,
    fetchApprovalDetails,
    fetchProposalSummary,
    signatureLoading: loading,
    proposalStatus,
    isPrimaryContactUser,
    isAgencyManager,
    isSubAgencyManager,
  } = useIPContext();
  const { t } = useTranslation();
  const planInfo = getSessionStore("planInfo");
  const currentProposalType = getSessionStore("currentProposalType");
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const proposalId = selectedVersion?.id ?? getSessionStore("proposalID");
  const toast: MutableRefObject<any> = useRef(null);
  const { openComments, getComments, setIsCurrPageLoading } = useComments();
  const userAccess = getObjectFromLocalStore("useraccess");
  const { isAgencyManagerActive } = useSelector(getIsAgencyManagerActiveData);
  const changesRequested = proposalStatus === PROPOSAL_STATUSES.FEEDBACK;

  const isContactUser = userAccess?.userType === USERTYPE.CONTACT;
  const isAdvisorUser = userAccess?.userType === USERTYPE.AGENT;
  const isPrimaryAdvisorOrAgencyManager = () =>
    isPrimaryAdvisor || isAgencyManager || isSubAgencyManager;

  CommonHookForAPI(() => {
    setIsCurrPageLoading(true);
    fetchApprovalDetails()
      .then(() => {
        setIsCurrPageLoading(false, true);
      })
      .catch(() => {
        setIsCurrPageLoading(false, true);
      });
  });
  const isPlanOrInactive = [
    PROPOSAL_STATUSES.PLAN_CREATED,
    PROPOSAL_STATUSES.REVOKED,
    PROPOSAL_STATUSES.IN_ACTIVE,
  ].includes(proposalStatus as any);

  const contactInfo = signatureDetails?.contact?.profile;
  const advisorInfo = signatureDetails?.contact?.contactAdvisors?.find(
    (advisor: any) => advisor?.agentType === "primary",
  )?.agent?.profile;

  const handleSeenComment = async () => {
    if (openComments?.length > 0) {
      makeRequest("proposalRequestCommentChanges")
        .post(
          [],
          currentProposalType === CurrentProposalType.PLANS
            ? selectedVersion?.id ?? planInfo?.planProposalId
            : proposalId,
          true,
        )
        .then(() => {
          toast?.current?.show({
            severity: TOASTTYPE.SUCCESS,
            summary: t("agentBranding.label.success"),
            detail: "Requested changes has been added to proposal",
            life: 3000,
          });

          getComments();
        })
        .catch(() => {
          toast?.current?.show({
            severity: TOASTTYPE.ERROR,
            summary: t("agentBranding.label.error"),
            detail: "Error in requesting changes",
            life: 3000,
          });
        })
        .finally(() => {
          fetchApprovalDetails();
          fetchProposalSummary();
        });
    }
  };
  const approvalAdress: any = {
    [USERTYPE.AGENT]: "interactiveProposalAdvisorProposalApproval",
    [USERTYPE.CONTACT]: "interactiveProposalContactProposalApproval",
    [USERTYPE.ADMIN]: "interactiveProposalAdminProposalApproval",
  };
  async function approveSignatureProposal() {
    try {
      const response = await makeRequest(approvalAdress[userAccess?.userType]).patch(
        undefined, // No body needed
        currentProposalType === CurrentProposalType.PLANS
          ? selectedVersion?.id ?? planInfo?.planProposalId
          : proposalId,
        true, // Indicates that `proposalId` is a request parameter
      );

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message,
      });
    } catch (error: any) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
      });
    } finally {
      setTimeout(() => {
        fetchApprovalDetails();
        fetchProposalSummary();
      }, 3000);
    }
  }
  const isUploadBtnDisabled = () => {
    if (userAccess?.userType === USERTYPE.AGENT) {
      return signatureDetails?.advisorApprovalStatus;
    }
    if (userAccess?.userType === USERTYPE.CONTACT) {
      return signatureDetails?.contactApprovalStatus;
    }
    return false;
  };

  const isUploadButtonDisabled = () => {
    if (changesRequested || isUploadBtnDisabled()) {
      return true;
    }
    if (isPlanOrInactive) {
      return true;
    }
    if (
      (isContactUser || isAdvisorUser) &&
      (isPrimaryContactUser || isPrimaryAdvisor || isAgencyManager || isSubAgencyManager)
    ) {
      if (
        (signatureDetails?.status === "received" || signatureDetails?.status === "signed") &&
        !signatureDetails?.signedProposalFileKey
      ) {
        return false;
      }
    }
    if (signatureDetails?.signedProposalFileKey && signatureDetails?.status === "uploaded") {
      return true;
    }
    if (isContactUser) {
      if (!isPrimaryContactUser) {
        return true;
      }
      if (signatureDetails?.signedProposalRejected) {
        return true;
      }

      if (
        !isPrimaryContactUser ||
        (signatureDetails?.signedProposalFileKey && signatureDetails?.status === "uploaded")
      ) {
        return true;
      }
    }
    if (isAdvisorUser) {
      if (!isPrimaryAdvisor) {
        return true;
      }
      if (signatureDetails?.signedProposalRejected) {
        return true;
      }
      if (!isPrimaryAdvisor && !isAgencyManagerActive) {
        return true;
      }

      if (!isPrimaryAdvisor && !(isAgencyManager || isSubAgencyManager)) {
        return true;
      }
    }
    return false;
  };
  const isContactUploadedProposal =
    signatureDetails?.signedProposalUploadedBy === signatureDetails?.contact?.id;

  const dateFormate = (date: any) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

  const proposalUploadedDate = signatureDetails?.signedProposalUploadedDate || null;
  const displayProposalUploadedDate = () => {
    if (proposalUploadedDate) {
      return dateFormate(proposalUploadedDate);
    }
    return null;
  };
  const displayContactApprovedDate = () => {
    if (signatureDetails?.contactApprovalDate) {
      return dateFormate(signatureDetails?.contactApprovalDate);
    }
    return null;
  };
  const displayAdvisorApprovedDate = () => {
    if (signatureDetails?.advisorApprovalDate) {
      return dateFormate(signatureDetails?.advisorApprovalDate);
    }
    return null;
  };
  return (
    <CommentLayout
      commentLayoutId="interactive-proposal-signature"
      className="border border-1 border-gray-300 rounded-2xl w-full min-h-screen shadow-lg "
    >
      <Toast ref={toast} position="top-center" />
      {loading && <ScreenLoader />}
      <UploadSection
        fetchApprovalDetails={fetchApprovalDetails}
        signatureDetails={signatureDetails}
        isUploadButtonDisabled={isUploadButtonDisabled()}
        isOpenComments={openComments?.length > 0}
        isAdmin={userAccess?.userType === USERTYPE.ADMIN}
        // eslint-disable-next-line react/jsx-no-bind
        onApprove={approveSignatureProposal}
        isProposalInactive={isPlanOrInactive}
      />

      <Card className="p-3 shadow-none">
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-center w-2 shrink-0">
            <div className="text-lg font-medium text-center">
              {contactInfo && capitalizeNames(`${contactInfo?.firstName} ${contactInfo?.lastName}`)}
            </div>
            <Divider className="custom-divider" />

            <div className="text-gray-500">Client Name</div>
          </div>
          <div className="flex flex-col items-center">
            {!(isContactUser && (openComments?.length > 0 || changesRequested)) && (
              <>
                {(openComments?.length > 0 || changesRequested) && !isContactUser ? (
                  <BrandingIconButton
                    label={
                      signatureDetails?.status === "feedback"
                        ? "Changes Requested"
                        : "Request Changes"
                    }
                    disabled={
                      isContactUser
                        ? true
                        : isPlanOrInactive
                        ? true
                        : !isContactUser
                        ? true
                        : signatureDetails?.status === "feedback"
                    }
                    className=" rounded-3xl flex items-center px-3 cursor-pointer"
                    style={{ border: `1px solid ${primaryColor}` }}
                    onClick={handleSeenComment}
                    icon={() => (
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4"
                      >
                        <path
                          d="M7.83366 1.1665L10.8337 3.99984M10.8337 3.99984L7.83366 6.83317M10.8337 3.99984H3.83366C2.3609 3.99984 1.16699 5.19374 1.16699 6.6665V10.8332"
                          stroke={primaryColor}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  />
                ) : (
                  <BrandingIconButton
                    label={
                      !signatureDetails?.signedProposalRejected &&
                      (signatureDetails?.contactApprovalStatus ||
                        signatureDetails?.signedProposalFileKey)
                        ? "Approved"
                        : "Approve"
                    }
                    disabled={
                      signatureDetails?.signedProposalRejected &&
                      signatureDetails?.signedProposalFileKey
                        ? true
                        : !signatureDetails?.signedProposalRejected &&
                          (signatureDetails?.contactApprovalStatus ||
                            signatureDetails?.signedProposalFileKey)
                        ? true
                        : isPlanOrInactive
                        ? true
                        : !isContactUser
                        ? true
                        : !isPrimaryContactUser
                    }
                    icon={() => (
                      <FaRegCircleCheck className="h-7" style={{ color: primaryColor }} />
                    )}
                    onClick={() => {
                      signatureDetails?.contactApprovalStatus
                        ? undefined
                        : approveSignatureProposal();
                    }}
                  />
                )}
              </>
            )}
            <Divider className="custom-divider" />
            <div className="text-gray-500 mt-2">Client Signature</div>
          </div>

          <div className="flex flex-col items-center w-2 shrink-0">
            <div className="text-lg font-medium text-center">
              {signatureDetails?.signedProposalRejected
                ? "-"
                : signatureDetails?.contactApprovalDate
                ? displayContactApprovedDate()
                : signatureDetails?.status === PROPOSAL_STATUSES.UPLOADED &&
                  !isContactUploadedProposal
                ? displayProposalUploadedDate()
                : displayContactApprovedDate() || "-"}
            </div>
            <Divider className="custom-divider" />

            <div className="text-gray-500 w-2 shrink-0">Date</div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-6 ">
          <div className="flex flex-col items-center w-2 shrink-0">
            <div className="text-lg font-medium text-center">
              {advisorInfo && capitalizeNames(`${advisorInfo?.firstName} ${advisorInfo?.lastName}`)}
            </div>
            <Divider className="custom-divider" />
            <div className="text-gray-500">Advisor Name</div>
          </div>
          <div className="flex flex-col items-center">
            {openComments?.length > 0 || changesRequested ? (
              <BrandingIconButton
                label={
                  signatureDetails?.status === "feedback" ? "Changes Requested" : "Request Changes"
                }
                disabled={
                  isPlanOrInactive
                    ? true
                    : !isPrimaryAdvisorOrAgencyManager()
                    ? true
                    : signatureDetails?.status === "feedback"
                }
                className=" rounded-3xl flex items-center px-3 cursor-pointer"
                style={{ border: `1px solid ${primaryColor}` }}
                onClick={handleSeenComment}
                icon={() => (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4"
                  >
                    <path
                      d="M7.83366 1.1665L10.8337 3.99984M10.8337 3.99984L7.83366 6.83317M10.8337 3.99984H3.83366C2.3609 3.99984 1.16699 5.19374 1.16699 6.6665V10.8332"
                      stroke={primaryColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              />
            ) : (
              <p className="flex items-center gap-2">
                <BrandingIconButton
                  label={
                    !signatureDetails?.signedProposalRejected &&
                    (signatureDetails?.advisorApprovalStatus ||
                      signatureDetails?.signedProposalFileKey)
                      ? "Approved"
                      : "Approve"
                  }
                  disabled={
                    signatureDetails?.signedProposalRejected &&
                    signatureDetails?.signedProposalFileKey
                      ? true
                      : !signatureDetails?.signedProposalRejected &&
                        (signatureDetails?.advisorApprovalStatus ||
                          signatureDetails?.signedProposalFileKey)
                      ? true
                      : isPlanOrInactive
                      ? true
                      : !isPrimaryAdvisorOrAgencyManager()
                  }
                  icon={() => <FaRegCircleCheck className="h-7" style={{ color: primaryColor }} />}
                  onClick={() => {
                    signatureDetails?.advisorApprovalStatus
                      ? undefined
                      : approveSignatureProposal();
                  }}
                />
              </p>
            )}
            <Divider className="custom-divider" />

            <div className="text-gray-500 mt-2">Advisor Signature</div>
          </div>
          <div className="flex flex-col items-center w-2 shrink-0">
            <div className="text-lg font-medium text-center">
              {signatureDetails?.signedProposalRejected
                ? "-"
                : displayAdvisorApprovedDate() || displayProposalUploadedDate() || "-"}
            </div>
            <Divider className="custom-divider" />

            <div className="text-gray-500 ">Date</div>
          </div>
        </div>
      </Card>
    </CommentLayout>
  );
};

export default InteractiveProposalSignature;
