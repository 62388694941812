import React from "react";

interface IAPPLogoIconProps {
  fill?: string;
}

const IAPPLogoIcon: React.FC<IAPPLogoIconProps> = ({ fill = "#ffffff" }) => (
  <svg
    id={`Layer_2_${fill}`}
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 155.46 162.56"
  >
    <defs>
      <style>{`.uuid-3f4b1a6e-2b7d-45de-b3ed-8d8cd2f7c803-${fill} { fill: ${fill}; }`}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className={`uuid-3f4b1a6e-2b7d-45de-b3ed-8d8cd2f7c803-${fill}`}
        d="M52.23,113.25l-5.45-9.44H16.9l-8.61-14.91h0S0,74.54,0,74.54l8.29-14.36h0s8.61-14.92,8.61-14.92h29.85l5.48-9.49h0s6.22-10.78,6.22-10.78l-9.65-16.71h-12.64V0h17.4l12.04,20.86h24.26L101.9,0h17.4V8.28h-12.64l-9.65,16.71,6.22,10.77h0s5.48,9.5,5.48,9.5h29.86l8.61,14.91h0s8.29,14.37,8.29,14.37l-8.29,14.36h0s-8.61,14.92-8.61,14.92h-29.89l-5.45,9.44h0s-8.61,14.92-8.61,14.92H60.84l-8.61-14.91h0Zm16.23,31.33h18.54l-9.27,17.96-9.27-17.96h0Zm24.68-11.89l-3.95,7.65h-22.92l-3.95-7.65h30.82Zm-35.13-58.19l-5.78-10.01h0s-6.32-10.95-6.32-10.95H21.66l-6.06,10.49-6.06,10.5,6.07,10.5,6.06,10.5h24.22l6.35-10.99h0s5.78-10.03,5.78-10.03h0Zm1.28-14.33l5.88,10.19h25.09l5.91-10.19h0s5.81-10.07,5.81-10.07l-5.03-8.7-7.09-12.28h-24.26l-6.06,10.49-6.05,10.48,5.81,10.06h0Zm43.94,24.37l6.35,10.99h24.22l6.06-10.49,6.06-10.5-6.07-10.5-6.06-10.5h-24.26l-6.31,10.94h0s-5.78,10.03-5.78,10.03l5.78,10.02h0Zm-49.73,14.41l6.04,10.45,6.06,10.5h24.26l6.06-10.49,6.04-10.46-5.79-10.03h0s-5.91-10.25-5.91-10.25h-25.07l-.13,.24-5.77,10h0s-5.79,10.04-5.79,10.04Z"
      />
    </g>
  </svg>
);

export default IAPPLogoIcon;
