import { ProjectionSDFilters } from "../utils/types";

export const projectionSDColumnJson: any = (selectedProjectionData: any) => [
  {
    field: "policyYear",
    header: "Policy Year",
  },
  {
    field: "insuredAge",
    header: "Insured Age",
  },
  {
    field: "estimatedLoanPayoffOrIncome",
    header: "Estimated Loan Payoff/Income",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "illustratedPlanAccumulatedValue",
    header: "Illustrated Plan Accumulated Value",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "illustratedPlanSurrenderValue",
    header: "Illustrated Plan Surrender Value",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionSDFilters.CASH_VALUE) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "illustratedPlanDB",
    header: "Illustrated Plan DB",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionSDFilters.DEATH_BENEFIT) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "planLoanRate",
    header: "Plan Loan Rate",
    formatter: (value: any) => `${value}%`,
  },
  {
    field: "minimumAFR",
    header: "Minimum AFR",
    formatter: (value: any) => `${value}%`,
  },
  {
    field: "planInterest",
    header: "Plan Interest",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "minimumAFRInterest",
    header: "Minimum AFR Interest",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "imputedCompensationToEmployee",
    header: "Imputed Compensation to Employee",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "eoyOutstandingLoanBalance",
    header: "EOY Outstanding Loan Balance",
    style: {
      opacity: !selectedProjectionData?.includes(ProjectionSDFilters.LOAN) ? 0.5 : 1,
    },
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];

export const headerColumnGroupSDProjection = {
  "Plan Projections": [
    "estimatedLoanPayoffOrIncome",
    "illustratedPlanAccumulatedValue",
    "illustratedPlanSurrenderValue",
    "illustratedPlanDB",
  ],
  "Interest %": ["planLoanRate", "minimumAFR"],
  "Interest $": ["planInterest", "minimumAFRInterest"],
  "Imputed Compensation": ["imputedCompensationToEmployee"],
  "Outstanding Balance": ["eoyOutstandingLoanBalance"],
};
