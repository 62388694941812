import IPPageNumber from "./IPPageNuber";
import { getSessionStore } from "app/utils/sessionStore";
import { ProposalTypes } from "app/utils/types";
import { getThemeColors } from "app/utils/utils";
import SummaryBGImg from "../images/summaryBg.svg";

const HexagonItem = ({ item, index }: { item: any; index?: number }) => {
  const number = item?.number?.toString();
  const suffixes = ["K", "M", "B", "T"];
  const endsWithSuffix = suffixes?.find((suffix) => number?.includes(suffix));

  // If it ends with a suffix, split the string into the number and the suffix
  const mainText = endsWithSuffix ? number.slice(0, -1) : number;
  const lastChar = endsWithSuffix ? number[number.length - 1] : "";
  const isSecondItem = index ? index * 13 : 0;

  return (
    <div
      className="absolute flex items-center"
      style={{
        top: `${item.top - isSecondItem}px`,
        left: `${item.left}px`,
      }}
    >
      <div className="relative">
        <svg
          width="130"
          height="130"
          viewBox="0 0 485.688 485.688"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M364.269,453.155H121.416L0,242.844L121.416,32.533h242.853l121.419,210.312L364.269,453.155z"
              fill={item.color}
            />
          </g>
        </svg>
        <div
          className="absolute inset-0 flex flex-col items-center justify-center text-white conthrax-font"
          style={{
            fontSize: endsWithSuffix ? "20px" : "26px",
          }}
        >
          <div
            className={`${endsWithSuffix ? "mt-3" : ""}`}
            style={{ lineHeight: endsWithSuffix ? "0.5" : "" }}
          >
            {mainText}
          </div>
          {endsWithSuffix && <div>{lastChar}</div>}
        </div>
      </div>

      {/* Right connecting line */}
      <div
        className="h-[2px]"
        style={{
          width: item.left === 200 ? "125px" : "35px",
          background: "linear-gradient(to right, #666666 0%, rgba(102, 102, 102, 0) 100%)",
          marginLeft: "-1px",
        }}
      />

      {/* Dot */}
      <div className="rounded-full bg-[#666666]" style={{ width: "7px", height: "7px" }} />

      {/* Label */}
      <div style={{ color: "#495057" }}>
        <div className="text-base font-medium whitespace-nowrap ml-2.5">{item.label}</div>
        {item.label2 && (
          <div className="text-base font-medium whitespace-nowrap ml-2.5">{item.label2}</div>
        )}
      </div>
    </div>
  );
};

const IPSummary = ({ summary, pageNumber }: { summary: any; pageNumber: string | number }) => {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor, secondaryColor } = getThemeColors(branding);
  const isSP = summary.proposalType === ProposalTypes.SplitDollar;

  const items = [
    {
      number: (() => {
        switch (summary.proposalType) {
          case ProposalTypes.KeyPersonInsurance:
            return summary?.employeesCount;
          case ProposalTypes.BuySell:
            return summary?.shareholdersCount;
          default:
            return 1;
        }
      })(),
      label: (() => {
        switch (summary.proposalType) {
          case ProposalTypes.KeyPersonInsurance:
            return "Key Employees";
          case ProposalTypes.BuySell:
            return "Shareholders";
          default:
            return "Policies";
        }
      })(),
      left: 200,
      top: 80,
      color: primaryColor,
    },
    {
      number: isSP ? summary?.totalLoanedPremium : summary?.lifePoliciesCount,
      label: isSP ? "Total Loaned" : "Life Policies",
      label2: isSP ? "Premiums" : "",
      left: 290,
      top: 180,
      color: primaryColor,
    },
    {
      number: isSP ? summary?.deathBenefitYear1 : summary?.disabilityPoliciesCount,
      label: isSP ? "Death Benefit" : "Disability Buy",
      label2: isSP ? "YEAR 1" : "Out Policies",
      left: 200,
      top: 280,
      color: secondaryColor,
    },
    {
      number: isSP ? summary?.projectedAnnualIncome : summary?.deathBenefitYear1,
      label: isSP ? "Projected Annual" : "Death Benefit",
      label2: isSP ? "Income" : "YEAR 1",
      left: 290,
      top: 380,
      color: secondaryColor,
    },
    {
      number: isSP ? summary?.beginningYear : summary?.disabilityBenefitYear1,
      label: isSP ? "Beginning Year" : "Disability Benefit",
      label2: isSP ? "" : "YEAR 1",
      left: 200,
      top: 480,
      color: secondaryColor,
    },
    {
      number: isSP ? summary?.totalIncomeReceivedAfter45Years : summary?.estimatedValuationYear1,
      label: (() => {
        switch (summary.proposalType) {
          case ProposalTypes.KeyPersonInsurance:
            return "Estimated Value";
          case ProposalTypes.BuySell:
            return "Estimated Valuation";
          default:
            return "Total Income";
        }
      })(),
      label2: isSP ? "Received" : "YEAR 1",
      left: 290,
      top: 580,
      color: primaryColor,
    },
    {
      number: isSP ? summary?.netDBReceivedAfter45Years : summary?.estimatedValuationYear10,
      label: (() => {
        switch (summary.proposalType) {
          case ProposalTypes.KeyPersonInsurance:
            return "Estimated Value";
          case ProposalTypes.BuySell:
            return "Estimated Valuation";
          default:
            return "Death Benefit";
        }
      })(),
      label2: isSP ? "Received" : "YEAR 10",
      left: 200,
      top: 680,
      color: primaryColor,
    },
    ...(summary.proposalType === ProposalTypes.SplitDollar
      ? [
          {
            number: summary?.totalAfter45Years,
            label: "Total",
            label2: "",
            left: 290,
            top: 780,
            color: primaryColor,
          },
        ]
      : []),
  ];

  const mainContent = () => {
    if (isSP) {
      return (
        <div className="relative">
          {/* Plan Stats Connection with gap for text and rounded curves */}
          <div className="absolute left-[85px] top-[316.7px]">
            <svg className="z-0 overflow-visible" viewBox="-50 -3 200 208" height="179" width="200">
              <g>
                <path
                  d="M 180 0 L 0 0 C 0 0 -20 0 -20 20 L -20 70 M -20 132 L -20 182 C -20 202 0 202 0 202 L 180 202"
                  stroke="black"
                  strokeWidth="2"
                  fill="none"
                />
              </g>
            </svg>
            <div
              className="absolute conthrax-font"
              style={{
                color: "#A6A6A6",
                fontSize: "13px",
                transform: "translateY(-50%)",
                left: "5px",
                top: "88px",
              }}
            >
              <div>Key Employee</div>
              <div>Benefits</div>
            </div>
          </div>

          {/* Valuation Connection with rounded corners and text between lines */}
          <div className="absolute left-[85px] top-[578.5px]">
            <svg className="z-0 overflow-visible" viewBox="-50 -3 250 208" height="178" width="250">
              <g>
                <path
                  d="M 180 0 L 0 0 C 0 0 -20 0 -20 20 L -20 70 M -20 132 L -20 182 C -20 202 0 202 0 202 L 180 202"
                  stroke="black"
                  strokeWidth="2"
                  fill="none"
                />
              </g>
            </svg>
            <div
              className="absolute conthrax-font"
              style={{
                color: "#A6A6A6",
                fontSize: "13px",
                transform: "translateY(-50%)",
                left: "5px",
                top: "90px",
              }}
            >
              <div>45 Years</div>
              <div>Later</div>
            </div>
          </div>

          {/* Hexagons */}
          {items?.map((item, index) => (
            <HexagonItem key={index} item={item} index={index} />
          ))}
        </div>
      );
    }
    return (
      <div className="relative">
        {/* Plan Stats Connection with gap for text and rounded curves */}
        <div className="absolute left-[85px] top-[243.5px]">
          <svg className="z-0 overflow-visible" viewBox="-50 65 215 289" height="302" width="215">
            <g>
              <path
                d="M 180 60 L 0 60 Q -20 60 -20 80 L -20 195 M -20 225 L -20 340 Q -20 360 0 360 L 85 360"
                stroke="black"
                strokeWidth="2"
                fill="none"
              />
            </g>
          </svg>
          <div
            className="absolute conthrax-font"
            style={{
              left: "-10px",
              top: "151px",
              color: "#A6A6A6",
              fontSize: "13px",
              transform: "translateY(-50%)",
            }}
          >
            Plan Stats
          </div>
        </div>

        {/* Valuation Connection with rounded corners and text between lines */}
        <div className="absolute left-[85px] top-[625px]">
          <svg className="z-0 overflow-visible" viewBox="-50 -20 250 140" height="140" width="250">
            <g>
              <path
                d={`M 180 0 L 0 0 Q -20 0 -20 20 L -20 ${
                  summary.proposalType === ProposalTypes.KeyPersonInsurance ? "30" : "37"
                } M -20 ${
                  summary.proposalType === ProposalTypes.KeyPersonInsurance ? "70" : "63"
                } L -20 80 Q -20 100 0 100 L 85 100`}
                stroke="black"
                strokeWidth="2"
                fill="none"
              />
            </g>
          </svg>
          {summary.proposalType === ProposalTypes.KeyPersonInsurance && (
            <div
              className="absolute conthrax-font"
              style={{
                left: "-10px",
                top: "62px",
                color: "#A6A6A6",
                fontSize: "13px",
                transform: "translateY(-50%)",
              }}
            >
              Key Employee
            </div>
          )}
          {summary.proposalType === ProposalTypes.KeyPersonInsurance && (
            <div
              className="absolute conthrax-font"
              style={{
                left: "6px",
                top: "80px",
                color: "#A6A6A6",
                fontSize: "13px",
                transform: "translateY(-50%)",
              }}
            >
              Value
            </div>
          )}
          {summary.proposalType === ProposalTypes.BuySell && (
            <div
              className="absolute conthrax-font"
              style={{
                left: "-10px",
                top: "70px",
                color: "#A6A6A6",
                fontSize: "13px",
                transform: "translateY(-50%)",
              }}
            >
              Valuation
            </div>
          )}
        </div>

        {/* Hexagons */}
        {items?.map((item, index) => (
          <HexagonItem key={index} item={item} />
        ))}
      </div>
    );
  };

  return (
    <div
      className="h-full"
      style={{
        backgroundImage: `url(${SummaryBGImg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col px-[1em] w-full max-w-[56em] mx-auto p-[2em] h-full">
        <div className="text-xl font-bold text-center interactive-proposal-text conthrax-font">
          Summary
        </div>
        <div style={{ marginLeft: "-60px", marginTop: "-70px" }}>{mainContent()}</div>
        <div className="mt-auto px-[4em]">
          <IPPageNumber number={pageNumber} />
        </div>
      </div>
    </div>
  );
};

export default IPSummary;
