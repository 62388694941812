export const SDAppendixPolicyJson = [
  {
    field: "policyYear",
    header: "Year",
  },
  {
    field: "insuredAge",
    header: "Insured Age",
  },
  {
    field: "totalPolicyPremium",
    header: "Total Policy Premium",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "planLoanRate",
    header: "Plan Loan Rate",
    formatter: (value: any) => `${value}%`,
  },
  {
    field: "minimumAFR",
    header: "Minimum AFR",
    formatter: (value: any) => `${value}%`,
  },
  {
    field: "endOfYearOutstandingLoanBalance",
    header: "End of Year Outstanding Loan Balance",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "estimatedLoanPayoffOrIncome",
    header: "Estimated Loan Payoff/Income",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "illustratedPlanAccumulatedValue",
    header: "Illustrated Plan Accumulated Value",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
  {
    field: "illustratedPlanSurrenderValue",
    header: "Illustrated Plan Surrender Value",
    formatter: (value: any) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value),
  },
];
