import { FC } from "react";

interface DeleteCommentProps {
  onCancel: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  message?: string;
}

const DeleteComment: FC<DeleteCommentProps> = ({
  onCancel,
  onDelete,
  isDeleting,
  message = "Delete this comment?",
}) => (
  <div
    className="w-full flex flex-col gap-3 px-4 py-3"
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <p className="text-white font-medium">{message}</p>
    <div className="flex justify-end gap-3 mt-2">
      <button
        type="button"
        className="text-white hover:text-gray-300 transition-colors"
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        className="bg-white px-3 py-1 rounded-full text-black !border-0 hover:!text-black font-medium hover:bg-gray-100 transition-colors disabled:opacity-50"
        onClick={onDelete}
        disabled={isDeleting}
      >
        {isDeleting ? "Deleting..." : "Delete"}
      </button>
    </div>
  </div>
);

export default DeleteComment;
