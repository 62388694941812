import CommentLayout from "app/uicomponents/macrocomponents/Comments/CommentLayout";
import { getObjectFromSessionStore, getSessionStore } from "app/utils/sessionStore";
import { Card } from "primereact/card";
import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import PropTypes from "prop-types";
import { getThemeColors } from "app/utils/utils";
import { ProposalTypes } from "app/utils/types";

interface InteractiveProposalSolutionInsightsProps {
  isDarkTheme: boolean;
}

const RightTriangle = () => (
  <svg width="25" height="25" viewBox="0 0 24 24" fill="#8A8AA3" xmlns="http://www.w3.org/2000/svg">
    <polygon points="6,4 20,12 6,20" />
  </svg>
);

const InteractiveProposalSolutionInsights: React.FC<InteractiveProposalSolutionInsightsProps> = ({
  isDarkTheme,
}) => {
  const [expanded, setExpanded] = useState<boolean[]>([true, true, true]);
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor } = getThemeColors(branding);
  const proposalType = getObjectFromSessionStore("proposalType") as ProposalTypes;

  const toggleSection = (index: number) => {
    setExpanded((prev) => prev.map((exp, i) => (i === index ? !exp : exp)));
  };

  const sections = [
    {
      title:
        proposalType === ProposalTypes.BuySell
          ? "Triggering Events"
          : proposalType === ProposalTypes.SplitDollar
          ? "Plan Agreement"
          : "Identify and Insure",
      content:
        proposalType === ProposalTypes.BuySell
          ? "Triggering events consist of departures (termination or retirement), divorce, disability, and death. When an event is triggered, the prevailing buy-sell agreement must be followed. For example, when a partner dies the buy-sell agreement may likely force the newly deceased partner to sell their respective share according to the valuation method and other provisions set forth in the buy-sell agreement."
          : proposalType === ProposalTypes.SplitDollar
          ? "A company enters into a written split dollar agreement with a key employee, which results in the purchase of a cash-value life insurance policy."
          : "The business selects a key employee whose loss would significantly impact operations and takes out a life or disability insurance policy on their behalf.",
    },
    {
      title:
        proposalType === ProposalTypes.BuySell
          ? "Valuation Method"
          : proposalType === ProposalTypes.SplitDollar
          ? "Plan Funding"
          : "Pay Premiums",
      content:
        proposalType === ProposalTypes.BuySell
          ? "The valuation method sets forth how the company will be valued. Common methods may include using a formula such as multiples of EBITDA, multiples of revenue, or agreed fair market value at the time of sale."
          : proposalType === ProposalTypes.SplitDollar
          ? "A loan from the company to the key employee provides the money needed to pay the premium on the policy. A portion of the cash value and death benefit equal to the loan is assigned to the company as collateral."
          : "The business pays regular premiums for the policy, maintaining coverage and ensuring the financial protection is in place.",
    },
    {
      title:
        proposalType === ProposalTypes.BuySell
          ? "Funding Strategy"
          : proposalType === ProposalTypes.SplitDollar
          ? "Plan Termination"
          : "Claim and Benefit",
      content:
        proposalType === ProposalTypes.BuySell
          ? "Insurance is a popular funding strategy because it can be neatly designed to meet two triggering events (death and disability), at a very reasonable price. Financing the risk with insurance allows cash and assets to be deployed to higher and better uses for the business, for example on capital investments, R&D, or other investments to grow the business."
          : proposalType === ProposalTypes.SplitDollar
          ? "At plan termination, the loan is paid off or forgiven and the collateral assignment is removed. This gives the employee sole access to the remaining policy benefits for supplemental retirement income and/or survivor benefits."
          : "In the event of the key person's death or disability, the business files a claim and receives a payout, which can be used to manage the financial impact, cover expenses, and stabilize the company during the transition.",
    },
  ];

  return (
    <CommentLayout
      commentLayoutId="interactive-proposal-solution-insights"
      className={`min-h-screen flex flex-col items-center p-4 ${isDarkTheme ? "dark-theme" : ""}`}
    >
      <div className="w-full max-w-6xl">
        <h3 className={`text-lg font-semibold ${isDarkTheme ? "text-white" : "text-gray-700"}`}>
          Overview
        </h3>
        <p className={`${isDarkTheme ? "text-[#A9A9BC]" : "text-gray-600"} mt-2`}>
          {proposalType === ProposalTypes.BuySell
            ? "A funded buy-sell agreement is critical to any multi-owner business. The buy-sell agreement can ensure company ownership continues in a manner that is in the best interests of the company and fair to the owners by spelling out what happens under different triggering events."
            : proposalType === ProposalTypes.SplitDollar
            ? "A split dollar loan plan can reward select key employees by helping them enhance their retirement income and provide survivor benefits for their family"
            : "Key Person insurance provides financial protection for a business by covering the loss of an essential employee, ensuring stability and continuity in the face of unexpected disruptions."}
        </p>
        <h4 className={`mt-4 font-semibold ${isDarkTheme ? "text-[#A9A9BC]" : "text-gray-700"}`}>
          {proposalType === ProposalTypes.BuySell
            ? "Here's how it works: A buy-sell agreement consists of three common elements:"
            : "Here's how it works:"}
        </h4>
      </div>

      <div className="w-full max-w-6xl mt-6">
        <div className="grid grid-cols-1 md:grid-cols-[1fr,auto,1fr,auto,1fr] items-start gap-4">
          {sections.map((section, index) => (
            <>
              <Card
                key={section.title}
                className={`shadow-md rounded-xl ${isDarkTheme ? "bg-[#3A3A50]" : "bg-[#f7f7f8]"}`}
              >
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => toggleSection(index)}
                >
                  <div
                    className={`w-1 h-2 p-2 py-3 flex items-center justify-center rounded-md shadow-sm
                    ${
                      expanded[index]
                        ? isDarkTheme
                          ? "bg-[#1E1E29] border border-gray-300"
                          : "bg-white border border-gray-300"
                        : isDarkTheme
                        ? "bg-[#1E1E29]"
                        : "bg-gray-200"
                    }`}
                  >
                    {expanded[index] ? (
                      <FaMinus className="text-gray-500 text-sm" />
                    ) : (
                      <FaPlus className="text-gray-600 text-sm" />
                    )}
                  </div>

                  <div className="flex items-center gap-2 ml-3">
                    <div
                      className="text-sm rounded-md text-white font-semibold px-2 py-1"
                      style={{ backgroundColor: primaryColor }}
                    >
                      {index + 1}
                    </div>
                    <h5
                      className={`font-semibold ${
                        isDarkTheme ? "text-[#f7f7f8]" : "text-gray-700"
                      }`}
                    >
                      {section.title}
                    </h5>
                  </div>
                </div>
                {expanded[index] && (
                  <div
                    className={`mt-3 p-4  rounded-md shadow ${
                      isDarkTheme ? "bg-[#1E1E29]" : "bg-white"
                    }`}
                  >
                    <p className={` ${isDarkTheme ? "text-[#f7f7f8]" : " text-gray-600"}`}>
                      {section.content}
                    </p>
                  </div>
                )}
              </Card>
              {index < sections.length - 1 && (
                <div className="hidden md:flex items-center justify-center self-center">
                  <RightTriangle />
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </CommentLayout>
  );
};

InteractiveProposalSolutionInsights.propTypes = {
  isDarkTheme: PropTypes.bool.isRequired,
};

export default InteractiveProposalSolutionInsights;
