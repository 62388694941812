import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./table.css";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";

interface ColumnDefinition {
  field: string; // Field name in the data
  header: string; // Header text for the column
  formatter?: (value: any, options: any, rowData?: any) => string; // Optional formatter function
  style?: React.CSSProperties; // Optional style for the column
}
interface InsuredTableProps {
  data: any[]; // Array of data objects
  columns: ColumnDefinition[]; // Array of column definitions
  hoverColor: string; // Dynamic hover color
  columnGroup?: React.ReactNode;
  scrollable?: boolean;
  scrollHeight?: string;
}
const InsuredTable: React.FC<InsuredTableProps> = ({
  data,
  columns,
  hoverColor,
  columnGroup,
  scrollable,
  scrollHeight,
}) => {
  const headerColumnGroup = columnGroup ? (
    <ColumnGroup>
      {columnGroup}
      <Row>
        {columns?.map((col) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{ ...col.style }}
            // headerStyle={{
            //   borderRightWidth: 1,
            //   borderRightColor: "var(--surface-100)",
            // }}
            align="center"
          />
        ))}
      </Row>
    </ColumnGroup>
  ) : null;
  return (
    <div className="card   border-[2px] border-borderColor policy-review-table">
      <DataTable
        value={data}
        scrollable={scrollable}
        scrollHeight={scrollHeight}
        tableStyle={{ minWidth: "65rem" }}
        className="p-datatable-striped insured-table "
        headerColumnGroup={headerColumnGroup}
        showGridlines
      >
        {columns.map((col) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{ ...col.style }}
            // headerStyle={{ borderRightWidth: 1, borderRightColor: "var(--surface-100)" }}
            align="center"
            body={(rowData, options) => {
              const value = rowData[col.field];
              const formattedValue = col.formatter ? col.formatter(value, options, rowData) : value;
              return <div className="p-2 insured-row">{formattedValue}</div>;
            }}
          />
        ))}
      </DataTable>
      <style>
        {`
     .insured-table .p-datatable-tbody > tr:hover {
        color: ${hoverColor};
        background-color: ${hoverColor}12!important;
      }
    `}
      </style>
    </div>
  );
};
export default InsuredTable;
