import {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  fetchRepliesStart,
  fetchRepliesSuccess,
  fetchRepliesFailure,
  addCommentStart,
  addCommentSuccess,
  addCommentFailure,
  addReplyStart,
  addReplySuccess,
  addReplyFailure,
  deleteCommentStart,
  deleteCommentSuccess,
  deleteCommentFailure,
  deleteReplyStart,
  deleteReplySuccess,
  deleteReplyFailure,
  FETCH_COURSE_COMMENTS,
  FETCH_COMMENT_REPLIES,
  ADD_COURSE_COMMENT,
  ADD_COMMENT_REPLY,
  DELETE_COURSE_COMMENT,
  DELETE_COMMENT_REPLY,
} from "app/redux/features/courses";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* fetchCourseComments(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { courseId, pageNumber = 1, pageSize = 10 },
    } = actions;

    yield put(fetchCommentsStart());

    const response = yield call(() =>
      makeRequest("courseComments").get(
        `${courseId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        true,
      ),
    );

    if (!response.data) {
      throw new Error("Invalid response format: missing data");
    }

    yield put(fetchCommentsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchCommentsFailure(error));
  }
}

function* fetchCommentReplies(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { commentId, pageNumber = 1, pageSize = 5 },
    } = actions;

    yield put(fetchRepliesStart({ commentId }));

    const response = yield call(() =>
      makeRequest("commentReplies").get(
        `${commentId}/replies?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        true,
      ),
    );

    if (!response.data) {
      throw new Error("Invalid response format: missing data");
    }

    yield put(fetchRepliesSuccess({ commentId, data: response.data }));
  } catch (error: any) {
    const { commentId } = actions.payload;
    yield put(fetchRepliesFailure({ commentId, error }));
  }
}

function* addCourseComment(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { text, courseId },
    } = actions;

    yield put(addCommentStart());

    const response = yield call(() =>
      makeRequest("commentThread").post(
        JSON.stringify({
          comment: text,
          courseId,
        }),
      ),
    );

    if (!response.data) {
      throw new Error("Invalid response format: missing data");
    }

    yield put(addCommentSuccess(response.data));

    // Fetch fresh comments after adding new one
    yield call(fetchCourseComments, {
      payload: {
        courseId,
        pageNumber: 1,
        pageSize: 10,
      },
    });
  } catch (error: any) {
    yield put(addCommentFailure(error));
  }
}

function* addCommentReply(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { text, commentId },
    } = actions;

    yield put(addReplyStart());

    const response = yield call(() =>
      makeRequest("commentReply").post(
        JSON.stringify({
          content: text,
          parentId: commentId,
        }),
      ),
    );

    if (!response.data) {
      throw new Error("Invalid response format: missing data");
    }

    yield put(addReplySuccess(response.data));

    // Fetch fresh replies immediately after adding new one
    yield call(fetchCommentReplies, {
      payload: {
        commentId,
        pageNumber: 1,
        pageSize: 5,
      },
    });
  } catch (error: any) {
    yield put(addReplyFailure(error));
  }
}

function* deleteCourseComment(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { commentId, courseId },
    } = actions;

    yield put(deleteCommentStart());

    const response = yield call(() => makeRequest("commentThread").delete(`${commentId}`, true));

    yield put(deleteCommentSuccess({ commentId, data: response.data || {} }));

    // Refetch comments to update the list
    if (courseId) {
      yield call(fetchCourseComments, { payload: { courseId, pageNumber: 1, pageSize: 10 } });
    }
  } catch (error: any) {
    yield put(deleteCommentFailure(error));
  }
}

function* deleteCommentReply(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { replyId, commentId },
    } = actions;

    yield put(deleteReplyStart());

    const response = yield call(() => makeRequest("commentReply").delete(`${replyId}`, true));

    yield put(deleteReplySuccess({ replyId, commentId, data: response.data || {} }));

    // Refetch replies to update the list
    if (commentId) {
      yield call(fetchCommentReplies, { payload: { commentId, pageNumber: 1, pageSize: 5 } });
    }
  } catch (error: any) {
    yield put(deleteReplyFailure(error));
  }
}

export default function* coursesSaga() {
  yield all([
    takeLatest(FETCH_COURSE_COMMENTS, fetchCourseComments),
    takeLatest(FETCH_COMMENT_REPLIES, fetchCommentReplies),
    takeLatest(ADD_COURSE_COMMENT, addCourseComment),
    takeLatest(ADD_COMMENT_REPLY, addCommentReply),
    takeLatest(DELETE_COURSE_COMMENT, deleteCourseComment),
    takeLatest(DELETE_COMMENT_REPLY, deleteCommentReply),
  ]);
}
